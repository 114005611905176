import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addAcccount, addFDatatoran, addRiotData, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, managEngineer, managTMOEngineer, rfApprovalActionChange } from '../../../../common/config';
import { addAcccountReducerAction, TierListReducerAction } from '../../../../store/actions/createTicketManageFields';
import { MultiSelect } from 'react-multi-select-component';
import Add from '../../../../icons/Svg/Add';
import Select from 'react-select'

// import './multiselect.css'
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const RiotModalBody = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();

  let [selectProjectCode, setProjectCode] = useState([{
    tech: "",
    passedS: [],
    failedS: []
  }])

  const bandoptions = [
    { 'label': 'L600', 'value': 'L600', sort:1 }, 
    { 'label': 'N600', 'value': 'N600', sort:2 }, 
    { 'label': 'L700', 'value': 'L700', sort:3 }, 
    { 'label': 'L1900', 'value': 'L1900',sort:4 }, 
    { 'label': 'L1900 - 2', 'value': 'L1900 - 2',sort:5}, 
    { 'label': 'N1900', 'value': 'N1900', sort:6 }, 
    { 'label': 'L2100', 'value': 'L2100', sort:7}, 
    { 'label': 'L2100 - 3', 'value': 'L2100 - 3', sort:8 }, 
    { 'label': 'L2500', 'value': 'L2500' , sort:9}, 
    { 'label': 'L2500_2', 'value': 'L2500_2' , sort:10}, 
    { 'label': 'L2500_3', 'value': 'L2500_3' , sort:11}, 
    { 'label': 'N2500', 'value': 'N2500' , sort:12}, 
    { 'label': 'NB-IoT', 'value': 'NB-IoT', sort:13 }, 
    { 'label': 'GSM', 'value': 'GSM', sort:14 }
  ]


  let [stbandoptions, setstbandoptions] = useState(bandoptions)
  

  
  let [Fsectors, setFsectors] = useState([[{
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }
  ]])

  let [Psectors, setPsectors] = useState([[{
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }]
  ])

  const [EngineerOpen, setEngineerOpen] = useState([]);
  const [siteFile, uploadedFile] = useState([]);



  console.log(props, "propspropspropsprops")

  if (props.type == "Edit") {
    // setValue("value",props.oldData.optionValue) 
  }

  let engineerArray = [];


  const projectData = useSelector((state) => {
    console.log("the State is=", state);




    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { engineerArray };
  });


  const findengineer = (id) => {
    setEngineerOpen(id)
  }

  const onSubmit = async (data) => {
    console.log("the data to submit is =", data)

    // data["dropDownId"]=props.parentuniqueId

    let final_data = []
    selectProjectCode.map((itt) => {
      let miss = itt.failedS.map(oneitt => {
        return oneitt.value
      })
      let findd = itt.passedS.map(oneitt => {
        return oneitt.value
      })
      let tech = itt.tech.label
      final_data.push({
        "tech": tech,
        "findd": findd.join(""),
        "miss": miss.join("")
      })
      console.log("the data to submit is =", final_data)

    })
    console.log(selectProjectCode, "data")

    let addThis = {
      "siteId": props.siteIdData,
      "parent_id": props.ranIdData,
      "riot_data": final_data
    }
    if(props.preRiot){
      addThis.preriot_data=addThis.riot_data
      delete addThis.riot_data
    }

    console.log("nbjkbe the site data is ==",props)
    
    let newform = new FormData();
    newform.append("addThis",JSON.stringify(addThis))

    
    for (let i = 0; i < siteFile.length; i++) {
      newform.append("all-files", siteFile[i]);
    }
    
    console.log(newform,"addThisaddThisaddThis")
    if(props.preRiot){
      newform.append("preRiot",1)
    }

    if(props.from=="Ran Dashboard"){
      dispatch(addAcccountReducerAction(addRiotData, newform))
    }else{
      dispatch(addAcccountReducerAction(addRiotData + "?ix=True", newform))
    }
    // if(props.type=="Add"){
    // dispatch(addAcccountReducerAction(addFDatatoran + "/" + props.unique_id_up, data))
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    // setValue("value",'')
  }

  
  

  let sectors = [
    {
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }
  ]

  
  

  const AddTech = () => {
    // setTechnologyError("");
    console.log("calltoAddTech")
    setProjectCode([...selectProjectCode, {
      tech: "",
      passedS: [],
      failedS: []
    }])

    setPsectors([...Psectors,[{
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }]])

    setFsectors([...Fsectors,[{
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }]])
    
  };
  const failedSector = (e, idx) => {
    // setTechnologyError("");

    console.log(selectProjectCode, selectProjectCode[idx], e, idx, "failedSector")
    let sec = [...selectProjectCode]
    let selected = e.map(oneit => {
      return oneit.label
    })
    let nwsec = []
    sectors.map((ittm) => {
      if (selected.indexOf(ittm.label) != -1) {
        nwsec.push({
          label: ittm.label,
          value: ittm.value
        })
      }
    })
    let PPsectors=[]
    sectors.map((ittm) => {
      if (selected.indexOf(ittm.label) == -1) {
        PPsectors.push({
          label: ittm.label,
          value: ittm.value
        })
      }
    })
    let newFFF=Psectors
    newFFF[idx]=PPsectors

    console.log(PPsectors,Psectors,sectors,Fsectors,"PPsectors,Psectors,sectors,Fsectors")

    setPsectors(newFFF)

    sec[idx]["failedS"] = nwsec
    setProjectCode(sec)
  };

  function customsorter(a,b){
    console.log(a,b,"customsorter")
    if(a.sort>b.sort){
      return 1
    }else{
      return -1
    }
  }

  const BandSector = (e, idx) => {
    // setTechnologyError("");
    // console.log(e,passedSector)
    let sec = [...selectProjectCode]
    console.log(e,stbandoptions,"BandSectorBandSector")
    // bandoptions.findIndex((itm))
    
    sec[idx]["tech"] = e
    setProjectCode(sec)
    let finalArr=[]
    stbandoptions.map((itm)=>{
      if(sec.findIndex((newitm)=>{return newitm.tech.label==itm.label})==-1){
        finalArr.push(itm)
      }
    })

    bandoptions.map((itm)=>{
      console.log(itm.value,finalArr.findIndex((newitm)=>{return itm.label==newitm.label})==-1,sec.findIndex((newitm)=>{return newitm.tech.label==itm.label})==-1,"itm____itm")

      if(finalArr.findIndex((newitm)=>{return itm.label==newitm.label})==-1 && sec.findIndex((newitm)=>{return newitm.tech.label==itm.label})==-1){
        finalArr.push(itm)
      }
    })

    finalArr.sort((a,b)=>{
      if(a.sort>b.sort){
        return 1
      }else{
        return -1
      }
    })
    console.log(finalArr,"finalArr")
    setstbandoptions(finalArr)
  };
  // const bandoptions = [{ 'label': 'LEA1', 'value': 'L600' }, { 'label': 'NEA1', 'value': 'N600' }, { 'label': 'LDA1', 'value': 'L700' }, { 'label': 'LPA1', 'value': 'L1900' }, { 'label': 'LPA2', 'value': 'L1900 - 2' }, { 'label': 'NPA1', 'value': 'N1900' }, { 'label': 'LAA1', 'value': 'L2100' }, { 'label': 'LFA1', 'value': 'L2100 - 3' }, { 'label': 'LKA1', 'value': 'L2500' }, { 'label': 'LKA2', 'value': 'L2500_2' }, { 'label': 'LKA3', 'value': 'L2500_3' }, { 'label': 'NKA', 'value': 'N2500' }, { 'label': 'TAA', 'value': 'NB-IoT' }]
  
  const passedSector = (e, idx) => {
    // setTechnologyError("");
    console.log(e,passedSector)
    let sec = [...selectProjectCode]
    let selected = e.map(oneit => {
      return oneit.label
    })
    let nwsec = []
    sectors.map((ittm) => {
      if (selected.indexOf(ittm.label) != -1) {
        nwsec.push({
          label: ittm.label,
          value: ittm.value
        })
      }
    })

    let FFsectors=[]
    sectors.map((ittm) => {
      if (selected.indexOf(ittm.label) == -1) {
        FFsectors.push({
          label: ittm.label,
          value: ittm.value
        })
      }
    })

    let newFFF=Fsectors
    newFFF[idx]=FFsectors
    console.log(FFsectors,newFFF,Psectors,sectors,Fsectors,"PPsectors,Psectors,sectors,Fsectors")

    setFsectors(newFFF)

    sec[idx]["passedS"] = nwsec
    setProjectCode(sec)
  };



  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    // uploadedFile(e.target.files[0])

    let files = []

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log(...files)
    uploadedFile(files)

  };
  useEffect(() => {
    // reset({})
    // setValue("value",'')
    dispatch(TierListReducerAction(managEngineer))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="inputEmail4">SiteId</label>
          <input style={{ height: "40px" }}
            type="text"
            className="form-control"
            id="inputEmail4"
            name='siteid'
            value={props.siteIdData}
            readOnly
          />
        </div>
        <div className="form-group col-md-8">
          <label htmlFor="inputEmail4" >Image File(.png, .jpg, .jpeg)</label>
          <div>
            <label class="custom-file-upload btn btn-outline-primary col-md-6">
              <input
                type="file"
                className="form-control btn btn-primary"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                name="file"
                multiple
                accept=".png, .jpg, .jpeg"
                onChange={uploadCsvFile}
              />
              <i class="fa fa-cloud-upload"></i> Choose File
            </label>

            {console.log(siteFile,"siteFilesiteFilesiteFilesiteFilesiteFile")}

            <label>{siteFile.length != 0 ? siteFile.length : "No"} File Selected</label>


          </div>
        </div>
      </div>

      {/* <div onClick={() => AddTech()}>
          <p className={'badge badge-primary fitContent'}><Add /></p>
        </div> */}
      {console.log(selectProjectCode,Psectors, "selectProjectCode")}
      {selectProjectCode.map((oneitm, index) => {
        { console.log(oneitm,Psectors[index], "selectProjectCodeoneitm") }
        { console.log(oneitm, "selectProjectCodeoneitm", oneitm.passedS, oneitm.failedS) }
        return <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Band</label>
            <Select
              className="text-dark"
              options={stbandoptions}
              onChange={(e) => {
                BandSector(e, index)
              }}
              value={oneitm.tech}
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Passed Sector</label>
            <MultiSelect
              className="text-dark expandable_multiselect"
              hasSelectAll={false}
              options={Psectors[index]}
              onChange={(e) => {
                passedSector(e, index)
              }}
              value={oneitm.passedS}
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Failed Sector</label>
            <MultiSelect
              className="text-dark"
              hasSelectAll={false}
              options={Fsectors[index]}
              onChange={(e) => {
                failedSector(e, index)
              }}
              value={oneitm.failedS}
            />
          </div>
          {console.log(selectProjectCode.length,"selectProjectCodeselectProjectCodeselectProjectCode")}
          {
            selectProjectCode.length - 1 == index ? <>
              <label htmlFor="inputEmail4">Add</label>
              <div onClick={() => AddTech()}>
                <p className={'badge badge-primary fitContent'}><Add /></p>
              </div></> : ""
          }


        </div>
      })}



      <button type="submit" className="btn btn-primary btn-xs mt-3">
        Submit
      </button>
    </form>
  )
}

export default RiotModalBody