
import React, { Component, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { AccountReducerAction, ChartStageWise, getBarChartData } from "../../../../store/actions/createTicketManageFields";
import { secondNest, secondStage, thirdNest } from "../../../../common/config";
import { Button } from "@mui/material";
import BackSign from "../../../../icons/Svg/BackSign";

const PunctualityRateStageThreeBarChart = ({ setCount,threecount,settwoCount, setThreeCount,setfivercount, noOfBarChart, filter }) => {


  // alert(filter,"filter")

  const dispatch = useDispatch()
  let [customWidth, setCustomWidth] = useState("")
  let [typeChart, settypeChart] = useState("bar")

  let customDataArray = useRef()

  let [completeData, setCompleteData] = useState({})
  let nxtURL = useRef()
  let [getData, setData] = useState({
    options: {
      chart: {
        // chart: {
        zoom: {
          enabled: true,
          type: 'x',
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
          },
          selection: {
            background: '#90CAF9',
            border: '#0D47A1'
          },
        },
        stacked: true,
        // },
        id: "basic-bar",
        events: {
          dataPointSelection: handleSliceClick,
        },
      },
      xaxis: {
        categories: [],
        scrollbar: {
          enabled: true,
        }
      },

      plotOptions: {
        bar: {
          vertical: true,
          columnWidth: '8px',

        }
      },
      stroke: {
        width: 1,
        colors: ["black"]
      },
      dataLabels: {

        position: 'top', // Place labels at the top of the bars
        style: {
          colors: ['black'], // Set the desired label text color
        },
      },
      title: {
        text: "",
        align: 'center',
        // margin: 2,
        offsetX: -50,
        offsetY: 30,
        style: {
          fontSize: '2rem',
          letterSpacing: 3,
          // fontWeight:  'bold',
          // fontFamily:  undefined,
          color: '#263238'
        },
      },
      tooltip: {
        // tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const clickedSliceData = noOfBarChart?.toolTip[dataPointIndex];

          return '<div class="custom-tooltip">' +
            '<span>' + clickedSliceData + '</span>' +
            '</div>';
        },
      },
    },
    series: [],
    theme: {
      mode: 'light',
    }

  })



  let [getpieData, setpieData] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  
  
  })
  let tempVar = {}
  console.log("thhjbkawefber", noOfBarChart)


  let actualData = useSelector((state) => {


    console.log("countering state", threecount)


    console.log("th jkebfugqvwefker", state)
    let barData = state.OneCommonResponse.barData
    console.log("fkmebhfvekr", barData)
    if (barData && barData.graphThreeData && threecount) {
      setCompleteData(barData)


      tempVar = barData
      customDataArray.current = barData.XaxisLabel
      nxtURL.current = barData.nextUrl
      console.log(";kiwjaghkjdbewrf=", barData.toolTip)


      if (barData.type) {
        settypeChart(barData.type)
      } else {
        settypeChart("bar")
      }




      
      // setpieData((prev) => {
      //   return {
      //     ...prev,
      //     series: barData.pieData.data,
      //     options: {
      //       chart: {
      //         width: 380,
      //         type: 'pie',
      //       },
      //       labels: barData.pieData.label,
      //       responsive: [{
      //         breakpoint: 480,
      //         options: {
      //           chart: {
      //             width: 200
      //           },
      //           legend: {
      //             position: 'bottom'
      //           }
      //         }
      //       }]
      //     }
      //   }
      // })

      setData((prev) => {
        return {
          ...prev,
          options: {
            ...prev.options,
            chart: {
              // chart: {
              type: barData.type ? barData.type : "bar",

              stacked: barData.stacked,
            },

            xaxis: {
              categories: barData.XaxisLabel,
              scrollbar: {
                enabled: true,
              }
            },
            yaxis: barData.yaxis ? barData.yaxis : [],
            colors: barData.colorCodeArray || [],
            plotOptions: {
              bar: {
                columnWidth: barData.width,
                // horizontal: barData.horizontal?barData.horizontal:false,
                horizontal: false,
                dataLabels: {
                  position: 'top', // Place labels at the top of the bars
                  style: {
                    // Set the desired label color
                  },
                },

              },

            },


            stroke: {
              width: barData.strokewidth ? barData.strokewidth : .5,
              curve: 'smooth',
              // colors: ["#78716c"]
            },

            markers: {
              size: 2,
            },

            color: barData.strokecolor ? barData.strokecolor : [],


            dataLabels: {

              enabled:false,
              position: 'top', // Place labels at the top of the bars
              style: {
                colors: ['#52525b'], // Set the desired label text color
              },
            },
            tooltip: {
              // tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                console.log(noOfBarChart, dataPointIndex, "noOfBarChart")
                // const clickedSliceData = noOfBarChart?.toolTip[dataPointIndex];

                return '<div class="custom-tooltip-new">' +
                  '<span>' + w.globals.initialSeries[seriesIndex].data[dataPointIndex] + '</span>' +
                  '</div>';
              },
            },
          },
          series: barData.graphThreeData.sort((a, b) => b?._id?.localeCompare(a._id)),
        }
      })
      setCustomWidth(barData.chartWidth)


      console.log("getDatagetDatagetData", getData)
      // if(barData.chartWidth && barData.chartWidth.includes("rem")){
      //   if(window.innerWidth-1000>parseInt(barData.chartWidth.substring(0,barData.chartWidth.length-3))){
      //     setCustomWidth("100%")
      //     console.log("cbhjwbdhjlhbdguweklff",(window.innerWidth-1000))
      //     console.log("cbhjwbdhjlhbdguweklff",parseInt(barData.chartWidth.substring(0,barData.chartWidth.length-3)))
      //   }
      //   else{
      //     setCustomWidth(barData.chartWidth)
      //   }
      // }
      // else{
      //   setCustomWidth(barData.chartWidth)
      // }
      // setCustomWidth(barData.chartWidth?barData.chartWidth:"100%")
      setThreeCount(false)
      return { tempVar, barData }
      console.log(";cewbfvgcekirvgfrgv=", getData)

    }

  })



  console.log("getDatagetDatagetData", getData)


  const backtowork = () => {

    dispatch(getBarChartData({}))
    setThreeCount(true)
    settwoCount(true)
    setCount(true)
    setfivercount(true)
    console.log("backtowork", completeData)
    dispatch(AccountReducerAction(completeData.backUrl + filter))
  };
  function handleSliceClick(event, chartContext, config) {

    // console.log("cjkabsgckvlkaesvhucfeiurk",config.w.config,config.dataPointIndex)
    // console.log("cjkabsgckvlkaesvhucfeiurk",customDataArray.current)


    if (nxtURL.current != "") {
      dispatch(getBarChartData({}))
      setThreeCount(true)
      settwoCount(true)
      setCount(true)
      setfivercount(true)

      console.log("filterfilterfilter", filter)

      const clickedSliceIndex = config.dataPointIndex;
      console.log("wfdefwffe", config)
      console.log("wfdefwffe", chartContext)
      console.log("wfdefwffe", event)
      let clickedSliceData = customDataArray.current[clickedSliceIndex];
      // if(clickCount.current<1){
      //   urlArray.push(clickedSliceData.trim()+"/")
      // }
      // if(clickCount.current==2){
      //   urlArray.push(clickedSliceData.trim()+"/")
      // }
      // console.log('Clicked slice index:',urlArray);
      // console.log('Custom data:', clickCount.current);
      // let sendUrl=secondNest
      // if(clickCount.current>1 && clickCount.current<4){
      //   sendUrl=thirdNest + urlArray[0]
      //   console.log("dqwdwe")
      //   // sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // if(clickCount.current>3 && clickCount.current<7){
      //   // sendUrl=thirdNest + urlArray[0]
      //   console.log("dqwdwe")  
      //   sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // if(6>clickCount.current>3){
      //   console.log("dklnwkjdbhlwebfhflwe")
      //   sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // else if(clickCount.current==2){
      //   sendUrl=thirdNest
      // }
      dispatch(AccountReducerAction(nxtURL.current + clickedSliceData + filter))
    }
    // clickCount.current +=1


  }



  // useEffect(()=>{
  //   dispatch(ChartStageWise(secondStage + "PunctualityRate","two"))
  // },[])


  console.log(actualData, "datadatadata")




  return (

    <>
    <div className="row">
     
      <div className="col-md-12">

        {console.log(completeData, 'completeData')}
        {completeData && completeData.backUrl && completeData.backUrl != "" ? <>
          <p className="text-black" style={{ width: "100%", cursor: "pointer" }} onClick={() => {
            backtowork()
          }}><BackSign /> BACK</p>
        </> : ""}


        {/* {completeData && completeData.backUrl && completeData.backUrl != "" ? <>
        <p className="text-black" style={{  width:"100%",cursor:"pointer"}} onClick={() => {
          backtowork()
        }}>BACK</p>
      </> : ""} */}


        {completeData && completeData.filterationthree ? <>
          <p className="text-black" style={{ width: "100%", cursor: "pointer" }}>
            {completeData.filterationthree}
          </p>
        </> : ""}

        <div style={{ width: "100%", overflowX: "scroll" }}>
          {console.log("kcnwelfkjnwenfd", window.innerWidth)}
          <center style={{ width: customWidth, overflowX: "scroll" }} className='stacked-chart shadow-lg p-3 mb-5 bg-white rounded "scrollable-navigation-chart-container"'>
            {/* width:data.graphTwoData.chartWidth?data.graphTwoData.chartWidth:"100%" */}
            <ReactApexChart
              options={getData.options}
              series={getData.series}
              type={typeChart}
              height={"400rem"}

            />



            {completeData && completeData.fakeLabel ? <>
              <p className="text-black" style={{ width: "100%", cursor: "pointer" }}>
                {completeData.fakeLabel}
              </p>
            </> : ""}
          </center>
        </div>
      </div>
      </div>
    </>

  );

}

export default PunctualityRateStageThreeBarChart;