import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { default as ReactSelect, components } from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { allTechnologyApi, updateGCpagdata, mopDataApi, updatefops, updatedevelopment, updaterfengineer, listSiteId, projectCode, updatemop, copymop, singleProjectApi, siteDblistSiteId, OneSiteDatabaseProjectCode, OneSiteDatabase, accountApi, OneSiteDatabaseProjectId, listUserApi_two, mopAssigner, MOPInstructionApi } from '../../../../common/config';
import { useForm } from "react-hook-form";
import { addAcccountReducerAction, AccountReducerAction, FetchSingleRecordAction, UserListReducerAction, popUpUpdate, setLoaderAction, clearFetchSingleRecordAction, clearProjectId, clearclearProjectIddAction, LCLmopDashboardReducerAction, getMOPInstructionAction } from '../../../../store/actions/createTicketManageFields';
import { useHistory } from "react-router-dom";
import moment from "moment";
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays'
import "react-datepicker/dist/react-datepicker.css";
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions';
import "../../../../css/formError.css";
import swal from 'sweetalert';
import { Button, Tab, Tabs } from 'react-bootstrap';
import './stylee.css';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import CommonCrew from '../../Forms/Ran Form/commoncrew';
import { commonsowDetail, commonzone, fun_time_zonechanger, moment_common_full_date_format, mopcommonsowDetail, projectIdValidator, techChecker, time_zonechanger } from '../../../../common/variables';
import { getValue } from '@mui/system';
import CommonModal from '../CommonModal';

// Tabs
const UpdatedSiteApprovalModal = (props) => {

  console.log(props, props.ctr, "props")

  // const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  // const [endDate, setEndDate] = useState(new Date("2014/02/10"));



  let history = useHistory();
  let technologyArray = []
  let array_cx_data = [];
  let array_ix_data = [];
  let [cxixtype, setcxixtype] = useState(true)
  let [InstructionCall, setInstructionCall] = useState(false)
  let [mopInstructionsId, setmopInstructionsId] = useState("")
  
  
  let [typeOfWorkst, settypeOfWorkst] = useState("")
  let [selectProjectCode, setProjectCode] = useState([])
  let [SelselectProjectCode, setSelProjectCode] = useState([])
  let [SelselectProjectCodename, setSelProjectCodename] = useState()
  let [selectProjectId, setProjectId] = useState([])
  let [stateonce, setstateonce] = useState(true)

  // let singleSite = {};
  let [sow, setSow] = useState([])
  const [required_reason, setrequired_reason] = useState()
  const [mop_reason, setmop_reason] = useState()
  let [tech, setTech] = useState([]);
  let [seltechOutage, setselTechOutage] = useState([]);

  let [DbName, setDbName] = useState([]);
  let [singleSitestate, setsingleSitestate] = useState([]);
  let [nwsingleSitestate, setnwsingleSitestate] = useState([]);
  let [sImpact, setSectorImpact] = useState([])
  let [startTime, setIntialTime] = useState()
  let [endTime, setFinalTime] = useState()
  let [thePId, setPId] = useState('')
  let [ctrCount, setCtrCount] = useState(1);
  let [sdate, setsdate] = useState();
  let [edate, setedate] = useState();
  let [sowError, setSowError] = useState(false)
  let [sectorImpactError, setSectorImpactError] = useState(false)
  let [techError, setTechError] = useState(false)
  let [startDateError, setStartDateError] = useState(false)
  let [endDateError, setEndDateError] = useState(false)
  let [mopstartDateError, setmopStartDateError] = useState(false)
  const [closer, setCloser] = useState(false);
  let [mopendDateError, setMopEndDateError] = useState(false)
  let [startEndTime, setStartEndTime] = useState(0)

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  let [popupclose, setpopupclose] = useState(false)
  let [exDownTimeStart, setExDownTimeStart] = useState("")
  let [exDownTimeEnd, setExDownTimeEnd] = useState("")
  let [dateError, setErrMessage] = useState("Required Field")
  let [mopDownTime, setDownTimeField] = useState('')
  let [siteError, setSiteError] = useState(false)
  let [accordianOperner, setAccordianOperner] = useState('')
  let [Operner, setOperner] = useState(false)
  // let [lat, setlatitude] = useState()
  // let [long, setlongitude] = useState()
  // let [sCity, setCity] = useState()
  // let [sState, setState] = useState()
  // let [sAddress, setAddress] = useState()
  // let [scounty, setcounty] = useState()
  let [locationData, setlocationData] = useState({})
  let [oneprid, setoneprid] = useState('')
  let [customesite, setcustomesite] = useState('')
  let [ppProjectCode, setppProjectCode] = useState('')
  let [ppProjectId, setppProjctId] = useState('')
  let [ond, setond] = useState(true)
  let [oneTimeAccount, setoneTimeAccount] = useState(true)
  let [isDisable, setDisable] = useState(false)
  const [cxArray, setcx] = useState([]);
  const [ixArray, setix] = useState([]);
  const [multiTechnology, setmultiTechnology] = useState([]);
  const [techsetter, settechsetter] = useState(false);

  let listalldata = [];
  let listallCXdata = [];
  let listallIXdata = [];
  let array_cx_main = [];
  let array_ix_main = [];

  // let  = []
  const [allTechnology, setallTechnology] = useState([])
  const [countRender, setCountRender] = useState(1)
  const [setdate, setStartDate] = useState("");
  const [mopsetdate, setmopStartDate] = useState("");
  const [endDateInterval, setendInterval] = useState("")
  const [fl, setFl] = useState(true)
  const [flp, setFlp] = useState(true)
  const [settter, setsetter] = useState(false)

  const [mopendDateInterval, setmopendInterval] = useState("")
  let dispatch = useDispatch()
  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"))

  const { register, setValue, handleSubmit, setError, formState: { errors } } = useForm({
    criteriaMode: 'all',
  });
  let current_id = ''
  if (props.id) {
    current_id = props.id
  }
  const dateObject = new Date();
  const currentDate = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
  // to return the date number(1-31) for the specified date

  let tomorrow = new Date()
  tomorrow.setDate(dateObject.getDate() + 1)

  let whatWork = props.work
  let siteArray = [];
  let nwsiteArray = [];
  let allProjects = [];
  let projectCodeOption = []
  let projectCodeArray = []
  let projectIdArray = []
  let projectIdOption = []
  let newTtempArray = []
  let accountArry = []

  let mopInstructions=[]



  if (whatWork == "add") {
    setCtrCount(2)
  }
  // console.log("whatWork", whatWork, "current_id", current_id)

  const handleChange = (event, nane, arra) => {
    setcxixtype(false)

    let ind = event.target.selectedIndex - 1;
    console.log("array_cx_data,array_ix_data", ind, nane, array_ix_data, array_cx_data);
    if (nane == "IXCrew") {

      console.log("array_ix_data,array_cx_data", array_ix_data[ind]);
      setValue("CXCrew" + "Name", "");
      setValue("CXCrew" + "Email", "");
      setValue("CXCrew" + "Phone", "");
      setValue("CXCrew" + "Vendor", "")
      setValue("CXCrew" + "Company", "")
      setValue("cxCrewId" + "")
      if (array_ix_data[ind]) {
        setValue(nane + "Name", array_ix_data[ind].name);
        setValue(nane + "Email", array_ix_data[ind].email);
        setValue(nane + "Phone", array_ix_data[ind].mobilenumber);
        setValue(nane + "Vendor", array_ix_data[ind].vendorresult[0].vendorName)
        setValue(nane + "Company", array_ix_data[ind].crewresult[0].crewCompanyName)
      }
    } else {
      console.log("array_ix_data,array_cx_data", array_cx_data[ind])
      setValue("IXCrew" + "Name", "");
      setValue("IXCrew" + "Email", "");
      setValue("IXCrew" + "Phone", "");
      setValue("IXCrew" + "Vendor", "")
      setValue("IXCrew" + "Company", "")
      setValue("ixCrewId" + "")


      if (array_cx_data[ind]) {
        setValue(nane + "Name", array_cx_data[ind].name);
        setValue(nane + "Email", array_cx_data[ind].email);
        setValue(nane + "Phone", array_cx_data[ind].mobilenumber);
        setValue(nane + "Vendor", array_cx_data[ind].vendorresult[0].vendorName)
        setValue(nane + "Company", array_cx_data[ind].crewresult[0].crewCompanyName)
      }
    }

  };
  // const handleChange = (event, nane, arra) => {


  //   let ind = event.target.selectedIndex - 1;
  //   console.log("gdrgjeio;rghiuersgh,eriusoso", listalldata, ind, listalldata[ind], listalldata[ind]);
  //   setValue(nane + "Name", listalldata[ind].name);
  //   setValue(nane + "Email", listalldata[ind].email);
  //   setValue(nane + "Phone", listalldata[ind].mobilenumber);

  //   setValue(nane + "Vendor", listalldata[ind].vendorresult[0].vendorName)

  //   setValue(nane + "Company", listalldata[ind].crewresult[0].crewCompanyName)

  // };


  // let current=new Date()
  // let timeznal=fun_time_zonechanger(commonzone,current)
  // console.log(timeznal.format("DD/MM/YYYY HH:mm"),moment().format("DD/MM/YYYY HH:mm"),timeznal.isBefore(moment()),"zonal")
  // if (current>moment(setdate)) {
  //   swal({
  //     title: "Alert !",
  //     text: "Activity start time should not be less than current time.",
  //     icon: "info",
  //   })
  // }


  const onOptionChange = (e, name = undefined) => {
    let val;
    if (e == "") {
      val = name
    } else {
      val = e.target.value;
    }

    array_cx_main.push(
      <option selected disabled>
        Select
      </option>
    );
    array_ix_main.push(
      <option selected disabled>
        Select
      </option>
    );

    if (listalldata) {
      if (val == "LeadName") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
        });
        console.log(array_ix_main, "array_ix_main");
      } else if (val == "Email") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
        })
      } else if (val == "ContactNumber") {

        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
        })
      }
    }



    setcx(array_cx_main);
    setix(array_ix_main)
  };

  const setTecheer = (selectedname) => {
    let alltt = []
    console.log("setTecheer", selectedname, selectProjectId, allTechnology)
    let sellid = []


    selectedname.map((ids) => {
      console.log("the ids -=", ids)
      sellid.push(ids.value)
    })

    let arr = []
    console.log(tech, "techitemitemitemitem")

    tech.map((item) => {

      console.log(item, sellid, "itemitemitemitem")

      if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
        arr.push({
          value: item.value,
          label: item.value.split("_CP_")[0],
        });
      }

    })

    console.log(arr, "techarr")
    setTech(arr)
  }

  const findDProjectId = (id, def = 0, pcode = []) => {

    setCountRender(1)
    console.log("projectCodeArray", id, projectCodeArray)
    setppProjectCode(id)

    setppProjctId('')
    let selectedIds = []
    let selectedname = []
    let nselectedIds = ""

    if (def == 0) {
      setProjectCode(id)
      selectedIds = id.map((item) => {
        console.log(item, "itemprojectCodeArray")
        selectedname.push(item.label)
        return item.value
      })
      nselectedIds = selectedIds.join(",")
    } else {
      let testpcode = []
      pcode.map((onetm) => {
        selectedname.push(onetm)
      })
      nselectedIds = id
    }


    console.log(OneSiteDatabase + id, id, projectCodeArray, selectedIds, selectedname, "projectCodeArrayb")
    console.log(SelselectProjectCodename, "projectCodeArrayb")
    setSelProjectCodename(selectedname)
    console.log(nselectedIds,"projectCodeArrayb")
    console.log(SelselectProjectCodename, "projectCodeArrayb")
    setSelProjectCode(nselectedIds.split(","))

    let newProjectIdSel = []
    let newIdProjectIdSel = []
    console.log(selectProjectId, SelselectProjectCodename, def, "projectCodeArrayb")
    if (def == 0) {
      selectProjectId.map((item) => {
        if (selectedname.indexOf(item.group) != -1) {
          newProjectIdSel.push(item)
          newIdProjectIdSel.push(item.value)
        }
      })
      setProjectId(newProjectIdSel)
      console.log(tech, newProjectIdSel, "techkatech")
      setTecheer(newProjectIdSel)
      // findDProjectId(newProjectIdSel)
    }

    setValue("projectCode", nselectedIds)
    setValue("projectId", nselectedIds)
    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))

  }






  const copycsvhandleChange = (event) => {
    console.log(event, "event.market")
    // addalldata("siteID")
    // setValue("siteId", event.siteId, { shouldValIDate: true })
    // siteIDChangeHandler(event.siteID)
    // setSite(event.siteID)

    // setSite(1)
    // setValue("market", "")
    setValue("market", event.market)
    // setlatitude(event.latitude)
    // setlongitude(event.longitude)
    // setState(event.state)
    // setCity(event.city)
    // setAddress(event.address)
    // setcounty(event.county)
    // setregion(event.region)
    let locdata = {
      "county": event.county,
      "latitude": event.latitude,
      "longitude": event.longitude,
      "state": event.county,
      "city": event.city,
      "address": event.address,
      "region": event.region
    }
    setlocationData(locdata)

    // console.log(lat,long,"lat,long")
    // setValue("siteAddress", event.address)
    // setValue("cityState", event.city+", "+event.state)
    // setValue("region", event.region)

  };

  const FetechAllDetail = (id, val = 0) => {
    // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")
    setPId(id)
    setppProjctId(id)
    console.log(id, selectProjectId, "ittecheck")

    let usedProjectId = []
    // selectProjectId.map((itte)=>{
    //   console.log(itte,"ittecheck")
    // })
    let selectedId = []

    let tprjid = []
    if (val == 0) {

      id.map((item) => {
        console.log(SelselectProjectCodename.indexOf(item.group), SelselectProjectCodename, item, "ohitsnotgood")
        if (SelselectProjectCodename.indexOf(item.group) != -1) {
          selectedId.push(item.value)
          usedProjectId.push(item)
        }
      })
      tprjid = usedProjectId
      setProjectId(usedProjectId)
    } else {
      id.map((item) => {
        selectedId.push(item.value)
      })
      tprjid = id
      setProjectId(id)
    }
    // dispatch(FetchSingleRecordAction(singleProjectApi, id));
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))
    // setCtrCount(1)
    console.log(tprjid, "selectProjectIdselectProjectId")
    setTecheer(tprjid)
    setValue("projectId", selectedId.join(","))
    console.log("FetechAllDetail", id)
  }


  const findDSiteId = (id,valer) => {
    console.log(id, "findDSiteId", allProjects)
    if (id != undefined && customesite!=id) {
      setcustomesite(id)
      setProjectId([])
      setProjectCode([])
      setSelProjectCode([])
      setTech([])
      findDProjectId(id, 1)
      if(valer){
        dispatch(AccountReducerAction(MOPInstructionApi + "/SiteCode/" + id))
      }

      // if(stateonce){
      //   setstateonce(false)
      //   dispatch(clearProjectId())
      // }

      setValue("market", "")
      setSiteError(false)
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        setond(true)
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
      }
    }
    else {
      setSiteError(true)
    }
  }


  const findProjectId = (id) => {
    console.log(id, "findDSiteId", projectIdArray)

    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, id))
  }


  console.log(errors, "errors")
  let siteInfo = []

  let options = useSelector((state) => {
    console.log("dfwef=", state);
    if(InstructionCall){
      console.log(customesite,"customesiteInstructionCall")
      dispatch(AccountReducerAction(MOPInstructionApi + "/SiteCode/" + customesite))
      setInstructionCall(false)
    }
    // if(userDetails.rolename=="RF Engineer"){
    //   console.log("the resp=",state.OneCommonResponse.singleSite)
    //   let siteInfo=state.OneCommonResponse.singleSite
    //   if(typeof (siteInfo)==typeof({})){
    //     return({singleSite:siteInfo})
    //   }
    // }
    // else{


    mopInstructions = state.CommonResponse.mopInstructions
    console.log(mopInstructions.length,mopInstructionsId,"mopInstrumopInstructionsIdctionsId")
    if(mopInstructions.length>0){
      if(mopInstructionsId!=mopInstructions[0].uniqueid){
        console.log(mopInstructions,mopInstructionsId,"mopInstrumopInstructionsIdctionsId")
        setmopInstructionsId(mopInstructions[0].uniqueid)
      }
    }



    listalldata = state.CommonResponse.listuser;
    listalldata.map((item) => {
      if (item.crewType == "CX") {
        array_cx_data.push(item);
      }
      if (item.crewType == "IX") {
        array_ix_data.push(item);
      }
    })

    let account = state.CommonResponse.accounts;
    if (account.length > 0 && accountArry.length == 0) {
      accountArry = account.map((item, index) => {
        if (index == 0 && oneTimeAccount) {
          // setoneTimeAccount(false)
          // setValue("account", item.uniqueid)
          // return <option selected value={item.uniqueid}>{item.accountName}</option>
        }
        return <option style={{ background: "white" }} value={item.uniqueid}>{item.accountName}</option>;
      });
    }
    allProjects = state.CommonResponse.startAwaitedSite
    // console.log("all projects is =",allProjects)
    if (allProjects && allProjects.length > 0) {

      siteArray = allProjects.map((project) => {
        // console.log("the project is =========",project)
        return <option>{project.siteId}</option>;
      });
    }


    projectIdOption = []
    projectIdArray = state.OneCommonResponse.productId
    if (flp && (whatWork == "Add" || whatWork == undefined)) {
      setFlp(false)
    }
    else {
      if (flp) {
        setFlp(false)
      }
      if (projectIdArray && projectIdArray.length > 0) {
        projectIdOption = projectIdArray.map((item, index) => {
          let availableId = []
          let anyArry = item.data
          availableId = item.data.map((id) => {
            return {
              label: id.projectId,
              group: item.projectCode,
              value: id.uniqueid
            }
          })

          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)

        })
      }
    }

    console.log("newTtempArraynewTtempArray", newTtempArray, projectIdArray)



    projectCodeOption = []
    // projectCodeSelectOption=selectProjectCode
    // setProjectCode(id)

    projectCodeArray = state.OneCommonResponse.productCode

    if (fl && (whatWork == "Add" || whatWork == undefined)) {
      setFl(false)
    }
    else {
      if (fl) {
        setFl(false)
      }

      if (projectCodeArray && projectCodeArray.length > 0) {
        projectCodeOption = projectCodeArray.map((item, index) => {


          console.log(SelselectProjectCode, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd); return item.projectCode == abcd.label }), "SelselectProjectCode")
          if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => {
            return item.projectCode == abcd.label
          }) == -1) {
            selectProjectCode.push({
              value: item.uniqueid,
              label: item.projectCode,
            })

          }
          return {
            value: item.uniqueid,
            label: item.projectCode,
          };
        })

      }
    }


    let siteInfotest = state.csvResponse.singleSite
    console.log("the siteinfo:", siteInfotest)
    if (siteInfotest) {
      console.log(countRender, siteInfotest.length == undefined || siteInfotest.length > 0, "siteInfotest")
      if (countRender == 1) {
        if (siteInfotest) {
          if (siteInfotest.length == undefined || siteInfotest.length > 0) {
            console.log(siteInfotest, "siteInfo,siteInfo")
            setCountRender(2)
            // setValue("siteId", '')
            copycsvhandleChange(siteInfotest)
            // siteIDChangeHandler(siteInfo.siteId)
          }
        }
        // console.log("file data==", fetchpdfdata)
        // siteIDChangeHandler(fetchpdfdata.siteID)
      }
    }


    let technology = state.CommonResponse.technology;
    
    let multiTechnologylcl = []
    console.log(technology, "technologytechnologytechnology", selectProjectId)
    // if(techsetter){
    // setallTechnology(state.CommonResponse.technology)
    let techOutage = []
    if (technology.length > 0 && technologyArray.length == 0) {
      
      techOutage = technology.map((item) => {
        return {
          value: item.technologyName,
          label: item.technologyName,
        };
      });
    }
    if (technology.length > 0 && technologyArray.length == 0) {
      if (selectProjectId.length > 0) {
        console.log("comeToStart")
        // setmultiTechnology([])
        console.log(technology, "technology")
        technologyArray = selectProjectId.map((ids) => {
          console.log("the ids -=", ids)
          let pId = {
            label: ids.label,
            value: ids.label,
            disabled: true
          }
          multiTechnologylcl.push(pId)
          let tmpArry = []
          tmpArry = technology.map((item) => {
            return {
              value: item.technologyName + "_CP_" + ids.value,
              label: item.technologyName,
            };
          });


          multiTechnologylcl.push(...tmpArry)
          // setmultiTechnology(multiTechnologylcl)
        })
        console.log(multiTechnology, "multiTechnologymultiTechnology")

        // settechsetter(false)
      }
      // }
    }

    console.log("the multiselect array is =", technologyArray, multiTechnology)
    // let technology = state.CommonResponse.technology;
    // if (technology.length > 0 && technologyArray.length == 0) {

    //   technologyArray = technology.map((item) => {
    //     return {
    //       value: item.uniqueid,
    //       label: item.technologyName,
    //     };
    //   });
    // }
    siteInfo = state.OneCommonResponse.singleMops;
    console.log(ctrCount == 1, siteInfo, siteInfo.length != 0, "ctrCount")
    if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
      console.log("the single site is =", whatWork, siteInfo[0])
      setsingleSitestate(siteInfo[0])
      // singleSite=siteInfo
      // console.log(singleSite,"singleSitesingleSite")
      // setTech(singleSite.technology)


      if (whatWork == "reassigner") {
        if (siteInfo[0].ixCrewId != "") {
          console.log("setDbName")
          DbName.push(siteInfo[0].CrewIdR.name)
          DbName.push(siteInfo[0].CrewIdR.crewType=="CX"?"CXCrew":"IXCrew")
          DbName.push(siteInfo[0].ixCrewId)

        }
        if (siteInfo[0].cxCrewId != "") {
          console.log("setDbName")
          DbName.push(siteInfo[0].CrewIdR.name)
          DbName.push(siteInfo[0].CrewIdR.crewType=="CX"?"CXCrew":"IXCrew")
          DbName.push(siteInfo[0].cxCrewId)
        }
        console.log(DbName, siteInfo[0].ixCrewId, siteInfo[0].cxCrewId, "siteInforeassigner")
        if (siteInfo[0].ixCrewId || siteInfo[0].cxCrewId) {

          console.log(siteInfo[0].ixCrewId != "", siteInfo[0].cxCrewId != "", "siteInforeassigner")
          if (siteInfo[0].ixCrewId != "") {

            console.log(siteInfo[0].ixCrewId != "", siteInfo[0].cxCrewId != "", "siteInforeassigner")
            setAccordianOperner("IXCrew")
            onOptionChange("", "name")
            // handleChange(siteInfo[0].cxCrewId, "IXCrew", "b")
            setValue("ixCrewId", siteInfo[0].ixCrewId)
          }
          if (siteInfo[0].cxCrewId != "") {

            setValue("cxCrewId", siteInfo[0].cxCrewId)
            onOptionChange("", "name")
            // handleChange(siteInfo[0].cxCrewId, "CXCrew", "b");
            console.log(siteInfo[0].ixCrewId != "", siteInfo[0].cxCrewId != "", "siteInforeassigner")
            setAccordianOperner("CXCrew")
          }
        }
        console.log(accordianOperner, "accordianOperner")
      }
      console.log(siteInfo[0],siteInfo[0].typeOfWork, "typeOfWorkaccordianOperner")
      if (whatWork != "Add" && whatWork != undefined) {
        setStartDate(new Date(siteInfo[0].mopStartTime + ":00"))
        setendInterval(new Date(siteInfo[0].mopEndTime + ":00"))
        if(siteInfo[0].typeOfWork=="SI"){
          setmopStartDate(new Date(siteInfo[0].downMopStartTime + ":00"))
          setmopendInterval(new Date(siteInfo[0].downMopEndTime + ":00"))
        }else{
          setmopStartDate("")
          setmopendInterval("")
        }
        setValue("mopDownTime", siteInfo[0].mopDownTime)
        setValue("market", "")
        settypeOfWorkst(siteInfo[0].typeOfWork)
      } else {
        setStartDate("")
        setendInterval("")
        setmopStartDate("")
        setmopendInterval("")
        setValue("mopDownTime", "")
      }
      // setDownTimeField(siteInfo[0].mopDownTime)
      if (whatWork == "edit" || whatWork == "update") {
        
        settypeOfWorkst(siteInfo[0].typeOfWork)

        // setStartDate(new Date(siteInfo[0].mopStartTime + ":00"))
        // setendInterval(new Date(siteInfo[0].mopEndTime + ":00"))
        // setmopStartDate(new Date(siteInfo[0].downMopStartTime + ":00"))
        // setmopendInterval(new Date(siteInfo[0].downMopEndTime + ":00"))
        findDSiteId(siteInfo[0].siteId,false)
        
        setInstructionCall(true)
        
        // dispatch(AccountReducerAction(MOPInstructionApi + "/SiteCode/" + siteInfo[0].siteId))
        setValue("group", siteInfo[0].group)
        setValue("typeOfWork", siteInfo[0].typeOfWork)
        setValue("sectorByOutage", siteInfo[0].sectorByOutage)
        setValue("CraneNeeded", siteInfo[0].CraneNeeded)
        setValue("crNumber", siteInfo[0].crNumber)
        setValue("justificationForRequest", siteInfo[0].justificationForRequest)
        setValue("justificationComment", siteInfo[0].justificationComment)
        setValue("mopDownTime", siteInfo[0].mopDownTime)
        // setDownTimeField(siteInfo[0].mopDownTime)
        setValue("sowDetail", siteInfo[0].sowDetail)
        setValue("account", siteInfo[0].accountId)
        setValue("market", siteInfo[0].market)
        setValue("projectCode", siteInfo[0].projectIdUnique)
        setValue("projectId", siteInfo[0].projectIdUnique)
        setValue("account", siteInfo[0].accountId)

        // setlatitude(siteInfo[0].longitude)
        // setlongitude(siteInfo[0].latitude)

        let locdata = {
          "county": siteInfo[0].county,
          "latitude": siteInfo[0].latitude,
          "longitude": siteInfo[0].longitude,
          "state": siteInfo[0].county,
          "city": siteInfo[0].city,
          "address": siteInfo[0].address,
          "region": siteInfo[0].region
        }
        setlocationData(locdata)
        let strdate = siteInfo[0].mopStartTime.replace(" ", "/").replace(":", "/").split("/")

        console.log(siteInfo[0], siteInfo[0].technology, "siteInfo[0].startTime")
        setValue("siteId", siteInfo[0].siteId, { shouldTouch: true, shouldValidate: true })
        setcustomesite(siteInfo[0].siteId)
        FetechAllDetail(siteInfo[0].rprojectId, 1)

        console.log(siteInfo[0].projectCode, "siteInfo[0].projectCode")

        siteInfo[0].projectCode.map((selItem) => {
          console.log(projectCodeOption, "selItem")
          console.log(selItem, "selItem")
        })


        findDProjectId(siteInfo[0].projectCodeId, 1, siteInfo[0].projectCode)
        // setSelProjectCode(siteInfo[0].projectCodeId.split(","))



        console.log("callalla", siteInfo[0].projectIdUnique)
        setPId(siteInfo[0].projectIdUnique)
        if (ond) {
          setSelProjectCode(siteInfo[0].projectCode)
          setppProjectCode(siteInfo[0].projectIdUnique)
          setppProjctId(siteInfo[0].projectIdUnique)
        }

        let arr = []
        arr = siteInfo[0].sowDetails.values.map((item) => {
          return {
            value: item,
            label: item,
          };
        })
        setSow(arr)
        
        arr = []

        if(siteInfo[0]?.techByTech){
          arr = siteInfo[0]?.techByTech?.values.map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        }
        
        console.log("setselTechOutage",arr,siteInfo[0])
        setselTechOutage(arr)

        
        arr = []
        arr = siteInfo[0].technology.values.map((item) => {
          return {
            value: item,
            label: item.split("_CP_")[0],
          };
        })

        console.log(arr, "techarr")
        setTech(arr)
        arr = []
        arr = siteInfo[0].sectorsImpacted.values.map((item) => {
          return {
            value: item,
            label: item,
          };
        })
        setSectorImpact(arr)
      }
      console.log("the response is the =", state.OneCommonResponse.singleMops[0])
      setCtrCount(2)
    }

    let singleSite = state.OneCommonResponse.singleSite;
    if (ctrCount == 1 && singleSite && singleSite.length != 0) {
      console.log("the single site is =", whatWork, singleSite)
      nwsiteArray = singleSite

    }
    // let projectCodeAr=state.OneCommonResponse.productCode
    // if(projectCodeAr && projectCodeAr.length>0){
    //   projectCodeOption =projectCodeAr.map((item)=>{
    //     return <option value={item.uniqueid}>{item.projectCode}</option>
    //   })
    // }


    console.log(siteInfo, "siteInfo")


    if (state.popUpState) {
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {

        setCloser(false)
        dispatch(popUpUpdate({}));
        props.setXlShow(false)
        dispatch(clearFetchSingleRecordAction())
        dispatch(getMOPInstructionAction([]))
        let Filleddata=dispatch(LCLmopDashboardReducerAction())

        console.log(Filleddata,"FilleddataFilleddata")
        dispatch(MopDatabaseAction(mopDataApi + Filleddata),true);

        // dispatch(MopDatabaseAction(mopDataApi,true));
        history.push("/PAG/Mop/database")
      }
    }


    // console.log(singleSite,"singleSite amit")
    return { technologyArray,techOutage, multiTechnologylcl, multiTechnology, accountArry, newTtempArray, projectCodeOption, projectIdOption, siteArray, nwsiteArray, array_cx_data, array_ix_data, mopInstructions }
    // }
  })



  const changestatus = (e) => {
    console.log(e.target.value, "changestatus")
    if (e.target.value == "Rejected") {
      setrequired_reason(<>
        <label htmlFor="inputEmail4">Reason</label>
        <input type='text' className='form-group' {...register("reason", { required: "Reason is required" })} />
      </>)
    } else {
      setrequired_reason(
        <>
          <label htmlFor="inputEmail4">Reason</label>
          <input type='text' className='form-group' {...register("reason", { required: false })} />
        </>)
    }

  }
  const formatData = (input) => {
    if (input > 9) {
      return input;
    } else return `0${input}`;
  };
  const formatHour = (input) => {
    if (input > 12) {
      return input - 12;
    }
    return input;
  };
  const format24Hour = ({ dd, mm, yyyy, HH, MM, SS }) => {
    return (`${mm}/${dd}/${yyyy} ${HH}:${MM}`);
  };
  // const format12Hour = ({ dd, mm, yyyy, hh, MM, SS }) => {
  //   console.log(`${mm}/${dd}/${yyyy} ${hh}:${MM}:${SS}`);
  // };

  const setDownTime = (e, type) => {
    // setmopStartDateError(false);
    let temp = 0
    if (type == "start") {
      console.log("", "mop_time")
      setmopStartDate(e)
      // if (e >= setdate & e <= endDateInterval) {

      //   let nxtEndTime = e.getTime() + 60 * 60 * 1000
      //   nxtEndTime = new Date(nxtEndTime)
      //   // setmopendInterval(nxtEndTime);
      //   // setDownTimeField(1)
      //   setValue("mopDownTime", 1)
      //   temp = Math.round(((e.getTime() + 60 * 60 * 24 * 1000) - e.getTime()) / 3600000)
      //   console.log("the temp data is =", temp)
      //   console.log(e > setdate, "e,setdate")
      //   // setDownTimeField(Math.round((e.getTime()+ 60 * 60 * 24 * 1000-e.getTime() ) / 3600000))


      //   console.log(e, mopsetdate, setdate, "eandmop")
      //   if (e < setdate) {
      //     swal({
      //       text: "Expected Downtime must in between Start and End Date Time",
      //       icon: "info",
      //     }).then((res) => {
      //       setmopStartDate(setdate)
      //       setValue("mopDownTime", Math.round((mopendDateInterval.getTime() - mopsetdate.getTime()) / 3600000))
      //       // setDownTimeField(Math.round((mopendDateInterval.getTime() - mopsetdate.getTime()) / 3600000))
      //     });
      // }
      // }
      // else {
      //   swal({
      //     text: "Expected Downtime must in between Start and End Date Time",
      //     icon: "info",
      //   })
      // }
    } else {
      // temp = Math.round((e.getTime() - mopsetdate.getTime()) / 3600000)
      // console.log("the temp enddata is =", temp, e)
      setmopendInterval(e)
      // setValue("mopDownTime", Math.round((e.getTime() - mopsetdate.getTime()) / 3600000))
      // // setDownTimeField(Math.round((e.getTime() - mopsetdate.getTime()) / 3600000))
      // console.log(e < setdate, "e,setdate")


      // if (e >= endDateInterval) {
      //   swal({
      //     text: "Expected Downtime must in between Start and End Date Time",
      //     icon: "info",
      //   }).then((res) => {
      //     console.log(setdate.getTime() + 60 * 60 * 24 * 1000, "daadad")
      //     setmopendInterval(endDateInterval)


      //     setValue("mopDownTime", Math.round((endDateInterval.getTime() - mopsetdate.getTime()) / 3600000))
      //     // setDownTimeField(endDateInterval.getTime() - mopsetdate.getTime() / 3600000)
      //   });
      // }


      // temp = Math.round((e.getTime() - mopsetdate.getTime()) / 3600000)
      // console.log("the temp enddata is =", temp)
      // setValue("mopDownTime", temp)
      // // setDownTimeField(temp)
      // if (temp > 24) {
      //   swal({
      //     text: "Down time can't exceed more than 24h",
      //     icon: "info",
      //   }).then((res) => {
      //     console.log(setdate.getTime() + 60 * 60 * 24 * 1000, "daadad")
      //     setmopendInterval(new Date(setdate.getTime() + 60 * 60 * 24 * 1000))
      //     setValue("mopDownTime", Math.round(((mopsetdate.getTime() + 60 * 60 * 24 * 1000) - mopsetdate.getTime()) / 3600000))
      //     // setDownTimeField(Math.round(((mopsetdate.getTime() + 60 * 60 * 24 * 1000) - mopsetdate.getTime()) / 3600000))
      //   });
      // }

      // temp = Math.round((e.getTime() - mopsetdate.getTime()) / 3600000)
      // if (temp < 1) {
      //   swal({
      //     text: "Minimum one hour down time allowed",
      //     icon: "info",
      //   }).then((res) => {
      //     console.log(mopsetdate.getTime() + 60 * 60 * 1000, "daadad")
      //     setmopendInterval(new Date(mopsetdate.getTime() + 60 * 60 * 1000))
      //     setValue("mopDownTime", 1)
      //     // setDownTimeField(1)
      //   });
      // }



    }
    // setValue("mopDownTime", mopDownTime)


    // console.log("me caller", (e.getTime() + 60 * 60 * 24 * 1000 - setdate.getTime()) / 3600000)


  }
  // let temp = 0


  const setDater = (e, type) => {
    setStartDateError(false);
    let temp = 0
    if (type == "start") {
      setStartDate(e);
      // setmopStartDate(e);
      // let nxtEndTime = e.getTime() + 60 * 60 * 24 * 1000
      // nxtEndTime = new Date(nxtEndTime)
      // // setendInterval(nxtEndTime);
      // // setmopendInterval(nxtEndTime);
      // temp = Math.round(((e.getTime() + 60 * 60 * 24 * 1000) - e.getTime()) / 3600000)
      // console.log("the temp data is =", temp)
      // setValue("mopDownTime", Math.round((e.getTime() + 60 * 60 * 24 * 1000 - e.getTime()) / 3600000))
      // setDownTimeField(Math.round((e.getTime()+ 60 * 60 * 24 * 1000-e.getTime() ) / 3600000))
    } else {
      setendInterval(e);
      // setmopendInterval(e);
      // temp = Math.round((e.getTime() - setdate.getTime()) / 3600000)
      // console.log("the temp enddata is =", temp)
      // setValue("mopDownTime", Math.round((e.getTime() - setdate.getTime()) / 3600000))
      // setStartEndTime(temp)
      // setDownTimeField(Math.round((e.getTime()-setdate.getTime() )/ 3600000))

      // if (temp > 24) {
      //   swal({
      //     title: "Alert !",
      //     text: "Down time can't exceed more than 24h",
      //     icon: "info",
      //   }).then((res) => {
      //     setendInterval(setdate.getTime() + 60 * 60 * 24 * 1000)
      //     // setDownTimeField(24)
      //     setValue("mopDownTime", 24)
      //   });
      // }
      // else if (temp < 1) {
      //   swal({
      //     title: "Oops.. !",
      //     text: "Minimum one hour down time allowed",
      //     icon: "info",
      //   }).then((res) => {
      //     setendInterval(setdate.getTime() + 60 * 60 * 1000)
      //     // setDownTimeField(1)
      //     setValue("mopDownTime", 1)
      //   });
      // }
      // else {
      //   setValue("mopDownTime", temp)
      // }



    }


    // console.log("me caller", (e.getTime() + 60 * 60 * 24 * 1000 - setdate.getTime()) / 3600000)

  }
  const changeMop = (e) => {
    if (e.target.value == "Day Mop") {

      setmop_reason(true)
    } else {
      setmop_reason(false)
    }

  }
  const fetechSingleProject = (id, siteId) => {
    console.log("single pro ");
    // console.log("the actual site id=",siteId)
    // current_id=id;
    dispatch(FetchSingleRecordAction(projectCode, id))
    setCtrCount(1)
    // setTech()

  };

  const styles = {
    width: "400px",

  };

  // let sowDetailArray = [{
  //   value: "IX",
  //   label: "IX"
  // }, {
  //   value: "CX",
  //   label: "CX"
  // }, {
  //   value: "Troubleshooting",
  //   label: "Troubleshooting"
  // }, {
  //   value: "E911",
  //   label: "E911"
  // }]

  let sectorsImpacted = [
    {
      value: "Alpha",
      label: "Alpha"
    },
    {
      value: "Beta",
      label: "Beta"
    },
    {
      value: "Gamma",
      label: "Gamma"
    },
    {
      value: "Delta",
      label: "Delta"
    },
    {
      value: "Epsilon",
      label: "Epsilon"
    },
    {
      value: "Zeta",
      label: "Zeta"
    },
  ]

  console.log("options.singleSite", options, errors)
  const handleSectorImpect = (e) => {
    setSectorImpact(e)
    setSectorImpactError(false)
  }
  const handleSowChanges = (e) => {
    console.log(e, "e")
    setSow(e)
    console.log("the e=2", e)
    setSowError(false)
  }
  const techhandleChange = (e) => {
    console.log("multiselect=", tech)
    // setTechnologyError('')
    setTech(e)
    setTechError(false)
  }

  const techOutagehandleChange = (e) => {
    console.log("multiselect=", tech)
    // setTechnologyError('')
    setselTechOutage(e)
  }

  

  // const fetechSingleProject = (id,siteId) => {
  //   console.log("single pro ");
  //   // console.log("the actual site id=",siteId)

  //   dispatch(FetchSingleRecordAction(projectCode,id))
  //   setCtrCount(1)
  //   setTech()

  // };


  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const handleStartTime = (e) => {
    setIntialTime(e.target.value)
  }
  const endTimehandler = (e) => {
    setFinalTime(e.target.value)
    console.log("the endtime =", endTime)
    console.log("the endtime =", e.target.value)
  }
  const onSubmit = async (data) => {
    setDisable(true)

    
    data["mopInstructionsId"]=mopInstructionsId



    let Ctr=0
    if (setdate == "") {
      Ctr=1
      swal({
        title: "Oops.. !",
        text: "Please select activity start date time",
        icon: "info",
      })
      setDisable(false)
      return null
    }
    else if (endDateInterval == "") {
      Ctr=1
      swal({
        title: "Oops.. !",
        text: "Please select activity end date time",
        icon: "info",
      })
      setDisable(false)
      return null
    }

    console.log(typeOfWorkst,"typeOfWorkst")
    if(typeOfWorkst == "SI"){
      if (mopsetdate == "") {
        Ctr=1
        swal({
          title: "Oops.. !",
          text: "Please select DownTime start date time",
          icon: "info",
        })
        setDisable(false)
        return null
      }
  
      else if (mopendDateInterval == "") {
        Ctr=1
        swal({
          title: "Oops.. !",
          text: "Please select DownTime end date time",
          icon: "info",
        })
        setDisable(false)
        return null
      }
    }
    
    if(Ctr==0){

      // alert("first TEST CASE Passed")
      
      let current=new Date()

      let timeznal=fun_time_zonechanger(commonzone,current)  //Current CST Time

      console.log(timeznal.format("MM/DD/YYYY HH:mm")>moment(setdate).format("MM/DD/YYYY HH:mm"),timeznal.format("MM/DD/YYYY HH:mm")<moment(setdate).format("MM/DD/YYYY HH:mm"),timeznal.isAfter(moment(setdate)),timeznal,"zonalerzonalerzonaler")

      console.log(timeznal.isBefore(moment(setdate)),timeznal.isAfter(moment(setdate)),timeznal,setdate,setdate,timeznal.format("DD/MM/YYYY HH:mm"),moment(setdate).format("DD/MM/YYYY HH:mm"),timeznal.isBefore(moment()),"zonaler")
      console.log(timeznal.format("DD/MM/YYYY HH:mm"),moment(setdate).format("DD/MM/YYYY HH:mm"),timeznal.isBefore(moment(setdate)),"zonaler")
      // let current=moment().format("MM/DD/YYYY HH:mm")
      // console.log("the start date and end date =", moment(setdate).format("MM/DD/YYYY HH:mm"), moment().format("MM/DD/YYYY HH:mm"))
      // console.log("the start date and end date =", moment(setdate).format("MM/DD/YYYY HH:mm").isAfter(current),moment(setdate).format("MM/DD/YYYY HH:mm").isBefore(current),setdate, "===fvdfvd===", endDateInterval)
      let startEnddiff = Math.round(endDateInterval.getTime() - setdate.getTime())
      
      if(whatWork == "Add" || whatWork == undefined || whatWork == "edit" || whatWork == "update"){
        if(timeznal.format("MM/DD/YYYY HH:mm")>moment(setdate).format("MM/DD/YYYY HH:mm")){
            swal({
              title: "Alert !",
              text: "Activity start time should not be less than current time.",
              icon: "info",
            })
            setDisable(false)
            return null
        }
      }
      if(whatWork!="assigner" && whatWork!="reassigner"){
        // if (timeznal.format("DD/MM/YYYY HH:mm").isAfter(moment(setdate).format("DD/MM/YYYY HH:mm"))) {
        // if(timeznal.format("MM/DD/YYYY HH:mm")>moment(setdate).format("MM/DD/YYYY HH:mm")){
        //   swal({
        //     title: "Alert !",
        //     text: "Activity start time should not be less than current time.",
        //     icon: "info",
        //   })
        //   setDisable(false)
        //   return null
        // }
        // else if (endDateInterval == setdate) {
        if (endDateInterval == setdate) {
          swal({
            title: "Alert !",
            text: "Start Date Time and End Date Time can't be same",
            icon: "info",
          })
          setDisable(false)
          return null
        }
  
        else if (setdate > endDateInterval) {
          swal({
            title: "Oops.. !",
            text: "Activity start time should be less than activity end time",
            icon: "info",
          })
          setDisable(false)
          return null
        }
        else {
          let temp = 0
          temp = Math.round((endDateInterval.getTime() - setdate.getTime()) / 3600000)
          console.log("the temp enddata is =", temp)
          if (temp > 24) {
            swal({
              title: "Alert !",
              text: "Activity end date time can't exceed 24 Hrs.",
              icon: "info",
            })
            setDisable(false)
            return null
          }
          else {

            if(typeOfWorkst == "SI"){
              let downtimeActEndTime = Math.round(endDateInterval.getTime() - mopsetdate.getTime())
              console.log("the down is the=", downtimeActEndTime)
              
              if (mopsetdate >= endDateInterval) {
                swal({
                  title: "Alert !",
                  text: "Expected downtime start time should be less than activity end time",
                  icon: "info",
                })
                setDisable(false)
                return null
              }
            
              // else if(mopsetdate>endDateInterval){
              //   swal({
              //     title: "Alert !",
              //     text: "Expected down start time can't exceed activity end time",
              //     icon: "info",
              //   })
              //   return null
              // }
              else {
                // moment(mopsetdate).isBefore(setdate)
                if (moment(mopsetdate).isBefore(setdate)) {
                  // if (moment(mopsetdate).isSame(setdate) == false) {
                    let tempSetDate=setdate.getTime()-10000
                    let tempMopSetdate=mopsetdate.getTime()
                    if(tempMopSetdate<tempSetDate){
                      swal({
                        title: "Alert !",
                        text: "Start downtime can not be less than activity start time",
                        icon: "info",
                      })
                      setDisable(false)
                      return null
                  }
                }
                if (mopsetdate >= mopendDateInterval) {
                  swal({
                    title: "Alert !",
                    text: "Start downtime can not be more than or equal to downtime end",
                    icon: "info",
                  })
                  setDisable(false)
                  return null
                }
    
                else if (mopsetdate == mopendDateInterval) {
                  swal({
                    title: "Alert !",
                    text: "Start downtime can not be equal to downtime end",
                    icon: "info",
                  })
                  setDisable(false)
                  return null
                }
                // if(moment(mopendDateInterval).isSame(endDateInterval)){}
                else if (moment(mopendDateInterval).isAfter(endDateInterval)) {
                  let tempEndDateInterval=endDateInterval.getTime()+10000
                  let tempMopEndDateInterval =mopendDateInterval.getTime()
                  if(tempEndDateInterval< tempMopEndDateInterval){
                  // if (moment(mopendDateInterval).isSame(endDateInterval) == false) {
                    console.log("the snkjffwefjewfwef=", mopendDateInterval, "===", endDateInterval, "==j======", mopendDateInterval.getTime() == endDateInterval.getTime())
                    swal({
                      title: "Alert !",
                      text: "End downtime can not be more than activity end time",
                      icon: "info",
                    })
                    setDisable(false)
                    return null
                  }
                }
                // if(endDateInterval<mopsetdate){
                //   swal({
                //     title: "Alert !",
                //     text: "Start downtime can not be more than activity end time",
                //     icon: "info",
                //   })
                //   return null
                // }
                // if(downIslessThenEnd<0){
                //   swal({
                //     title: "Alert !",
                //     text: "Start downtime can not be more than activity end time",
                //     icon: "info",
                //   })
                //   return null
                // }
    
              }
              
              
            }

            // alert("SECOND TEST CASE Passed")
            // console.log("flkwnrfkj=",mopsetdate ," flwkenfokwne=",setdate)
            // let downIslessThenStart =Math.round(mopsetdate.getTime()-setdate.getTime())
            // console.log("the dtime =",mopsetdate>setdate)
  
  
  
            //   let downIslessThenEnd =Math.round(endDateInterval.getTime()-mopsetdate.getTime())
  
            // let downStartEnd= Math.round(mopendDateInterval.getTime()-mopsetdate.getTime())
            // if(downStartEnd==0){
            //   swal({
            //     title: "Alert !",
            //     text: "Start downtime and end down time  can not be equal",
            //     icon: "info",
            //   })
            //   return null
            // }
            // else if(downStartEnd<0){
            //   swal({
            //     title: "Alert !",
            //     text: "End down time  can not be less then start down time",
            //     icon: "info",
            //   })
            //   return null
            // }
            // console.log("en=",endDateInterval.getTime()," =vsdfvs=",mopendDateInterval.getTime())
            // let actEndDownEnd= Math.round(endDateInterval.getTime()-mopendDateInterval.getTime())
            // console.log("rtjknkjnedf=",actEndDownEnd)
            // if(actEndDownEnd<0){
            //   swal({
            //     title: "Alert !",
            //     text: "End down time  can not be more then activity end time",
            //     icon: "info",
            //   })
            //   return null
            // }
          }
        }
      }
      
      console.log(SelselectProjectCodename, selectProjectId, "SelselectProjectCodename,")

      if (whatWork == "Add" || whatWork == undefined || whatWork == "edit" || whatWork == "update") {
        if (projectIdValidator(SelselectProjectCodename, selectProjectId)) {
          setDisable(false)
          swal({
            title: "Alert !",
            text: "Please Select Project Id In All The Selected Project Code",
            icon: "info",
          })
          return null
        }
        if (techChecker(selectProjectId, tech)) {
          setDisable(false)
          swal({
            title: "Alert !",
            text: "Please Select Technology In All The Selected Project Id",
            icon: "info",
          })
          return null
        }
      }



      if(typeOfWorkst == "SI"){
        let finalmopDownTime = Math.round((mopendDateInterval.getTime() - mopsetdate.getTime()) / 3600000)
        setValue("mopDownTime", finalmopDownTime)
        data['mopDownTime'] = finalmopDownTime
      }else{
        data['mopDownTime'] = 0
      }


      dispatch(popUpUpdate({}));
      data["userId"] = userDetails.uniqueid
      console.log("dataonSubmit", userDetails.rolename, whatWork, data, "data")
      let newdata = {}
      
      data['mopStartTime'] = moment(setdate).format(moment_common_full_date_format)
      data['mopEndTime'] = moment(endDateInterval).format(moment_common_full_date_format)
      
      if(typeOfWorkst == "SI"){
        data['downMopStartTime'] = moment(mopsetdate).format(moment_common_full_date_format)
        data['downMopEndTime'] = moment(mopendDateInterval).format(moment_common_full_date_format)
      }else{
        data['downMopStartTime'] = ""
        data['downMopEndTime'] = ""
      }
      // else
      //  if (setdate == endDateInterval) {
      //   setErrMessage("start time and time can't be same")
      //   setEndDateError(true)
      //   return null
      // }
      // else {
      //   setEndDateError(true)
      //   return null
      // }
      if (userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || userDetails.rolename == "GC Login" || userDetails.rolename == "PAG Engineer" || userDetails.rolename == "Manage Engineer" ||(userDetails.rolename == "Manage Engineer" && userDetails.department.values.includes("MOP"))) {
        
        // console.log(mopendDateInterval, "mopendDateInterval")
        // const mopformatEnd = {
        //   dd: formatData(mopendDateInterval.getDate()),
        //   mm: formatData(mopendDateInterval.getMonth() + 1),
        //   yyyy: mopendDateInterval.getFullYear(),
        //   HH: formatData(mopendDateInterval.getHours()),
        //   hh: formatData(formatHour(mopendDateInterval.getHours())),
        //   MM: formatData(mopendDateInterval.getMinutes()),
        //   SS: formatData(mopendDateInterval.getSeconds()),
        // };

        // if (endDateInterval) {

        //   console.log("the start=", format24Hour(format))
        //   console.log("the end=", format24Hour(formatEnd))
        //   data['mopStartTime'] = format24Hour(format);
        //   data['mopEndTime'] = format24Hour(formatEnd)
        //   // data['mopDownTime'] = mopDownTime
        //   data['downMopStartTime'] = format24Hour(mopformat);
        //   data['downMopEndTime'] = format24Hour(mopformatEnd)
        // }
        // else
        //  if (setdate == endDateInterval) {
        //   setErrMessage("start time and time can't be same")
        //   setEndDateError(true)
        //   return null
        // }
        // else {
        //   setEndDateError(true)
        //   return null
        // }

        if (whatWork == "Add" || whatWork == undefined) {
          let sowArry = { values: [] }
          console.log("outside sow ", sow)
          if (sow.length > 0) {
            setSowError(false)
            console.log("inside sow ", sow)
            sowArry.values = sow.map((item) => {
              return item.value
            })
          }
          else {
            console.log("error is error =", sow)
            setSowError(true)
            return null
          }
          data['sowDetails'] = sowArry

          let sendTech = { values: [] }
          if (tech.length > 0) {
            sendTech.values = tech.map((item) => {
              return item.value
            })
          }
          else {
            setTechError(true)
            return null
          }
          data['technology'] = sendTech
          let sectorsImpacted = { values: [] }
          if (sImpact.length > 0) {
            sectorsImpacted.values = sImpact.map((item) => {
              return item.value
            })
          }
          else {
            setSectorImpactError(true)
            return null
          }
          data['sectorsImpacted'] = sectorsImpacted
          
          let techByTech = { values: [] }
          if (seltechOutage.length > 0) {
            techByTech.values = seltechOutage.map((item) => {
              return item.value
            })
          }
          data['techByTech'] = techByTech

          data = {
            ...data,
            ...locationData
          }
          console.log(data, "dasdsad")
          dispatch(setLoaderAction(true))
          console.log(data,"final to go data")
          dispatch(addAcccountReducerAction(updateGCpagdata + data['projectId'], data))  ////PRACT
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "edit") {
          let sowArry = { values: [] }
          sowArry.values = sow.map((item) => {
            return item.value
          })

          
          data['sowDetails'] = sowArry

          
          let techByTech = { values: [] }
          if (seltechOutage.length > 0) {
            techByTech.values = seltechOutage.map((item) => {
              return item.value
            })
          }
          data['techByTech'] = techByTech
          // let sendTech = { values: [] }
          // if (tech.length > 0) {
          //   sendTech.values = tech.map((item) => {
          //     return item.label
          //   })
          // }
          // else {
          //   setTechError(true)
          //   return null
          // }
          // data['technology'] = sendTech

          let sendTech = { values: [] }
          if (tech.length > 0) {
            sendTech.values = tech.map((item) => {
              return item.value
            })
          }
          else {
            setTechError(true)
            return null
          }
          data['technology'] = sendTech
          let sectorsImpacted = { values: [] }
          if (sImpact.length > 0) {
            sectorsImpacted.values = sImpact.map((item) => {
              return item.value
            })
          }
          else {
            setSectorImpactError(true)
            return null
          }
          
          // if (endDateInterval) {

          //   console.log("the start=", format24Hour(format))
          //   console.log("the end=", format24Hour(formatEnd))
          //   data['mopStartTime'] = format24Hour(format);
          //   data['mopEndTime'] = format24Hour(formatEnd)
          //   data['mopDownTime'] = mopDownTime
          //   data['downMopStartTime'] = format24Hour(mopformat);
          //   data['downMopEndTime'] = format24Hour(mopformatEnd)
          //   data['sectorsImpacted'] = sectorsImpacted
          // }
          // else if (setdate == endDateInterval) {
          //   setErrMessage("start time and time can't be same")
          //   setEndDateError(true)
          //   return null
          // }
          // else {
          //   setEndDateError(true)
          //   return null
          // }
          data['sectorsImpacted'] = sectorsImpacted
          console.log(thePId, "project_parent_id")
          data = {
            ...data,
            ...locationData
          }
          data['project_parent_id'] = thePId
          console.log(mopDownTime)
          
          console.log(data,"final to go data")
          dispatch(addAcccountReducerAction(updatemop + current_id, data))  ////PRACT
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "update") {
          let sowArry = { values: [] }
          sowArry.values = sow.map((item) => {
            return item.value
          })
          data['sowDetails'] = sowArry
          // let sendTech = { values: [] }
          // sendTech.values = tech.map((item) => {
          //   return item.label
          // })
          // data['technology'] = sendTech


          let sendTech = { values: [] }
          if (tech.length > 0) {
            sendTech.values = tech.map((item) => {
              return item.value
            })
          }
          else {
            setTechError(true)
            return null
          }
          data['technology'] = sendTech
          let sectorsImpacted = { values: [] }
          sectorsImpacted.values = sImpact.map((item) => {
            return item.value
          })
          
          data['mopStartTime'] = moment(setdate).format(moment_common_full_date_format)
          data['mopEndTime'] = moment(endDateInterval).format(moment_common_full_date_format)
          
          if(typeOfWorkst == "SI"){
            data['downMopStartTime'] = moment(mopsetdate).format(moment_common_full_date_format)
            data['downMopEndTime'] = moment(mopendDateInterval).format(moment_common_full_date_format)
          }else{
            data['downMopStartTime'] = ""
            data['downMopEndTime'] = ""
          }
          data['sectorsImpacted'] = sectorsImpacted
          data['project_parent_id'] = thePId
          data = {
            ...data,
            ...locationData
          }
          console.log(data, thePId, "datadata")

          console.log(data,"final to go data")

          dispatch(addAcccountReducerAction(copymop + data['projectId'], data)) //PRACT
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "assigner") {
          console.log(data, cxixtype, data["cxCrewId"], data["ixCrewId"], "assignerdata", data)
          data["edit"] = 0
          // if (data.ixCrewId != undefined) {
          //   data["cxCrewId"] = ""
          // }
          // if (data.cxCrewId != undefined) {
          //   data["ixCrewId"] = ""
          // }
          
          console.log(data,"final to go data")
          dispatch(addAcccountReducerAction(mopAssigner + current_id, data)) //PRACT
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "reassigner") {
          console.log(data, "assignerdata", data)
          data["edit"] = 1
          
          console.log(data,"final to go data")
          dispatch(addAcccountReducerAction(mopAssigner + current_id, data)) //PRACT
          // history.push("/PAG/Mop/database")
        }


      }
      else if (userDetails.rolename == "FOPS") {
        newdata['mopStartTime'] = data['mopStartTime']
        newdata['mopEndTime'] = data['mopEndTime']
        newdata['mopDownTime'] = data['mopDownTime']
        newdata['downMopStartTime'] = data['downMopStartTime'];
        newdata['downMopEndTime'] = data['downMopEndTime']
        newdata['fopsstatus'] = data['status']
        newdata['fopsreason'] = data['reason']
        newdata['fopsaddby'] = "FOPS"
        
        console.log(newdata,"final to go data")
        dispatch(addAcccountReducerAction(updatefops + current_id, newdata)) //PRACT
        // history.push("/PAG/Mop/database")
      }
      else if (userDetails.rolename == "Development") {
        newdata['mopStartTime'] = data['mopStartTime']
        newdata['mopEndTime'] = data['mopEndTime']
        newdata['mopDownTime'] = data['mopDownTime']
        newdata['downMopStartTime'] = data['downMopStartTime'];
        newdata['downMopEndTime'] = data['downMopEndTime']
        newdata['devstatus'] = data['status']
        newdata['devreason'] = data['reason']
        newdata['devaddby'] = "Development"
        console.log(newdata,"final to go data")
        dispatch(addAcccountReducerAction(updatedevelopment + current_id, newdata)) //PRACT
        // history.push("/PAG/Mop/database")

      } else if (userDetails.rolename == "RF Engineer") {
        newdata['mopStartTime'] = data['mopStartTime']
        newdata['mopEndTime'] = data['mopEndTime']
        newdata['mopDownTime'] = data['mopDownTime']
        newdata['downMopStartTime'] = data['downMopStartTime'];
        newdata['downMopEndTime'] = data['downMopEndTime']
        if (whatWork == "dev") {
          newdata['devstatus'] = data['status']
          newdata['devreason'] = data['reason']
          newdata['devaddby'] = "RF Engineer"
          console.log(newdata,"final to go data")
          dispatch(addAcccountReducerAction(updatedevelopment + current_id, newdata)) //PRACT
          // window.location.reload()
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "ops") {
          newdata['fopsstatus'] = data['status']
          newdata['fopsreason'] = data['reason']
          newdata['fopsaddby'] = "RF Engineer"
          console.log(newdata,"final to go data")
          dispatch(addAcccountReducerAction(updatefops + current_id, newdata)) //PRACT
          // history.push("/PAG/Mop/database")
        } else if (whatWork == "rf") {
          newdata['rfstatus'] = data['status']
          newdata['rfreason'] = data['reason']
          newdata['rfaddby'] = "RF Engineer"
          console.log(newdata,"final to go data")
          dispatch(addAcccountReducerAction(updaterfengineer + current_id, newdata)) //PRACT
          // history.push("/PAG/Mop/database")
        }

      }
      dispatch(popUpUpdate({}));
      setCloser(true) //PRACT
      // assigner
      // props.setXlShow(false)
      // history.push("/PAG/Mop/database")
      // console.log(data)


      // props.setXlShow(false)
      // history.push("/PAG/Mop/database")

    }
  }

  const timer = setTimeout(() => {

    console.log(popupclose, "popupclose")
    clearTimeout(timer)
    if (popupclose) {
      setpopupclose(false)
      swal({
        title: "OOPS",
        text: "Session Timed Down",
        icon: "info",
      })
      props.setXlShow(false)
      clearTimeout(timer)
    } else {
      setpopupclose(false)
      console.log("clearTimeout")
      clearTimeout(timer)
    }
    setpopupclose(false)

  }, 300000);


  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    dispatch(popUpUpdate({}));
    // setCloser(true);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };



  function mopinscreate(datavalue) {
    return <>
      <table className='showone' style={{ color: "black", width: "100%" }}>
        <thead>
          <th style={{ background: "#143b64", color: "white", height: "45px", paddingLeft: "5px", fontWeight: 500, fontSize: "13px" }}>S:Site Code</th>
          <th style={{ background: "#143b64", color: "white", height: "45px", paddingLeft: "5px", fontWeight: 500, fontSize: "13px" }}>S:24x7 Site Access?</th>
          <th style={{ background: "#143b64", color: "white", height: "45px", paddingLeft: "5px", fontWeight: 500, fontSize: "13px" }}>S:Site Access Details</th>
          <th style={{ background: "#143b64", color: "white", height: "45px", paddingLeft: "5px", fontWeight: 500, fontSize: "13px" }}>S:Site Access Details Updated by </th>
      </thead>
      <tr>
        <td style={{ fontSize : "13px" }}>{datavalue.SiteCode}</td>
        <td style={{ fontSize : "13px" }}>{datavalue.SiteAccess}</td>
        <td style={{ fontSize : "13px" }}>{datavalue.AccessDetails}</td>
        <td style={{ fontSize : "13px" }}>{datavalue.AccessDetailsUpdated}</td>
      </tr>
    </table >
    </>

  }

  useEffect(() => {
    console.log(whatWork == undefined, whatWork == "Add", "whatWorksaaa", whatWork, userDetails.rolename, userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")))
    if (whatWork != "Add" && whatWork != undefined) {
      // dispatch(FetechProductCode)
      dispatch(FetchSingleRecordAction(mopDataApi, props.id))

    }
    // dispatch(fetechSingleProject(props.id))
    dispatch(AccountReducerAction(accountApi));
    dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(AccountReducerAction(siteDblistSiteId))


    dispatch(UserListReducerAction(listUserApi_two));
  }, [])

  console.log(getValue("typeOfWork"),typeOfWorkst,"typeOfWork","UpdatedSiteApprovalModal")
  return (
    <div>
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
      <div style={{ width: "100%", backgroundColor: "white" }}>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            {(whatWork != "assigner" && whatWork != "reassigner") ?
              <>
                {
                  userDetails.rolename == "GC Login" && (whatWork != "edit" && whatWork != "reassigner" && whatWork != "assigner") ? <>
                    {/* // <>  <div> */}

                    {/* <label for="exampleFormControlSelect1">Site Id</label> */}
                    {/* <select
                            style={{height:"32px"}}
                            className="form-group"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(mopDataApi,id))
                            // }}
                            {...register("siteUniqueId", {
                              required: "Please select site id",
                              onChange: (e) => {
                                console.log("single pro ", e.target.value);

                                fetechSingleProject(e.target.value);
                              },
                            })}
                          > */}

                    {/* /* {options.siteArray} */}
                    {/* </select> */}
                    {/* {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )} */}
                    {/* </div>  */}
                    <div className="form-group col-md-3">
                      {/* <label for="exampleFormControlSelect1">
                        Site Id
                      </label> */}
                      {console.log(options.mopInstructions,"options.mopInstructions")}
                      <div className="form-row">
                        <div style={{"height":"34px"}} className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                        </div>
                        <div className="form-group col-md-6">
                          {options.mopInstructions.length > 0 ?
                            <>
                              <p className={'badge badge-custom-size badge-success'} style={{"width": "100%","height": "20px","padding": "1px !important"}} onClick={() => {
                                CommonModalFun(mopinscreate(options.mopInstructions[0]), "hello", "MOP Instructions", "xl")
                              }}>Site Access Info</p>

                            </> :
                            ""}
                        </div>
                      </div>
                      <input className='form-control' list="cars"
                        style={{ height: "32px" }}
                        defaultValue={whatWork != "Add" && whatWork != undefined ? singleSitestate.siteId : ""}
                        onBlur={(e) => {
                          findDSiteId(e.target.value,true)
                        }}
                      />
                      <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                        Required Field
                      </p>
                      <datalist id="cars">

                        {options.siteArray}
                      </datalist>
                    </div>
                  </> :
                    <> <div className="form-group col-md-3">
                      {/* <label htmlFor="inputEmail4 " >Site Id </label> */}
                      <div className="form-row">
                        <div style={{"height":"34px"}} className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                        </div>
                        <div className="form-group col-md-6">
                          {options.mopInstructions.length > 0 ?
                            <>
                              <p className={'badge badge-custom-size badge-success'} style={{"width": "100%","height": "20px","padding": "1px !important"}} onClick={() => {
                                CommonModalFun(mopinscreate(options.mopInstructions[0]), "hello", "MOP Instructions", "xl")
                              }}>Site Access Info</p>

                            </> :
                            ""}
                        </div>
                      </div>
                      <input style={{ height: "32px" }} type='text' className='form-group'


                        value={
                          singleSitestate.siteId
                            ? singleSitestate.siteId
                            : ""
                        }
                      ></input>
                    </div>
                    </>
                }

                {
                  userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner") ? <>

                    <div className="form-group col-md-3" style={{ background: "white" }}>
                      <label >Project Code</label>
                      <MultiSelect
                        className="text-dark"
                        hasSelectAll={false}
                        options={options.projectCodeOption}
                        onChange={findDProjectId}
                        value={selectProjectCode}
                      />
                      {/* <select
                        style={{ height: "32px", background: "white", color: "white" }}
                        className="form-group"
                        isSearchable={true}
                        {...register("projectCode", {
                          required: "Please select project code",
                          onChange: (e) => {
                            findDProjectId(e.target.value)
                            // FetechAllDetail(e.target.value);
                            console.log("the target value= ", e.target.value);
                          }
                        })
                        }
                      >
                        {options.projectCodeOption}
                      </select> */}
                      {errors.projectUniqueId && (
                        <p className="error">
                          {errors.projectUniqueId.message}
                        </p>
                      )}
                    </div></> :
                    <>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputEmail4" >Project Code </label>
                        <input style={{ height: "32px" }} type='text' className='form-group'


                          value={
                            singleSitestate.projectCode
                              ? singleSitestate.projectCode
                              : ""
                          }
                        ></input>
                      </div>
                    </>
                }
                {
                  userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner") ? <>
                    <div className="form-group col-md-3 ">
                      <label >Project Id</label>
                      <MultiSelect
                        className="text-dark"
                        hasSelectAll={false}

                        options={newTtempArray}
                        onChange={FetechAllDetail}
                        value={selectProjectId}
                      />
                      {/* <select
                        style={{ height: "32px" }}
                        className="form-group"
                        isSearchable={true}
                        {
                        ...register("projectId", {
                          required: "Please select project Id",
                          onChange: (e) => {
                            FetechAllDetail(e.target.value);
                          }
                        }
                        )
                        }
                      >
                        {options.projectIdOption}
                      </select> */}
                      {errors.projectUniqueId && (
                        <p className="error">
                          {errors.projectUniqueId.message}
                        </p>
                      )}
                    </div></> :
                    <>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputEmail4" >Project Id </label>
                        <input style={{ height: "32px" }} type='text' className='form-group'


                          value={
                            singleSitestate.projectId
                              ? singleSitestate.fnprojectId.join(", ")
                              : ""
                          }
                        ></input>
                      </div>
                    </>}

                <div className="form-group col-md-3" style={{ borderColor: "gray !important" }}>
                  <label htmlFor="inputEmail4" >Market </label>
                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?

                      <input style={{ height: "32px", borderColor: "gray !important" }} type='text'
                        // disabled
                        disabled

                        className='form-group'
                        {...register("market", {
                        })}
                      /> : <input style={{ height: "32px", borderColor: "gray !important" }} type='text' className='form-group'
                        value={
                          singleSitestate.market
                            ? singleSitestate.market
                            : ""
                        }
                      />
                  }

                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4" >Account </label>
                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      <>
                        <select style={{ height: "32px" }}
                          className="form-control"
                          id="exampleFormControlSelect1"
                          {...register("account", {
                            required: "Please select account"
                          })}
                        >
                          <option value="">Select</option>
                          {options.accountArry}
                        </select>
                        {errors.account && (
                          <p className="error">
                            {errors.account.message}
                          </p>
                        )}
                      </>
                      : <input style={{ height: "32px" }} type='text' className='form-group'


                        value={
                          singleSitestate.account
                            ? singleSitestate.account
                            : ""
                        }
                      />
                  }

                </div>



                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4" >Company</label>

                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ? <input style={{ height: "32px" }}

                      value={userDetails.crewresult.crewCompanyName}

                      {...register("company", {
                        // required:"Please select group"
                      })}
                      type='text' className='form-control' /> : <input style={{ height: "32px" }}

                        value={singleSitestate.company}
                        type='text' className='form-group' />
                  }
                  {/* {errors.company && (
                            <p >{errors.company.message}</p>
                          )} */}
                </div>


                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4" >Vendor</label>

                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ? <input style={{ height: "32px" }}

                      value={userDetails.vendorresult.vendorName}

                      {...register("crewvendor", {
                        // required:"Please select group"
                      })}
                      type='text' className='form-control' /> : <input style={{ height: "32px" }}

                        value={singleSitestate.crewvendor}
                        type='text' className='form-group' />
                  }
                  {/* {errors.company && (
                            <p >{errors.company.message}</p>
                          )} */}
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4" >Owner</label>

                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ? <input style={{ height: "32px" }}

                      value={userDetails.name}
                      {...register("owner", {
                        // required:"Please select group"
                      })}
                      type='text' className='form-control' /> : <input style={{ height: "32px" }}

                        value={singleSitestate.owner}
                        type='text' className='form-group' />
                  }

                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Owner-Email</label>


                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ? <input style={{ height: "32px" }}

                      value={userDetails.email}
                      {...register("ownerEmail", {
                        // required:"Please select group"
                      })}
                      type='text' className='form-control' /> : <input style={{ height: "32px" }}

                        value={singleSitestate.ownerEmail}
                        type='text' className='form-group' />
                  }

                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Owner Contact</label>

                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ? <input style={{ height: "32px" }}

                      value={userDetails.mobilenumber}
                      {...register("OwnerContact", {
                        // required:"Please select group"
                      })}
                      type='text' className='form-control' /> : <input style={{ height: "32px" }}

                        value={singleSitestate.OwnerContact}
                        type='text' className='form-group' />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label for="exampleFormControlSelect1">Development Or Operation</label>

                  {

                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      //  <>
                      //     <select style={{ height: "32px" }}
                      //       className="form-control"

                      //       id="exampleFormControlSelect1"
                      //       {...register("Group",{
                      //         required:"Please select type of work"
                      //           })}
                      //     >

                      //       <option >Select</option>
                      //       <option value="Development">Development</option>
                      //       <option value="Operation">Operation</option>
                      //     </select>
                      //     {errors.Group && (
                      //   <p className="error">
                      //     {errors.Group.message}
                      //   </p>
                      // )}
                      //   </>
                      <>
                        <select style={{ height: "32px" }}
                          {...register("group", {
                            required: "Please Select Development Or Operation"
                          })}
                          className="form-control" aria-label="Default select example">
                          <option selected value="">Select</option>
                          <option value="Development">Development</option>
                          <option value="Operation">Operation</option>
                        </select>
                        {errors.group && (
                          <p className="error">
                            {errors.group.message}
                          </p>
                        )}</>
                      : <><input style={{ height: "32px" }}

                        value={singleSitestate.group}
                        type='text' className='form-control' />

                      </>
                  }

                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4" >Type Of Work(SI/NSI)</label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <select style={{ height: "32px" }}
                        {...register("typeOfWork", {
                          required: "Please select type of work",
                          onChange: (e)=>{
                            settypeOfWorkst(e.target.value)
                            if(e.target.value=="NSI"){
                              setmopStartDate("")
                              setmopendInterval("")
                            }
                          }
                        })}
                        className="form-control" aria-label="Default select example">
                        <option selected value="">Select</option>
                        <option value="SI">SI</option>
                        <option value="NSI">NSI</option>
                      </select>
                      {errors.typeOfWork && (
                        <p className="error">
                          {errors.typeOfWork.message}
                        </p>
                      )}</> : <input style={{ height: "32px" }}
                        type='text' className='form-control'

                        value={
                          singleSitestate.typeOfWork
                            ? singleSitestate.typeOfWork
                            : ""
                        } />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Sectors Impacted</label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <MultiSelect
                        className="text-dark"
                        options={sectorsImpacted}
                        onChange={handleSectorImpect}
                        value={sImpact}
                      />
                      <p className='error' style={{ display: sectorImpactError ? "block" : "none" }}>Required Field</p>
                    </>
                    : <textarea style={{ height: "32px" }}
                      type='text' className='form-control'
                      value={singleSitestate.sectorsImpacted ? singleSitestate.sectorsImpacted.values : ""} />}


                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Sector by Sector Outage</label>


                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <select style={{ height: "32px" }}
                        {...register("sectorByOutage", {
                          required: "Please select Sector outage"
                        })}
                        className="form-control" aria-label="Default select example">
                        <option selected disabled value={''}>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.sectorByOutage && (
                        <p className="error">
                          {errors.sectorByOutage.message}
                        </p>
                      )}
                    </>
                    : <input style={{ height: "32px" }}
                      type='text' className='form-control'

                      value={
                        singleSitestate.sectorByOutage
                          ? singleSitestate.sectorByOutage
                          : ""
                      } />
                  }

                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Technology </label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <MultiSelect
                        className="text-dark"
                        hasSelectAll={false}
                        options={options.multiTechnologylcl}
                        onChange={techhandleChange}
                        value={tech}
                      />
                      <p className='error' style={{ display: techError ? "block" : "none" }}>Required Field</p>
                    </> : <textarea style={{ height: "32px" }}
                      type='text' className='form-control'
                      value={singleSitestate ? singleSitestate.techtake : ""} />}

                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Technology By Technology Outage  </label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <MultiSelect
                        className="text-dark"
                        hasSelectAll={false}
                        options={options.techOutage}
                        onChange={techOutagehandleChange}
                        value={seltechOutage}
                      />
                      <p className='error' style={{ display: techError ? "block" : "none" }}>Required Field</p>
                    </> : <textarea style={{ height: "32px" }}
                      type='text' className='form-control'
                      value={singleSitestate?.techByTech?.values} />}

                </div>


                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">SOW</label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <><MultiSelect
                      className="text-dark"
                      options={mopcommonsowDetail}
                      onChange={handleSowChanges}
                      value={sow}
                    />
                      <p className='error' style={{ display: sowError ? "block" : "none" }}>Required Field</p>
                    </> : <textarea style={{ height: "32px" }}
                      type='text' className='form-control'
                      value={singleSitestate.sowDetails ? singleSitestate.sowDetails.values : ""} />}

                </div>


                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">SOW Details</label>
                  {
                    userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      <>
                        <textarea style={{ height: "32px" }} {...register("sowDetail", {
                          required: "Please Enter SOW Details",
                          maxLength: {
                            value: 2000,
                            message: "Only 2000 characters are allowed"
                          }
                        })} type='text' className='form-control' />
                        {errors.sowDetail && (
                          <p className="error">
                            {errors.sowDetail.message}
                          </p>
                        )}
                      </> :
                      <textarea style={{ height: "32px" }} value={singleSitestate.sowDetail ? singleSitestate.sowDetail : ""} className='form-control' />
                  }

                </div>




                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Justification For Request</label>


                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <textarea style={{ height: "32px" }}
                        {...register("justificationForRequest", {
                          required: "Please Enter Justification For Request",
                          maxLength: {
                            value: 2000,
                            message: "Only 2000 characters are allowed",
                          },
                        })}
                        type='text' className='form-control' />
                      {errors.justificationForRequest && (
                        <p className="error">
                          {errors.justificationForRequest.message}
                        </p>
                      )}
                    </>
                    : <textarea style={{ height: "32px" }} type='text' value={
                      singleSitestate.justificationForRequest
                        ? singleSitestate.justificationForRequest
                        : ""} className='form-control' />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Justification Comments </label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <textarea style={{ height: "32px" }}
                        {...register("justificationComment", {
                          required: "Please Enter Justification Comment",
                          maxLength: {
                            value: 2000,
                            message: "Only 2000 characters are allowed",
                          }
                        })}
                        type='text' className='form-control' />
                      {errors.justificationComment && (
                        <p className="error">
                          {errors.justificationComment.message}
                        </p>
                      )}
                    </> : <textarea style={{ height: "32px" }} type='text' value={
                      singleSitestate.justificationComment
                        ? singleSitestate.justificationComment
                        : ""} className='form-control' />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">CR No.</label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <input style={{ height: "32px" }}
                        {...register("crNumber", {
                          required: "Please Enter CR Number",
                          pattern:{
                            value:/CR[0-9]{9}$/,
                            message:"Please Enter A Valid CR Number ( CR+9 digits )"
                          }
                        })}

                        
                        type='text' className='form-control' />
                      {errors.crNumber && (
                        <p className="error">
                          {errors.crNumber.message}
                        </p>
                      )}
                    </>
                    : <input style={{ height: "32px" }}
                      type='text' className='form-control'

                      value={
                        singleSitestate.crNumber
                          ? singleSitestate.crNumber
                          : ""
                      } />
                  }
                </div>

                
                
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Activity Start Date Time : </label>
                  <br />
                  {
                    1 == 1 ?
                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      // <input style={{height:"32px"}} onChange={(e)=>handleStartTime(e)} type="datetime-local" class="form-control" min="1/29/2023"/>:
                      <>
                        <DatePicker
                          style={styles}
                          className="form-control"
                          selected={setdate}
                          onChange={(e) => { setDater(e, "start"); }}
                          showTimeSelect
                          popperPlacement="top"
                          // excludeOutOfBoundsTimes
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="MM/dd/yyyy hh:mm aa"
                          minDate={new Date()}
                          maxDate={addDays(new Date(), 31)}
                        />
                        <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p>
                      </>
                      :
                      <input style={{ height: "32px" }} type='text' value={singleSitestate.mopStartTime ? singleSitestate.mopStartTime : ""} className='form-control' />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4"> Activity End Date Time: </label>
                  <br />
                  {
                    1 == 1 ?
                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?

                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      // <input style={{height:"32px"}} onChange={(e)=>endTimehandler(e)} class="form-control" type="datetime-local" min="1/30/2023" />:
                      <>
                        <DatePicker

                          className="form-control"
                          selected={endDateInterval}
                          onChange={(e) => { setendInterval(e); setEndDateError(false); setDater(e, "end") }}
                          showTimeSelect

                          timeFormat="HH:mm"
                          // minTime={setdate?setdate.getHours()+":"+setdate.getMinutes():""}
                          // maxTime={setdate.getHours(15).getMinutes(45)}
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="MM/dd/yyyy hh:mm aa"
                          minDate={setdate}
                          maxDate={setdate ? setdate.getTime() + 60 * 60 * 24 * 1000 : ""}
                        />
                        <p className='error' style={{ display: endDateError ? "block" : "none" }}>{dateError}</p>
                      </> :
                      <input style={{ height: "32px" }} type='text' value={singleSitestate.mopEndTime ? singleSitestate.mopEndTime : ""} className='form-control' />
                  }
                </div>
                {
                    typeOfWorkst == "SI" ?
                    <>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Expected DownTime Start : </label>
                  <br />
                  {
                    // 1 == 1 ?
                    
                    typeOfWorkst == "SI" ?
                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?

                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      // <input style={{height:"32px"}} onChange={(e)=>handleStartTime(e)} type="datetime-local" class="form-control" min="1/29/2023"/>:
                      <>
                        <DatePicker
                          style={styles}
                          className="form-control"
                          selected={mopsetdate}
                          onChange={(e) => { setExDownTimeStart(e); setDownTime(e, "start"); }}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="MM/dd/yyyy hh:mm aa"
                          minDate={setdate}
                          maxDate={setdate ? setdate.getTime() + 60 * 60 * 24 * 1000 : ""}
                        />
                        <p className='error' style={{ display: mopstartDateError ? "block" : "none" }}>Required Field</p>
                      </>
                      :
                      <input disabled style={{ height: "32px" }} type='text' 
                      // value={singleSitestate.mopStartTime ? singleSitestate.mopStartTime : ""} 
                      value={""}
                      className='form-control' />
                  }
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4"> Expected DownTime End: </label>
                  <br />
                  {
                    // 1 == 1 ?
                    
                    typeOfWorkst == "SI" ?
                      // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                      // <input style={{height:"32px"}} onChange={(e)=>endTimehandler(e)} class="form-control" type="datetime-local" min="1/30/2023" />:
                      <>
                        <DatePicker

                          className="form-control"
                          selected={mopendDateInterval}
                          onChange={(e) => { setmopendInterval(e); setExDownTimeEnd(e); setMopEndDateError(false); setDownTime(e, "end") }}
                          showTimeSelect

                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="MM/dd/yyyy hh:mm aa"
                          minDate={setdate}
                          maxDate={setdate ? setdate.getTime() + 60 * 60 * 24 * 1000 : ""}
                        />
                        <p className='error' style={{ display: endDateError ? "block" : "none" }}>{dateError}</p>
                      </> : <input disabled style={{ height: "32px" }} type='text' 
                      // value={singleSitestate.mopEndTime ? singleSitestate.mopEndTime : ""} 
                      value={""}
                      className='form-control' />
                  }
                </div>
                </>:""}
                {/* <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Down Time (H)</label> */}
                {
                  // 1 == 1 ?
                  // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?

                  // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                  // <>
                  //   <input style={{ height: "32px" }}
                  //     {...register("mopDownTime", {
                  //       // required: "Please enter downtime",
                  //       // min: {
                  //       //   value: 1,
                  //       //   message: "Atleast one hour down time allowed"
                  //       // },
                  //       // max: {
                  //       //   value: 24,
                  //       //   message: "Atmost 24 hour down time allowed"
                  //       // }
                  //     })}
                  //     readOnly
                  //     type='number' className='form-control' />
                  //   {/* {errors.mopDownTime && (
                  //     <p className="error">
                  //       {errors.mopDownTime.message}
                  //     </p>
                  //   )} */}
                  // </>
                  // :
                  // <input style={{ height: "32px" }}
                  //   type='text' className='form-control'
                  //   value={singleSitestate.mopDownTime ? singleSitestate.mopDownTime : ""} />
                  //   }

                  // </div>
                }
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Crane Needed</label>

                  {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                    <>
                      <select style={{ height: "32px" }}
                        {...register("CraneNeeded", {
                          required: "Please Select Crane Nedded"
                        })}
                        className="form-control" aria-label="Default select example">
                        <option selected disabled value={""}>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>

                      </select>
                      {errors.CraneNeeded && (
                        <p className="error">
                          {errors.CraneNeeded.message}
                        </p>
                      )}
                    </>
                    : <input style={{ height: "32px" }}
                      type='text' className='form-control'

                      value={
                        singleSitestate.CraneNeeded
                          ? singleSitestate.CraneNeeded
                          : ""
                      } />
                  }

                </div>



                {/* {userDetails.rolename=="Vendor (ASP) IX Crew Manager" || userDetails.rolename=="Vendor (SP) CX Crew Manager"||userDetails.rolename=="GC Login"? */}
                {/* <>
                      // <div className="form-group col-md-3">
                      //   <label htmlFor="inputEmail4">MOP</label>
                      //   <select  style={{height:"32px"}}
                      //   {
                      //     ...register("mop", {
                      //     onChange: (e) => { changeMop(e) }
                      //   })
                      //   }
                      //   className="form-group" aria-label="Default select example">
                      //     <option selected>Select</option>
                      //     <option value="Day Mop">Day Mop</option>
                      //     <option value="Night Mop">Night Mop</option>

                      //     </select>
                      // </div>

                      <div className="form-group col-md-3">
                        {mop_reason}
                        {errors.reason && (
                          <p className="error">
                            {errors.reason.message}
                          </p>
                        )}
                        
                      </div>
                      </> */}
                {/* :""} */}

                {/* {userDetails.rolename!="Vendor (ASP) IX Crew Manager" && userDetails.rolename!="Vendor (SP) CX Crew Manager" && userDetails.rolename!="GC Login"? 
                    <>
                    
                      </>
                    :""}
                    */}


                {userDetails.rolename != "Vendor (ASP) IX Crew Manager" && userDetails.rolename != "Vendor (SP) CX Crew Manager" && (userDetails.rolename != "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                  <>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Status</label>
                      <select style={{ height: "32px" }}
                        {
                        ...register("status", {
                          required: "Status is required",
                          onChange: (e) => { changestatus(e) }

                        })
                        }
                        className="form-group" aria-label="Default select example">
                        <option selected disabled value="">Select</option>
                        <option value="Approved">Approve</option>
                        <option value="Rejected">Reject</option>
                        <option value="Pending">Pending</option>

                      </select>
                      {errors.status && (
                        <p className="error">
                          {errors.status.message}
                        </p>
                      )}
                    </div>

                    <div className="form-group col-md-3">

                      {required_reason}
                      {/* {
                  required_reason==true?: <input type='text' className='form-group' {...register("reason")} />
                } */}

                      {errors.reason && (
                        <p className="error">
                          {errors.reason.message}
                        </p>
                      )}

                    </div>
                  </>
                  : ""}

              </> : <>
                {userDetails.rolename == "PAG Engineer" || userDetails.rolename == "Manage Engineer" || (userDetails.rolename == "Manage Engineer" && userDetails.department.values.includes("MOP")) || (userDetails.rolename == "GC Login" && (whatWork == "assigner" || whatWork == "reassigner")) ?
                  <>
                    {/* <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">MOP</label>
                  <select style={{ height: "32px" }}
                    {
                    ...register("mop", {
                      onChange: (e) => { changeMop(e) }
                    })
                    }
                    className="form-group" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="Day Mop">Day Mop</option>
                    <option value="Night Mop">Night Mop</option>

                  </select>
                </div> */}
                    {/* {mop_reason ? <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Reason</label>
                  <input type='text' className='form-group' {...register("dayMopReason", { required: "Reason is required" })} />
                  {errors.dayMopReason && (
                    <p className="error">
                      {errors.dayMopReason.message}
                    </p>
                  )}
                </div>

                  : ''
                } */}





                    {/* <div className="form-group col-md-3">
                <label htmlFor="inputEmail4">Reason</label>
                {singleSitestate.mop=="Day Mop"?<input style={{height:"32px"}}  value={singleSitestate.dayMopReason? singleSitestate.dayMopReason: ""} className='form-group'/>:""}
                
              </div> */}
                    {/* <br />
                    <br />

                    <Tabs
                      defaultActiveKey={accordianOperner}
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      onSelect={(e) => {
                        setAccordianOperner(e)
                      }}
                    >
                      <Tab eventKey="IXCrew" title="IX Crew">
                        {/* <div
                          id="flush-collapseOneaa"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOneaa"
                          data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"> */}
                    {/* <div className="form-row mt-2 p-2">
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              IX Search By----
                            </label>

                            <div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="ContactNumber"
                                    onChange={onOptionChange}
                                    name="optradio1"
                                  />
                                  Contact Number
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="Email"
                                    onChange={onOptionChange}
                                    name="optradio1"
                                  />
                                  E-mail
                                </label>
                              </div>
                              <div class="form-check-inline disabled">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="LeadName"
                                    onChange={onOptionChange}
                                    name="optradio1"
                                  />
                                  Lead Name
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-5">
                            <label for="exampleFormControlSelect1">
                              <span style={{ color: "red" }}>*</span>
                              Enter Lead Name
                            </label>


                            <select style={{ height: "32px" }}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              {...register("ixCrewId", {
                                onChange: (e) => {
                                  handleChange(e, "IXCrew", "b");
                                },
                              })}
                            >
                              {ixArray}
                            </select>
                          </div>{" "}
                          <div className="form-group col-md-2 mt-2">
                            <label for="exampleFormControlSelect1"></label>
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              IX Crew Vendor
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              readOnly
                              {...register("IXCrewVendor")}
                            />
                          </div>
                          <div className="form-group col-md-4 ">
                            <label for="exampleFormControlSelect1">
                              IX Crew Lead Contact Number
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              readOnly
                              {...register("IXCrewPhone")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              IX Crew Lead Name
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("IXCrewName")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              IX Crew Lead Company{" "}
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("IXCrewCompany")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              IX Crew Lead E-mail ID
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("IXCrewEmail")}
                            />
                          </div> */}
                    {/* </div>
                          </div> */}
                    {/* 
                        </div>
                      </Tab>
                      <Tab eventKey="CXCrew" title="CX Crew"> */}
                    {/* <div
                          id="flush-collapseOnea"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOnea"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body"> */}
                    {/* <div className="form-row mt-2 p-2">
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              CX Search By work----
                            </label>

                            <div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="ContactNumber"
                                    onChange={onOptionChange}
                                    name="optradio"
                                  />
                                  Contact Number
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="Email"
                                    onChange={onOptionChange}
                                    name="optradio"
                                  />
                                  E-mail
                                </label>
                              </div>
                              <div class="form-check-inline disabled">
                                <label class="form-check-label">
                                  <input
                                    style={{ height: "20px" }}
                                    type="radio"
                                    className="form-check-input"
                                    value="LeadName"
                                    onChange={onOptionChange}
                                    name="optradio"
                                  />
                                  Lead Name
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-5">
                            <label for="exampleFormControlSelect1">
                              <span style={{ color: "red" }}>*</span>
                              Enter Lead Name
                            </label>


                            <select style={{ height: "32px" }}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              {...register("cxCrewId", {
                                onChange: (e) => {
                                  handleChange(e, "CXCrew", "b");
                                },
                              })}
                            >
                              {cxArray}
                            </select>
                          </div>{" "}
                          <div className="form-group col-md-2 mt-2">
                            <label for="exampleFormControlSelect1"></label>
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              CX Crew Vendor
                            </label>

                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              readOnly
                              {...register("CXCrewVendor")}
                            />
                          </div>
                          <div className="form-group col-md-4 ">
                            <label for="exampleFormControlSelect1">
                              CX Crew Lead Contact Number
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              readOnly
                              {...register("CXCrewPhone")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              CX Crew Lead Name
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("CXCrewName")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              CX Crew Lead Company{" "}
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("CXCrewCompany")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label for="exampleFormControlSelect1">
                              CX Crew Lead E-mail ID
                            </label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              {...register("CXCrewEmail")}
                            />
                          </div>
                        </div> */}
                    {/* </div>
                        </div> */}
                    {/* </Tab>
                    </Tabs> */}

                    {/* <div className="form-group col-md-12 accordion" id="">
                      <div className="accordion-item">
                        <h2
                      className="accordion-header"
                      id="flush-headingOnea"
                      > }
                        <Button
                          className="accordion-button collapsed mr-2"
                          type="button"
                          variant="outline-primary btn-xxs"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOneaa"
                          aria-expanded="false"
                          aria-controls="flush-collapseOneaa"
                        >
                          IX Crew
                        </Button>
                        <Button
                          className="accordion-button collapsed mr-2"
                          type="button"
                          variant="outline-primary btn-xxs"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOnea"
                          aria-expanded="false"
                          aria-controls="flush-collapseOnea"
                        >
                          {" "}
                          CX Crew{" "}
                        </Button>


                        </h2>
                      </div>
                    </div> */}


                    {whatWork == "assigner" ?
                      <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} /> : ""}
                    {console.log(whatWork,"whatWorkwhatWork")}
                    {console.log(DbName, "listalldataone")}
                    {whatWork == "reassigner" ? DbName[1] && DbName[0] && DbName[2] ?
                      <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} /> : "" : ""}



                  </> : ""}

              </>}



          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary btn-xs py-2 px-4 mt-2" disabled={isDisable ? true : false} >
              Submit
            </button>
          </div>
        </form>
      </div >

    </div >
  )
}

export default UpdatedSiteApprovalModal