import React, { Fragment } from 'react'

// import PageTItle from '../../../layouts/PageTitle'
import { SplitButton, ButtonGroup, Dropdown,Button } from 'react-bootstrap';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';




const NetworkTwo = () => {

 

  return (
    <Fragment>
    {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

    <div style={{ display: 'block', width: "100%", padding: 30 }}>
    {/* <h4>React-Bootstrap Tab Component</h4> */}
   


   
      <div className='row mt-3'>


        
     
     {/* <Button className='ml'variant='light'  > <i className="fa fa-angle-double-left" > Back to Grid</i></Button>
      <Button className='ml-2' variant='info'>  <i className="fa fa-angle-double-right" > Submit Request</i></Button>
      <Button className='float-right' variant='info' style={{marginLeft:"600px"}}> <i className="fa fa-save" > Save </i></Button>

      */}
     

       <div className='card mt-3' style={{width:"100%"}}>
         
     

       <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOneDetails">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

      
      ><span style={{color:"red"}}>*</span>
Add New Site
 </Button>
    </h2>
    <div
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
     
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1"> Site Id</label>
     <input type="text" className="form-control" id="inputAddress" placeholder="Enter Side Id" />

     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Project Code</label>
     <input type="text" className="form-control" id="inputAddress" placeholder="Enter Project code" />

     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Technology </label>
     <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Technology" />

     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Account</label>
     <select className="form-control" id="exampleFormControlSelect1" >
       <option>--All--</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Market</label>
     <select className="form-control" id="exampleFormControlSelect1" >
       <option>--All--</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Equipment</label>
     <select className="form-control" id="exampleFormControlSelect1" >
       <option>--All--</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">NW Engineer Responsible for Site Info</label>
     <select className="form-control" id="exampleFormControlSelect1" >
       <option>--Select Engineer--</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">Field Ops</label>
     <select className="form-control" id="exampleFormControlSelect1" >
       <option>--Select Filed Ops--</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
     </div>
   
   
   
    
     
   </div>

      </div>
    </div>
  </div>
      </div>
    
   
     </div>
     <div className='card' style={{width:"100%"}}>
         
    

         <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOnea">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOnea"
        aria-expanded="false"
        aria-controls="flush-collapseOnea"
      >
Site Information   
</Button>
    </h2>
    <div
      id="flush-collapseOnea"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOnea"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Date Add</label>
           <input type="date" className="form-control" id="inputAddress" placeholder="" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Configuration</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--Select--</option>
       <option>Current Configuration</option>
       <option>Final Configuration</option>
       
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Site Type</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--Select--</option>
       <option>DIY</option>
       <option>OEM</option>
       <option>Building</option>

       
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">SRAN</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter SRAN" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">BTS </label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter BTS" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">BSC </label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter BSC" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">UMTS</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>UMTS ID</option>
       <option>UMTS ID 2nd</option>
       <option>UMTS ID 3rd</option>
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">RNC</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter RNC" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">LTE </label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>LTE ID</option>
       <option>LTE ID 2nd</option>
       <option>LTE ID 3rd</option>
       <option>LTE ID 4th</option>
       <option>LTE ID 5th</option>
       <option>LTE ID 6th</option>
       <option>LTE ID 7th</option>
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">5G</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>5G ID</option>
       <option>5G ID 2nd</option>
       <option>5G ID 3rd</option>
       <option>5G ID 4th</option>
       <option>5G ID 5th</option>
       <option>5G ID 6th</option>
       <option>5G ID 7th</option>
     
     </select>
           </div>

           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">OEM</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>NOKIA</option>
       <option>ERICSSON</option>
       <option>SAMSUNG</option>
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">GSM OSS ID</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--select--</option>
       <option>SY3OSS</option>
       <option>SYUOSS60</option>
       <option>SY3OSS</option>
       <option>SYL3ENM</option>
       
       
       <option>SY4OSS</option>
       <option>Pinnacle</option>
       <option>Metairai road</option>
       
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">5G OSS ID</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--select--</option>
       <option>SY3OSS</option>
       <option>SYUOSS60</option>
       <option>SY3OSS</option>
       <option>SYL3ENM</option>
       
       
       <option>SY4OSS</option>
       <option>Pinnacle</option>
       <option>Metairai road</option>
       
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">UMTS OSS ID</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--select--</option>
       <option>SY3OSS</option>
       <option>SYUOSS60</option>
       <option>SY3OSS</option>
       <option>SYL3ENM</option>
       
       
       <option>SY4OSS</option>
       <option>Pinnacle</option>
       <option>Metairai road</option>
       
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">LTE OSS ID</label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>--select--</option>
       <option>SY3OSS</option>
       <option>SYUOSS60</option>
       <option>SY3OSS</option>
       <option>SYL3ENM</option>
       
       
       <option>SY4OSS</option>
       <option>Pinnacle</option>
       <option>Metairai road</option>
       
     
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Site Name </label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Site Name" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Site Address</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Site Address" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">City </label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter City " />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Physical County</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Physical County" />

           </div>
       
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Latitude</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Latitude" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Longitude</label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="Enter Latitude" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Comments</label>
           <textarea  type="text" className="form-control" id="inputAddress" placeholder="" />

           </div>
         </div>
        
      </div>
    </div>
  </div>
        </div>
        
    
       </div>
     
   

     <div className='card' style={{width:"100%"}}>
    
       <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOneEngg">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOneEngg"
        aria-expanded="false"
        aria-controls="flush-collapseOneEngg"
      >
RNDCIQ

</Button>
    </h2>
    <div
      id="flush-collapseOneEngg"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOneEngg"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
         <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">RNDCIQ  Engineer</label>
         <select className="form-control" id="exampleFormControlSelect1">
       <option>(Select)</option>
       <option>Adarsh</option>
       <option>Alok</option>
       <option>Amit</option>
       <option>Amrita</option>
     </select>
         </div>
         <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">RNDCIQ Status</label>
   <select className="form-control" id="exampleFormControlSelect1">
     <option>(Select)</option>
     <option>In-Progress</option>
     <option>Complete</option>
     <option>Awating</option>
     <option>NA</option>
   </select>
         </div>
        
         <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">RNDCIQ Requested Date</label>
         <input type="date" className="form-control" id="inputAddress" placeholder="" />

         </div>
         
        <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">RNDCIQ Submission Date</label>
         <input type="date" className="form-control" id="inputAddress" placeholder="" />
         


       </div>
       <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">Comments(If Any)</label>
         <textarea type="text" className="form-control" id="inputAddress" placeholder="" />
         


       </div>
       </div>
       
      </div>
    </div>
  </div>
</div>  

   
     </div>
     <div className='card' style={{width:"100%"}}>
    
    <div className="accordion accordion-flush" id="accordionFlushExample">
<div className="accordion-item">
 <h2 className="accordion-header" id="flush-headingOneEnggg">
   <Button
     className="accordion-button collapsed mr-2"
     type="button"
     variant='outline-primary btn-xxs'

     data-bs-toggle="collapse"
     data-bs-target="#flush-collapseOneEnggg"
     aria-expanded="false"
     aria-controls="flush-collapseOneEnggg"
   >
Scripting Details

</Button>
 </h2>
 <div
   id="flush-collapseOneEnggg"
   className="accordion-collapse collapse"
   aria-labelledby="flush-headingOneEnggg"
   data-bs-parent="#accordionFlushExample"
 >
   <div className="accordion-body">
   <div className="form-row mt-2 p-2">
      <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Scripting Engineer</label>
      <select className="form-control" id="exampleFormControlSelect1">
    <option>(Select)</option>
    <option>Adarsh</option>
    <option>Alok</option>
    <option>Amit</option>
    <option>Amrita</option>
  </select>
      </div>
      <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Scripting Request Date</label>
      <input type="date" className="form-control" id="inputAddress" placeholder="" />

      </div>
     
      <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">LKF Request Date</label>
      <input type="date" className="form-control" id="inputAddress" placeholder="" />

      </div>
      
     <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">LKF Receive Date</label>
      <input type="date" className="form-control" id="inputAddress" placeholder="" />
      


    </div>

    <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Scripting Status</label>
      <select className="form-control" id="exampleFormControlSelect1">
    <option>(Select)</option>
    <option>Complete</option>
    <option>On-Hold</option>
    <option>In-Progress</option>
    <option>NA</option>
  </select>
      </div>
      <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Scripts Upload</label>
      <input type="file" className="form-control" id="inputAddress" placeholder="" />
      


    </div>
    <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Scripts Upload status</label>
      <select className="form-control" id="exampleFormControlSelect1">
    <option>(Select)</option>
    <option>Yes</option>
    <option>NO</option>

  </select>      


    </div>
    <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Script Release Date</label>
      <input type="date" className="form-control" id="inputAddress" placeholder="" />
      


    </div>
    <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Comments(If Any)</label>
      <textarea type="text" className="form-control" id="inputAddress" placeholder="" />
      


    </div>
    </div>
    
   </div>
 </div>
</div>
</div>  


  </div>
  <div className='card' style={{width:"100%"}}>
    
    <div className="accordion accordion-flush" id="accordionFlushExample">
<div className="accordion-item">
 <h2 className="accordion-header" id="flush-headingOneEngw">
   <Button
     className="accordion-button collapsed mr-2"
     type="button"
     variant='outline-primary btn-xxs'

     data-bs-toggle="collapse"
     data-bs-target="#flush-collapseOneEngw"
     aria-expanded="false"
     aria-controls="flush-collapseOneEngw"
   >
Management Details

</Button>
 </h2>
 <div
   id="flush-collapseOneEngw"
   className="accordion-collapse collapse"
   aria-labelledby="flush-headingOneEngw"
   data-bs-parent="#accordionFlushExample"
 >
   <div className="accordion-body">
   <div className="form-row mt-2 p-2">
     
      
     
      
      
     <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Price</label>
      <input type="date" className="form-control" id="inputAddress" placeholder="Enetr Price" />
      


    </div>
    <div className="form-group col-md-4">
      <label for="exampleFormControlSelect1">Comments(If Any)</label>
      <textarea type="text" className="form-control" id="inputAddress" placeholder="" />
      


    </div>
    </div>
    
   </div>
 </div>
</div>
</div>  


  </div>

   
 </div>
 
  </div>

   
   
    

   

  </Fragment>
  )
}

export default NetworkTwo
