import React, { Fragment, useEffect, useState, useRef } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button, Card, Col } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import "../../../../css/formError.css";
import { useDispatch, useSelector } from "react-redux";
import { async } from "q";
import { uploadSiteTicketAction, CSVUploadAction, PDFUploadAction, NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import { AccountReducerAction, GetMarketReducerAction, ProjectBucketReducerAction, ProjectStatusReducerAction, GetFilterUserReducerAction, gms_utms, FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { columns, data } from './Data.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import { accountApi, allEquipmentApi, allTechnologyApi, getMarket, projectBucketApi, projectStatusApi, fetchFilterUser, ossApi, crewCompanyApi, singleRFDS, groupByoss, listSiteId, projectCode, siteDblistSiteId, OneSiteDatabaseProjectCode, OneSiteDatabase } from "../../../../common/config";

// import { setValue } from "@syncfusion/ej2-base";
import "react-data-table-component-extensions/dist/index.css";
// import { default as MultiSelect, components } from "react-select";
import { useHistory } from "react-router-dom";
import { height } from "@mui/system";
import { reformArray } from "../../../../common/variables";
import { MultiSelect } from "react-multi-select-component";

const NetworkTwo = (props) => {


  // console.log("props",props)

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#24292D",
      minHeight: "2px",

      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? ''
        : isSelected
          ? ''
          : undefined,
      zIndex: 1
    }),
    menu: base => ({
      ...base,
      // overrIDe border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: styles => ({
      ...styles,

    })
  };
  const url = window.location.href.split("/")
  let ID = url.pop()

  const dateObject = new Date();
  const currentDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  const firstDate = `01/01/${dateObject.getFullYear()}`;
  const firstDateObject = new Date(firstDate);
  const timeDifference = dateObject.getTime() - firstDateObject.getTime();
  const totalWeek = Math.ceil(timeDifference / (1000 * 3600 * 24 * 7));


  // Table variables
  const [umtsArray, setUmt] = useState([])
  const [lteArray, setLte] = useState([])
  const [bandArray, set5G] = useState([])
  const [listBucket, setListBucket] = useState([])
  const [listUser, setListUser] = useState({})
  const [selectedClient, setSelectedClient] = useState([]);
  const [accordianState, setAccordianState] = useState(false)
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [countRender, setCountRender] = useState(1)
  const [projectCodeState, setProjectCodeState] = useState('')
  const [crCodeState, setCrCodeState] = useState('')
  const history = useHistory()
  let columns = []
  let rowData = []
  let rowDataOne = []
  function handleSelectChange(event) {
    console.log(event)
  }
  function accordianHandler() {
    setAccordianState(!accordianState)
  }


  // const s
  let temp = []
  let options = []
  let pStatusArry = []
  let gsm_utmsdata = []
  let accountArry = []
  let onelistUserArry = []
  let twolistUserArry = []
  let threelistUserArry = []
  let fourlistUserArry = []
  let fivelistUserArry = []
  let sixlistUserArry = []
  let listUserArry = {}
  let marketArry = []
  let filteruserlist = []
  let equipmentArry = [];
  let technologyArray = [];
  let umtFieldData = [];
  let lteFieldData = [];
  let mixedModeFieldData = [];
  let fiveGFieldData = [];
  let fetchpdfdata = []
  let ossOptionArry = {}
  let crewCompanyArray = []
  let ossResp = []
  let bscArray = []
  let btsArray = []
  let rncArray = []
  let allProjects = [];
  let projectCodeArray = []
  let projectCodeOption = []
  let siteArray = [];
  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      weekOfAddition: totalWeek,
      dateOfAddition: currentDate,
      // group:'development'
    }
  });
  const dispatch = useDispatch();

  const addalldata = (site) => {
    setValue("siteId", site)

    umtFieldData.push("U" + site);
    lteFieldData.push("L" + site);
    mixedModeFieldData.push("M" + site);
    fiveGFieldData.push("N" + site);
    for (let i = 2; i <= 3; i++) {
      umtFieldData.push("U" + site + i);
      lteFieldData.push("L" + site + i);
      mixedModeFieldData.push("M" + site + i);
      fiveGFieldData.push("N" + site + i);
    }
    console.log("csfvsdrfg", lteFieldData);
    console.log("csfvsdrfg", mixedModeFieldData);
    console.log("csfvsdrfg", umtFieldData);
    console.log("csfvsdrfg", fiveGFieldData);

  }

  const csvhandleChange = (event) => {
    console.log(event, "event")
    // addalldata("siteID")
    setValue("siteId", event.siteId, { shouldValIDate: true })
    // siteIDChangeHandler(event.siteID)
    // setSite(event.siteID)

    setSite(1)
    setValue("latitude", event.latitude)
    setValue("longitute", event.longitude)
    setValue("siteAddress", event.address)
    setValue("cityState", event.cityState)
    setValue("region", event.region)

  };

  const copycsvhandleChange = (event) => {
    console.log(event, "event")
    // addalldata("siteID")
    setValue("siteId", event.siteId, { shouldValIDate: true })
    // siteIDChangeHandler(event.siteID)
    // setSite(event.siteID)

    setSite(1)
    setValue("latitude", event.latitude)
    setValue("longitute", event.longitude)
    setValue("siteAddress", event.address)
    setValue("cityState", event.city + ", " + event.state)
    setValue("region", event.region)

  };
 
  var userDetails = JSON.parse(localStorage.getItem("userDetails"));

  //Error States
  let [siteIDError, setSiteIDError] = useState('')
  let [technologyError, setTechnologyError] = useState(false)
  let [projectCodeError, setProjectCodeError] = useState('')
  let [crCodeError, setCrCodeError] = useState('')

  let [umtsOptionArray, setOptionArray] = useState([]);
  let [lteOptionArray, setLteOptionArray] = useState([]);
  let [mixedModeOptionArray, setMixedModeOptionArray] = useState([]);
  let [fiveGOptionArray, setFiveGOptionArray] = useState([]);
  let [site, setSite] = useState("");
  let [rfds, setRFDS] = useState(0);
  let [tech, setTech] = useState([]);
  let [selectedUMTS, setSelectedUMTS] = useState([])
  let [selectedLte, setSelectedLte] = useState([])
  let [selected5g, setSelected5g] = useState([])
  let [selectedMixedMode, setSelectedMixedMode] = useState([])
  let [noOfRender, setNoRender] = useState(1)
  const siteIDRef = useRef();



  // Change handler function for multiple select input fields 
  const techhandleChange = (e) => {
    console.log("multiselect=", tech)
    setTechnologyError(false)
    setTech(e)
  }
  const UMTSChangeHandler = (data) => {
    setSelectedUMTS(data)
  }
  const lteChangeHandler = (data) => {
    setSelectedLte(data)
  }
  const fiveGChangeHandler = (data) => {
    setSelected5g(data)
  }
  const mixedModeChangeHandler = (data) => {
    setSelectedMixedMode(data)
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks)
    console.log("file=", siteFile)
    console.log(columns)
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "OCR")
    // form.append("remarks",remarks);
    setRemarks("")
    console.log("the form data is =", form)
    // uploadedFile()
    dispatch(PDFUploadAction(singleRFDS, form))
    setCountRender(1)
    console.log("form data =", form)
    // await csvhandleChange(options)
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0], "dsadsaads")
    uploadedFile(e.target.files[0]);
  };




  const findDSiteId = (id) => {
    if (allProjects.length > 0)
      console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
    let idObject = allProjects.filter((item) => item.siteId == id)
    if (idObject.length > 0) {
      dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
    }
  }


  const findDProjectId = (id) => {

    setCountRender(1)
    console.log(id, projectCodeArray, "projectCodeArray")
    if (projectCodeArray.length > 0)
      console.log("the respasfsdvgfdsbdtrh =", ...projectCodeArray.filter((item) => item.projectCode == id))
    let idObject = projectCodeArray.filter((item) => item.projectCode == id)
    if (idObject.length > 0) {
      dispatch(FetchSingleRecordAction(OneSiteDatabase, projectCodeArray.filter((item) => item.projectCode == id)[0].uniqueid))
    }
  }

  const handleChange = (event, nane, arra) => {
    let ind = event.target.selectedIndex
    console.log(nane, arra, ind)

    if (ind == 0) {
      setValue(nane + "Name", "Name")
      setValue(nane + "Email", "Email")
      setValue(nane + "Phone", "Phone")
    }

    if (ind != 0) {

      filteruserlist.map((item) => {
        console.log(item.role == arra)
        if (item.role == arra) {
          console.log(item.data[ind - 1])

          let { roleEmail, roleName, roleNumber } = item.data[ind - 1]

          console.log(roleEmail, roleName, roleNumber, "roleEmail,roleName,roleNumber")
          setValue(nane + "Name", roleName)
          setValue(nane + "Email", roleEmail)
          setValue(nane + "Phone", roleNumber)
        }
      })

    }



  };
  //

  // const s

  const siteIDChangeHandler = (site) => {
    // addalldata(e)

    console.log(site, "sitesitesitesite")
    setValue("siteId", site)
    setSiteIDError("")
    umtFieldData.push("U" + site);
    lteFieldData.push("L" + site);
    mixedModeFieldData.push("M" + site);
    fiveGFieldData.push("N" + site);

    dispatch(gms_utms("/PAG/gsm_utms/" + site))
    for (let i = 2; i <= 3; i++) {
      umtFieldData.push("U" + site + i);
      lteFieldData.push("L" + site + i);
      mixedModeFieldData.push("M" + site + i);
      fiveGFieldData.push("N" + site + i);
    }

    temp = [];
    let lteTemp = [];
    let mixedModeTemp = [];
    let fiveGTemp = [];
    lteTemp = lteFieldData.map((item) => {
      return {
        value: item,
        label: item,
      };
      // return <option value={item}>{item}</option>
    });
    mixedModeTemp = mixedModeFieldData.map((item) => {
      return {
        value: item,
        label: item,
      };
      // return <option value={item}>{item}</option>
    });
    fiveGTemp = fiveGFieldData.map((item) => {
      return {
        value: item,
        label: item,
      };
      // return <option value={item}>{item}</option>
    });
    console.log(umtFieldData, "umtFieldData");
    temp = umtFieldData.map((item) => {
      return {
        value: item,
        label: item,
      };

      // return <option value={item}>{item}</option>
    });
    console.log("temp==", temp);
    setOptionArray(temp);
    setLteOptionArray(lteTemp);
    setMixedModeOptionArray(mixedModeTemp);
    setFiveGOptionArray(fiveGTemp);
    console.log("umtsOptionArray=", umtsOptionArray);
  };
  //Week and Date
  let siteData = {}
  //State Manage Section
  options = useSelector((state) => {


    console.log("dfwef=", state);
    if (ID != "createProject") {
      let siteInfo = state.OneCommonResponse.singleSite
      if (typeof (siteInfo) == typeof (siteData)) {
        siteData = siteInfo
        console.log("the single site info =", siteData)

        return (siteData)
      }


    }
    else {

      let resp = state.CommonResponse.projectBucktet;
      console.log("resp =", resp);
      if (resp.length > 0) {
        temp = resp.map((item, index) => {
          if (index == 0) {
            // setValue("projectBucket", item.uniqueid)
            console.log(item.uniqueid, "projectBucketitem.uniqueID")
            return <option value={item.uniqueid}>{item.Bucket}</option>;
          }
          else {
            return <option value={item.uniqueid}>{item.Bucket}</option>;
          }
        });
      }
      projectCodeArray = state.OneCommonResponse.productCode
      if (projectCodeArray && projectCodeArray.length > 0) {
        projectCodeOption = projectCodeArray.map((item) => {
          return <option>{item.projectCode}</option>
        })
      }

      console.log(state, "the single site")
      let siteInfo = state.OneCommonResponse.singleSite
      console.log(typeof (siteInfo), siteInfo.length, typeof (siteData), countRender == 1, countRender, "siteInsiteInfo")
      if (countRender == 1) {
        if (siteInfo.length == undefined || siteInfo.length > 0) {
          console.log(siteInfo, "siteInfo,siteInfo")
          setCountRender(2)
          setValue("siteId", '')
          copycsvhandleChange(siteInfo)
          siteIDChangeHandler(siteInfo.siteId)
        }
        console.log("file data==", fetchpdfdata)
        // siteIDChangeHandler(fetchpdfdata.siteID)
      }
      console.log(siteInfo, "siteInfositeInfo")
      allProjects = state.CommonResponse.startAwaitedSite
      // console.log("all projects is =",allProjects)
      if (allProjects && allProjects.length > 0) {

        siteArray = allProjects.map((project) => {
          // console.log("the project is =========",project)
          return <option>{project.siteId}</option>;
        });
      }
      let projectStatus = state.CommonResponse.projectStatus;
      if (projectStatus.length > 0 && pStatusArry.length == 0) {
        pStatusArry = projectStatus.map((item, index) => {
          console.log(item.uniqueid, "projectStatusitem.uniqueID")
          if (index == 0) {
            // setValue("projectStatus", item.uniqueid)
            return <option value={item.uniqueid} >{item.Status}</option>;
          }
          else {
            return <option value={item.uniqueid}>{item.Status}</option>;
          }
        });
      }



      let account = state.CommonResponse.accounts;
      if (account.length > 0 && accountArry.length == 0) {
        accountArry = account.map((item) => {
          return <option value={item.uniqueid}>{item.accountName}</option>;
        });
      }
      let crewCompany = state.CommonResponse.crewCompany
      if (crewCompany && crewCompany.length > 0 && crewCompanyArray.length == 0) {
        crewCompanyArray = crewCompany.map((item) => {
          return <option value={item.uniqueid}>{item.crewCompanyName}</option>
        })

      }
      ossResp = state.CommonResponse.oss.ossData
      if (ossResp && ossResp.length != 0) {
        console.log("the updateed oss =", ossResp)
        ossResp.map((item) => {
          console.log("the oss object=", item)
          ossOptionArry[item.name] = item.data.map((temp) => {
            return <option>{temp}</option>
          })

        })
        console.log("the Aranged data=", ossOptionArry)
      }
      let gsm_utms = state.CommonResponse.gsm_utms;
      if (gsm_utms && gsm_utms.length > 0) {
        console.log(" && gsm_umts.length>0", gsm_utms)

        gsm_utmsdata = gsm_utms.map((item, index) => {

          console.log("itemitemitem", item)
          if (item.BSC != "None") {
            // ossResp.push({"uniqueID":item.BSC,"OssID":item.BSC})

            bscArray.push(<option>{item.BSC}</option>)
            console.log("options.ossOptionArry", options, options.ossOptionArry)
          } if (item.BTS != "None") {
            // ossResp.push({"uniqueID":item.BSC,"OssID":item.BSC})

            btsArray.push(<option>{item.BTS}</option>)
            console.log("options.ossOptionArry", options, options.ossOptionArry)
          }

          if (item.RNC != "None") {

            console.log(item.RNC)

            rncArray.push(<option>{item.RNC}</option>)
            console.log(umtFieldData, "umtFieldData")
          }

          if (countRender == 1) {

            setCountRender(2)
            siteIDChangeHandler(item.MAIN_ID)
            console.log("file data==", fetchpdfdata)
            // siteIDChangeHandler(fetchpdfdata.siteID)
          }

          console.log(item.BSC, item.BTS, item.RNC, "data", "gsm_utmsdata")
        });
      }

      filteruserlist = state.CommonResponse.filteruserlist
      if (filteruserlist.length > 0) {
        console.log(filteruserlist, "filteruserlist")
        // return <option value={item.uniqueID}>{item.accountName}</option>
        let dadadata = {}

        filteruserlist.map((item) => {
          console.log(item, "item")

          if (item.role == "MobileTeam Manager") {


            onelistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            onelistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            onelistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })

          }
          else if (item.role == "Cluster Engineer") {


            twolistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            twolistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            twolistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })
            // twolistUserArry=[item.roleEmail,item.roleName,item.mobilenumber]
          } else if (item.role == "FOPS Manager") {
            threelistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            threelistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            threelistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })
          } else if (item.role == "FOPS Engineer") {
            fourlistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            fourlistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            fourlistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })
          } else if (item.role == "RF Engineer") {
            fivelistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            fivelistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            fivelistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })
          } else if (item.role == "Site Development Manager") {
            sixlistUserArry["name"] = item.data.map((item) => { return <option value={item.roleName}>{item.roleName}</option> })
            sixlistUserArry["email"] = item.data.map((item) => { return <option value={item.roleEmail}>{item.roleEmail}</option> })
            sixlistUserArry["phone"] = item.data.map((item) => { return <option value={item.roleNumber}>{item.roleNumber}</option> })

          }


        })


        console.log(dadadata, "dadadata")
      }



      fetchpdfdata = state.CommonResponse.fetchedpdfdata
      if (countRender == 1) {
        if (fetchpdfdata != 0) {
          setCountRender(2)
          setValue("siteId", '')
          csvhandleChange(fetchpdfdata)
          siteIDChangeHandler(fetchpdfdata.siteId)
        }
        console.log("file data==", fetchpdfdata)
        // siteIDChangeHandler(fetchpdfdata.siteID)
      }
      let market = state.CommonResponse.markets;
      if (market.length > 0 && marketArry.length == 0) {
        marketArry = market.map((item) => {
          return <option value={item.uniqueid}>{item.marketName}</option>;
        });
      }
      let technology = state.CommonResponse.technology;
      if (technology.length > 0 && technologyArray.length == 0) {
        technologyArray = technology.map((item) => {
          return {
            value: item.uniqueid,
            label: item.technologyName,
          };
        });
      }
      let equipment = state.CommonResponse.equipments;
      if (equipment.length > 0 && equipmentArry.length == 0) {
        equipmentArry = equipment.map((item) => {
          return <option value={item.uniqueid}>{item.equipmentName}</option>;
        });
      }

      console.log("list bucket arry =", listBucket);

    }
    return {
      temp,
      pStatusArry,
      accountArry,
      marketArry,
      technologyArray,
      equipmentArry,
      fetchpdfdata,
      ossOptionArry,
      crewCompanyArray,
      gsm_utmsdata,
      bscArray,
      btsArray,
      rncArray,
      siteArray,
      projectCodeOption
    };
  });
  console.log("options =", options);
  console.log("rfds = out", rfds)
  if (rfds == 0) {
    setRFDS(1)
    console.log("rfds = in", rfds, props.location, props.location.state != undefined)
    if (props.location.rdfsItem != "" && props.location.state != undefined) {
      console.log("setter = in", rfds, props.location)
      console.log(props.location, "props")
      setValue("siteId", '')
      csvhandleChange(props.location.rdfsItem)
      siteIDChangeHandler(props.location.rdfsItem.siteId)
    }
  }

  console.log("gc login mop")


  let [bandCount, setBandCount] = useState(1);
  let [lteCount, setLteCount] = useState(1);
  let [count, setCount] = useState(2);
  const addUMTSFields = () => {
    setCount(count++);

    setUmt([...umtsArray, count <= 7 ? <option>USITEID{count}</option> : null]);
    setCount(count++);
  };
  const addLTEFields = () => {
    setLteCount(lteCount++);

    if (lteCount <= 9) {
      setLte([
        ...lteArray,
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">LTE ID {lteCount}</label>
          <input
            type="text"
            className="form-control"
            placeholder={"LTE ID " + lteCount}
            {...register("LteID" + lteCount)}
          />
        </div>,
      ]);
      setLteCount(lteCount++);
    }
  };






  const add5GField = () => {
    setBandCount(bandCount++);

    if (bandCount <= 9) {
      set5G([
        ...bandArray,
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">5G {bandCount}</label>
          <input
            type="text"
            className="form-control"
            placeholder={"5G ID " + bandCount}
            {...register("5G" + bandCount)}
          />
        </div>,
      ]);
      setBandCount(bandCount++);
    }
  };
  // console.log("project bucket =",options.temp[0].props.value)
  const onSubmit = async (data) => {
    // if(site==''){
    //   setSiteIDError("Please enter site ID")
    //   return
    // }
    // if(site.length<6){
    //   setSiteIDError("Please enter valID site ID")
    //   return 
    // }
    // if(site.length>9){
    //   setSiteIDError("Only 9 Characters are allowed")
    //   return
    // }
    // if(projectCodeState==''){
    //   setProjectCodeError("Please enter project code")
    //   return
    // }
    // if(projectCodeState.length<6){
    //   setProjectCodeError("Please enter valID project code")
    //   return
    // }
    // else if(projectCodeState.length>8){
    //   setProjectCodeError("Please enter valID project code")
    //   return
    // }
    // if(tech==null || tech.length==0){
    //   setTechnologyError("Please Select Technology")
    //   return
    // }
    // data['siteID']=site;

    if (tech.length > 0) {
      data['technology'] = reformArray(tech)
    }
    else {
      setTechnologyError(true)
      return null
    }

    data['dateOfAddition'] = currentDate
    data['weekOfAddition'] = totalWeek
    data['engineerAddingProject'] = userDetails.name
    data['UMTSID'] = selectedUMTS.length > 0 ? reformArray(selectedUMTS) : []
    data['LTEID'] = selectedLte.length > 0 ? reformArray(selectedLte) : []
    data['5GID'] = selected5g.length > 0 ? reformArray(selected5g) : []
    data['projectCode'] = projectCodeState
    data['crCode'] = crCodeState
    data['mixedModeID'] = selectedMixedMode.length > 0 ? reformArray(selectedMixedMode) : []
    console.log("ubmit ======vdfdbfxbgd=====", data);

    dispatch(uploadSiteTicketAction(data));
    data = {};
    dispatch(NetworkDatabaseAction())
    // history.push("/PAG/network/database")
  };
  console.log("fe");
  useEffect(() => {
    dispatch(ProjectBucketReducerAction(projectBucketApi));
    dispatch(ProjectStatusReducerAction(projectStatusApi));
    dispatch(AccountReducerAction(accountApi));
    dispatch(GetMarketReducerAction(getMarket));
    dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(AccountReducerAction(allEquipmentApi));
    dispatch(GetFilterUserReducerAction(fetchFilterUser))
    dispatch(AccountReducerAction(groupByoss))
    dispatch(AccountReducerAction(crewCompanyApi))
    dispatch(AccountReducerAction(siteDblistSiteId))
    if (options.fetchpdfdata) {
      csvhandleChange(options.fetchpdfdata)
    }
  }, []);

  return (
    <Fragment>
      {/* <PageTItle activeMenu='valIDation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%", padding: 30 }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <div className="row mt-3">
          <div className="card" style={{ width: "100%" }}>
            <div
              className="accordion accordion-flush"
              ID="accordionFlushrfds">
              <div className="accordion-item">
                <h2 className="accordion-header" ID="flush-headingrfds">
                  <Button
                    className="accordion-button collapsed mr-2"
                    type="button"
                    variant="outline-primary btn-xxs"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapserfds"
                    aria-expanded="false"
                    aria-controls="flush-collapserfds"
                  >
                    Form Filled by RFDS File
                  </Button>
                </h2>
                <div
                  ID="flush-collapserfds"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingrfds"
                  data-bs-parent="#accordionFlushrfds"
                >
                  <div className="accordion-body">
                    <form onSubmit={submitHandler} encType="multipart/form-data" className="mx-2">
                      <div className="input-group mb-3 fileSection">

                        <div>
                          <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input style={{ height: "32px" }}
                              type="file"
                              className="form-control btn btn-primary btn-xs mt-3 mr-2"
                              ID="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              name="file"
                              onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose File
                          </label>
                          {/* <label>Remarks</label>
                            <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

                        </div>

                        <button
                          className="btn btn-outline-primary btn-xs col-xm-12"
                          type="submit"
                          ID="inputGroupFileAddon04"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            {/* <Button className='ml'variant='light'  > <i className="fa fa-angle-double-left" > Back to GrID</i></Button>
      <Button className='ml-2' variant='info'>  <i className="fa fa-angle-double-right" > Submit Request</i></Button>
      <Button className='float-right' variant='info' style={{marginLeft:"600px"}}> <i className="fa fa-save" > Save </i></Button>

      */}



            <div className="card " style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOneDetails">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                    >
                      <span style={{ color: "red" }}>*</span>
                      Add New Site
                    </Button>
                  </h2>
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">

                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                {" "}
                                Site Id
                              </label>
                              <input style={{ height: "32px" }}
                                type="text"

                                className="form-control"
                                ID="inputAddress"
                                placeholder="Enter Site Id"
                                readOnly
                                defaultValue={url != "createProject" ? options.siteID : ""}

                              // {
                              // ...register("siteId", {
                              //   onBlur: (e) => { siteIDChangeHandler(e.target.value) },
                              //   required: "Site ID is required",
                              //   minLength: {
                              //     value: 8,
                              //     message: "Please enter valid site id"
                              //   },
                              //   maxLength: {
                              //     value: 16,
                              //     message: "Only 16 characters are allowed"
                              //   }
                              // })
                              // }
                              />
                              {errors.siteID && (
                                <p className="error">
                                  {errors.siteID.message}
                                </p>
                              )}
                            </div>
                            :
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                {" "}
                                Site Id
                              </label>
                              {/* <input style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                ID="inputAddress"
                                placeholder="Enter Site Id"
                                //  defaultValue={url!="createProject"?options.siteID:""}

                                {
                                ...register("siteId", {
                                  onBlur: (e) => { siteIDChangeHandler(e.target.value) },
                                  required: "Site ID is required",
                                  minLength: {
                                    value: 8,
                                    message: "Please enter valID site ID"
                                  },
                                  maxLength: {
                                    value: 16,
                                    message: "Only 16 characters are allowed"
                                  }
                                })
                                }
                              />
                              {errors.siteID && (
                                <p className="error">
                                  {errors.siteID.message}
                                </p>
                              )} */}
                              <input className='form-control' list="cars"
                                style={{ height: "32px" }}
                                // onBlur={(e)=>{
                                //     findDSiteId(e.target.value)
                                //   }}

                                {
                                ...register("siteId", {
                                  required: "Please enter Site id",
                                  onBlur: (e) => {
                                    findDSiteId(e.target.value)
                                    setProjectCodeError('')
                                    console.log("project Code ==jbjhbjhk", projectCodeError)
                                  }
                                })
                                }

                              // {
                              //   ...register("siteId",{
                              //     required:"Site Id is required",
                              //     onChange: (e) => {
                              //       console.log("single pro ", e.target.value);
                              //       console.log("single pro ", e.target.label);


                              //     },
                              //     onBlur:(e)=>{
                              //       findDSiteId(e.target.value)
                              //     } ,
                              //     minLength:{
                              //       value:5,
                              //       message:"Please enter valid site id"
                              //     },
                              //     maxLength:{
                              //       value:16,
                              //       message:"Only 16 characters are allowed"
                              //     }
                              //   })
                              // }

                              />
                              {errors.siteId && (
                                <p className="error">
                                  {errors.siteId.message}
                                </p>
                              )}
                              <datalist id="cars">

                                {options.siteArray}
                              </datalist>

                            </div>
                        }
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Project Code
                          </label>
                          <input style={{ height: "32px" }} className="form-control" list="projectCode"
                            value={projectCodeState}

                            {
                            ...register("projectCode", {
                              required: "Please enter project code",
                              onChange: (e) => {
                                setProjectCodeState(e.target.value)
                                findDProjectId(e.target.value)
                                setProjectCodeError('')
                                console.log("project Code ==jbjhbjhk", projectCodeError)
                              }
                            })
                            }
                          />
                          {errors.projectCode && (
                            <p className="error">
                              {errors.projectCode.message}
                            </p>
                          )}
                          <datalist id="projectCode">
                            {options.projectCodeOption}
                          </datalist>
                          {/* <input
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Project code"
                            list="colors"
                            {...register("projectCode", {
                              required: "Please enter project code",
                              minLength: {
                                value: 8,
                                message: "Please enter valID ID",
                              },
                              maxLength: {
                                value: 16,
                                message: "Only 16 characters are allowed",
                              },
                            })}
                          /> */}

                          {projectCodeError.length !== 0 ?
                            <p className="error">
                              {projectCodeError}
                            </p>
                            : <p></p>
                          }
                        </div>

                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Technology
                          </label>
                          <MultiSelect
                            // className="dark"

                            options={options.technologyArray}
                            onChange={techhandleChange}
                            value={tech}
                          />
                          {/* <MultiSelect
                            options={options.technologyArray}
                            // styles={customStyles}
                            isMulti
                            name="colors"
                            closeMenuOnSelect={false}
                            hIDeSelectedOptions={false}
                            components={{ Option }}
                            onChange={techhandleChange}
                            value={tech}
                            allowSelectAll={true}
                            variant="danger"
                            styles={customStyles}
                            classNamePrefix="select"
                          /> */}
                          <p className="error" style={{ display: technologyError ? "block" : "none" }}>Please select technology</p>


                          {/* <input
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Technology"
                            {...register("technology",{
                              required:"Please enter technology",
                              maxLength:{
                                value:32,
                                message:"Only 32 characters are allowed"
                              }
                            })}
                          /> */}
                          {/* {errors.technology && (
                            <p className="error">{errors.technology.message}</p>
                          )} */}
                        </div>

                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">Account</label>
                              <input className="form-control" readOnly type="text" defaultValue={options.account}></input>
                            </div>
                            : <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">Account</label>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                ID="exampleFormControlSelect1"
                                {...register("account", {
                                  required: "Please select account"
                                })}
                              >
                                <option selected disabled value=''>select</option>
                                {options.accountArry}
                              </select>
                              {errors.account && (
                                <p className="error">
                                  {errors.account.message}
                                </p>
                              )}
                            </div>
                        }
                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">Market</label>
                              <input className="form-control" readOnly type="text" defaultValue={options.market}></input>
                            </div>
                            :
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">Market</label>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                ID="exampleFormControlSelect1"
                                {...register("market", {
                                  required: "Please select market"
                                })}
                              >
                                <option selected disabled value=''>select</option>
                                {options.marketArry}
                              </select>
                              {errors.account && (
                                <p className="error">
                                  {errors.account.message}
                                </p>
                              )}
                            </div>
                        }
                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">Equipment</label>
                              <input className="form-control" readOnly type="text" defaultValue={options.equipment}></input>
                            </div>
                            :
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                Equipment
                              </label>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                ID="exampleFormControlSelect1"
                                {...register("equipment", {
                                  required: "Please select equipment"
                                })}
                              >
                                <option selected disabled value=''>select</option>
                                {options.equipmentArry}
                              </select>
                              {errors.equipment && (
                                <p className="error">
                                  {errors.equipment.message}
                                </p>
                              )}
                            </div>

                        }

                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Engineer Adding Project
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"

                            value={ID != "createProject" ? options.engineerAddingProject : userDetails.name}
                            disabled
                            // placeholder="Enter Technology"
                            {...register("engineerAddingProject")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Date Of Addition
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            
                            className="form-control"
                            // ID="inputAddress"
                            disabled
                            value={ID != "createProject" ? options.dateOfAddition : currentDate}
                            {...register("dateOfAddition")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Week #
                          </label>
                          <input style={{ height: "32px" }}
                            type="number"
                            className="form-control"
                            ID="inputAddress"
                            defaultValue={ID != "createProject" ? options.weekOfAddition : totalWeek}
                            disabled
                            {...register("weekOfAddition")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">Group</label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {...register("group")}
                          >
                            <option selected>Development</option>
                            <option>Operation</option>
                          </select>
                        </div>
                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                Project Bucket
                              </label>
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                defaultValue={options.projectBucket}
                              ></input>
                            </div> :
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                Project Bucket
                              </label>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                ID="exampleFormControlSelect1"
                                {...register("projectBucket",
                                  { required: "Please Select Project Bucket" })}
                              >
                                {options.temp}
                              </select>
                              {errors.projectBucket && (
                                <p className="error">
                                  {errors.projectBucket.message}
                                </p>)
                              }
                            </div>
                        }
                        {
                          ID != "createProject" ?
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                Project Status
                              </label>
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                defaultValue={options.projectStatus}
                              ></input>
                            </div> :
                            <div className="form-group col-md-4">
                              <label for="exampleFormControlSelect1">
                                Project Status
                              </label>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                ID="exampleFormControlSelect1"
                                {...register("projectStatus",
                                  { required: "Please Select Project Status" })}
                              >
                                {options.pStatusArry}
                              </select>
                              {errors.projectStatus && (
                                <p className="error">
                                  {errors.projectStatus.message}
                                </p>)
                              }
                            </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOnea">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOnea"
                      aria-expanded="false"
                      aria-controls="flush-collapseOnea"
                    >
                      Contact Info
                    </Button>
                  </h2>
                  <div
                    ID="flush-collapseOnea"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOnea"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">

                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            T Mobile Manger
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("tMobileMangerName", {
                              onChange: (e) => { handleChange(e, "tMobileManger", "MobileTeam Manager") }
                            })
                            }
                          >
                            <option value=''>Name</option>
                            {onelistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            T Mobile Manger Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("tMobileMangerEmail", {
                              onChange: (e) => { handleChange(e, "tMobileManger", "MobileTeam Manager") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {onelistUserArry.email}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            T Mobile Manger Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("tMobileMangerPhone", {
                              onChange: (e) => { handleChange(e, "tMobileManger", "MobileTeam Manager") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {onelistUserArry.phone}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Cluster Engineer
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("clusterEngineerName", {
                              onChange: (e) => { handleChange(e, "clusterEngineer", "Cluster Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Name</option>
                            {twolistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Cluster Engineer Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("clusterEngineerEmail", {
                              onChange: (e) => { handleChange(e, "clusterEngineer", "Cluster Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {twolistUserArry.email}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Cluster Engineer Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("clusterEngineerPhone", {
                              onChange: (e) => { handleChange(e, "clusterEngineer", "Cluster Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {twolistUserArry.phone}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Manager Name
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsManagerName", {
                              onChange: (e) => { handleChange(e, "fopsManager", "FOPS Manager") }
                            })
                            }
                          >
                            <option selected value=''>Name</option>
                            {threelistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Manager Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsManagerEmail", {
                              onChange: (e) => { handleChange(e, "fopsManager", "FOPS Manager") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {threelistUserArry.email}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Manager Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsManagerPhone", {
                              onChange: (e) => { handleChange(e, "fopsManager", "FOPS Manager") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {threelistUserArry.phone}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Engineer
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsEngineerName", {
                              onChange: (e) => { handleChange(e, "fopsEngineer", "FOPS Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Name</option>
                            {fourlistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Engineer Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsEngineerEmail", {
                              onChange: (e) => { handleChange(e, "fopsEngineer", "FOPS Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {fourlistUserArry.email}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            FOPS Engineer Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("fopsEngineerPhone", {
                              onChange: (e) => { handleChange(e, "fopsEngineer", "FOPS Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {fourlistUserArry.phone}

                          </select>

                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            RF Engineer Name
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("rfEngineerName", {
                              onChange: (e) => { handleChange(e, "rfEngineer", "RF Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Name</option>
                            {fivelistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            RF Engineer Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("rfEngineerEmail", {
                              onChange: (e) => { handleChange(e, "rfEngineer", "RF Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {fivelistUserArry.email}

                          </select>

                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            RF Engineer Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("rfEngineerPhone", {
                              onChange: (e) => { handleChange(e, "rfEngineer", "RF Engineer") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {fivelistUserArry.phone}

                          </select>

                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Site Development Manager Name
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("siteDevelopmentManagerName", {
                              onChange: (e) => { handleChange(e, "siteDevelopmentManager", "Site Development Manager") }
                            })
                            }
                          >
                            <option selected value=''>Name</option>
                            {sixlistUserArry.name}

                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Site Development Manager Email
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("siteDevelopmentManagerEmail", {
                              onChange: (e) => { handleChange(e, "siteDevelopmentManager", "Site Development Manager") }
                            })
                            }
                          >
                            <option selected value=''>Email</option>
                            {sixlistUserArry.email}

                          </select>

                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Site Development Manager Phone
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {
                            ...register("siteDevelopmentManagerPhone", {
                              onChange: (e) => { handleChange(e, "siteDevelopmentManager", "Site Development Manager") }
                            })
                            }
                          >
                            <option selected value=''>Phone</option>
                            {sixlistUserArry.phone}

                          </select>

                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Vendor (SP) CX Tower Crew Company
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {...register("CXTowerCrewVendorCompany")}
                          >
                            <option selected disabled value="">select</option>
                            {options.crewCompanyArray}
                            {/* <option >MobileComm Professionals Inc</option>
                            <option>T-Mobile</option>
                            <option>BetaCom</option>
                            <option>Hitechtower</option>
                            <option>Steel waves</option>
                            <option>Cross Roads</option>
                            <option>Americom Wireless</option>
                            <option>T&T Network Solutions</option>
                            <option>FWC Communication</option>
                            <option>CT Networks</option>
                            <option>Point Wireless</option>
                            <option>Womacktower</option> */}
                          </select>
                        </div>{" "}
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Vendor (ASP) IX Field Engineer Company
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {...register("IXFieldEngineerVendorCompany")}
                          >
                            <option selected disabled value="">select</option>
                            {options.crewCompanyArray}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card" style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOneEngg">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneEngg"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneEngg"
                    >
                      Site Information/OSS
                    </Button>
                  </h2>
                  <div
                    ID="flush-collapseOneEngg"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneEngg"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">

                        <Col xl='4'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">GSM OSS</label>
                                <select style={{ height: "32px", wIDth: "290px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("gsmOss")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry.GSM : ""}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">GSM BSC ID</label>
                                <select style={{ height: "32px", wIDth: "290px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("gsmOss")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.bscArray}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">GSM BTS ID</label>
                                <select style={{ height: "32px", wIDth: "290px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("gsmBtsID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.btsArray}
                                </select>
                              </div>

                            </Card.Body>

                          </Card>
                        </Col>
                        <Col xl='4'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>

                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">UMTS OSS </label>
                                <select style={{ height: "32px", wIDth: "270px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("UMTSOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry.UMTS : ""}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">UMTS RNC  ID</label>
                                <select style={{ height: "32px", wIDth: "270px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("UMTSRNCID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.rncArray}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">UMTS </label>
                                <MultiSelect style={{ wIDth: "290px" }}
                                  options={umtsOptionArray}

                                  isMulti
                                  name="colors"
                                  closeMenuOnSelect={false}
                                  hIDeSelectedOptions={false}

                                  onChange={UMTSChangeHandler}
                                  value={selectedUMTS}
                                  allowSelectAll={true}
                                  styles={customStyles}
                                  variant="danger"
                                  classNamePrefix="select"
                                />

                              </div>



                            </Card.Body>

                          </Card>
                        </Col>
                        <Col xl='4'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>
                              <div className="form-group col-sm-12">
                                <label for="exampleFormControlSelect1">LTE/5G OSS</label>
                                <MultiSelect style={{ wIDth: "290px" }}
                                  options={lteOptionArray}

                                  isMulti
                                  name="colors"
                                  closeMenuOnSelect={false}
                                  hIDeSelectedOptions={false}

                                  onChange={lteChangeHandler}
                                  value={selectedLte}
                                  allowSelectAll={true}
                                  styles={customStyles}
                                  variant="danger"
                                  classNamePrefix="select"
                                />

                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">5G </label>
                                <select style={{ height: "32px", wIDth: "270px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("5GOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry['5G'] : ""}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">
                                  Mixed Mode
                                </label>
                                <MultiSelect
                                  options={mixedModeOptionArray}

                                  isMulti
                                  name="colors"
                                  closeMenuOnSelect={false}
                                  hIDeSelectedOptions={false}

                                  onChange={mixedModeChangeHandler}
                                  value={selectedMixedMode}
                                  allowSelectAll={true}
                                  styles={customStyles}
                                  variant="danger"
                                  classNamePrefix="select"
                                />

                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">LTE </label>
                                <select style={{ height: "32px", wIDth: "270px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("LTEOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry.LTE : ""}
                                </select>
                              </div>


                            </Card.Body>

                          </Card>
                        </Col>
                        {/* <Col xl='3'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>
                              <div className="form-group col-lg-12">
                                <label for="exampleFormControlSelect1">5G</label>
                                <MultiSelect 
                                  options={lteOptionArray}

                                  isMulti
                                  name="colors"
                                  closeMenuOnSelect={false}
                                  hIDeSelectedOptions={false}

                                  onChange={lteChangeHandler}
                                  value={selectedLte}
                                  allowSelectAll={true}
                                  styles={customStyles}
                                  variant="danger"
                                  classNamePrefix="select"
                                />

                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">5G OSS ID</label>
                                <select style={{height:"32px",wIDth:"200px"}}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("5GOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry['5G'] : ""}
                                </select>
                              </div>



                            </Card.Body>

                          </Card>
                        </Col> */}


                        <Col xl='4'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>

                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">CRAN Cabinets OSS </label>
                                <select style={{ height: "32px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("CRANCabinetsOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry['CRAN Cabinets'] : ""}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">
                                  CRAN Cabinets
                                </label>
                                <textarea style={{ height: "32px" }}
                                  className="form-control"
                                  {...register("cranCabinets")}
                                ></textarea>
                              </div>


                            </Card.Body>

                          </Card>
                        </Col>
                        <Col xl='4'>
                          <Card style={{ border: "1px solID white" }} className='text-white '>

                            <Card.Body>

                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">SRAN Cabinets OSS </label>
                                <select style={{ height: "32px" }}
                                  className="form-control"
                                  ID="exampleFormControlSelect1"
                                  {...register("SRANCabinetsOSSID")}
                                >
                                  <option selected disabled value=''>select</option>
                                  {options.ossOptionArry ? options.ossOptionArry['SRAN Cabinets'] : ""}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="exampleFormControlSelect1">
                                  SRAN Cabinets
                                </label>
                                <textarea style={{ height: "32px" }}
                                  className="form-control"
                                  {...register("SranCabinets")}
                                ></textarea>
                              </div>



                            </Card.Body>

                          </Card>
                        </Col>

                        {/* <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Mixed Mode
                          </label>
                          <MultiSelect
                            options={mixedModeOptionArray}
                      
                            isMulti
                            name="colors"
                            closeMenuOnSelect={false}
                            hIDeSelectedOptions={false}
                            components={{Option}}
                            onChange={mixedModeChangeHandler}
                            value={selectedMixedMode}
                            allowSelectAll={true}
                            styles={customStyles}
                            variant="danger"
                            classNamePrefix="select"
                          />

                        </div> */}


                        {/* <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Mixed Mode
                          </label>
                          <MultiSelect
                            options={mixedModeOptionArray}

                            isMulti
                            name="colors"
                            closeMenuOnSelect={false}
                            hIDeSelectedOptions={false}
                            components={{ Option }}
                            onChange={mixedModeChangeHandler}
                            value={selectedMixedMode}
                            allowSelectAll={true}
                            styles={customStyles}
                            variant="danger"
                            classNamePrefix="select"
                          />

                        </div>
                        */}

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOneEnggg">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneEnggg"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneEnggg"
                    >
                      Site Location Iformation
                    </Button>
                  </h2>
                  <div
                    ID="flush-collapseOneEnggg"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneEnggg"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Latitude
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Latitude"
                            {...register("latitude")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Longitude
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Longitute"
                            {...register("longitute")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Site Address
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Site Address"
                            {...register("siteAddress")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">City, State </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter City "
                            {
                            ...register("cityState")
                            }
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Region
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Region"
                            {
                            ...register("region")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Form Section */}
            <div className="card" style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOneEngw">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneEngw"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneEngw"
                    >
                      SITE Historical Information
                    </Button>
                  </h2>
                  <div
                    ID="flush-collapseOneEngw"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneEngw"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Site Type
                          </label>
                          <select style={{ height: "32px" }}
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {...register("siteType")}
                          >
                            <option>DIY</option>
                            <option>O&M</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            CR Numbers
                          </label>
                          <input style={{ height: "32px" }} className="form-control" list="cars"
                            value={crCodeState}
                            onChange={(e) => {
                              setCrCodeState(e.target.value)
                              setCrCodeError('')
                            }}

                          />
                          <datalist ID="cars">
                            <option value="BMW" />
                            <option value="Bentley" />
                            <option value="Mercedes" />
                            <option value="Audi" />
                            <option value="Volkswagen" />
                          </datalist>
                          {/* <select
                            className="form-control"
                            ID="exampleFormControlSelect1"
                            {...register("crNumbers")}
                          >
                            <option></option>
                            <option>DIY</option>
                            <option>O&M</option>
                          </select> */}
                        </div>
                        {/* <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">Issue List</label>
                          <input
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Issue List"
                            {
                              ...register("issueList")
                            }
                          />
                        </div> */}
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Trouble Tickets
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Trouble Tickets"
                            {...register("troubleTickets")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Login/Logouts History
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Login/Logouts History"
                            {...register("loginLogoutsHistory")}
                          />
                        </div>
                        <div className="col-md-4">
                          RIOT:
                          <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input style={{ height: "32px" }}
                              type="file"
                              className="form-control btn btn-primary"
                              ID="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              name="file"
                              {...register("RIOTFile")}
                            // onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose RIOT File
                          </label>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">RIOT</label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="RIOT Comment"
                            {...register("RIOTComment")}
                          />
                        </div>
                        <div className="col-md-4 text-xm">
                          CBN:
                          <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input style={{ height: "32px" }}
                              type="file"
                              className="form-control btn btn-primary"
                              ID="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              name="file"
                              {...register("CBNFile")}
                            // onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose CBN File
                          </label>
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">CBN</label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="CBN"
                            {...register("CBNComment")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            BASE PO(s)
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="BASE PO(s)"
                            {...register("BASEPOs")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            CR PO(s)
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder="CR PO(s)"
                            {...register("CRPOs")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label for="exampleFormControlSelect1">
                            Comments(If Any)
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            ID="inputAddress"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                ID="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" ID="flush-headingOneEngw">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xxs"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneEngw"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneEngw"
                    >
                      File Uploads
                    </Button>
                  </h2>
                  <div
                    ID="flush-collapseOneEngw"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneEngw"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div>
                          RNDCIQ:
                          <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input style={{ height: "32px" }}
                              type="file"
                              className="form-control btn btn-primary"
                              ID="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              name="file"
                              {...register("RNDCIQCsvFile")}
                            // onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose RNDCIQ
                            File
                          </label>
                        </div>
                        <hr />
                        <div>
                          CDD:
                          <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input style={{ height: "32px" }}
                              type="file"
                              className="form-control btn btn-primary"
                              ID="inputGroupFile04"
                              aria-describedby="inputGroupFileAddon04"
                              aria-label="Upload"
                              name="file"
                              {...register("CDDFile")}
                            // onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose CDD File
                          </label>
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <button className="btn btn-outline-danger btn-xs">Reset</button>
          <button type="submit" className="btn btn-outline-primary btn-xs mx-2">
            Submit
          </button>
        </form>
      </div>
    </Fragment>
  );
}

export default NetworkTwo;
