import React, { Fragment, useState, useEffect } from "react";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';
// import { addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, projectCode, ranAddSupport, singleProjectApi } from "../../../common/config";;
// import { AccountReducerAction, addUserAction, FetchSingleRecordAction, UserListReducerAction } from "../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";
import { Tab, Tabs } from 'react-bootstrap';
import Setcx from '../Forms/Ran Form/Setcx'
import Setix from '../Forms/Ran Form/Setix'
import { useHistory, useLocation } from "react-router-dom";
import { NetworkDatabaseAction } from "../../../store/actions/dashboardActions";
import { AccountReducerAction, addUserAction, FetchSingleRecordAction, popUpUpdate, TierListReducerAction, UserListReducerAction } from "../../../store/actions/createTicketManageFields";
import { accountApi, addCrNumber, allTechnologyApi, enooaddApi, ixModule, ixSiteStatus, listennoTicketApi, listSiteId, listUserApi_two, managEngineer, OneRanTech, OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, projectCode, ranAddSupport, ranDashboard, ranlistSite, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, ranSiteStatus, singleProjectApi, siteDblistSiteId, troubleTicketApi } from "../../../common/config";
import { MultiSelect } from "react-multi-select-component";
import CommonCrew from "../Forms/Ran Form/commoncrew";
import AdvancedCommonCrew from "../Forms/Ran Form/advancedcommoncrew";
import moment from "moment"
import AdvmultipleTechnologyForm from "../Forms/Ran Form/AdvmultipleTechnologyForm";
import { commonzone, time_zonechanger } from "../../../common/variables";
import MultipleTechnology from "../Forms/Ran Form/multipleTechnologyForm";
import Advcommoncrewupdated from "../Forms/Ran Form/advcommoncrewupdated";
const SpeedTest = () => {




  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});
  let [accordianOperner, setAccordianOperner] = useState('CXCrew')
  let [selectProjectCode, setProjectCode] = useState([])
  let [selectProjectId, setProjectId] = useState([])
  let [EditedId, setEditedId] = useState([])
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  let currentUrl = window.location.href
  let currentUrlnosplit = window.location.href
  console.log("the current url is =", currentUrl)
  currentUrl = currentUrl.split("/")
  let pageType = currentUrl.pop()
  let dashboardType =currentUrl.pop()
  console.log("kbjjkhsbfkjherf==",dashboardType)
  let pageState = false
  if (pageType != "form") {
    pageState = true
  }
  var path = window.location.pathname;
  const history = useHistory()
  let editableId = query.get('ids')

  


  let pageFromdata = "E911"

  const [ontimeran, setontimeran] = useState(true);
  const [UpdateRanData, setUpdateRanData] = useState(false);
  const [fload, setfload] = useState(true);
  const [ixData, setixData] = useState();
  const [ixDataid, setixDataid] = useState();
  let [DbName, setDbName] = useState([]);

  const [ixName, setixName] = useState();

  const [ttdataidd, setttdataidd] = useState("");

  let dynaamicForm = [{
    label: "",
    type: "",
    value: ""
  }]



  let [SelselectProjectCode, setSelProjectCode] = useState([])
  const [npcode, setpcode] = useState("");
  const [npidd, setpidd] = useState("");
  const [sidd, setsidd] = useState("");
  const [techread, settechread] = useState("");
  const [techreadarr, settechreadarr] = useState([]);

  let parentId = "Self"
  // let ranId = query.get('ranId')

  let type, edit_index, creatorId, dataObj;

  console.log(ontimeran)
  if (history.location && ontimeran) {
    if (history.location.state) {
      editableId = history.location.state.ids
      type = history.location.state.type
      edit_index = history.location.state.index
    }
    if (history.location.creator) {
      parentId = history.location.creator.creatorId
      pageFromdata = history.location.creator.createFrom
      setUpdateRanData(true)
      setontimeran(false)
      dataObj = {
        "siteUniqueId": history.location.creator.siteUniqueId,
        "ranId": history.location.creator.ranId,
        "projectUniqueId": history.location.creator.projectUniqueId,

      }
    }
  }

  console.log("errors","  ",errors)
  let errorlen = Object.keys(errors).length
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#24292D",
      minHeight: "2px",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? ''
        : isSelected
          ? ''
          : undefined,
      zIndex: 1
    }),


    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: styles => ({
      ...styles,
    })
  };

  let singleSite = {};
  let siteArray = [];
  let siteArrayAll = [];
  let ttcategory = [];
  let array_main = [];
  let listalldata = [];
  let technologyArray = []
  let projectCodeArray = []
  let projectIdArray = []
  let engineerArray = []
  let projectIdOption = []

  const [cxdata, setcxdata] = useState("");
  const [ixdata, setixdata] = useState("");
  const [cxCrew, getCxCrew] = useState('');
  const [iXCrew, getIxCrew] = useState("")
  let [oneprid, setoneprid] = useState('')
  let [thePId, setPId] = useState('')
  let [SelectedSingleSIte, setSingleSIte] = useState('')
  let [stopenTTs, setstopenTTs] = useState('')
  let [OneTT, setOneTT] = useState(true)
  let [ppOneSite, setppOneSite] = useState('')



  let [ond, setond] = useState(true)
  const [countRender, setCountRender] = useState(1)
  let [RenderDrOne, setRenderDrOne] = useState(true);
  let [ocnd, setocnd] = useState(true)
  let [opcnd, setopcnd] = useState(true)
  let [opind, setopind] = useState(true)
  let [ppProjectCode, setppProjectCode] = useState('')
  let [ppProjectId, setppProjctId] = useState('')
  const [checkboxOpen, setCheckboxOpen] = useState("")
  const [EngineerOpen, setEngineerOpen] = useState([]);
  const [closer, setCloser] = useState(false);
  let awaitedSiteArray = []
  let projectCodeOption = []
  // dispatch(fetechSingleProject(singleProjectApi,id))
  let [plannedSow, setPlannedSow] = useState([]);
  let [techaff, settechaff] = useState([]);
  let [ttdata, setttdata] = useState([]);
  let [psapdata, setpsapdata] = useState([]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  

  const handleShow = () => setShow(true);
  const [techchanggg, settechchanggg] = useState(true);
  const dispatch = useDispatch();

  const [umtsArray, setUmt] = useState([]);

  const [cxArray, setcx] = useState([]);
  const [ixArray, setix] = useState([]);

  const [lteArray, setLte] = useState([]);
  let [lteCount, setLteCount] = useState(1);
  let [count, setCount] = useState(1);
  let [mopReason, setMopState] = useState(false)
  const [newAccount, setAccount] = useState({});





  let [techie, setTech] = useState([]);
  let [allTech, setallTech] = useState([]);
  let newTtempArray = []
  let [selectedTech, setSelectedTech] = useState()
  let [ctrCount, setCtrCount] = useState(1);
  let [actualSiteId, setSiteId] = useState()
  // let history = useHistory()


  let multipleTechno = []
  let customError = {
    "projectCode": "Please Select Project Code",
    "projectId": "Please Select Site Project Id",
    "technology": "Please Select Technology",
    "technologyAffected": "Please Select Technology Affected",
    "tTType": "Please Select TT Type",
    "uploadFile": "Please Upload File",
    "uploadImage": "Please Upload Image "
  }                                            

  console.log(history, "history")

  
  let accountArry=[]
  
  let pdsingleSite = [];  
  let [oneTimeAccount, setoneTimeAccount] = useState(true)


  let techAffectedArray = [
    {
      value: "GSM",
      label: "GSM",
    },
    {
      value: "UTMS",
      label: "UTMS",
    },
    {
      value: "LTE",
      label: "LTE",
    },
    {
      value: "5G",
      label: "5G",
    },
  ];
  let tttypeArray = [
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "48 Hr. Alarm",
      label: "48 Hr. Alarm",
    },
    {
      value: "Post IX RF Support",
      label: "Post IX RF Support",
    },
    {
      value: "Customer Request",
      label: "Customer Request",
    },
    {
      value: "PAG Internal",
      label: "POST IX RF SUPPORT",
    },
    {
      value: "Troubleshooting",
      label: "Troubleshooting",
    },
    {
      value: "TMO FOPS",
      label: "TMO FOPS",
    },
    {
      value: "TMO RF",
      label: "TMO RF",
    },
    {
      value: "RTWP",
      label: "RTWP",
    },
  ];
  let plannedSowArray = [
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "troubleShooting",
      label: "Troubleshooting",
    },
  ];


  const techAffectedChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    settechaff(e);
  };
  const plannedSowChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setPlannedSow(e);
  };
  const ttChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setttdata(e);
  };



  const abc = (abcd, name) => {
    console.log(abcd, name);

    setcxdata(<Setcx getCxCrew={getCxCrew} />)
    setixdata(<Setix getIxCrew={getIxCrew} />)

    if (name == "cx") {
      setixdata("");
    }
    if (name == "ix") {
      setcxdata("");
    }
  };


  const techChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    let arre=[]
    e.map((itt)=>{
      arre.push({
        label:itt.label,
        value:itt.label
      })
    })
    settechreadarr(arre)
    setPlannedSow(e);
    settechchanggg(true)
  };
  const fetechSingleProject = (id, siteId) => {
    let siteind=siteArrayAll.findIndex(one=>one.siteId==id)

    if(siteind!=-1 && SelectedSingleSIte!=id){
      setValue("projectUniqueId", "")
      setProjectCode([])
      setProjectId([])
      setPlannedSow([])
      setValue("market", "")
      setValue("sectorNested", {})
    //   // let siteArrayAllId=siteArrayAll[siteind].siteUniqueId
      let siteArrayAllId=siteArrayAll[siteind].uniqueSiteId
      console.log("single pro ");
    // console.log("the actual site id=",siteId)
      setValue("siteUniqueId", id)
      setSingleSIte(id)
      setppOneSite(siteArrayAllId)
      
      setOneTT(true)
      setopind(true)
      dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, siteArrayAllId))

      

      if (path.includes("ranCallTest")) {
        dispatch(FetchSingleRecordAction(ranOneSiteProjectCode, id))
      }
      else if(path.includes("ixCallTest") || path.includes("ixSpeedTest")){
        dispatch(FetchSingleRecordAction(ixModule, id))
      }
        
      setCtrCount(1)
    }
    
    // let siteind=siteArrayAll.findIndex(one=>one.siteId==id)
    // if(siteind!=-1){
    //   // let siteArrayAllId=siteArrayAll[siteind].siteUniqueId
    //   let siteArrayAllId=siteArrayAll[siteind].uniqueSiteId
      
    //   setppOneSite(siteArrayAllId)
    //   setProjectCode([])
    //   setProjectId([])
    //   setPlannedSow([])

    //   setValue("siteUniqueId",id)


    //   if (path.includes("ttdb")) {
    //     dispatch(clearFetchSingleRecordAction())
    //   }
    //   dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, siteArrayAllId))
    // }
    // console.log("single pro ");
    // // console.log("the actual site id=",siteId)
    // setValue("siteUniqueId", id)
    // setSingleSIte(id)
    // setOneTT(true)
    // setopind(true)
    // dispatch(FetchSingleRecordAction(ranOneSiteProjectCode, id))
    // setCtrCount(1)
  };


  const FetechAllDetailSingle = (id) => {
    console.log("FetechAllDetailSingle")
    dispatch(FetchSingleRecordAction(OneRanTech, id));
  }


  const FetechAllDetail = (id) => {
    setProjectId(id)
    let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    console.log(test, "test")
    let tprjid = []
    let newuniqueProjetId = []
    let selectedId = id.map((item) => {
      tprjid.push(item)
      return item.value
    })

    console.log(newuniqueProjetId, "newuniqueProjetIdnewuniqueProjetId")
    // setPId(test.projectId)
    // if (test) {
    //   setond(false)
    //   if (test.length > 0) {
    //     setppProjctId(test.projectId)

    //   }
    // }
    // dispatch(FetchSingleRecordAction(singleProjectApi, id));
    // setppProjctId(test.projectId)

    setValue("projectUniqueId", selectedId.join(","))
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")));

    setTecheer(tprjid)
    // dispatch(FetchSingleRecordAction(ranDashboard, id))
    // // setCtrCount(1)
    // console.log("FetechAllDetail", id)
  }

  const setTecheer = (selectedname) => {
    let alltt = []
    console.log("setTecheer", selectedname)
    let sellid = []


    selectedname.map((ids) => {
      console.log("the ids -=", ids)
      sellid.push(ids.value)
    })

    let arr = []
    console.log(plannedSow, "techitemitemitemitem")

    plannedSow.map((item) => {

      console.log(item, sellid, "itemitemitemitem")

      if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
        arr.push({
          value: item.value,
          label: item.value.split("_CP_")[0],
        });
      }

    })

    console.log(arr, "techarr")
    setPlannedSow(arr)
  }


  const findengineer = (id) => {
    setEngineerOpen(id)
    // console.log(id,"id")
    // if (id != undefined) {

    //   let idObject = engineerArray.filter((item) => {
    //     item.name == id
    //   })
    //   console.log(idObject,"idObject")
    //   // setValue("engineerOpeningtt",idObject)

    // }
  }
  console.log(dataObj, UpdateRanData, "dataObj")

  // let ttcategoryArray = ["E911 Completed", "Speed Test & E911 Completed", "Speed Test Completed", "Not Ready", "Ready", "Failed", "NA"]
  let ttcategoryArray = ["Speed Test Completed", "Not Ready", "Ready", "Failed", "NA"]
  
  const findDProjectId = (id, def = 0, pcode = []) => {
    setCountRender(1)
    console.log("projectCodeArray", id, projectCodeArray)
    setppProjectCode(id)

    setppProjctId('')
    let selectedIds = []
    let selectedname=[]
    let nselectedIds = ""
    if (def == 0) {
      setProjectCode(id)
      selectedIds = id.map((item) => {
        selectedname.push(item.label)
        return item.value
      })
      nselectedIds = selectedIds.join(",")
    } else {
      let testpcode = []

      // testpcode=projectCodeArray.map((ittm)=>{
      //   if(pcode.indexOf(ittm.label)!=-1){
      //     return {
      //       label:ittm.label,
      //       value:ittm.value
      //     }
      //   }
      // })
      // setProjectCode(testpcode)
      nselectedIds = id
    }
    console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

    setSelProjectCode(nselectedIds.split(","))
    // dispatch(FetchSingleRecordAction(ranOneSiteDatabaseprojectId, nselectedIds))
    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))
    setValue("projectUniqueId", id)

    let newProjectIdSel = []
    let newIdProjectIdSel = []
    if (def == 0) {
      selectProjectId.map((item) => {

        console.log(selectedname,item,"item.group,item.group")
        if (selectedname.indexOf(item.group) != -1) {
          newProjectIdSel.push(item)
          newIdProjectIdSel.push(item.value)
        }
      })
      setProjectId(newProjectIdSel)
      // console.log(tech, newProjectIdSel, "techkatech")
      setTecheer(newProjectIdSel)
      // findDProjectId(newProjectIdSel)
    }

  }

  const FetechTTId = (id) => {
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
  }

  const setCheckboxOpenhandleChange = (e) => {
    console.log(e, "setCheckboxOpenhandleChange")
    setCheckboxOpen(e)
    setValue("preExistingTech", e)
  };

  const techAffectedChange = (e) => {
    console.log("multiselect=", techie);
    // setTechnologyError("");
    setSelectedTech(e);
  };


  const addCrDetails = () => {
    let userId = JSON.parse(localStorage.getItem("userDetails"))
    userId = userId.uniqueid
    const sendData = {
      crnumber: getValues("crNumber"),
      siteId: getValues("siteUniqueId"),
      userId: userId
    }
    dispatch(addUserAction(addCrNumber, sendData))
  }
  const projectData = useSelector((state) => {
    console.log("the State is=", state,state.popUpState.popstatus.status);
    let allProjects = state.CommonResponse.startAwaitedSite
    siteArrayAll=allProjects
    let all_enno_data =dashboardType=="ixCallTest" || dashboardType=="ixSpeedTest"?state.OneCommonResponse.singleIXSite: state.CommonResponse.enno_data
    console.log("all projects is =",all_enno_data)

    pdsingleSite = []
    // pdsingleSite = state.OneCommonResponse.pdsingleSite; /// for project
    pdsingleSite = state.csvResponse.singleSite;
    if (state.popUpState) {



      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        
        dispatch(popUpUpdate({}));
        console.log("cjhcker caller",currentUrl,currentUrl.includes("add"),currentUrl.includes("ranCallTest"),currentUrl.includes("ixCallTest"),currentUrl.includes("editenno"))

        if (currentUrlnosplit.includes("add")) {
          history.push("/PAG/E911/dashboard")
        }
        if (currentUrlnosplit.includes("ranCallTest")) {

          dispatch(AccountReducerAction(ranDashboard));
          dispatch(AccountReducerAction(ranSiteStatus));
          history.push("/PAG/ran/support/dashboard")
        }
        else if(currentUrlnosplit.includes("ixCallTest")  || path.includes("ixSpeedTest")){
          dispatch(AccountReducerAction(ixModule));
          dispatch(AccountReducerAction(ixSiteStatus));
          history.push("/PAG/ix/dashboard")
        }
        if (currentUrlnosplit.includes("editenno")) {
          history.push("/PAG/E911/Tracker")
        }
      }


      if (state.popUpState.popstatus.status == 409 && closer) {
        setCloser(false)
        swal({
          title: "Oops",
          text: state.popUpState.popstatus?state.popUpState.popstatus.data.msg:"",
          icon: "error",
          button: "Ok",
        })
        
        dispatch(popUpUpdate({}))
        // setModalCentered(false)
            
      }
    }
    
    console.log("currentUrlnosplit",currentUrlnosplit.includes("editenno"))

    if (currentUrlnosplit.includes("editenno")) {

      console.log(all_enno_data, "all_enno_dataall_enno_data")

      // let siteInfo =[]
      // if (all_enno_data.length > 0 ) {
      //   siteInfo = all_enno_data[0]
      // }
      // else if(all_enno_data){
      //   siteInfo = all_enno_data
      // }
      // if (siteInfo && fload) {
      //   let oneall_enno_data = siteInfo
      //   console.log(oneall_enno_data, "oneall_enno_data")
      //   let pc = oneall_enno_data.projectDataa.map((item) => {
      //     return item.projectId
      //   });
      //   // let newpii = [];
      //   // let pcodd = all_enno_data.projectbUniqueId.map((e) => {
      //   //   newpii.push(e.pId)
      //   //   return e.projectId
      //   // })

      //   setfload(false)
      //   setixData(oneall_enno_data.ixCrewId["_id"]["$oid"])
      //   setpidd(pc.join(", "))
      //   setpcode(oneall_enno_data.projectCode.join(", "))
      //   settechread(oneall_enno_data.techuniqueId)


      //   // alphaSector
      //   // betaSector
      //   // gammaSector
      //   // deltaSector
      //   // epsilonSector
      //   // zetaSector
      //   setValue("alphaSector", oneall_enno_data.alphaSector)
      //   setValue("betaSector", oneall_enno_data.betaSector)
      //   setValue("gammaSector", oneall_enno_data.gammaSector)
      //   setValue("deltaSector", oneall_enno_data.deltaSector)
      //   setValue("epsilonSector", oneall_enno_data.epsilonSector)
      //   setValue("zetaSector", oneall_enno_data.zetaSector)
      //   setValue("OpenTTids", oneall_enno_data.OpenTTids)

      //   setEditedId(oneall_enno_data.ennouniqueId)
      //   console.log("setstopenTTs",singleSite.OpenTTids)
      //   setstopenTTs(oneall_enno_data.OpenTTids)
      //   setValue("siteUniqueId", oneall_enno_data.siteUniqueId)

      //   setCheckboxOpenhandleChange(oneall_enno_data.preExistingTech)
      //   if (oneall_enno_data.preExistingTech) {
      //     setValue("prealphaSector", oneall_enno_data.prealphaSector)
      //     setValue("prebetaSector", oneall_enno_data.prebetaSector)
      //     setValue("pregammaSector", oneall_enno_data.pregammaSector)
      //     setValue("predeltaSector", oneall_enno_data.predeltaSector)
      //     setValue("preepsilonSector", oneall_enno_data.preepsilonSector)
      //     setValue("prezetaSector", oneall_enno_data.prezetaSector)
      //   }
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)
      //   setValue("remarks", oneall_enno_data.remarks)
      //   setValue("testPhoneNumber", oneall_enno_data.testPhoneNumber)
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)

      //   setpsapdata(oneall_enno_data.engineerSupportingenno)

      //   // setValue("projectCodeId", all_enno_data[0].projectCode.join(","))
      //   // setValue("projectUniqueId", all_enno_data[0].projectCode.join(","))
      //   setValue("account", oneall_enno_data.account)
      //   setValue("market", oneall_enno_data.market)



      // }
      // if (all_enno_data.length > 0 && fload) {
      //   let oneall_enno_data = all_enno_data[0]
      //   console.log(oneall_enno_data, "oneall_enno_data")
      //   let pc = oneall_enno_data.projectDataa.map((item) => {
      //     return item.projectId
      //   });
      //   // let newpii = [];
      //   // let pcodd = all_enno_data.projectbUniqueId.map((e) => {
      //   //   newpii.push(e.pId)
      //   //   return e.projectId
      //   // })

      //   setfload(false)
      //   setixData(oneall_enno_data.ixCrewId["_id"]["$oid"])
      //   setpidd(pc.join(", "))
      //   setpcode(oneall_enno_data.projectCode.join(", "))
      //   settechread(oneall_enno_data.techuniqueId)


      //   // alphaSector
      //   // betaSector
      //   // gammaSector
      //   // deltaSector
      //   // epsilonSector
      //   // zetaSector
      //   setValue("alphaSector", oneall_enno_data.alphaSector)
      //   setValue("betaSector", oneall_enno_data.betaSector)
      //   setValue("gammaSector", oneall_enno_data.gammaSector)
      //   setValue("deltaSector", oneall_enno_data.deltaSector)
      //   setValue("epsilonSector", oneall_enno_data.epsilonSector)
      //   setValue("zetaSector", oneall_enno_data.zetaSector)
      //   setValue("OpenTTids", oneall_enno_data.OpenTTids)

      //   setEditedId(oneall_enno_data.ennouniqueId)
      //   console.log("setstopenTTs",singleSite.OpenTTids)
      //   setstopenTTs(oneall_enno_data.OpenTTids)
      //   setValue("siteUniqueId", oneall_enno_data.siteUniqueId)

      //   setCheckboxOpenhandleChange(oneall_enno_data.preExistingTech)
      //   if (oneall_enno_data.preExistingTech) {
      //     setValue("prealphaSector", oneall_enno_data.prealphaSector)
      //     setValue("prebetaSector", oneall_enno_data.prebetaSector)
      //     setValue("pregammaSector", oneall_enno_data.pregammaSector)
      //     setValue("predeltaSector", oneall_enno_data.predeltaSector)
      //     setValue("preepsilonSector", oneall_enno_data.preepsilonSector)
      //     setValue("prezetaSector", oneall_enno_data.prezetaSector)
      //   }
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)
      //   setValue("remarks", oneall_enno_data.remarks)
      //   setValue("testPhoneNumber", oneall_enno_data.testPhoneNumber)
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)

      //   setpsapdata(oneall_enno_data.engineerSupportingenno)

      //   // setValue("projectCodeId", all_enno_data[0].projectCode.join(","))
      //   // setValue("projectUniqueId", all_enno_data[0].projectCode.join(","))
      //   setValue("account", oneall_enno_data.account)
      //   setValue("market", oneall_enno_data.market)



      // }
      // if (all_enno_data.length > 0 && fload) {
      //   let oneall_enno_data = all_enno_data[0]
      //   console.log(oneall_enno_data, "oneall_enno_data")
      //   let pc = oneall_enno_data.projectDataa.map((item) => {
      //     return item.projectId
      //   });
      //   // let newpii = [];
      //   // let pcodd = all_enno_data.projectbUniqueId.map((e) => {
      //   //   newpii.push(e.pId)
      //   //   return e.projectId
      //   // })

      //   setfload(false)
      //   setixData(oneall_enno_data.ixCrewId["_id"]["$oid"])
      //   setpidd(pc.join(", "))
      //   setpcode(oneall_enno_data.projectCode.join(", "))
      //   settechread(oneall_enno_data.techuniqueId)


      //   // alphaSector
      //   // betaSector
      //   // gammaSector
      //   // deltaSector
      //   // epsilonSector
      //   // zetaSector
      //   setValue("alphaSector", oneall_enno_data.alphaSector)
      //   setValue("betaSector", oneall_enno_data.betaSector)
      //   setValue("gammaSector", oneall_enno_data.gammaSector)
      //   setValue("deltaSector", oneall_enno_data.deltaSector)
      //   setValue("epsilonSector", oneall_enno_data.epsilonSector)
      //   setValue("zetaSector", oneall_enno_data.zetaSector)
      //   setValue("OpenTTids", oneall_enno_data.OpenTTids)

      //   setEditedId(oneall_enno_data.ennouniqueId)
      //   console.log("setstopenTTs",singleSite.OpenTTids)
      //   setstopenTTs(oneall_enno_data.OpenTTids)
      //   setValue("siteUniqueId", oneall_enno_data.siteUniqueId)

      //   setCheckboxOpenhandleChange(oneall_enno_data.preExistingTech)
      //   if (oneall_enno_data.preExistingTech) {
      //     setValue("prealphaSector", oneall_enno_data.prealphaSector)
      //     setValue("prebetaSector", oneall_enno_data.prebetaSector)
      //     setValue("pregammaSector", oneall_enno_data.pregammaSector)
      //     setValue("predeltaSector", oneall_enno_data.predeltaSector)
      //     setValue("preepsilonSector", oneall_enno_data.preepsilonSector)
      //     setValue("prezetaSector", oneall_enno_data.prezetaSector)
      //   }
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)
      //   setValue("remarks", oneall_enno_data.remarks)
      //   setValue("testPhoneNumber", oneall_enno_data.testPhoneNumber)
      //   setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)

      //   setpsapdata(oneall_enno_data.engineerSupportingenno)

      //   // setValue("projectCodeId", all_enno_data[0].projectCode.join(","))
      //   // setValue("projectUniqueId", all_enno_data[0].projectCode.join(","))
      //   setValue("account", oneall_enno_data.account)
      //   setValue("market", oneall_enno_data.market)



      // }

      console.log(all_enno_data,"")
      if (all_enno_data.length > 0 && fload) {
        let oneall_enno_data = all_enno_data[0]
        console.log(oneall_enno_data, "oneall_enno_data")
        let pc = oneall_enno_data.projectDataa.map((item) => {
          return item.projectId
        });
        // let newpii = [];
        // let pcodd = all_enno_data.projectbUniqueId.map((e) => {
        //   newpii.push(e.pId)
        //   return e.projectId
        // })

        setfload(false)
        setixData(oneall_enno_data.ixCrewId)
        setixDataid(oneall_enno_data.ixCrewId["_id"]["$oid"])
        setixName(oneall_enno_data.ixCrewId["name"])
        setpidd(pc.join(", "))
        setpcode(oneall_enno_data.projectCode.join(", "))
        settechread(oneall_enno_data.techuniqueId)
        console.log(oneall_enno_data.account,path.includes("add"),"oneall_enno_data")
        setttdataidd(oneall_enno_data.account)
        DbName.push(oneall_enno_data.ixCrewId.name)
        DbName.push(oneall_enno_data.ixCrewId.crewType=="CX"?"CXCrew":"IXCrew")
        DbName.push(oneall_enno_data.ixCrewId["_id"]["$oid"])


        // alphaSector
        // betaSector
        // gammaSector
        // deltaSector
        // epsilonSector
        // zetaSector
        setValue("alphaSector", oneall_enno_data.alphaSector)
        setValue("betaSector", oneall_enno_data.betaSector)
        setValue("gammaSector", oneall_enno_data.gammaSector)
        setValue("deltaSector", oneall_enno_data.deltaSector)
        setValue("epsilonSector", oneall_enno_data.epsilonSector)
        setValue("zetaSector", oneall_enno_data.zetaSector)
        setValue("OpenTTids", oneall_enno_data.OpenTTids)
        setsidd(oneall_enno_data.siteId)
        

        setEditedId(oneall_enno_data.ennouniqueId)
        console.log("setstopenTTs",singleSite.OpenTTids)
        setstopenTTs(oneall_enno_data.OpenTTids)
        setValue("siteUniqueId", oneall_enno_data.siteUniqueId)

        setCheckboxOpenhandleChange(oneall_enno_data.preExistingTech)
        if (oneall_enno_data.preExistingTech) {
          setValue("prealphaSector", oneall_enno_data.prealphaSector)
          setValue("prebetaSector", oneall_enno_data.prebetaSector)
          setValue("pregammaSector", oneall_enno_data.pregammaSector)
          setValue("predeltaSector", oneall_enno_data.predeltaSector)
          setValue("preepsilonSector", oneall_enno_data.preepsilonSector)
          setValue("prezetaSector", oneall_enno_data.prezetaSector)
        }
        setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)
        setValue("remarks", oneall_enno_data.remarks)
        setValue("testPhoneNumber", oneall_enno_data.testPhoneNumber)
        setValue("engineerSupportingenno", oneall_enno_data.engineerSupportingenno)

        setpsapdata(oneall_enno_data.engineerSupportingenno)

        // setValue("projectCodeId", all_enno_data[0].projectCode.join(","))
        // setValue("projectUniqueId", all_enno_data[0].projectCode.join(","))
        setValue("account", oneall_enno_data.account)
        setValue("market", oneall_enno_data.market)



      }
    }




    if (allProjects && allProjects.length != 0) {
      siteArray = allProjects.map((project) => {


        // console.log("the project is =========",project)

        if (SelectedSingleSIte == project.siteUniqueId) {
          return <option selected value={project.siteUniqueId}>{project.siteId}</option>;
        } else {
          return <option value={project.siteUniqueId}>{project.siteId}</option>;
        }
      });
      singleSite=dashboardType=="ixCallTest" || dashboardType=="ixSpeedTest"?state.OneCommonResponse.singleIXSite: state.OneCommonResponse.singleSite;
      
      console.log(singleSite.length,singleSite!={} && singleSite.length != 0 ,dashboardType=="ixCallTest",dashboardType,state.OneCommonResponse,singleSite,ctrCount,"ctrCountctrCount")
      
      if (ctrCount == 1 && Object.keys(singleSite).length !== 0 && singleSite.length != 0 ) {

        
        setCtrCount(2)
        console.log(singleSite,ctrCount,"ctrCountctrCount")
        
        let siteId =singleSite.siteId || singleSite.ndResult?singleSite.ndResult.siteId:""
        setsidd(siteId)

        console.log(singleSite, "singleSitesingleSite")

        
        let technology={}

        if((path.includes("ixCallTest") || path.includes("ixSpeedTest"))&&(singleSite.requestForm!="MOP")){
          technology = singleSite.preHWLayerConfiguration;
        }else{
          technology = singleSite.technology;
        }
        let oneByOneTech = []
        console.log(technology, "technologytechnology")
        if (technology) {

          technologyArray = technology.values.map((item) => {

            oneByOneTech.push(item.split("_CP_")[0])
            return {
              value: item,
              label: item.split("_CP_")[0],
            };
          });
          console.log(technologyArray, "technologyArray")
          setPlannedSow(technologyArray)
          setallTech(technologyArray)
          let pc = singleSite.projectbUniqueId.map((item) => {
            return item.projectCode
          });
          setpcode(pc.join(", "))
          let newpii = [];
          let pcodd = singleSite.projectbUniqueId.map((e) => {
            newpii.push(e.pId)
            return e.projectId
          })
          setttdataidd(singleSite.ndResult.account)
          setValue("projectCodeId", newpii.join(","))
          setValue("projectUniqueId", newpii.join(","))
          setValue("account", singleSite.ndResult.account)
          setValue("market", singleSite.ndResult.market)
          setValue("siteUniqueId", singleSite.ndResult.siteUniqueId)
          setppOneSite(singleSite.ndResult.siteUniqueId)
          setValue("ranprojectId", singleSite.ranSupportId)
          setValue("OpenTTids", singleSite.OpenTTids)

          setstopenTTs(singleSite.OpenTTids)
          // let optt = singleSite.troubleTickets.map((onnn) => {
          //   return onnn.ttid
          // })
          // setstopenTTs(optt.join(", "))


          setUpdateRanData(true)




          setpidd(pcodd.join(", "))
          console.log(oneByOneTech,"sasjasdjasasdhjasd")
          let arrer=[]
          oneByOneTech.map((itt)=>{
            arrer.push({
              label:itt,
              value:itt
            })
          })
          settechreadarr(arrer)
          settechread(oneByOneTech.join(", "))
          // setonenew(newpii.join(","))
          FetechAllDetailSingle(newpii.join(","))
        }
        console.log("the response is the =", state.OneCommonResponse)
      }
    }

    let account = state.CommonResponse.accounts;
    if (account.length > 0 && accountArry.length == 0) {
      accountArry = account.map((item, index) => {
        if (index == 0 && oneTimeAccount) {
          // setoneTimeAccount(false)
          // setValue("account", item.uniqueid)
          // return <option selected value={item.uniqueid}>{item.accountName}</option>
        }
        return <option style={{ background: "white" }} value={item.uniqueid}>{item.accountName}</option>;
      });
    }
    let allAwaitedSite = state.CommonResponse.startAwaitedSite
    // if(allAwaitedSite && allAwaitedSite.length>0){
    // awaitedSiteArray
    // }

    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }


    multipleTechno = []


    let technology = state.CommonResponse.technology;
    if (technology.length > 0 && technologyArray.length == 0) {
      if (selectProjectId.length > 0) {
        console.log("comeToStart")
        // setmultiTechnology([])
        console.log(technology, "technology")
        technologyArray = selectProjectId.map((ids) => {
          console.log("the ids -=", ids)
          let pId = {
            label: ids.label,
            value: ids.label,
            disabled: true
          }
          multipleTechno.push(pId)
          let tmpArry = []
          tmpArry = technology.map((item) => {
            return {
              value: item.technologyName + "_CP_" + ids.value,
              group: ids.label,
              label: item.technologyName,
            };
          });


          multipleTechno.push(...tmpArry)
          // setmultiTechnology(multiTechnologylcl)
        })
        console.log(multipleTechno, "multiTechnologymultiTechnology")

        // settechsetter(false)
      }
      // }
    }


    // let rsingleSite = state.OneCommonResponse.singleRanTechSite;
    // if (rsingleSite && rsingleSite.technology) {


    //   let sitelabel = {
    //     label: rsingleSite.projectId,
    //     value: rsingleSite.projectId,
    //     disabled: true
    //   }
    //   multipleTechno.push(sitelabel)
    //   let defTech = []
    //   defTech = rsingleSite.technology.map((defaultTech) => {
    //     return {
    //       value: defaultTech + "_CP_" + rsingleSite.newuniqueId,
    //       label: defaultTech,
    //     };
    //   })
    //   multipleTechno.push(...defTech)
    // }
    // else if (rsingleSite.length >= 1) {
    //   // setAccount(rsingleSite[0].account)
    //   // setMarket(rsingleSite[0].market)
    //   setValue("account", rsingleSite[0].account)
    //   setValue("market", rsingleSite[0].market)
    //   console.log(rsingleSite, "singleSitesingleSitesingleSite")
    //   // setTech(singleSite.technology)
    //   technologyArray = rsingleSite.map((sites) => {
    //     let sitelabel = {
    //       label: sites.projectId,
    //       value: sites.projectId,
    //       disabled: true
    //     }
    //     multipleTechno.push(sitelabel)
    //     let defTech = []
    //     defTech = sites.technology.map((defaultTech) => {
    //       return {
    //         value: defaultTech + "_CP_" + sites.newuniqueId,
    //         label: defaultTech
    //       };
    //     })
    //     multipleTechno.push(...defTech)
    //   })
    //   // technologyArray = singleSite.technology.values.map((item) => {
    //   //   return {
    //   //     value: item,
    //   //     label: item,
    //   //   };
    //   // });
    //   console.log("the response singleSite is the =", multipleTechno, state.OneCommonResponse.singleSite)
    //   // setCtrCount(2)
    // }


    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      projectIdOption = projectIdArray.map((item) => {
        console.log(ppProjectId, item.uniqueid, "ppidd")
        if (ppProjectId == item.uniqueid) {
          if (opind) {
            FetechAllDetail(item.uniqueid)
            setopind(false)
          }
          let availableId = []
          let anyArry = item.data
          availableId = item.data.map((id) => {

            console.log(id, "ididididididid")
            return {
              label: id.projectId,
              group: item.projectCode,
              value: id.uniqueid
            }
          })

          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)
          // return <option selected value={item.uniqueid}>{item.projectId}</option>
        } else {
          let availableId = []
          let anyArry = item.data
          if (anyArry) {
            availableId = anyArry.map((id) => {
              console.log(id, "ididididididid")
              return {
                label: id.projectId,
                group:item.projectCode,
                value: id.uniqueid
              }
            })
          }


          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)


          // return <option value={item.uniqueid}>{item.projectId}</option>
        }
      })
    }

    let projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item) => {
        let TTIds=item.ttresult
        // console.log(TTIds.join(", "),"TTIdsTTIdsTTIds")
        // if(OneTT){
        //   setOneTT(false)
        //   setstopenTTs(TTIds.join(", "))
        // }
        
        // setValue("openTTs",TTIds.join(", "))
        if (SelselectProjectCode.indexOf(item.projectCode) > 0 && selectProjectCode.findIndex(abcd => {
          return item.projectCode == abcd.label
        }) == -1) {
          selectProjectCode.push({
            value: item.uniqueid,
            label: item.projectCode,
          })
        }
        return {
          value: item.uniqueid,
          label: item.projectCode,
        };
        // return <option value={item.uniqueid}>{item.projectCode}</option>
      })

    }
    console.log("state.CommonResponse.technology=",state.OneCommonResponse.productCode, state.CommonResponse.technology)
    console.log("projectCodeOption =", projectCodeOption);


    if (ttcategoryArray && ttcategoryArray.length > 0) {
      ttcategory = ttcategoryArray.map((item) => {
        return <option value={item}>{item}</option>
      })
    }





    if (dataObj && RenderDrOne) {
      setocnd(true)
      setopind(true)
      setRenderDrOne(false)
      setValue("siteUniqueId", dataObj.siteUniqueId)
      setValue("ranprojectId", dataObj.ranId)
      setValue("projectUniqueId", dataObj.projectUniqueId)
      fetechSingleProject(dataObj.siteUniqueId)
      findDProjectId(dataObj.projectUniqueId, 1)
      // FetechAllDetail(dataObj.ranId)
      setppProjctId(dataObj.ranId)
      console.log(dataObj, "dataObjdataObj")
    }
    
    return { siteArray,accountArry,pdsingleSite, singleSite, technologyArray, projectCodeOption, engineerArray, newTtempArray, projectIdOption, ttcategory };

  });


  console.log(projectData, "projectData")


  let Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const addUMTSFields = () => {
    setCount(count++);

    if (count <= 7) {
      setUmt([
        ...umtsArray,
        <div className="form-group col-md-4">
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"UMTS Id " + count}
          />
        </div>,
      ]);
      setCount(count++);
    }
  };

  let techAffected = [
    {
      value: "GSM",
      label: "GSM"
    },
    {
      value: "UTMS",
      label: "UTMS"
    },
    {
      value: "LTE",
      label: "LTE"
    },
    {
      value: "5G",
      label: "5G"
    }
  ]
  const addLTEFields = () => {
    setLteCount(lteCount++);

    if (lteCount <= 9) {
      setLte([
        ...lteArray,
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">LTE ID {lteCount}</label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"LTE Id " + lteCount}
          />
        </div>,
      ]);
      setLteCount(lteCount++);
    }
  };
  const onSubmit = async (data) => {
    console.log("data is ===", data);
    let userId = JSON.parse(localStorage.getItem("userDetails"))
    userId = userId.uniqueid


    // data["cxCrewId"] = cxCrew
    // data["ixCrewId"] = iXCrew

    data['assume'] = ''
    if (!path.includes("editenno")) {
      data['userId'] = userId
      data['OpenTTids'] = stopenTTs
      data['addDate'] = currentDate
      data['integrationDate'] = currentDate
      data["UpdateRanData"] = UpdateRanData
      data['ennoCallTestDate'] = currentDate

      let sendTech = {
        values: []
      }
      sendTech.values = plannedSow.map((item) => {
        return item.value
      })
      data['technology'] = sendTech
      data['siteUniqueId'] = ppOneSite

      // SelectedSingleSIte
    }else{
      delete data.account
      delete data.siteUniqueId
    }

    let sendtechaff = { values: [] }
    sendtechaff.values = techaff.map((item) => {
      return item.value
    })
    data['techAffected'] = sendtechaff

    let sendSow = { values: [] }
    sendSow.values = ttdata.map((item) => {
      return item.value
    })
    data['ttdata'] = sendSow
    // data[""]
    data["ixCrewId"]=data["ixCrewUniqueId"]
    delete data["ixCrewUniqueId"]
    console.log(data, "enooaddApi")

    data["typeDashboard"]=dashboardType


    console.log(data,"datadatadatadatadata")


    if (path.includes("editenno")) {
      dispatch(addUserAction(enooaddApi + "/" + path.split("/").pop(), data))
    } else {
      
      if(dashboardType=="ixCallTest" || dashboardType=="ixSpeedTest"){
        dispatch(addUserAction(enooaddApi+"?ix=True&dashboardType="+dashboardType, data))
      }else{
        dispatch(addUserAction(enooaddApi, data))
      }
    }



    //   if(dashboardType=="ixCallTest"){
    //     dispatch(addUserAction(enooaddApi + "/" + EditedId+"?ix=True", data))
    //   }
    //   else{
    //     dispatch(addUserAction(enooaddApi + "/" + EditedId, data))
    //   }
    // } else {
    //   if(dashboardType=="ixCallTest"){
    //   dispatch(addUserAction(enooaddApi +"?ix=True", data))
    // }
    // else{
    //     dispatch(addUserAction(enooaddApi, data))

    //   }
    // }
    dispatch(popUpUpdate({}))
    setCloser(true)
    // history.push("/PAG/E911CallTest/dashboard")
  };

  const raiseTT = () => {
    history.push("/PAG/trobleTicke/form")
  }



  const dateObject = new Date();
  // const currentDate = moment.tz(commonzone).format("MM/DD/YYYY");

  const currentDate = time_zonechanger(commonzone, "MM/DD/YYYY")
  const onOptionChange = (e) => {
    let val = e.target.value;

    array_main = (
      <option selected disabled>
        Select
      </option>
    );

    if (listalldata) {
      if (val == "LeadName") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.name}</option>;
        });
        console.log(array_main, "array_main");
      } else if (val == "Email") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.email}</option>;
        });
      } else if (val == "ContactNumber") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.mobilenumber}</option>;
        });
      }
    }

    setcx(array_main);
    setix(array_main)
  };

  let data = useSelector((state) => {
    console.log(state, "statestatestate");

    // let list_user=state
    listalldata = state.CommonResponse.listuser;

    return { array_main };
  });
  console.log("he site object", projectData.singleSite)
  const handleChange = (event, nane, arra) => {


    let ind = event.target.selectedIndex;
    console.log("gdrgjeio;rghiuersgh,eriusoso", listalldata[ind].uniqueid);
    // setValue(nane+"Id",listalldata[ind].uniqueid)
    setValue(nane + "Name", listalldata[ind].name);
    setValue(nane + "Email", listalldata[ind].email);
    // setValue(nane+"Vendor",listalldata[ind].)
    // setValue(nane+"Company",listalldata[ind].)
    setValue(nane + "Phone", listalldata[ind].mobilenumber);

    setValue(nane + "Vendor", listalldata[ind].vendorresult[0].vendorName)

    setValue(nane + "Company", listalldata[ind].crewresult[0].crewCompanyName)
    // if(ind==0){
    //   setValue(nane+"Name","Name")
    //   setValue(nane+"Email","Email")
    //   setValue(nane+"Phone","Phone")
    // }

    // if(ind!=0){

    //   filteruserlist.map((item)=>{
    //     console.log(item.role==arra)
    //     if(item.role==arra){
    //       console.log(item.data[ind-1])

    //       let {roleEmail,roleName,roleNumber}=item.data[ind-1]

    //       console.log(roleEmail,roleName,roleNumber,"roleEmail,roleName,roleNumber")
    //       setValue(nane+"Name",roleName)
    //       setValue(nane+"Email",roleEmail)
    //       setValue(nane+"Phone",roleNumber)
    //     }
    //   })

    // }

  };

  useEffect(() => {

    if (path.includes("ranCallTest")) {
      dispatch(FetchSingleRecordAction(ranDashboard, path.split("/").pop()))
    }
    else if(path.includes("ixCallTest") || path.includes("ixSpeedTest")) {
      dispatch(FetchSingleRecordAction(ixModule, path.split("/").pop()))
    }
    dispatch(UserListReducerAction(listUserApi_two));
    dispatch(AccountReducerAction(allTechnologyApi));
    // dispatch(AccountReducerAction(listSiteId));
    dispatch(AccountReducerAction(siteDblistSiteId));
    

    // dispatch(AccountReducerAction(ranlistSite));
    dispatch(TierListReducerAction(managEngineer))
    dispatch(AccountReducerAction(accountApi));
    if (path.includes("editenno")) {
      dispatch(AccountReducerAction(listennoTicketApi + "/" + path.split("/").pop()));
    }
  }, []);

  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%", marginTop: "-1.4rem" }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input style={{height:"32px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Side Id"
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}
                        {/* <div className="form-group col-md-4"> */}
                        {/* <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input className="form-control" list="cars"
                      
                          //  onChange={(e)=>{
                            // setCrCodeState(e.target.value)
                            // setCrCodeError('')
                          // }}
                            // {
                            //   ...register("siteId",{
                            //     required:"Site Id is required",
                            //     minLength:{
                            //       value:5,
                            //       message:"Please enter valid site id"
                            //     },
                            //     maxLength:{
                            //       value:16,
                            //       message:"Only 16 characters are allowed"
                            //     }
                            //   })
                            // }
                            
                          />
                          <datalist id="cars">
                              <option value="BMW" />
                              <option value="Bentley" />
                              <option value="Mercedes" />
                              <option value="Audi" />
                              <option value="Volkswagen" />
                          </datalist>
                        </div> */}

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Date of Add</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            disabled
                            defaultValue={currentDate}
                            value={currentDate}
                            {...register("addDate")}
                          />
                        </div>
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Site Id</label>
                          {
                            // editableId==undefined?
                            path.includes("add") ?
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {...register("siteUniqueId", {
                                  required: "Please select Site id",
                                  onChange: (e) => {
                                    console.log("single pro ", e.target.value);

                                    fetechSingleProject(e.target.value);
                                  },
                                })}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.siteArray}
                              </select>
                              : <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                disabled
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {...register("siteUniqueId")}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.siteArray}
                              </select>
                          }
                          {errors.siteUniqueId && (
                            <p className="error">{errors.siteUniqueId.message}</p>
                          )} 
                          */}
                                                  {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Site Id</label>
                          { */}

{/*                             
                    //   <input className='form-control' list="cars"
                    //   style={{ height: "32px" }}
                    //   defaultValue={whatWork != "Add" && whatWork != undefined ? singleSitestate.siteId : ""}
                    //   onBlur={(e) => {
                    //     findDSiteId(e.target.value)
                    //   }}
                    // />
                    // <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                    //   Required Field
                    // </p>
                    // <datalist id="cars">

                    //   {options.siteArray}
                    // </datalist>
                            // editableId==undefined?
                            // path.includes("add") ?<>
                            //   <input
                            //     list="cars"
                            //     style={{ height: "32px" }}
                            //     className="form-control"
                            //     autoComplete={"off"}
                            //     id="exampleFormControlSelect1"
                            //     // onSelect={(e)=>{
                            //     //   console.log("single pro ",e)
                            //     //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            //     // }}
                            //     // value={siteIdVal}
                            //     {...register("siteUniqueId", {
                            //       required: "Please select Site id",
                            //       onChange: (e) => {
                            //         console.log("single pro ", e.target.value);

                            //         fetechSingleProject(e.target.value);
                            //       },
                            //     })}
                            //   />
                            //     {/* // <option selected disabled value="">
                            //     //   Select
                            //     // </option>
                            //     // {projectData.siteArray} */}

                                
                            {/* //     <datalist id="cars">

                            //     {projectData.siteArray}
                            //   </datalist>
                            //   </>
                            //   :

                            //   <input
                            //     style={{ height: "32px" }}
                            //     type="text"
                            //     className="form-control"
                            //     id="input "
                            //     readOnly
                            //     value={sidd}
                            //   /> */}
                            {/* //   <select
                            //   style={{ height: "32px" }}
                            //   className="form-control"
                            //   id="exampleFormControlSelect1"
                            //   disabled
                            //   // onSelect={(e)=>{
                            //   //   console.log("single pro ",e)
                            //   //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            //   // }}
                            // >
                            //   <option selected disabled value="">
                            //     Select
                            //   </option>
                            //   {projectData.siteArray}
                            // </select>
                        //   }
                        //   {errors.siteUniqueId && (
                        //     <p className="error">{errors.siteUniqueId.message}</p>
                        //   )} */}
                        {/* // </div>  */}


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Site Id</label>
                          {

                            
                    //   <input className='form-control' list="cars"
                    //   style={{ height: "32px" }}
                    //   defaultValue={whatWork != "Add" && whatWork != undefined ? singleSitestate.siteId : ""}
                    //   onBlur={(e) => {
                    //     findDSiteId(e.target.value)
                    //   }}
                    // />
                    // <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                    //   Required Field
                    // </p>
                    // <datalist id="cars">

                    //   {options.siteArray}
                    // </datalist>
                            // editableId==undefined?
                            path.includes("add") ?<>
                              <input
                                list="cars"
                                style={{ height: "32px" }}
                                className="form-control"
                                autoComplete={"off"}
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                // value={siteIdVal}
                                {...register("siteUniqueId", {
                                  required: "Please select Site id",
                                  onChange: (e) => {
                                    console.log("single pro ", e.target.value);

                                    fetechSingleProject(e.target.value);
                                  },
                                })}
                              />
                                {/* // <option selected disabled value="">
                                //   Select
                                // </option>
                                // {projectData.siteArray} */}

                                
                                <datalist id="cars">

                                {projectData.siteArray}
                              </datalist>
                              </>
                              :

                              <input
                                style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                id="input "
                                readOnly
                                value={sidd}
                              />
                            //   <select
                            //   style={{ height: "32px" }}
                            //   className="form-control"
                            //   id="exampleFormControlSelect1"
                            //   disabled
                            //   // onSelect={(e)=>{
                            //   //   console.log("single pro ",e)
                            //   //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            //   // }}
                            // >
                            //   <option selected disabled value="">
                            //     Select
                            //   </option>
                            //   {projectData.siteArray}
                            // </select>
                          }
                          {errors.siteUniqueId && (
                            <p className="error">{errors.siteUniqueId.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label>Project Code</label>
                          {
                            path.includes("add") ?
                              <MultiSelect
                                className="text-dark"
                                hasSelectAll={false}
                                options={projectData.projectCodeOption}
                                onChange={findDProjectId}
                                value={selectProjectCode}
                              /> :
                              <input
                                style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                id="input "
                                readOnly
                                value={npcode}
                              />}
                          {errorlen > 0 && path.includes("add") && selectProjectCode.length == 0 ? <p className="error">{customError.projectCode}</p> : ""}
                          {/* <select
                            style={{ height: "32px" }}
                            className="form-control"
                            {
                            ...register("projectUniqueId",
                              { onChange: (e) => { findDProjectId(e.target.value) } }
                            )
                            }
                          >
                            <option selected disabled value=''>select</option>
                            {projectData.projectCodeOption}
                          </select> */}
                        </div>

                        <div className="form-group col-md-3 ">
                          <label >Project Id</label>

                          {path.includes("add") ?
                            <MultiSelect
                              className="text-dark"
                              hasSelectAll={false}
                              options={projectData.newTtempArray}
                              onChange={FetechAllDetail}
                              value={selectProjectId}
                            /> :
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={npidd}
                            />}
                          {errorlen > 0 && path.includes("add") && selectProjectId.length == 0 ? <p className="error">{customError.projectId}</p> : ""}
                          {/* <select
                            style={{ height: "32px" }}
                            className="form-group"
                            isSearchable={true}
                            {
                            ...register("ranprojectId", {
                              required: "Please select project Id",
                              onChange: (e) => {
                                FetechAllDetail(e.target.value);
                              }
                            }
                            )
                            }
                          >
                            <option disabled value=''>select</option>
                            {projectData.projectIdOption}
                          </select>
                          {errors.ranprojectId && (
                            <p className="error">
                              {errors.ranprojectId.message}
                            </p>
                          )} */}
                        </div>

                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Account</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            {...register("account")}
                          />
                          {errors.accountt && (
                            <p className="error">{errors.accountt.message}</p>
                          )}
                        </div> */}
                        
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4" >Account </label>
                          {path.includes("add") ?
                            // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                              <>
                                <select style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  {...register("account", {
                                    required: "Please select account"
                                  })}
                                >
                                  <option value="">Select</option>
                                  {projectData.accountArry}
                                </select>
                                {errors.account && (
                                  <p className="error">
                                    {errors.account.message}
                                  </p>
                                )}
                              </>:<input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={ttdataidd}
                            />
                          }
                        </div>
                        {console.log("ttdataidd","ttdataidd",ttdataidd)}
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Market</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            {...register("market")}
                          />
                        </div> */}
                        
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Market</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            value={
                              projectData.pdsingleSite ? projectData.pdsingleSite.market ? projectData.pdsingleSite.market : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].market : "" : ""
                            }
                            {...register("market")}
                          />
                        </div> */}
                        
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Market</label>
                          {path.includes("add") ?<input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            value={
                              projectData.pdsingleSite ? projectData.pdsingleSite.market ? projectData.pdsingleSite.market : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].market : "" : ""
                            }
                          />:<input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            {...register("market")}
                          />}
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Technology </label>



                          {path.includes("add") ?
                            <MultiSelect
                              options={multipleTechno}
                              onChange={techChangleHandler}
                              value={plannedSow}
                              required
                              hasSelectAll={false}
                            /> :
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={techread}
                            />}
                            {path.includes("add") ?
                              errorlen > 0 && plannedSow.length == 0 ? 
                                  <p className="error">{customError.technology}</p> 
                                :""
                              : ""}
                        </div>



                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Cabinet IDs
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            disabled
                            className="form-control"
                            ID="inputAddress"
                            placeholder="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
                          />
                        </div>
                        {path.includes("editenno")?
                        <>
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Alpha Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("alphaSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Beta Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("betaSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Gamma Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("gammaSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Delta Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("deltaSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Epsilon Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("epsilonSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Zeta Sector</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("zetaSector")}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.ttcategory}
                          </select>
                          {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div>
                        </>
                        :""}

                        {console.log(techreadarr,techchanggg,"techreadarr")}


                        {techchanggg?
                          path.includes("add") || path.includes("ranCallTest") || path.includes("ixCallTest") || path.includes("ixSpeedTest")?
                          <AdvmultipleTechnologyForm XlShow={techchanggg} setXlShow={settechchanggg} seleCname={""} register={register} getValues={getValues} setValue={setValue} formAll={dynaamicForm} selTecch={plannedSow} dynmaicForm={(<><input type="text"></input></>)} labelLing={ttcategoryArray} array={["one", "two", "three", "four"]} />
                          :<AdvmultipleTechnologyForm XlShow={techchanggg} setXlShow={settechchanggg} seleCname={""} register={register} getValues={getValues} setValue={setValue} editable={true} formAll={dynaamicForm} selTecch={techreadarr} dynmaicForm={(<><input type="text"></input></>)} labelLing={ttcategoryArray} array={["one", "two", "three", "four"]} />
                          :""
                        }
                        {path.includes("add") ?
                        
                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">Pre-existing Technologies</label>
                          <input type="checkbox" {
                            ...register("preExistingTech",
                              { onChange: (e) => { setCheckboxOpenhandleChange(e.target.checked) } }
                            )}></input>
                        </div>

                        :""}
                        <div className="form-group col-md-12" style={{ display: checkboxOpen ? "block" : "none" }}>
                          {techchanggg?
                            path.includes("add") ?
                            <AdvmultipleTechnologyForm XlShow={techchanggg} setXlShow={settechchanggg} getValues={getValues} seleCname={"Pre"} register={register} setValue={setValue} formAll={dynaamicForm} selTecch={plannedSow} dynmaicForm={(<><input type="text"></input></>)} labelLing={ttcategoryArray} array={["one", "two", "three", "four"]} />:""
                          :""
                          }
                          {/* <div className="form-row">

                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Alpha Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {
                                ...checkboxOpen ? {
                                  ...register("prealphaSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>

                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Beta Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {
                                ...checkboxOpen ? {
                                  ...register("prebetaSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Gamma Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {
                                ...checkboxOpen ? {
                                  ...register("pregammaSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }

                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Delta Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}

                                {
                                ...checkboxOpen ? {
                                  ...register("predeltaSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Epsilon Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {
                                ...checkboxOpen ? {
                                  ...register("preepsilonSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Zeta Sector</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {
                                ...checkboxOpen ? {
                                  ...register("prezetaSector", {
                                    required: "Please select Alpha Sector",
                                  })
                                } : ""
                                }
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.uniqueid && (
                                <p className="error">{errors.uniqueid.message}</p>
                              )}
                            </div>
                          </div> */}
                        </div>





                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Integration date</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            disabled
                            defaultValue={currentDate}
                            value={currentDate}
                            {...register("integrationDate")}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Speed Test Date</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            disabled
                            defaultValue={currentDate}
                            value={currentDate}
                            {...register("ennoCallTestDate")}
                          />
                        </div>





                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT ID (s)
                          </label>
                          
                          <input style={{ height: "32px" }}
                            type="text"
                            value={stopenTTs}
                            {...register("OpenTTids")}
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter TT Id"
                            disabled
                          />
                        </div>


                        {/* <div className="form-group col-md-4">
                          <div className="form-row">
                            <div className="form-group col-md-8">
                              <label>TT (s) Opened?</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                {
                                ...register("projectUniqueId",
                                  { onChange: (e) => { FetechAllDetail(e.target.value) } }
                                )
                                }
                              >
                                <option selected disabled value=''>select</option>
                                {projectData.projectCodeOption}
                              </select>
                            </div>
                            <div className="form-group col-md-4">
                              <button style={{marginTop:"2rem"}} className="mt-4 btn btn-sm btn-warning" >Refresh</button>
                            </div>
                          </div>
                        </div> */}








                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Engineer Supporting Speed Test
                          </label>
                          {/* <input style={{ height: "32px" }}
                            type="text"
                            {...register("engineerSupportingenno", {
                              required: "Please select Engineer Opening TT"
                            })}
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Select Engineer"
                          /> */}


                          <input style={{ height: "32px" }}
                            type="text"
                            {...register("engineerSupportingenno", {
                              required: "Please select Engineer Support E911"
                            })}
                            onBlur={(e) => { findengineer(e.target.value) }}
                            list="engOpen"
                            className="form-control"
                            ID="inputAddress"
                            defaultValue={EngineerOpen}
                            placeholder="Select Engineer"
                          />

                          <datalist id="engOpen">
                            {projectData.engineerArray}
                          </datalist>
                          {errors.engineerSupportingenno && (
                            <p className="error">
                              {errors.engineerSupportingenno.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Test Phone Number
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            {...register("testPhoneNumber", {
                              required: "Please Enter Test Phone Number",
                              minLength: {
                                value: 10,
                                message: "Please enter 10 digit phone number "
                              },
                              maxLength: {
                                value: 10,
                                message: "Please enter 10 digit phone number "
                              }


                            })}
                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter Test Phone Number"
                          />
                          {errors.testPhoneNumber && (
                            <p className="error">
                              {errors.testPhoneNumber.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Remarks
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            {...register("remarks")}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}
                          />
                        </div>



                      </div>

                      {console.log("DbNameDbName",DbName)}

                      
                    {/* {currentUrl.includes("editenno") && DbName[1] && DbName[0] && DbName[2] ?
                      <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} /> : ""} */}
                      {currentUrlnosplit.includes("editenno") && ixData ? <Advcommoncrewupdated label={"PSAP Done By:"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={ixName} currentValueId={ixDataid} /> : ""}

                      {!currentUrlnosplit.includes("editenno") ? <Advcommoncrewupdated label={"PSAP Done By:"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={""} currentValueId={""} /> : ""}

                    </div>
                  </div>






                  <div className="card" style={{ width: "100%", marginTop: "1rem" }}>


                    {/* :  */}
                    {/* ""} */}
                    {/* 
<Tabs
  defaultActiveKey={accordianOperner}
  id="uncontrolled-tab-example"
  className="mb-3"
  onSelect={(e) => {
    setAccordianOperner(e)
  }}
><Tab eventKey="CXCrew" title="CX Crew">
    <div className="accordion-body">
      <div className="form-row mt-2 p-2">
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            CX Search By work---- 
          </label>

          <div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="ContactNumber"
                  onChange={onOptionChange}
                  name="optradio"
                />
                Contact Number
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="Email"
                  onChange={onOptionChange}
                  name="optradio"
                />
                E-mail
              </label>
            </div>
            <div class="form-check-inline disabled">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="LeadName"
                  onChange={onOptionChange}
                  name="optradio"
                />
                Lead Name
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-5">
          <label for="exampleFormControlSelect1">
            <span style={{ color: "red" }}>*</span>
            Enter Lead Name
          </label>


          <select style={{ height: "32px" }}
            className="form-control"
            id="exampleFormControlSelect1"
            // {...register("cxCrewId", {

            //   onChange: (e) => {
            //     handleChange(e, "CXCrew", "b");
            //   },
            // })}

            {...accordianOperner == "CXCrew" ?
              {
                ...register("cxCrewId", {
                  required: "Please Select CX Crew",
                  onChange: (e) => {
                    handleChange(e, "CXCrew", "b");
                  }
                })
              } :
              {
                ...register("cxCrewId", {
                  required: false
                })
              }}
          >
            {cxArray}

          </select>
          {errors.cxCrewId && (
            <p className="error">{errors.cxCrewId.message}</p>
          )}
        </div>{" "}
        <div className="form-group col-md-2 mt-2">
          <label for="exampleFormControlSelect1"></label>
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            CX Crew Vendor
          </label>

          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            readOnly
            {...register("CXCrewVendor")}
          />
        </div>
        <div className="form-group col-md-4 ">
          <label for="exampleFormControlSelect1">
            CX Crew Lead Contact Number
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            readOnly
            {...register("CXCrewPhone")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            CX Crew Lead Name
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("CXCrewName")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            CX Crew Lead Company{" "}
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("CXCrewCompany")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            CX Crew Lead E-mail ID
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("CXCrewEmail")}
          />
        </div>
      </div>
    </div>
  </Tab>
  <Tab eventKey="IXCrew" title="IX Crew">

    <div className="accordion-body">
      <div className="form-row mt-2 p-2">
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            IX Search By----
          </label>

          <div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="ContactNumber"
                  onChange={onOptionChange}
                  name="optradio"
                />
                Contact Number
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="Email"
                  onChange={onOptionChange}
                  name="optradio"
                />
                E-mail
              </label>
            </div>
            <div class="form-check-inline disabled">
              <label class="form-check-label">
                <input
                  style={{ height: "20px" }}
                  type="radio"
                  className="form-check-input"
                  value="LeadName"
                  onChange={onOptionChange}
                  name="optradio"
                />
                Lead Name
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-5">
          <label for="exampleFormControlSelect1">
            <span style={{ color: "red" }}>*</span>
            Enter Lead Name
          </label>





          <select style={{ height: "32px" }}
            className="form-control"
            id="exampleFormControlSelect1"
            {...register("ixCrewId", {
              onChange: (e) => {
                handleChange(e, "IXCrew", "b");
              },
            })}

            {...accordianOperner == "IXCrew" ?
              {
                ...register("ixCrewId", {
                  required: "Please Select IX Crew",
                  onChange: (e) => {
                    handleChange(e, "IXCrew", "b");
                  }
                })
              } :
              {
                ...register("ixCrewId", {
                  required: false
                })
              }}
          >
            {ixArray}
          </select>
          {errors.ixCrewId && (
            <p className="error">{errors.ixCrewId.message}</p>
          )}
        </div>{" "}
        <div className="form-group col-md-2 mt-2">
          <label for="exampleFormControlSelect1"></label>
        </div>

        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            IX Crew Vendor
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            readOnly
            defaultValue={"---Select---"}
            {...register("IXCrewVendor")}
          />
        </div>
        <div className="form-group col-md-4 ">
          <label for="exampleFormControlSelect1">
            IX Crew Lead Contact Number
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            readOnly
            {...register("IXCrewPhone")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            IX Crew Lead Name
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("IXCrewName")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            IX Crew Lead Company{" "}
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("IXCrewCompany")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">
            IX Crew Lead E-mail ID
          </label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            id="input "
            readOnly
            {...register("IXCrewEmail")}
          />
        </div>
      </div>
    </div>
  </Tab>
</Tabs> */}

                  </div>
                  <div className="text-center">
                    {/* <button
                      onClick={(e) => abc(e, "cx")}
                      className="btn btn-primary mx-2 btn-xs"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      CX
                    </button>
                    <button
                      onClick={(e) => abc(e, "ix")}
                      className="btn btn-primary mx-2 btn-xs"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      IX
                    </button>
                    {/* <button
                      onClick={(e) => abc(e, "")}
                      className="btn btn-primary mx-2 btn-xs"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo,#collapseOne"
                      aria-expanded=""
                      aria-controls="collapseTwo,collapseOne"
                    >
                      Both
                    </button> */}
                  </div>
                  {/* {cxdata}
                  {ixdata}  */}

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                    <button type="button" onClick={() => history.goBack()} className="btn btn-danger btn-xs mx-2">Back</button>
                  </div>
                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >
          </div>
        </form >

      </div >
    </Fragment >
  );
};

export default SpeedTest;
