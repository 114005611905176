import React, { useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AccountReducerAction, ClearProductCode, FetchSingleRecordAction, TierListReducerAction, addUserAction, clearGetSingleProjectDatabase, clearProjectId, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import { OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, accountApi, ixModule, managEngineer, ranDashboard, singleProjectApi, siteDblistSiteId, allTechnologyApi } from "../../../common/config";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import DatePicker from 'react-datepicker';
import { columnsArray, commonzone, fun_time_zonechanger, moment_common_full_date_format, reformArray } from "../../../common/variables";
import AdvancedCommonCrew from "../Forms/Ran Form/advancedcommoncrew";
import { useAsyncDebounce } from "react-table";
import { useHistory } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Advcommoncrewupdated from "../Forms/Ran Form/advcommoncrewupdated";
const moment = require('moment');
const IXModule = () => {
  const styles = {
    width: "400px",

  };

  
  let history = useHistory()
  let allProjects = []
  let accountArry = []
  let newTtempArray = []
  let projectIdArray = []
  let multipleTechno = []
  let technologyArray = []
  let dispatch = useDispatch()
  
  const [closer, setCloser] = useState(false)
  let [sow, setSow] = useState({
    selectedSow: [],
    sowList: [
      {
        label: "IX",
        value: "IX"
      },
      // {
      //   label: "NEA",
      //   value: "NEA"
      // },
      {
        label: "Troubleshooting",
        value: "Troubleshooting"
      },
      {
        label: "Call Test(PSAP/Speed Test)",
        value: "Call Test(PSAP/Speed Test)"
      }
    ],
    errorState: false
  })
  let [showReason, setShowReason] = useState(false)
  let disableRef =useRef(false)
  let [disableButton,setDisable] =useState(false)
  let [singleSitestate, setsingleSitestate] = useState([]);
  let [tech, setTech] = useState([]);
  let [customesite, setcustomesite] = useState('')
  let [post, setPost] = useState([]);
  let [oneprid, setoneprid] = useState('')
  let [ppProjectId, setppProjctId] = useState('')
  let [SelselectProjectCode, setSelProjectCode] = useState([])
  let [selectProjectId, setProjectId] = useState([])
  let [siteError, setSiteError] = useState(false)
  let [ctrCount, setCtrCount] = useState(1);
  const [setdate, setStartDate] = useState();
  const [EngineerOpen, setEngineerOpen] = useState([]);
  let [selectProjectCode, setProjectCode] = useState([])
  let [startDateError, setStartDateError] = useState(false)
  let siteArray = [];
  let projectCodeOption = []
  let siteInfo = []
  let engineerArray = [];
  let options = useSelector((state) => {
    let technology = state.CommonResponse.technology;
    if (technology.length > 0) {
      multipleTechno = []
      if (technology.length > 0) {
        console.log("comeToStart")
        // setmultiTechnology([])
        console.log(technology, "technology")
        multipleTechno = technology.map((ids) => {
            // console.log("the ids -=", ids)
            // let pId = {
            //   label: ids.label,
            //   value: ids.label,   
            //   disabled: true
            // }
            // multipleTechno.push(pId)
            // let tmpArry = []
            // tmpArry = technology.map((item) => {
            return {
              value: ids.uniqueid ,
              label: ids.technologyName,
            };
          // });


          // multipleTechno.push(...tmpArry)
          // setmultiTechnology(multiTechnologylcl)
        })
        console.log(multipleTechno, "multiTechnologymultiTechnology")

        // settechsetter(false)
      }
      // }
    }
    siteInfo = state.csvResponse.singleSite;
    console.log("th e stacdsfcsd=", state)
    // console.log( siteInfo, siteInfo.length != 0, "ctrCount")
    if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
      console.log("the single site is =", siteInfo)
      setsingleSitestate(siteInfo)
      setCtrCount(2)
    }
    allProjects = state.CommonResponse.startAwaitedSite
    // console.log("all projects is =",allProjects)
    if (allProjects && allProjects.length > 0) {

      siteArray = allProjects.map((project) => {
        // console.log("the project is =========",project)
        return <option>{project.siteId}</option>;
      });
    }
    let account = state.CommonResponse.accounts;
    if (account.length > 0 && accountArry.length == 0) {
      accountArry = account.map((item) => {
        return <option value={item.uniqueid}>{item.accountName}</option>;
      });
    }
    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      let projectIdOption = projectIdArray.map((item, index) => {
        let availableId = []
        let anyArry = item.data
        availableId = item.data.map((id) => {
          return {
            label: id.projectId,
            value: id.uniqueid
          }
        })

        newTtempArray.push({
          label: item.projectCode,
          value: item.projectCode,
          disabled: true,
        })
        newTtempArray.push(...availableId)

      })
    }



    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 || state.popUpState.popstatus.status == 200) && closer) {
        setCloser(false)
        popUpUpdate({})

        // dispatch(AccountReducerAction(ixModule))
        dispatch(AccountReducerAction(ixModule+"?ix=calender"))
        history.push("/PAG/ix/calendera",{reload:true})
      }
    }



    let projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item, index) => {


        console.log(SelselectProjectCode, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd); return item.projectCode == abcd.label }), "SelselectProjectCode")
        if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => {
          return item.projectCode == abcd.label
        }) == -1) {
          selectProjectCode.push({
            value: item.uniqueid,
            label: item.projectCode,
          })

        }
        return {
          value: item.uniqueid,
          label: item.projectCode,
        };
      })

    }
    return { siteArray, projectCodeOption, engineerArray, accountArry }
  })
  console.log("the site id --dwef=",options.siteArray)
  const techChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setTech(e);
  };
  const postChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setPost(e);
  };
  // const findDSiteId = (id) => {
  //   console.log(id, "findDSiteId", allProjects)
  //   if (id != undefined) {
  //     //   setcustomesite(id)
  //     //   setProjectId([])
  //     setProjectCode([])
  //     setSelProjectCode([])
  //     //   setTech([])
  //     findDProjectId(id, 1)

  //     // if(stateonce){
  //     //   setstateonce(false)
  //     //   dispatch(clearProjectId())
  //     // }

  //     setValue("siteId", id)
  //     let siteOneData=allProjects.filter((item) => item.siteId == id)

  //     console.log(siteOneData,"siteOneDatasiteOneData")
  //     if(siteOneData.length>0){
  //       let abc = setValue("siteUniqueId", siteOneData[0].uniqueSiteId)
  //       setSiteError(false)
  //       dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, siteOneData[0].uniqueSiteId))
  //       // if (allProjects.length > 0)
  //       //   console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
  //       // let idObject = allProjects.filter((item) => item.siteId == id)
  //       // if (idObject.length > 0) {
  //         // setond(true)
  //       }
  //     }else{
  //       console
  //       setProjectCode([])
  //       setSelProjectCode([])
  //     }
      
  //   }
  //   else {
  //     setSiteError(true)
  //   }
  // }.

  const findDSiteId = (id) => {
    console.log(id, "findDSiteId", allProjects)
    if (id != undefined && customesite!=id) {
      setcustomesite(id)
      setoneprid('')
      setppProjctId('')
      setTech([])
      setProjectId([])
      setProjectCode([])
      dispatch(clearGetSingleProjectDatabase())
      dispatch(clearProjectId())
      dispatch(ClearProductCode())
      // projectData.singleSite = []
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        // setond(true)

        // dispatch(FetchSingleRecordAction(projectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))   //from project Databasee
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))   //from network Databasee
      }
    }
    else {
      // setSiteError(true)
    }
  }
  
  const setHandler = (e) => {
    setSow((prev) => {
      return { ...prev, selectedSow: e, errorState: false }
    })
  }
  const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()

  const onSubmit = async (data) => {
    setDisable(true)
    if (sow.selectedSow.length == 0) {
      setSow((prev) => {
        return { ...prev, errorState: true }
      })
      return null
    }
    if (showReason == false) {
      delete data["SchedulerCancelRollbackReason"]
    }
    data["preHWLayerConfiguration"]=reformArray(tech)
    data["postHWLayerConfiguration"]=reformArray(post)
    data["sowList"] = reformArray(sow.selectedSow)
    
    data["scheduleDate"]= moment(setdate).format(moment_common_full_date_format)
    // data["scheduleDate"] = fun_time_zonechanger(commonzone, setdate).format(moment_common_full_date_format)
    data["requestForm"]="IX"
    console.log("the data iss =", data)

    popUpUpdate({})

    dispatch(addUserAction(ixModule, data))
    setCloser(true)
  }
  const FetechAllDetail = (id) => {
    // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")
    // setPId(id)
    // setppProjctId(id)
    setProjectId(id)
    let selectedId = id.map((item) => {
      return item.value
    })
    // dispatch(FetchSingleRecordAction(ranDashboard, selectedId));
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))
    // setCtrCount(1)
    setValue("projectUniqueId", selectedId.join(","))
    // /console.log("FetechAllDetail", id)
  }
  console.log("the col =", columnsArray("S.no	Site ID	Project Type	Project ID	RTT Actual	DIY Vendor	Integrator Name	GC	Monitoring start Date	Time Monitoring Start (CST TZ)	0 Hour Status	0 Hr Rprt Sent	24 Hour Status	24 Hr Rprt Sent	48 Hour Status	48 Hr Rprt Sent	72 Hour Status	72 Hr Rprt Sent	Final Status	Integration Alarm Free report	End (CST TZ)	Comments	Unlock Date by RF"))
  const findDProjectId = (id, def = 0, pcode = []) => {

    // setCountRender(1)
    // console.log("projectCodeArray", id, projectCodeArray)
    // setppProjectCode(id)

    // setppProjctId('')
    let selectedIds = []
    let nselectedIds = ""
    if (def == 0) {
      setProjectCode(id)
      selectedIds = id.map((item) => {
        return item.value
      })
      nselectedIds = selectedIds.join(",")
    } else {
      let testpcode = []

      // testpcode=projectCodeArray.map((ittm)=>{
      //   if(pcode.indexOf(ittm.label)!=-1){
      //     return {
      //       label:ittm.label,
      //       value:ittm.value
      //     }
      //   }
      // })
      // setProjectCode(testpcode)
      nselectedIds = id
    }
    // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

    setSelProjectCode(nselectedIds.split(","))
    setValue("projectCode", nselectedIds)
    setValue("projectcodeUniqueId", selectedIds.join(","))
    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))
    setoneprid(id)



  }

  useEffect(() => {
    dispatch(AccountReducerAction(siteDblistSiteId))
    dispatch(TierListReducerAction(managEngineer))
    dispatch(AccountReducerAction(allTechnologyApi));
    // dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(AccountReducerAction(accountApi));
  }, [])
  return (
    <fregment className="m-6">

      <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            Site Id
          </label>
          <input className='form-control' list="cars"
            style={{ height: "32px" }}

            onBlur={(e) => {
              findDSiteId(e.target.value)
            }}
          />
          <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
            Required Field
          </p>
          <datalist id="cars">

            {options.siteArray}
          </datalist>
        </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Project Code</label>

            <MultiSelect
              className="text-dark"
              hasSelectAll={false}
              options={options.projectCodeOption}
              onChange={findDProjectId}
              value={selectProjectCode}
            />
          </div>
        <div className="form-group col-md-3 ">
          <label >Project Id</label>
          <MultiSelect
            className="text-dark"
            hasSelectAll={false}

            options={newTtempArray}
            onChange={FetechAllDetail}
            value={selectProjectId}
          />
          {/* {errors.projectUniqueId && (
                        <p className="error">
                          {errors.projectUniqueId.message}
                        </p>
                      )} */}
        </div>
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">Account</label>
          <select style={{ height: "32px" }}
            className="custom-select-style form-control"
            ID="exampleFormControlSelect1"
            {...register("account", {
              required: "Please select account"
            })}
          >
            <option selected disabled value=''>Select...</option>
            {options.accountArry}
          </select>
          {errors.account && (
            <p className="error">
              {errors.account.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Market</label>

          <input
            style={{ height: "32px" }}
            type="text"
            readOnly
            className="form-control"
            value={
              singleSitestate
                ? singleSitestate.market
                : ""
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Activity Date</label>

          <DatePicker
            style={styles}
            className="form-control"
            selected={setdate}
            onChange={(e) => { setStartDate(e); setStartDateError(false) }}
            showTimeSelect
            popperPlacement="top"
            // excludeOutOfBoundsTimes
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="MM/dd/yyyy hh:mm aa"
            minDate={new Date()}
          //   maxDate={addDays(new Date(), 31)}
          />
          <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">MOP</label>

          <select
            style={{ height: "32px" }}
            type="text"
            className="custom-select-style form-control"
            {...register("dayNightMop", { required: "Shift is required" })}
          >
            <option selected disabled value="">Select...</option>
            <option value="Day">Day</option>
            <option value="Night">Night</option>
          </select>
          {
            errors.sheduleShift && (
              <p className="error">
                {errors.sheduleShift.message}
              </p>
            )
          }
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">SOW</label>
          <MultiSelect
            className="text-dark"
            hasSelectAll={false}
            options={sow.sowList}
            onChange={setHandler}
            value={sow.selectedSow}
          />
          <p className='error' style={{ display: sow.errorState ? "block" : "none" }}>Required Field</p>
          {/* <select
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            {...register("sheduleSow", { 
            
              required: "Reason is required" })}
          >
             <option selected disabled value="">Select</option>
            <option value="IX">IX</option>
            <option value="NEA">NEA</option>
            <option value="Troubleshooting">Troubleshooting</option>
            <option value="Call Test(PSAP/Speed Test)">Call Test(PSAP/Speed Test)</option>
            </select> */}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Service Affected</label>

          <select
            style={{ height: "32px" }}
            type="text"
            className="custom-select-style form-control"
            {...register("serviceAffected", { required: "Service Affected is required" })}
          >
            <option selected disabled value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="NO">No</option>
          </select>
          {
            errors.serviceAffected && (
              <p className="error">
                {errors.serviceAffected.message}
              </p>
            )
          }
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Pre HW/Layer Configuration</label>
          <MultiSelect
                            options={multipleTechno}
                            onChange={techChangleHandler}
                            value={tech}
                            required
                            hasSelectAll={false}
                          />
          {/* <input
            style={{ height: "32px" }}
            type="text"
            {...register("PreHWConfiguration", { required: "Pre HW Configuration is required" })}
            className="form-control"
          /> */}
          {
            errors.PreHWConfiguration && (
              <p className="error">
                {errors.PreHWConfiguration.message}
              </p>
            )
          }
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Post HW/Layer Configuration </label>

          <MultiSelect
                            options={multipleTechno}
                            onChange={postChangleHandler}
                            value={post}
                            required
                            hasSelectAll={false}
                          />
          {/* <input
            style={{ height: "32px" }}
            type="text"
            {...register("PostHWConfiguration", { required: "Post HW Configuration is required" })}
            className="form-control"
          /> */}
          {
            errors.PostHWConfiguration && (
              <p className="error">
                {errors.PostHWConfiguration.message}
              </p>
            )
          }
        </div>
        {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Integration/RAN Assigned Engineer</label>

          <input
            style={{ height: "32px" }}
            type="text"
            {...register("IntegrationRANAssignedEngineer", { required: "Reason is required" })}
            className="form-control"
          />
        </div> */}
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Pre-Integration</label>

          <select
            style={{ height: "32px" }}
            // type="text"
            {...register("SchedulerPreIntegration", { required: "Pre-Integration is required" })}
            className="form-control"

          >
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
          {errors.SchedulerPreIntegration && (
            <p className="error">
              {errors.SchedulerPreIntegration.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Scheduler Note</label>

          <input
            style={{ height: "32px" }}
            type="text"
            {...register("SchedulerNote", { required: "Scheduler Note is required" })}
            className="form-control"
          />
          {errors.SchedulerNote && (
            <p className="error">
              {errors.SchedulerNote.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">

            IX/RAN Assigned Engg
          </label>
          <input style={{ height: "32px" }}
            type="text"
            {...register("SchedulerAssignedIntegrationEngineer", {
              required: "IX/RAN Assigned Eng is required"
            })}
            onBlur={(e) => { setEngineerOpen(e.target.value) }}
            list="engOpen"
            className="form-control"
            ID="inputAddress"
            defaultValue={EngineerOpen}
            placeholder="Select Engineer"
          />

          <datalist id="engOpen">
            {options.engineerArray}
          </datalist>

          {errors.SchedulerIntegrationEngineer && (
            <p className="error">{errors.SchedulerIntegrationEngineer.message}</p>
          )}
        </div>

        {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Status</label>

          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            {...register("SchedulerStatus", { required: "Status is required" })}
          />
                {errors.SchedulerStatus && (
                                <p className="error">
                                  {errors.SchedulerStatus.message}
                                </p>
                              )}
        </div> */}
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Status</label>

          <select
            style={{ height: "32px" }}
            type="text"

            // onChange={(e)=>{
            //   console.log("thehdewfwef=",e.target.value)
            //   e.target.value=="Canceled"||e.target.value=="Rollback"?setShowReason(true):setShowReason(false)}}
            {...register("postActivityStatusSiteLevel", {
              onChange: (e) => {
                console.log("watch", e.target.value);
                e.target.value == "Cancelled" || e.target.value == "Rollback" ? setShowReason(true) : setShowReason(false)
              },
              required: "Reason is required"
            })}
            className="custom-select-style form-control"
          >
            <option selected disabled value="">Select...</option>
            <option value="Scheduled">Scheduled</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Rollback">Rollback</option>
            <option value="On-Hold">On-Hold</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Completed">Completed</option>
            {/* <option value="Completed with No Alarm">Completed with No Alarm</option>
            <option value="Completed with NSI Alarm">Completed with NSI Alarm</option>
            <option value="Completed with SI Alarm">Completed with SI Alarm</option> */}
          </select>
          {errors.SchedulerStatus && (
            <p className="error">
              {errors.SchedulerStatus.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">CR No.</label>
                    <>
                      <input style={{ height: "32px" }}
                        {...register("crNumber", {
                          required: "CR Number is required",
                          pattern:{
                            value:/CR[0-9]{9}$/,
                            message:"Please Enter A Valid CR Number ( CR+9 digits )"
                          }
                        })}

                        
                        type='text' className='form-control' />
                      {errors.crNumber && (
                        <p className="error">
                          {errors.crNumber.message}
                        </p>
                      )}
                    </>
                    </div>
        {showReason ?
          <div className="form-group col-md-3" >
            <label htmlFor="inputEmail4">Cancel/Rollback Reason</label>

            <textarea
              style={{ height: "32px" }}
              // defaultValue={showReason?"":""}
              // type="text"
              {...register("SchedulerCancelRollbackReason", { required: showReason })}
              className="form-control"
            />
            {errors.SchedulerCancelRollbackReason && (
              <p className="error">
                Reason is required
              </p>
            )}
          </div>

          : ''
        }
        
        <Advcommoncrewupdated label={"FE :"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={""} currentValueId={""} />
        {/* <AdvancedCommonCrew label={"FE :"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={''} currentValueId={""} /> */}
        <div className="d-flex justify-content-center mb-5" style={{ width: "100%" }}>
          <button type="submit" className="btn btn-primary btn-xs py-2 mr-2 px-4 mt-2" disabled={ disableButton}>
            Submit
          </button>
          
          <button className="btn btn-danger btn-xs py-2  px-4 mr-2 mt-2" onClick={() => history.push("/PAG/ix/dashboard")}>Back</button>
        </div>
      </form>
    </fregment>
  );
};

export default IXModule;
