import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";

import { Row, Col, Card, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from 'react-date-range';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import moment from "moment"
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon, dbdatetomoment, getdatedatalistnext, ageofdatalistnext, getProgressBar, time_zonechanger, moment_common_full_date_format, commonzone, momnt_hour_ageofdata, smart_more_info, rancustomDataTableOptionObject } from "../../../common/variables";
import { AccountReducerAction, addUserAction, ExportXLSXDownloadReducerAction, FetchSingleRecordAction, GetTimeLineAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import { approveOrDisapprove, getAllDropDown, listSiteId, ranDashboard, ranSiteStatus, singleProjectApi, timelineApi, reqPost, reqPreCheckCancel, ranDashboardTimeLine, reqPostCheckCancel, ranSpilOver, export_Spillover_ran_report } from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import CommonModal from "../Custom/CommonModal";
import { momnt_ageofdata } from "../../../common/variables";

const ExecutiveSpilloverReport = () => {
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false)
  const [reqPreModal, setReqPreModal] = useState(false)
  const [siteDetail, setSiteDetail] = useState({})
  const [siteUId, setSiteId] = useState()
  const history = useHistory();
  const [showDatePicker, setDatePicker] = useState(false)
  const [closer, setCloser] = useState(false);
  
  const [exportApiData, setexportApiData] = useState("");
  const [mapModal, setMapModal] = useState(false)
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }])
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [OneTimere, setOneTimere] = useState(false);
  const [ResetFilter, setResetFilter] = useState(false);

  const [bulkApproveModalState, setBulkApproveModal] = useState(false);
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");

  console.log(history, "history")

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  const [uniqueId, setUniqueId] = useState()

  const dispatch = useDispatch();
  let getMuiTheme = () => createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            "padding": "6px 0px"
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {

            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px !important",

            margin: "auto"

            // maxHeight: 45,
            //   fontSize: 10,
            //   overflowY: "hidden",
            //   overflowX: "auto",
            //   padding: "3px",
            //   margin: "auto",
            //   textAlign: "center",
            //   width: "max-content",


          },
        }
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {

            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: 'blue',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  });

  let columns = [

    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // minWidth:"150px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },

    
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    // {
    //   "name": "siteId",
    //   "label": "Site ID",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "min-width": "90px",
    //         "max-width": "140px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },
    {
      "name": "market",
      "label": "Market",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "50px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
   
    {
      "name": "pSow",
      "label": "Planned SoW",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "nowrap",
            "min-width": "260px",
            "max-width": "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "sowCommentsModal",
      "label": "SoW Comments",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "260px",
            "max-width": "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "serviceAffected",
      "label": "Service Affecting",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "80px",
            "max-width": "100px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "",
      "label": "Issue causing Spillover",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "",
      "label": "Actions to be taken & ETA",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttIdsmodal",
      "label": "MPULSE TT ID(s)",
      "sortable": false,
      options: {
        filter: false,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "pierttIdsmodal",
      "label": "PIER TT ID(s)",
      "sortable": false,
      options: {
        filter: false,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    
    {
      "name": "ttIds",
      "label": "MPULSE TT ID(s)",
      "sortable": false,
      options: {
        filter: false,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "pierttIds",
      "label": "PIER TT ID(s)",
      "sortable": false,
      options: {
        filter: false,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttimpact",
      "label": "TT Impact",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttVendorData",
      "label": "HardCost Vendor",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },

    {
      "name": "ttCompanyDatamodal",
      "label": "CX/IX Crew Company",
      "sortable": false,
      options: {
        filter: false,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "250px",
            "max-width": "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttCompanyData",
      "label": "CX/IX Crew Company",
      "sortable": false,
      options: {
        filter: false,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    // {
    //   "name": "cxixcrewcompany",
    //   "label": "CX/IX Crew Company",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "min-width": "125px",
    //         "max-width": "145px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },
    {
      "name": "ttOwnerDatamodal",
      "label": "TT Owner",
      "sortable": false,
      options: {
        filter: false,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttOwnerData",
      "label": "TT Owner",
      "sortable": false,
      options: {
        filter: false,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "250px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "nestingTime",
      "label": "Nest Time",
      "sortable": false,
      options: {
        filter: false,


        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "60px",
            "max-width": "80px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "TMOOutageApprovedMWTime",
      "label": "Outage Approved Window",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "NestingTimeLeft",
      "label": "Nesting Time Left",
      "sortable": false,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "90px",
            "max-width": "100px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    }
  ];

  <th scope="col">CX Crew Vendor</th>
  let cxTableHeader = ['CX Crew Vendor', 'CX Crew Lead Contact Number', 'CX Crew Lead Name', 'CX Crew Lead Company', 'CX Crew Lead E-mail ID']
  let ixTableHeader = ['IX Crew Vendor', 'IX Crew Lead Contact Number', 'IX Crew Lead Name', 'IX Crew Lead Company', 'IX Crew Lead E-mail ID']
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];

  let excelData = []
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {

    console.log("openModal", data, reqPreModal)
    if (data == 'reqPreCheck') {
      setReqPreModal(true)
      console.log("the req premodal =", data)
      setUniqueId(id)
      setCloser(true)

    }
    else
      if (id == null) {
        setCrewModal(true)
        setSiteDetail(data)
        console.log("th dta is  =", reqPreModal)
        console.log("the main data is =", siteDetail)
      }
  }



  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };

  const count = useSelector((state) => {
    console.log("the state is=", state);
    let sitesdata = state.CommonResponse.ranDashboard;
    
    let totalCount=state.countState.ranTrackerCount
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }



    let ranSiteStatus = state.CommonResponse.ranSiteStatus
    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus)
      siteStatus = ranSiteStatus
    }
    console.log(mark, "mark");

    if (Object.keys(state.CommonResponse.ranSpilOver).length > 0) {
      if (state.CommonResponse.ranSpilOver.length > 0) {


        rowData = state.CommonResponse.ranSpilOver.map((item, index) => {

          console.log("i am caling", state)
          item['action'] = <><button className="btn btn-primary shadow btn-xs sharp mr-1"  ><i className="fa fa-pencil"></i></button>
            <button className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></button></>
          item["5GId"] = "";
          item["LTEId"] = "";
          item["UMTSId"] = "";
          item["mixedModeId"] = "";
          item['crNo'] = item.crNumber

          let pierttidsData = item.troubleTickets.map((itemInner) => {
            return itemInner.tmoPierTtid;
          });

          item["pierttIds"] = pierttidsData.join(", ");


          let ttidsData = item.troubleTickets.map((itemInner) => {
            return itemInner.ttid;
          });

          


          let ttimpactData = item.troubleTickets.map((itemInner) => {
            return itemInner.impact;
          });

          item["ttimpact"] = ttimpactData.join(", ");




          let ttOwnerData = item.troubleTickets.map((itemInner) => {
            return itemInner.engineerOpeningtt;
          });

          item["ttOwnerData"] = ttOwnerData.join(", ");


          

          let ttVendorData = item.troubleTickets.map((itemInner) => {
            return itemInner.vendor;
          });

          item["ttVendorData"] = ttVendorData.join(", ");


          

          let ttCompanyData = item.troubleTickets.map((itemInner) => {
            return itemInner.company;
          });

          item["ttCompanyData"] = ttCompanyData.join(", ");

          // console.log(momnt_ageofdata())
          console.log("momnt_ageofdata",time_zonechanger(commonzone,moment_common_full_date_format),item.lastNestEnd,momnt_hour_ageofdata(time_zonechanger(commonzone,moment_common_full_date_format),item.lastNestEnd,moment_common_full_date_format))
          let btime = ageofdatalistnext(item.lastNestEnd);
          let busetime = 0;
          if (btime) {
            busetime = btime[3];
          }



          let initial_value = item.nestingTime - busetime;
          let totalValue = item.nestingTime;
          var proper = ((initial_value / totalValue) * 100).toFixed()
          let perpg = 0
          // if (pgbar[0] > 100) {
          //   perpg = 100
          // } else if (pgbar[0] < 0) {
          //   perpg = 0
          // } else {
          //   perpg = pgbar[0]
          // }


          // progre
          
          console.log(proper,initial_value,totalValue, item["postCheckDelivered"], "proper")


          let leftTime=momnt_hour_ageofdata(time_zonechanger(commonzone,moment_common_full_date_format),item.lastNestEnd,moment_common_full_date_format)
          let leftTimeSplit=leftTime.split(" ")[0]
          leftTimeSplit=leftTimeSplit.replace(",","")

          console.log("leftTimeSplit",leftTimeSplit)

          if(+leftTimeSplit>=1){
            item["NestingTimeLeft"] = <p className="badge badge-success p-1">{leftTime}</p>
          }
          if(+leftTimeSplit<1 && +leftTimeSplit>=0){
            item["NestingTimeLeft"] = <p className="badge badge-warning p-1">{leftTime}</p>
          }
          if(+leftTimeSplit<0){
            item["NestingTimeLeft"] = <p className="badge badge-danger p-1">{leftTime}</p>
          }
          
          // item["NestingTimeLeft"] = leftTimeSplit
          // const pgbar = getProgressBar(getdatedatalistnext(item.preCheckRequest), getdatedatalistnext(item.lastNestEnd))
          // item["progress"] = (
          //   <div
          //     className={"custom-cursor"}
          //     style={{ width: 35, height: 35 }}
          //   >


          //     <CircularProgressbar
          //       value={perpg}
          //       maxValue={100}
          //       styles={applyStyle}
          //       text={`${perpg}%`}
          //     />


          //   </div>
          // );









          item["TMOOutageApprovedMWTime"] = item["RF_Approved_MW_Time"] && item["RF_Approved_EndTime_Time"] ? getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1] : ""
          const myMomentObject = dbdatetomoment(getdatedatalistnext(item["scheduleDate"]))
          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()


          if (item["mopDownTime"]) {
            let cTime = new Date()
            let sdate = moment(new Date(item["mop9StartTime"])).format("MM/DD/YYYY")
            console.log("the date is =", sdate)
            console.log("the date is =", moment(item["mopStartTime"]).format("MM/DD/YYYY"))
            // console.log("the date is =",item["mopDownTime"])
            // console.log("the real data=",cTime.getHours())
            // console.log("the real datasdasa=",cTime.getDate())
            // console.log("the real datdadasdasdasdaada=", sdate.getDate())

            // console.log("the date is =",item["mopDownTime"].getDate())
            // if(sdate.getMonth()==cTime.getMonth() && sdate.getDate()==cTime.getDate() && cTime.getHours()>=sdate.getHours() ){
            //   let remainingTime =sdate.getHours()>=cTime.getHours()
            //   console.log("the difference is =",remainingTime)
            //   item['progress']=<div style={{ width: 40, height: 40 }}><CircularProgressbar value={remainingTime/100} maxValue={1} text={`${remainingTime}%`} /></div>
            // }
            console.log("the real time=", (new Date(item["mopDownTime"])).getTime())
          }

          // item['siteId'] = <a href={'/PAG/ran/support/dashboard/'+item.newuniqueId} target="_blank">{item.siteId}</a>
          // console.log("item is =", item["technology"]);
          // item['technology']=''

          // dasdas

          item['LTPCR'] = <p>{item.preCheckRequest ? dbdatetodate(item.preCheckRequest) : ""}</p>
          item['LTPCD'] = <p>{item.preCheckDelivered ? dbdatetodate(item.preCheckDelivered) : ""}</p>
          item['LTPOR'] = <p>{item.postCheckRequest ? dbdatetodate(item.postCheckRequest) : ""}</p>
          item['LTPOD'] = <p>{item.postCheckDelivered ? dbdatetodate(item.postCheckDelivered) : ""}</p>

          item['sowCommentsModal'] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"

              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                      fontSize: "13px"
                    }}
                    className="CommonModal"
                  >
                    {item['sowComments']}
                  </p>,
                  "Comment",
                  "SOW Comments"
                )
              }
            >
              {item['sowComments'] ? item['sowComments'].slice(0, 64) + "..." : ""}
            </p>
          );



          

          item["ttIds"] = ttidsData.join(", ");
          item["pierttIdsmodal"] = smart_more_info(pierttidsData.join(", "),"","PIER TT ID(s)",CommonModalFun);
          item["ttIdsmodal"] = smart_more_info(ttidsData.join(", "),"","MPULSE TT ID(s)",CommonModalFun);
          item["ttCompanyDatamodal"] = smart_more_info(ttCompanyData.join(", "),"","TT Company",CommonModalFun);
          item["ttOwnerDatamodal"] = smart_more_info(ttOwnerData.join(", "),"","TT Owner",CommonModalFun);





          // if(item.ranStatus !="Login Awaited - CR Approved" && item.ranStatus !="Login Awaited - CR Approved")
          // else if(item.)
          let pSow = "";
          let pSowArray = item["sowList"].values;
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow)
          item["pSow"] = pSow;
          //Technology


          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["cxixcrewcompany"] = ""

          if (item.ixCrewIdcrewLeadCompany) {
            item["cxixcrewcompany"] = item.ixCrewIdcrewLeadCompany
          }
          if (item.cxCrewIdcrewLeadCompany) {
            item["cxixcrewcompany"] = item.cxCrewIdcrewLeadCompany
          }
          let tempDate = "Scheduled Date	Week No.	ENTRY MODE	Service Affecting	Outage Approval?	Site ID	Assigned Engineer	Outage Approved MW Time	Nesting Time	Progress	Schedule Source	Project Code	Technology	GC Type	GC Vendor	GC Company	GC Name	GC Email	GC Contact#	MPULSE TT-ID	PIERTT-ID	CR No.	Planned SoW	Day MOP or Night MOP	Manual Precheck Request	Login Time PreChecks Requested(Mob-App)	PRE-CHECKs	Log in time PreCheck Delivered	Crew	Call Test Activity	Manual Postcheck Request	Logout Time PostChecks Requested(Phone e-mail or Mobile-App)	Post Checks	Log out time Postchecks delivered	Pier Updated	Site Type"
          tempDate = tempDate.split("\t")
          // excelData=tempDate.map((temp)=>{
          let excelObject = {}
          for (let i = 0; i < tempDate.length; i++) {

            excelObject[tempDate[i]] = "item['']"

          }
          excelData.push(excelObject)
          // })
          console.log("the excel data is =", excelData)
          return item;
        });
        excelData = state.CommonResponse.ranDashboard.map((item) => {

          let pSow = "";
          let pSowArray = item["sowList"].values;
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow)
          item["pSow"] = pSow;
          if (item['mopStartTime'] && item['mopEndTime']) {
            let mopSTime = item['mopStartTime'].split(" ").pop()
            let mopETime = item['mopEndTime'].split(" ").pop()
            item['rfMWTime'] = mopSTime + " to " + mopETime
          }
          // let excelObject = {
          //   "Schedule Date": item["mopStartTime"],
          //   "Main Id": item.ndResult.siteId,
          //   "Service Affected": item["serviceAffected"],
          //   "Technology": item["temp"],
          //   "Project Code": item["projectCode"],
          //   "RAN Bucket": item["ranBucket"],
          //   "Cr Number": item["crNo"],
          //   "Cr Status": item["crStatus"],
          //   "Planned SOW": item["pSow"],
          //   "RAN Status": item["ranStatus"],
          //   "Equipment": item["equipment"],
          //   "Day or Night MOP": item["dayNightMop"],
          //   "Day MOP Reason": item["dayMopReason"],
          //   "Rf Approval MW Time": item["rfMWTime"],
          //   // "Manual PreCheck Request": item["mPrecheckReq"],
          //   "Login Time PreCheck Request(mobile)": dbdatetodate(item.preCheckRequest),
          //   "Pre-Checks": '',// item["preCheck"],
          //   "Login Time Precheck Delivered": item[""],// item["LTPCD"],
          //   "Nesting Time": item["mopDownTime"],
          //   // "Progress": item["progress"],
          //   "Crew": "",// item["crew"],
          //   "Call Test Activity": item[""],// item["CTA"],
          //   "Manual Postcheck Request": item[""],// item["postCh"],
          //   "Logout Time Postcheck Request": item[""],// item["LTPOR"],
          //   "Post Checks": item[""],// item["postChecks"],
          //   "Logout Time Postcheck Delivered": item[""],// item["LTPOD"],
          //   "Pier Updated": item[""],
          //   // "Site Type": item.ndResult.siteType
          // }
          // return excelObject
        })


      }
    }

    console.log("columns", state.sites.sites.TotalTicket);

    return { rowData,totalCount, columns, mark, siteStatus };
  });

  const options = {

    download: false,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    responsive: true,
  };


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }
  

  const ClearFilter = () => {
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();

    setOneTimere(true)

    setResetFilter(true)
    setexportApiData(ranSpilOver)
    dispatch(AccountReducerAction(ranSpilOver,true,true));
  };


  const exportAllData = () => {

    dispatch(ExportXLSXDownloadReducerAction(export_Spillover_ran_report,"RANSPILOVER_" + moment(new Date()).format("MM-DD-YYYY"),exportApiData))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }


  
  const setDateFilter = (e) => {
    e.preventDefault();
    // dateRange[0].startDate = moment(fstartdate).format("mm/dd/yyyy");
    // dateRange[0].endDate = moment(fenddate).format("mm/dd/yyyy");
    // console.log("the filtered data=", dateRange);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");

    // if (currentUrl.includes("?")) {
    //   dispatch(
    //     AccountReducerAction(
    //       ranDashboard +
    //         "?" +
    //         urlArray.pop() +
    //         "&start=" +
    //         dateRange[0].startDate +
    //         "&end=" +
    //         dateRange[0].endDate
    //     )
    //   );
    // } else {

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }

    if (fenddate < fstartdate) {
      
      swal("Oops", "End Date Should be Greater than Start Date", "info");
      return;


    } else {
      setResetFilter(true)
      
      setexportApiData(export_Spillover_ran_report + "?start=" + moment(fstartdate).format("MM/DD/yyyy") + "&end=" + moment(fenddate).format("MM/DD/yyyy"))
      
      dispatch(
        AccountReducerAction(
          ranSpilOver +
          "?start=" +
          moment(fstartdate).format("yyyy-MM-DD") +
          "&end=" +
          moment(fenddate).format("yyyy-MM-DD")
        )
      );
    }
    // }
  };

  const AddCustomButton = () => (
    // <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
      
      
      
    //   <ExportToExcel
    //     apiData={excelData}
    //     fileName={"RanDashboard_" + moment(new Date()).format("MM/DD/YYYY")}
    //   />


    // </fregment>

    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >


      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>


      {/* <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",

          margin: "18px 10px auto 10px"
        }}
      >
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          selected={fenddate}
          autoComplete={"off"}
          onChange={(e) => {
            setfenddate(e);
          }}
          minDate={fstartdate}
          dateFormat="MM/dd/yyyy"
        />
      </div> */}
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>

      {/* {userDetails.rolename !== "GC Login"
        // && (
        // <button
        //   className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
        //   data-toggle="modal"
        //   data-target="#exampleModall"
        //   onClick={() => setMapModal(true)}
        // >
        //   Map View
        // </button>
        // )
      } */}
      
      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>

      {/* <ExportToExcel
        apiData={excelData}
        fileName={"RanDashboard_Tracker" + moment(new Date()).format("MMDDYYYY")}
      /> */}
    </fregment>
    
  )
  console.log("yhe row data isn=", rowData);

  console.log("the excel data is =", excelData)
  useEffect(() => {
    dispatch(AccountReducerAction(ranSpilOver));

  }, []);

  const sortedOrder={
    // name: 'scheduleDate',
    // direction: 'desc'
  }
  return (
    <>
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
      <div className="mui-t-h"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >

        <ThemeProvider theme={getMuiThemeCommon("200px")}>

          <MUIDataTable
            data={rowData}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton, true, "Excutive spillover Report")}
            options={rancustomDataTableOptionObject(AddCustomButton,false,"RanTracker", "","",sortedOrder,AccountReducerAction,export_Spillover_ran_report,history.location.search,rowData.length,count.totalCount,[fstartdate,fenddate],OneTimere,setOneTimere,setResetFilter,ResetFilter)}
          />
        </ThemeProvider>
      </div>

    </>
  );
};

export default ExecutiveSpilloverReport;
