import React, { useEffect, useState } from 'react'
import PieScore from '../Custom/ScoreCardComponent/PieScore'
import ScoreBarChart from '../Custom/ScoreCardComponent/ScoreBarChart'
import CommonTable from '../Custom/CommonTable'
import { common } from '../../../services/AdminService'
import { scoreCard } from '../../../common/config'
import PieFilterData from '../Custom/ScoreCardComponent/PieFilterData'
import PieScoreUpdated from '../Custom/ScoreCardComponent/PieScoreUpdated'
import PunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageFourScoreBarChart'
import PunctualityRateStageThreeBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageThreeBarChart'
import PunctualityRateStageTwoBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageTwoBarChart'
import ScoreBarChartLine from '../Custom/ScoreCardComponent/ScoreBarChartLine'
import ExistPunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/ExistPunctualityRateStageFourScoreBarChart'
import TWOTOPunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/TWOTOPunctualityRateStageFourScoreBarChart'

const ScoreCard = () => {
    const [pieHideOrUnHide,setType]=useState(false)
    const [StageTwo,setStageTwo]=useState(false)
    let [count,setCount]=useState(true)
    let [filter,setFilter]=useState("")
    const [twopieHideOrUnHide,settwoType]=useState(false)
    let [twocount,settwoCount]=useState(true)
    let [threecount,setThreeCount]=useState(true)
    let [fourcount,setFourCount]=useState(true)
    
    let [fivercount,setfivercount]=useState(true)
    let [twofilter,settwoFilter]=useState("")
    let [globalPieType,setGlobalPieType]=useState("")
    
    const [noOfBarChart,setBarData]=useState({
      "XaxisLabel": [],
      "graphData": [],
      "toolTip": []
  })

  const [noOftwoBarChart,settwoBarData]=useState({
    "XaxisLabel": [],
    "graphData": [],
    "toolTip": []
})


  return (
    <div className='pb-4' >
        
        <PieScoreUpdated setFilter={setFilter} filter={filter} setfivercount={setfivercount} fivercount={fivercount} count={count} setCount={setCount} twocount={twocount} settwoCount={settwoCount} threecount={threecount} setThreeCount={setThreeCount} fourcount={fourcount} setFourCount={setFourCount}  setBarData={setBarData} setStageTwo={setStageTwo} noOfBarChart={noOfBarChart} setType={setType} setGlobalPieType={setGlobalPieType}/>
        {/* {StageTwo? */}
        {/* ""} */}
        {pieHideOrUnHide?
        <>
        {
        ["DowntimeRate"].indexOf(globalPieType)!=-1?
        <ScoreBarChartLine globalPieType={globalPieType} filter={filter} setFilter={setFilter} count={count} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOfBarChart} />:
        <ScoreBarChart globalPieType={globalPieType} filter={filter} setFilter={setFilter} count={count} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount}  setfivercount={setfivercount} noOfBarChart={noOfBarChart} />}


        <CommonTable   />
        </>
       :<></>
        }
        {/* {
            noOfBarChart.map((item)=>{
                return <ScoreBarChart />
            })
        } */}
        {
          ["PunctualityRate","TroubleTicketRate","SiteGoingoutofApprovedMWRate","DowntimeRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageTwoBarChart typeOfChart={"bar"} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOftwoBarChart} /> : ""
        }
        {/* {
          ["TroubleTicketRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageTwoBarChart typeOfChart={"line"} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} noOfBarChart={noOftwoBarChart} /> : ""
        } */}

        {
          ["PunctualityRate","TroubleTicketRate","SiteGoingoutofApprovedMWRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageThreeBarChart filter={twofilter} setFilter={settwoFilter} threecount={threecount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOftwoBarChart} /> : ""
        }
        
        {
          ["TroubleTicketRate","PunctualityRate"].indexOf(globalPieType)!=-1?
          <TWOTOPunctualityRateStageFourScoreBarChart typeOfChart={"bar"} setfivercount={setfivercount} fivercount={fivercount} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} noOfBarChart={noOftwoBarChart} /> : ""
        }
        {/* {
          ["PunctualityRate","TroubleTicketRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageFourScoreBarChart globalPieType={globalPieType} filter={twofilter} setFilter={settwoFilter} fourcount={fourcount} setFourCount={setFourCount} noOfBarChart={noOftwoBarChart} /> : ""
        } */}

        

        
        
    
    </div>
  )
}

export default ScoreCard