import { stat } from 'fs';
import { awsChart } from '../actions/createTicketManageFields';
import {
    CONFIRMED_CREATE_POST_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_POSTS,
    CREATE_POST_ACTION,
    FETECH_PROJECT_STATUS,
    FETECH_BUCKET,
    FETECH_SITES,
    FETECH_ACCOUNT,
    ONEFETECH_ACCOUNT,
    FETECH_USER_ACCOUNT,
    FETECH_USER_ROLE,
    FETECH_USER_LIST,
    FETECH_TIER_LIST,
    FETECH_MARKETS,
    ONE_USER_ACCOUNT,
    FETECH_FILTER_USER,
    FETECH_CSV,
    FETECH_PDF,
    FETECH_TECHNOLOGY,
    FETECH_EQUIPMENT,
    UPDATE_POP_UP,
    FETECH_VENDOR,
    FETECH_CREW_COMPANY,
    FETECH_OSS,
    FETECH_SINGLE_PROJECT,
    FETECH_RAN_DASHBOARD,
    FETECH_TIMELINE,
    FETECH_RAN_STATUS,
    FETECH_NETWORK_SITE_STATUS,
    FETECH_SITEID,
    FETECH_CR_NUMBER,
    FETECH_RAN_TRACKER,
    FETECH_ALL_DROP_DOWN,
    FETECH_SINGLE_DROPDOWN,
    FETECH_PRODUCT_CODE,
    FETECH_GSM_UTMS,
    FETECH_RFDS_LIST,
    FETECH_CSV_DATA,
    FETECH_SINGLE_MOPS,
    FETECH_PRODUCT_ID,
    NETWORK_DATABASE_FETECH_CSV_DATA,
    INFLIGHT_FETECH_CSV_DATA,
    MAGENTA_FETECH_CSV_DATA,
    GET_ALL_MAP_SITES,
    FETECH_SINGLE_PROJECTDATABASE,
    FETECH_SINGLE_RAN_PROJECT,
    FETECH_SINGLE_TECH_RAN_PROJECT,
    FETECH_TT_PROJECT,
    FETECH_E911_PROJECT,
    FETECH_RAN_SPILOVER,
    FETECH_NESTING_TIMELINE,
    FETECH_RAN_NESTING_REPORT,
    FETECH_RAN_NESTING_TRACKER,
    FETECH_GEO_FENCING,
    VENDOR_CHART_TIMELINE,
    DASHBOARD_VENDOR_CHART_TIMELINE,
    TT_AGING_CHART_TIMELINE,
    WAR_ROOM_CHART_TIMELINE,
    SET_LOADER,
    CLEAR_FETECH_SINGLE_MOPS,
    CLEAR_FETECH_SINGLE_RAN_PROJECT,
    SET_ASSINGNED_ENGINEER,
    FETECH_IX_DASHBOARD,
    FETECH_SINGLE_IX_PROJECT,
    FETECH_RTT_DASHBOARD,
    FETECH_MOP_STATUS,
    CLEAR_IX_SINGLE_API,
    FETECH_COUNT_DATA,
    FETECH_MOP_SITES,
    FETECH_CLEAR_RAN_DASHBOARD,
    FETECH_NEW_E911_PROJECT,
    FETECH_CLEAR_TT_PROJECT,
    FETECH_CLEAR_RAN_TRACKER,
    AWS_CHART_TIMELINE,
    FETECH_CLEAR_SLA_RAN_TRACKER,
    FETECH_SLA_RAN_TRACKER,
    FETECH_CLEAR_RAN_NESTING_REPORT,
    FETECH_CLEAR_RAN_NESTING_TRACKER,
    TIME_ZONE,
    FETECH_MANAGE_UTILIZATION,
    FETECH_PIER_TT_GROUP,
    FETECH_72_Alarm,
    SSV_DASHBOARD,
    FETECH_MOBILE_DATA_COP_TIMELINE,
    LAUNCH_SSVDASHBOARD,
    FILTERDROPDOWN,
    LCL_MOP_DASHBOARD_FILTER,
    RESET_LCL_MOP_DASHBOARD_FILTER,
    RESET_LCL_RAN_DASHBOARD_FILTER,
    LCL_RAN_DASHBOARD_FILTER,
    RESET_LCL_TT_FILTER,
    LCL_ENOO_FILTER,
    RESET_LCL_ENOO_FILTER,
    LCL_TT_FILTER,
    LCL_LEXPORT_TT_FILTER,
    FETECH_TT_STATUS,
    Save_Last_Saved_Data,
    FETECH_MOP_Instruction,
    FUNVENDOR_SCORE_CARD,
    AWS_CHART_CALL_TIMELINE,
    FETECH_SINGLE_A_DROPDOWN,
    LCL_SSV_FILTER,
    RESET_LCL_SSV_FILTER,
    LCL_LSSV_FILTER,
    RESET_LCL_LSSV_FILTER,
    RESET_LCL_IX_DASHBOARD_FILTER,
    LCL_IX_DASHBOARD_FILTER,
    LCL_SLA_DASHBOARD_FILTER,
    RESET_LCL_SLA_DASHBOARD_FILTER,
    RESET_LCL_NESTING_FILTER,
    LCL_NESTING_FILTER,
    CHAT_MESSAGE_PENDING,
    CLEAR_NETWORK_DATABASE_FETECH_CSV_DATA,
    ADVANCE_FETECH_RAN_DASHBOARD,
    NOTIFICATION_LIST,
    ADVANCE_CLEAR_FETECH_RAN_DASHBOARD,
    LAUNCH_APPROVER_SSVDASHBOARD,
    SSV_APPROVER_DASHBOARD,
    NETWORK_DATABASE_FETECH_LOGGER_DATA,
    GETBARCHARTDATA,
    GETBARCHARTDATATWO,
    PRESWAPCHECKLIST,
    PRESWAPCHECKLISTOLD,
    FUNVENDOR_SCORE_CARD_DASHBOARD

} from '../actions/PostTypes';

const chartData={
    ttVendorChartData:[],
    vendorDashboard:[],
    ttVendorAgingChartData:[],
    warRoomCoustomer:[],
    awsChart:[],
    awsChartCall:[]
}

const initialState = {
    posts: [],
};
const dashinitialState = {
    dashposts: [],
};
const intialSiteState ={
    sites:[],
};
const oldDataState={
    precheckData:{}
}
const countAllState ={
    mopCount:0,
    ranCount:0,
    inflightCount:0,
    magentaCount:0,
    networkDBCount:0,
    ranTrackerCount:0,
    ranSlaCount:0,
    ranDailyReportCount:0,
    ranSpilOverCount:0,
    ranNestingReportCount:0,
    ranNestingTrackerCount:0,
    listEnooCount:0,
    listttCount:0
};



const lclFilterDashboard ={
    mopDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    ranDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    slaDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    ixDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    tt:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    ssvDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    lssvDashboard:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    ttexport:[],
    enoo:{
        dateUrl:"",
        filterUrl:"",
        topUrl:""
    },
    ttalldata:[]
}

const popUpState ={
    popstatus:{},
}
const csvSiteState ={
    magenta:[],
    inflight:[],
    networkdatabase:[],
    networkLogger:{},
    getAllSiteMap:[]
}


const vendorScoreCardResponse={ 

    funVendorScoreCard:[],
    funVendorScoreCardDashboard:[]
}

const initialCommonResponse={  
    preswapchecklist:[], 
    preswapchecklistOld:[],    
    projectBucktet:[],
    projectStatus:[],
    accounts:[],
    mopInstructions:[],
    userrole:[],
    listuser:[],
    listIXData:[],
    listrttData:[],
    listaccounts:[],
    markets:[],
    filteruserlist:[],
    fetchedpdfdata:[],
    technology:[],
    equipments:[],
    vendor:[],
    crewCompany:[],
    oss:[],
    ranDashboard:[],
    notificationList:[],
    ranSpilOver:[],
    ranNesting:[],
    ranNestingReport:[],
    ranNestingTracker:[],
    geoFencing:[],
    manageUtilization:[],
    nestingTime:[],
    chatMessagePending:[],
    ranTracker:[],
    ranSlaTracker:[],
    ranSiteStatus:[],
    ttSiteStatus:[],
    mopSiteStatus:[],
    networkDatabaseSiteStatus:[],
    startAwaitedSite:[],
    crNumberList:[],
    allDropDown:[],
    pierTTGroup:[],
    singleDropDown:[],
    singleADropDown:[],
    mobileDataCopTimeline:[],
    rfdsList:[],
    gsm_utms:[],
    tt_data:[],
    enno_data:[],
    timeZones:[],
    sevenTwoSites:[],
    SSVDashboardData:[],
    LaunchSSVData:[],
    SSVApproverDashboardData:[],
    LaunchSSVApproverData:[]
}

const loaderState={
    spinState:false
}

const oneinitialCommonResponse={       
    projectBucktet:[],
    projectStatus:[],
    accounts:[],
    userrole:[],
    listuser:[],
    listaccounts:[],
    listtier:[],
    markets:[],
    singleSite:[],
    pdsingleSite:[],
    singleRanTechSite:[],
    timelineLogs:[],
    productCode:[],
    productId:[],
    singleMops:[],
    singleIXSite:[],
    ChatInfo:{
        assignedEngineer:"",
        siteId:"",
        ranSupportId:""
    },
    filterDropDown:[],
    barData:{},
    barDatatwo:{}

}

export default function PostsReducer(state = initialState, actions) {
    if (actions.type === CREATE_POST_ACTION) {
        const post = {
            id: Math.random(),
            title: 'Post Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const posts = [...state.posts];
        posts.push(post);
        return {
            ...state,
            posts,
        };
    }


    
    

    if (actions.type === CONFIRMED_DELETE_POST_ACTION) {
        const posts = [...state.posts];
        const postIndex = posts.findIndex(
            (post) => post.id === actions.payload,
        );

        posts.splice(postIndex, 1);

        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_EDIT_POST_ACTION) {
        const posts = [...state.posts];
        const postIndex = posts.findIndex(
            (post) => post.id === actions.payload.id,
        );

        posts[postIndex] = actions.payload;
        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_CREATE_POST_ACTION) {
        const posts = [...state.posts];
        posts.push(actions.payload);

        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_GET_POSTS) {
        return {
            ...state,
            posts:actions.payload,
        };
    }
    return state;
}

export function DashPostsReducer(state = dashinitialState, actions) {
    if (actions.type === CREATE_POST_ACTION) {
        const post = {
            id: Math.random(),
            title: 'Post Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const dashposts = [...state.dashposts];
        dashposts.push(post);
        return {
            ...state,
            dashposts,
        };
    }

    if (actions.type === CONFIRMED_DELETE_POST_ACTION) {
        const dashposts = [...state.posts];
        const postIndex = dashposts.findIndex(
            (post) => post.id === actions.payload,
        );

        dashposts.splice(postIndex, 1);

        return {
            ...state,
            dashposts,
        };
    }

    if (actions.type === CONFIRMED_EDIT_POST_ACTION) {
        const posts = [...state.posts];
        const postIndex = posts.findIndex(
            (post) => post.id === actions.payload.id,
        );

        posts[postIndex] = actions.payload;
        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_CREATE_POST_ACTION) {
        const posts = [...state.posts];
        posts.push(actions.payload);

        return {
            ...state,
            posts,
        };
    }

    if (actions.type === CONFIRMED_GET_POSTS) {
        return {
            ...state,
            dashposts: actions.payload,
        };
    }
    return state;
}

export function siteReducer(state =intialSiteState,actions){

        if(actions.type===FETECH_SITES){
            console.log(state.sites,actions.payload,"dasdkjsajdasjdkas")
            let adata=state.sites
            adata.push(...actions.payload)
            console.log(adata)
            return {
                ...state,
                sites:adata
            }
        }
        if(actions.type===FETECH_MOP_SITES){
            return {
                ...state,
                sites:actions.payload
            }
        }
        return state
}



export function oldDataReducer(state=oldDataState,actions){
    if(actions.type===Save_Last_Saved_Data){
        console.log(actions.payload,"actions.payload")
        if(actions.payload.forType==="Precheck"){
            return {
                ...state,
                precheckData:actions.payload
            }
        }
        if(actions.payload.forType==="Postcheck"){
            return {
                ...state,
                postcheckData:actions.payload
            }
        }
    }
    return state
}


export function countAllReducer(state =countAllState,actions){
    if(actions.type===FETECH_COUNT_DATA){
        console.log(actions.payload,"actions.payload")
        if(actions.payload[1]==="RAN Dashboard"){
            return {
                ...state,
                ranCount:actions.payload[0]
            }
        }
        if(actions.payload[1]==="MOP Dashboard"){
            return {
                ...state,
                mopCount:actions.payload[0]
            }
        }
        if(actions.payload[1]==="inflight"){
            return {
                ...state,
                inflightCount:actions.payload[0]
            }
        }
        if(actions.payload[1]==="magenta"){
            return {
                ...state,
                magentaCount:actions.payload[0]
            }
        }
        
        if(actions.payload[1]==="networkDB"){
            return {
                ...state,
                networkDBCount:actions.payload[0]
            }
        }

        if(actions.payload[1]==="ranTracker"){
            return {
                ...state,
                ranTrackerCount:actions.payload[0]
            }
        }

        if(actions.payload[1]==="ranSla"){
            return {
                ...state,
                ranSlaCount:actions.payload[0]
            }
        }
        

        if(actions.payload[1]==="ranNestingReport"){
            return {
                ...state,
                ranNestingReportCount:actions.payload[0]
            }
        }
        

        if(actions.payload[1]==="ranNestingTracker"){
            return {
                ...state,
                ranNestingTrackerCount:actions.payload[0]
            }
        }
        
        if(actions.payload[1]==="ranSpilOver"){
            return {
                ...state,
                ranSpilOverCount:actions.payload[0]
            }
        }
        
        if(actions.payload[1]==="ranDailyActivity"){
            return {
                ...state,
                ranDailyReportCount:actions.payload[0]
            }
        }

        

        if(actions.payload[1]==="List ENOO"){
            return {
                ...state,
                listEnooCount:actions.payload[0]
            }
        }

        if(actions.payload[1]==="List TT"){
            return {
                ...state,
                listttCount:actions.payload[0]
            }
        }


        

        

        
        
        
    }
    return state
}




export function OnePopUpReducer(state=popUpState,action){

    console.log(action.type,UPDATE_POP_UP,"OnePopUpReduceraction")
    if(action.type===UPDATE_POP_UP){

        console.log(action.payload,"action.payload")
        return{
            ...state,
            popstatus:action.payload
        }
    }
    
    return state
}



export function lcl_dashboard_filter_Reducer(state=lclFilterDashboard,action){

    console.log(action.type,UPDATE_POP_UP,action,action.type===LCL_LEXPORT_TT_FILTER,"LCL_LEXPORT_TT_FILTER","OnePopUpReduceraction")
    if(action.type===LCL_MOP_DASHBOARD_FILTER){
        let adata=state.mopDashboard
        adata={
            ...adata,
            ...action.payload
        }
        // adata.push(...action.payload)
        console.log(action.payload,"action.payload")
        return{
            ...state,
            mopDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_MOP_DASHBOARD_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            mopDashboard:lclFilterDashboard.mopDashboard
        }
    }

    if(action.type===LCL_RAN_DASHBOARD_FILTER){
        let adata=state.ranDashboard
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            ranDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_RAN_DASHBOARD_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            ranDashboard:lclFilterDashboard.ranDashboard
        }
    }

    if(action.type===LCL_SLA_DASHBOARD_FILTER){
        let adata=state.slaDashboard
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            slaDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_SLA_DASHBOARD_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            slaDashboard:lclFilterDashboard.slaDashboard
        }
    }

    if(action.type===LCL_IX_DASHBOARD_FILTER){
        let adata=state.ixDashboard
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            ixDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_IX_DASHBOARD_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            ixDashboard:lclFilterDashboard.ixDashboard
        }
    }

    if(action.type===LCL_TT_FILTER){
        let adata=state.tt
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            tt:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_TT_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            tt:lclFilterDashboard.tt
        }
    }

    if(action.type===LCL_SSV_FILTER){
        let adata=state.ssvDashboard
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            ssvDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_SSV_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            ssvDashboard:lclFilterDashboard.ssvDashboard
        }
    }

    if(action.type===LCL_LSSV_FILTER){
        let adata=state.lssvDashboard
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            lssvDashboard:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_LSSV_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            lssvDashboard:lclFilterDashboard.lssvDashboard
        }
    }

    if(action.type===LCL_LEXPORT_TT_FILTER){



        let data=action.payload
        // console.log(data,state.ttexport.pop(data[0]),"LCL_LEXPORT_TT_FILTER")

        let fullOld=state.ttexport

        console.log(fullOld,"fullOldfullOld")
        // console.log(fullOld.splice(fullOld.indexOf(data[0]), 1),data,"fullOldfullOld")
        if(data[1]=="First"){
            return{
                ...state,
                ttexport:data[0]
            }
        }
        if(data[1]=="Full Data"){
            return{
                ...state,
                ttalldata:data[0]
            }
        }
        if(data[1]=="remove"){

            // console.log(fullOld,data[0],fullOld.indexOf(data[0]),"datdatdat")
            // let dat=fullOld.splice(fullOld.indexOf(data[0]), 1)
            
            // delete fullOld[fullOld.indexOf(data[0])]
            // console.log(fullOld,data[0],fullOld.indexOf(data[0]),"datdatdat")
            
            // console.log(dat,"datdatdat")

            let newdata=fullOld.splice(fullOld.indexOf(data[0]), 1)
            return{
                ...state,
                ttexport:fullOld
            }
        }
        if(data[1]=="add"){

            let newdata=fullOld.push(data[0])
            return{
                ...state,
                ttexport:fullOld
            }
        }
        
    }
    

    if(action.type===LCL_NESTING_FILTER){
        let adata=state.enoo
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            enoo:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_NESTING_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            enoo:lclFilterDashboard.enoo
        }
    }
    

    if(action.type===LCL_ENOO_FILTER){
        let adata=state.enoo
        adata={
            ...adata,
            ...action.payload
        }
        console.log(action.payload,"action.payload")
        return{
            ...state,
            enoo:adata
        }
    }
    // alert(RESET_LCL_MOP_DASHBOARD_FILTER,action.type)
    if(action.type===RESET_LCL_ENOO_FILTER){
        
        console.log(lclFilterDashboard,"lclFilterDashboard")
        return{
            ...state,
            enoo:lclFilterDashboard.enoo
        }
    }
    
    return state
}





export function ChartReducer(state=chartData,action){

    if(action.type===VENDOR_CHART_TIMELINE){

        return{
            ...state,
            ttVendorChartData:action.payload
        }
    }

    if(action.type===DASHBOARD_VENDOR_CHART_TIMELINE){

        return{
            ...state,
            vendorDashboard:action.payload
        }
    }
    if(action.type===TT_AGING_CHART_TIMELINE){

        return{
            ...state,
            ttVendorAgingChartData:action.payload
        }
    }

    if(action.type===WAR_ROOM_CHART_TIMELINE){

        return{
            ...state,
            warRoomCoustomer:action.payload
        }
    }


    if(action.type===AWS_CHART_TIMELINE){

        return{
            ...state,
            awsChart:action.payload
        }
    }
    if(action.type===AWS_CHART_CALL_TIMELINE){

        return{
            ...state,
            awsChartCall:action.payload
        }
    }

    

    

    

    
    return state
}


export function OnecommonReducer(state=oneinitialCommonResponse,action){
    if(action.type==GETBARCHARTDATA){
        return {
            ...state,
            barData:action.payload
        }
    }

    
    if(action.type==GETBARCHARTDATATWO){
        return {
            ...state,
            barDatatwo:action.payload
        }
    }
    if(action.type===ONE_USER_ACCOUNT){
        return{
            ...state,
            listuser:action.payload
        }
    }
    if(action.type===FETECH_SINGLE_PROJECTDATABASE){
        return {
            ...state,
            pdsingleSite:action.payload
        }
    }
    if(action.type===FETECH_TIMELINE){
        return {
            ...state,
            timelineLogs:action.payload
        }
    }
    // if(action.type===CHART_TIMELINE){
    //     return {
    //         ...state,
    //         chartData:action.payload
    //     }
    // }
    if(action.type===FETECH_PRODUCT_CODE){
        console.log("FETECH_PRODUCT_CODE","amit_logger")
        return {
            ...state,
            productCode:action.payload
        }
    }
    if(action.type===FETECH_PRODUCT_ID){


        console.log("FETECH_PRODUCT_ID","amit_logger")
        return {
            ...state,
            productId:action.payload
        }
    }
    if(action.type===FETECH_SINGLE_MOPS){

        return {
            ...state,
            singleMops:action.payload
        }
    }

    if(action.type===CLEAR_FETECH_SINGLE_MOPS){

        return {
            ...state,
            singleMops:[]
        }
    }
    if(action.type==CLEAR_IX_SINGLE_API){
   return{
        ...state,
        singleIXSite:[]
    }
}
    if(action.type==CLEAR_FETECH_SINGLE_RAN_PROJECT){

        return {
            ...state,
            singleSite:[]
        }
    }

    if(action.type===FETECH_SINGLE_RAN_PROJECT){
        return {
            ...state,
            singleSite:action.payload
        }
    }

    if(action.type===FETECH_SINGLE_IX_PROJECT){
        return {
            ...state,
            singleIXSite:action.payload
        }
    }
    if(action.type===FETECH_SINGLE_TECH_RAN_PROJECT){
        return {
            ...state,
            singleRanTechSite:action.payload
        }
    }
    if(action.type==FILTERDROPDOWN){
       return { ...state,
        filterDropDown:action.payload
       }
    }
    if(action.type===SET_ASSINGNED_ENGINEER){
        console.log("the payload=",action.payload)
        return{
            ...state,
            ChatInfo:action.payload
            }
    }
    
    return state
}







export function vendorScoreCardReducer(state=vendorScoreCardResponse,action){
    if (action.type === FUNVENDOR_SCORE_CARD) {
        return {
            ...state,
            funVendorScoreCard:action.payload,
        };
    }

    if (action.type === FUNVENDOR_SCORE_CARD_DASHBOARD) {
        return {
            ...state,
            funVendorScoreCardDashboard:action.payload,
        };
    }


    
    return state
}
export function commonReducer(state=initialCommonResponse,action){

        // if(action.type===SET_LOADER){
        //     return{
        //         ...state,
        //         loaderState:action.payload
        //     }
        // }
        if (action.type === PRESWAPCHECKLIST) {
            return {
                ...state,
                preswapchecklist:action.payload,
            };
        }
        if (action.type === PRESWAPCHECKLISTOLD) {
            return {
                ...state,
                preswapchecklistOld:action.payload,
            };
        }
        
        if(action.type==TIME_ZONE){
            return {
                ...state,
                timeZones:action.payload
            }
        }

        if(action.type==SSV_DASHBOARD){
            return {
                ...state,
                SSVDashboardData:action.payload
            }
        }
        if(action.type==LAUNCH_SSVDASHBOARD){
            return {
                ...state,
                LaunchSSVData:action.payload
            }
        }


        if(action.type==SSV_APPROVER_DASHBOARD){
            return {
                ...state,
                SSVApproverDashboardData:action.payload
            }
        }
        if(action.type==LAUNCH_APPROVER_SSVDASHBOARD){
            return {
                ...state,
                LaunchSSVApproverData:action.payload
            }
        }


        
        if(action.type===FETECH_BUCKET){
            return{
                ...state,
                projectBucktet:action.payload
            }
        }

        if(action.type===FETECH_TT_PROJECT){
            let adata=state.tt_data
            adata.push(...action.payload)
            console.log(adata)
            return {
                ...state,
                tt_data:adata
            }
            return{
                ...state,
                tt_data:action.payload
            }
        }

        if(action.type===FETECH_CLEAR_TT_PROJECT){
            return{
                ...state,
                tt_data:action.payload
            }
        }
        

        if(action.type===FETECH_E911_PROJECT){
            // return{
            //     ...state,
            //     enno_data:action.payload
            // }

            let adata=state.enno_data
            adata.push(...action.payload)
            console.log(adata)
            return {
                ...state,
                enno_data:adata
            }
        }

        if(action.type==FETECH_72_Alarm){
            return{
                    ...state,
            sevenTwoSites:action.payload
            }
        }


        if(action.type===FETECH_NEW_E911_PROJECT){
            return{
                ...state,
                enno_data:action.payload
            }
        }


        


        
        if(action.type===FETECH_PROJECT_STATUS){
            return{
                ...state,
                projectStatus:action.payload
            }
        }
        if(action.type===FETECH_ACCOUNT){
            return{
                ...state,
                accounts:action.payload
            }
        }
        if(action.type===FETECH_MOP_Instruction){
            return{
                ...state,
                mopInstructions:action.payload
            }
        }

        
        if(action.type===FETECH_USER_ROLE){
            return{
                ...state,
                userrole:action.payload
            }
        }
        
        if(action.type===FETECH_USER_ACCOUNT){
            return{
                ...state,
                listaccounts:action.payload
            }
        }

        
        if(action.type===FETECH_TIER_LIST){
            return{
                ...state,
                listtier:action.payload
            }
        }
        if(action.type===FETECH_USER_LIST){
            return{
                ...state,
                listuser:action.payload
            }
        }

        if(action.type===FETECH_RTT_DASHBOARD){
            return{
                ...state,
                listrttData:action.payload
            }
        }
        
        
        if(action.type===FETECH_IX_DASHBOARD){
            return{
                ...state,
                listIXData:action.payload
            }
        }

        if(action.type===FETECH_MARKETS){
            return{
                ...state,
                markets:action.payload
            }
        }

        if(action.type===FETECH_FILTER_USER){
            return{
                ...state,
                filteruserlist:action.payload
            }
        }

        
        if(action.type===FETECH_CSV){
            return{
                ...state,
                filterCSV:action.payload
            }
        }

        if(action.type===FETECH_PDF){
            return{
                ...state,
                fetchedpdfdata:action.payload
            }
        }


        
        if(action.type===FETECH_TECHNOLOGY){
            return {
                ...state,
                technology:action.payload
            }
        }
        if(action.type===FETECH_EQUIPMENT){
            return {
                ...state,
                equipments:action.payload
            }
        }
        if(action.type===FETECH_VENDOR){
            return {
                ...state,
                vendor:action.payload
            }
        }
        if(action.type===FETECH_CREW_COMPANY){
            return {
                ...state,
                crewCompany:action.payload
            }
        }

        
        if(action.type===FETECH_OSS){
            return {
                ...state,
                oss:action.payload
            }
        }
        if(action.type===FETECH_RAN_DASHBOARD){

            let adata=state.ranDashboard
            adata.push(...action.payload)
            console.log(adata)
            return {
                ...state,
                ranDashboard:adata
            }
        }

        if(action.type===ADVANCE_FETECH_RAN_DASHBOARD){

            let adata=state.ranDashboard


            let newdata=action.payload

            let dataindex=adata.findIndex(oneitm=>oneitm.ranSupportId==newdata.ranSupportId)
            // alert(dataindex)
            adata[dataindex]=action.payload
            console.log(adata)
            return {
                ...state,
                ranDashboard:adata
            }
        }


        if(action.type===ADVANCE_CLEAR_FETECH_RAN_DASHBOARD){

            let adata=state.notificationList


            let newdata=action.payload

            let dataindex=adata.filter(oneitm=>oneitm.uniqueId!==newdata)
            // alert(dataindex)
            // adata[dataindex]=action.payload
            console.log(adata)
            return {
                ...state,
                notificationList:dataindex
            }
        }


        


        

        if(action.type===NOTIFICATION_LIST){


            let adata=state.notificationList

            let newadata=[]
            newadata.push(action.payload)

            newadata.push(...adata)

            
            return {
                ...state,
                notificationList:newadata
            }
        }


        
        
        if(action.type===FETECH_CLEAR_RAN_DASHBOARD){
            
            return {
                ...state,
                ranDashboard:action.payload
            }
        }

        

        if(action.type===FETECH_RAN_SPILOVER){
            return {
                ...state,
                ranSpilOver:action.payload
            }
        }


        if(action.type===FETECH_RAN_NESTING_REPORT){

            let adata=state.ranNestingReport
            adata.push(...action.payload)
            console.log(adata)
            return {
                ...state,
                ranNestingReport:adata
            }
        }
        if(action.type===FETECH_CLEAR_RAN_NESTING_REPORT){
            
            return {
                ...state,
                ranNestingReport:action.payload
            }
        }

        if(action.type===FETECH_RAN_NESTING_TRACKER){

            let adata=state.ranNestingTracker
            adata.push(...action.payload)
            console.log(adata)
            return {
                ...state,
                ranNestingTracker:adata
            }
        }
        if(action.type===FETECH_CLEAR_RAN_NESTING_TRACKER){
            
            return {
                ...state,
                ranNestingTracker:action.payload
            }
        }

        // if(action.type===FETECH_RAN_NESTING){
        //     return {
        //         ...state,
        //         ranNesting:action.payload
        //     }
        // }

        if(action.type===FETECH_GEO_FENCING){
            return {
                ...state,
                geoFencing:action.payload
            }
        }

        if(action.type===CHAT_MESSAGE_PENDING){
            return {
                ...state,
                chatMessagePending:action.payload
            }
        }

        

        if(action.type===FETECH_MANAGE_UTILIZATION){
            return {
                ...state,
                manageUtilization:action.payload
            }
        }

        if(action.type===FETECH_NESTING_TIMELINE){
            return {
                ...state,
                nestingTime:action.payload
            }
        }

        

        
        if(action.type===FETECH_RAN_STATUS){
            return{
                ...state,
                ranSiteStatus:action.payload
            }
        }

        if(action.type===FETECH_TT_STATUS){
            return{
                ...state,
                ttSiteStatus:action.payload
            }
        }

        

        if(action.type===FETECH_MOP_STATUS){
            return{
                ...state,
                mopSiteStatus:action.payload
            }
        }

        
        if(action.type===FETECH_NETWORK_SITE_STATUS){
            return{
                ...state,
                networkDatabaseSiteStatus:action.payload
            }
        }
        if(action.type===FETECH_SITEID){
            return {
                ...state,
                startAwaitedSite:action.payload 
            }
        }
        if(action.type===FETECH_CR_NUMBER){
            return{
                ...state,
                crNumberList:action.payload
            }
        }
        if(action.type ===FETECH_RAN_TRACKER){
            let adata=state.ranTracker

            console.log("FETECH_RAN_TRACKER",adata)
            adata.push(...action.payload.tableData)
            return {
                ...state,
                ranTracker:adata
            }
        }


        if(action.type ===FETECH_SLA_RAN_TRACKER){
            let adata=state.ranSlaTracker

            console.log("FETECH_SLA_RAN_TRACKER",adata)
            adata.push(...action.payload.tableData)
            return {
                ...state,
                ranSlaTracker:adata
            }
        }
        
        if(action.type ===FETECH_CLEAR_SLA_RAN_TRACKER){
            return {
                ...state,
                ranSlaTracker :action.payload.tableData
            }
        }
        
        if(action.type ===FETECH_CLEAR_RAN_TRACKER){
            return {
                ...state,
                ranTracker :action.payload.tableData
            }
        }

        
        if(action.type===FETECH_ALL_DROP_DOWN){
            
            return {
                ...state,
            
                allDropDown:action.payload
            }
        }

        if(action.type===FETECH_PIER_TT_GROUP){
            
            return {
                ...state,
            
                pierTTGroup:action.payload
            }
        }

        
        if(action.type===FETECH_MOBILE_DATA_COP_TIMELINE){
            return {
                ...state,
                mobileDataCopTimeline:action.payload
            }
        }
        if(action.type===FETECH_SINGLE_DROPDOWN){
            return {
                ...state,
                singleDropDown:action.payload
            }
        }
        
        if(action.type===FETECH_SINGLE_A_DROPDOWN){
            return {
                ...state,
                singleADropDown:action.payload
            }
        }
        if(action.type===FETECH_GSM_UTMS){
            return {
                ...state,
                gsm_utms:action.payload
            }
        }
        if(action.type===FETECH_RFDS_LIST){
            console.log(action.payload,"action.payload")
            return {
                ...state,
                rfdsList:action.payload
            }
        }


        


        // gsm_utms
        return state
}


export function csvReducer(state=csvSiteState,action){

    
    if(action.type===GET_ALL_MAP_SITES){
        return{
            ...state,
            getAllSiteMap:action.payload
        }
    }

    if(action.type===MAGENTA_FETECH_CSV_DATA){
        let adata=state.magenta
        console.log(adata,adata.length,"MAGENTA_FETECH_CSV_DATA")
        adata.push(...action.payload)
        console.log(adata,adata.length,"MAGENTA_FETECH_CSV_DATA")
        return{
            ...state,
            magenta:adata
        }
    }
    if(action.type===INFLIGHT_FETECH_CSV_DATA){
        
        let adata=state.inflight
        adata.push(...action.payload)

        console.log(adata.length,"adataadataadataadataadata")
        return{
            ...state,
            inflight:adata
        }
    }
    if(action.type===NETWORK_DATABASE_FETECH_CSV_DATA){
        let adata=state.networkdatabase
        adata.push(...action.payload)

        return{
            ...state,
            networkdatabase:adata
        }
    }
    if(action.type===CLEAR_NETWORK_DATABASE_FETECH_CSV_DATA){
        // let adata=state.networkdatabase
        // adata.push(...action.payload)

        return{
            ...state,
            networkdatabase:action.payload
        }
    }

    if(action.type===NETWORK_DATABASE_FETECH_LOGGER_DATA){
        // let adata=state.networkdatabase
        // adata.push(...action.payload)

        return{
            ...state,
            networkLogger:action.payload
        }
    }

    

    if(action.type===FETECH_SINGLE_PROJECT){
        return {
            ...state,
            singleSite:action.payload
        }
    }
    if(action.type===FETECH_TIMELINE){
        return {
            ...state,
            timelineLogs:action.payload
        }
    }
    if(action.type===FETECH_PRODUCT_CODE){

        console.log()
        return {
            ...state,
            productCode:action.payload
        }
    }
    return state
}
export function loaderReducer(state=loaderState,action){
    console.log("amit Loader,loaderReducer",action.type,action.payload)
    if(action.type===SET_LOADER){
        return{
            
            spinState:action.payload
        }
    }
    return state
}