import React, { Fragment, useState, useEffect, useRef } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import "../../../../css/formError.css";
import moment from "moment"
import { addCrNumber, allTechnologyApi, ixModule, listSiteId, listUserApi_two, managEngineer, managTMOEngineer, networkOneSiteDatabaseprojectId, OneSiteDatabase, OneSiteDatabaseProjectCode, projectCode, ranAddSupport, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, singleProjectApi, updateranticket } from "../../../../common/config";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, clearGetSingleProjectDatabase, clearProjectId, popUpUpdate, TierListReducerAction, UserListReducerAction } from "../../../../store/actions/createTicketManageFields";

import { FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";

import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import CommonCrew from "./commoncrew";
import { commonsowDetail, mopcommonsowDetail, projectIdValidator, reformArray, techCheckerwitharray } from "../../../../common/variables";
import swal from "@sweetalert/with-react";

const GCLogin = () => {

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({});
    let count = useRef(1)
    let countSecond = useRef(1)
    let [sassumeTime, setassumeTime] = useState()
    let [sstartTime, setstartTime] = useState()
    let [plannedSow, setPlannedSow] = useState([]);
    let [tech, setTech] = useState([]);
    let [pTech, setPTech] = useState([]);
    let [errore, seterrore] = useState(false)
    let [SelselectProjectCodename, setSelProjectCodename] = useState()
    let [selectProjectId, setProjectId] = useState([])
    let [selectnewProjectId, setselectnewProjectId] = useState([])
    let [DbName, setDbName] = useState([]);
    let [mopReason, setMopState] = useState(false)
    const [singleSite, setsingleSite] = useState({});
    const [EngineerOpen, setEngineerOpen] = useState([]);
    let [selectProjectCode, setProjectCode] = useState([])
    let [SelselectProjectCode, setSelProjectCode] = useState([])
    const [onetimer, setonetimer] = useState(true);
    let [SelCheckBox, setSelCheckBox] = useState('')
    let [ppProjectId, setppProjctId] = useState('')
    let currentSiteStatus = 8
    const dispatch = useDispatch();


    const url = window.location.href.split("/")
    let urlindexid = url.pop()
    let activeUrl = url.pop()
    const [closer, setCloser] = useState(false);
    let history = useHistory()

    let multipleTechno = []
    let postTechno = []
    let newTtempArray = []

    let siteArray = [];
    let projectIdArray = []
    let projectIdOption = []
    let projectCodeOption = []
    let projectCodeArray = []
    let engineerArray = []
    let technologyArray = []
    let newsingleSite = {}

    let allProjects = []

    let customSite = {
        pC: [],
        pI: []
    };


    const styles = {
        width: "400px",
    };
    const customStyles = {
        control: (base, state) => ({
            ...base,
            // background: "#24292D",
            minHeight: "2px",
            // match with the menu
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                // borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? ''
                : isSelected
                    ? ''
                    : undefined,
            zIndex: 1
        }),


        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: styles => ({
            ...styles,
        })
    };

    function gotoback() {
        dispatch(clearFetchSingleRecordAction())
        if(dashboardType == "ixDashboard"){
            history.push("/PAG/ix/dashboard")
        }else{
            history.push("/PAG/ran/support/dashboard")
        }
        
    }

    const findengineer = (id) => {
        setEngineerOpen(id)
    }


    const techChangleHandler = (e) => {
        console.log("Pow multiple select=", e);
        setTech(e);
    };
    const ptechChangleHandler = (e) => {
        console.log("Pow multiple select=", e);
        setPTech(e);
    };
    const plannedSowChangleHandler = (e) => {
        console.log("Pow multiple select=", e);
        setPlannedSow(e);
    };

    const findDSiteId = (id) => {
        setProjectCode([])
        dispatch(FetchSingleRecordAction(projectCode, id))
    }




    const onSubmit = async (data) => {
        console.log("data is === gclogin", data, errors, "sassumeTime", sassumeTime, "sstartTime", sstartTime)
        if (tech.length == 0 || plannedSow.length == 0 || sassumeTime == undefined || sassumeTime == undefined) {
            seterrore(true)
            return
        }
        data['RF_Approved_MW_Time'] = moment(sstartTime).format("MM/DD/YYYY HH:mm")
        data['RF_Approved_Assume_Time'] = moment(sassumeTime).format("HH:mm")
        seterrore(false)
        let userId = JSON.parse(localStorage.getItem("userDetails"))
        userId = userId.uniqueid
        data['userId'] = userId
        let sendTech = {
            values: []
        }
        sendTech.values = tech.map((item) => {
            return item.value
        })
        data['technology'] = sendTech

        let sendSow = { values: [] }
        sendSow.values = plannedSow.map((item) => {
            return item.value
        })
        data['sowList'] = sendSow
        if (projectIdValidator(SelselectProjectCodename, selectProjectId)) {
            // setDisable(false)
            swal({
                title: "Alert !",
                text: "Please Select Project Id In All The Selected Project Code",
                icon: "info",
            })
            return null
        }

        if (techCheckerwitharray(selectnewProjectId, tech)) {
            {
                swal({
                    title: "Alert !",
                    text: "Please Select Technology In All The Selected Project Id",
                    icon: "info",
                })
                return null
            }
        }

        if (dashboardType == "ixDashboard") {
            console.log("kjbwdhljwekbd th eix =", dashboardType)
            data["postHWLayerConfiguration"] = reformArray(pTech)
            dispatch(addUserAction(updateranticket + "/" + urlindexid + "?ix=True", data))
        }
        else {
            dispatch(addUserAction(updateranticket + "/" + urlindexid, data))
        }
        console.log("data is ===", data);
        setCloser(true);
        dispatch(popUpUpdate({}));
    };


    const setTecheer = (selectedname) => {
        let alltt = []
        console.log("setTecheer", selectedname)
        let sellid = []
        selectedname.map((ids) => {
            console.log("the ids -=", ids)
            sellid.push(ids.mainId)
        })
        let arr = []
        console.log(tech, sellid, "techitemitemitemitem")

        tech.map((item) => {

            console.log(sellid, "sellid", item.value.split("_CP_")[1], "itemitemitemitem")

            if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
                arr.push({
                    value: item.value,
                    label: item.value.split("_CP_")[0],
                });
            }

        })

        console.log(arr, "techarr")
        setTech(arr)
    }

    const findDProjectId = (id, def = 0, pcode) => {

        let nselectedIds = ""
        let selectedname = []
        let selectedIds = []
        console.log(id, "findDProjectIdfindDProjectId")
        if (def == 0) {
            selectedIds = id.map((item) => {
                console.log(item, "itemprojectCodeArray")
                selectedname.push(item.label)
                return item.value
            })
            nselectedIds = selectedIds.join(",")
        }

        console.log(nselectedIds, selectedname, "nselectedIdsnselectedIds")
        // if()
        dispatch(FetchSingleRecordAction(networkOneSiteDatabaseprojectId, nselectedIds))
        setSelProjectCode(selectedname)
    }
    const FetechAllDetail = (id) => {

        setProjectId(id)
        let tempidd = []
        console.log("the selected value=id", id, tempidd)
        let selectedId = id.map((item) => {
            if (item.mainId != undefined) {
                tempidd.push(item.mainId)
            }
            return item.value
        })
        console.log("the selected value=", tempidd)
        setselectnewProjectId(tempidd)
        setTecheer(id)
        setValue("projectUniqueId", selectedId.join(","))
        // let url =window.location.href.split("/")
        // console.log("the kjfiuebrfkhgc=",url)
        // let dashboardType=url.slice(-2)[0]
        // if(dashboardType=="ixDashboard"){
        //     // dispatch(FetchSingleRecordAction(ixModule, index));
        // }
        // else{

        // dispatch(FetchSingleRecordAction(ixModule, selectedId.join(",")));
        // }
        dispatch(FetchSingleRecordAction(singleProjectApi, selectedId.join(",")));
        // }
    }


    let curl = window.location.href.split("/")
    console.log("the kjfiuebrfkhgc=", url)
    let dashboardType = url.slice(-1)[0]
    console.log("The dashboard type nffhwerof-=", dashboardType)
    const projectData = useSelector((state) => {
        console.log("the State is=", state);


        if (state.popUpState) {
            // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
            if (state.popUpState.popstatus.status == 201 && closer) {
                setCloser(false);
                dispatch(popUpUpdate({}));
                dispatch(clearFetchSingleRecordAction())
                if (dashboardType == "ixDashboard") {
                    history.push("/PAG/ix/dashboard")
                }
                else {
                    history.push("/PAG/ran/support/dashboard")
                }
            }
        }

        allProjects = state.CommonResponse.startAwaitedSite
        if (allProjects && allProjects.length != 0) {
            siteArray = allProjects.map((project) => {
                return <option>{project.siteId}</option>;
            });
        }

        let siteTemp = dashboardType == "ixDashboard" ? state.OneCommonResponse.singleIXSite : state.OneCommonResponse.singleSite
        if (siteTemp) {
            newsingleSite = siteTemp
            console.log("ljkcbvckjsdacdefve=", newsingleSite)
            if (count.current == 1 && newsingleSite['RF_Approved_MW_Time']) {
                // console.log("ljbsdcbdlkjsavbef==",newsingleSite['RF_Approved_MW_Time'])
                let approvalDate = new Date(siteTemp.RF_Approved_MW_Time)
                setstartTime(approvalDate)
                count.current = 2
            }
            if (countSecond.current == 1 && newsingleSite["RF_Approved_Assume_Time"]) {
                let approvalDate = new Date("05/13/2023 " + newsingleSite["RF_Approved_Assume_Time"])
                console.log("ljbsdcbdlkjsavbef==", approvalDate)

                setassumeTime(approvalDate)
                countSecond.current = 2
            }

            console.log(".jicbs cgij dkgauefcwef=", newsingleSite)
        }
        // newsingleSite = dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite

        console.log("th dashboard type =", newsingleSite)
        console.log(onetimer, "onetimeronetimeronetimer")
        if (onetimer) {

            let singleSite = dashboardType == "ixDashboard" ? state.OneCommonResponse.singleIXSite : state.OneCommonResponse.singleSite
            // console.log(singleSite, singleSite != {}, singleSite.dtSiteId, singleSite.projectaUniqueId, "singleSitesingleSitesingleSitesingleSitesingleSitesingleSite")
            if (singleSite && singleSite.length != 0 && singleSite != {} && singleSite.projectaUniqueId && singleSite.projectaUniqueId.length > 0) {

                setonetimer(false)
                setsingleSite(singleSite)
                findDSiteId(singleSite.dtSiteId)

                console.log(singleSite.projectaUniqueId, "singleSite.projectaUniqueId")
                let projectCodename = []
                let projectSelCodename = []
                if (singleSite.postHWLayerConfiguration && singleSite.postHWLayerConfiguration.values.length > 0) {
                    let postTech = singleSite.postHWLayerConfiguration.values.map((item) => {
                        return {
                            label: item,
                            value: item
                        }
                    })
                    console.log("bfijerabgfiluadsgbre=", postTech)
                    setPTech(postTech)
                }
                singleSite.projectbUniqueId.map((item) => {
                    if (projectCodename.indexOf(item.projectCode) == -1) {
                        projectCodename.push(item.projectCode)
                        projectSelCodename.push({
                            label: item.projectCode,
                            value: item.pId
                        })
                    }

                })

                console.log(projectSelCodename, "projectSelCodenameprojectSelCodename")
                // setSelProjectCode(projectCodename)

                findDProjectId(projectSelCodename)

                console.log(projectCodeOption, projectCodename, "projectCodenameprojectCodename")

                let fillid = singleSite.projectaUniqueId.map((itm, index) => {
                    console.log(singleSite.projectbUniqueId[index].pId, "singleSite.projectaUniqueId[index].pId")
                    return {
                        label: itm.projectId,
                        value: itm.pId,
                        group: itm.projectCode,
                        mainId: singleSite.projectbUniqueId[index].pId
                    }
                })


                console.log(singleSite.projectcodeUniqueId, projectCodename, fillid, singleSite.dtSiteId, "fillidfillid")
                // findDProjectId(singleSite.projectcodeUniqueId, 1, projectCodename)
                FetechAllDetail(fillid)
                setValue("projectcodeUniqueId", singleSite.projectcodeUniqueId)

                // findDSiteId(singleSite.dtSiteId)

                console.log(singleSite, singleSite, "singleSitesleSite")
                let seltechnologyArray = singleSite.technology ? singleSite.technology.values.map((item) => {
                    return {
                        value: item,
                        label: item.split("_CP_")[0],
                    };
                }) : []
                console.log(seltechnologyArray, "multipleTechnomultipleTechnomultipleTechnomultipleTechno")
                setTech(seltechnologyArray)


                let pC = []
                let pI = []
                let sTech = []

                singleSite.projectbUniqueId.map((item) => {
                    console.log(item, "itemitemitem")
                    pC.push(item.projectCode)
                    pI.push(item.projectId)
                })

                customSite = {
                    "pC": pC,
                    "pI": pI,
                    "sTech": ""
                }
                console.log(singleSite, "singleSitesingleSite")
                technologyArray = singleSite.ndResult.technology.values.map((item) => {
                    return {
                        value: item,
                        label: item,
                    };
                });
                let dataselsow = singleSite.sowList.values.map((onew) => {
                    return {
                        value: onew,
                        label: onew,
                    }
                })


                if (singleSite.ixCrewId.name != undefined) {
                    console.log("setDbName")
                    DbName.push(singleSite.ixCrewId.name)
                    DbName.push(singleSite.ixCrewId.crewType == "IX" ? "IXCrew" : "CXCrew")
                    DbName.push(singleSite.ixCrewId.uniqueid)

                }
                if (singleSite.cxCrewId.name != undefined) {
                    console.log("setDbName")
                    DbName.push(singleSite.cxCrewId.name)
                    DbName.push(singleSite.cxCrewId.crewType == "IX" ? "IXCrew" : "CXCrew")
                    DbName.push(singleSite.cxCrewId.uniqueid)
                }

                console.log(DbName, "DbNameDbName")

                setPlannedSow(dataselsow)



                // sstartTime
                console.log(singleSite, "dbrfmw", singleSite.RF_Approved_Assume_Time)
                console.log(moment(singleSite.RF_Approved_MW_Time, "MM/DD/YYYY HH:mm"), "dbrfmw", moment(singleSite.RF_Approved_Assume_Time, "HH:mm").toDate())
                console.log(",snbkhjcvsldjhbcasv", moment(singleSite.RF_Approved_MW_Time, "MM/DD/YYYY HH:mm"))
                // setstartTime(moment(singleSite.RF_Approved_MW_Time, "MM/DD/YYYY HH:mm").toDate()|| "")
                // setassumeTime(moment(singleSite.RF_Approved_Assume_Time, "HH:mm").toDate())

                setSelCheckBox("LeadName")

                setValue("RF_Approval", singleSite.RF_Approval)
                setValue("serviceAffected", singleSite.serviceAffected)
                setValue("tmoDeploymentManager", singleSite.tmoDeploymentManager)
                setValue("crNumber", singleSite.crNumber)
                
                if (singleSite.dayNightMop == "Day") {
                    setMopState(true)
                    setValue("dayMopReason", singleSite.dayMopReason)
                } else {
                    setMopState(false)
                }
                console.log(singleSite.ixCrewId.name, singleSite.cxCrewId.name, "singleSite.cxCrewId.name")
                console.log(DbName, "DbNameDbNameDbName")
                // handleChange(e, "CXCrew", "b")
                setValue("dayNightMop", singleSite.dayNightMop, { shouldValidate: true })
                console.log("the response singleSite is the =", state.OneCommonResponse.singleSite)
                // setCtrCount(2)

            }

        }

        let newSite = state.OneCommonResponse.singleSite

        let technodata = state.CommonResponse.technology

        if (technodata && technodata.length > 0) {
            postTechno = technodata.map((ids) => {

                return {
                    value: ids.technologyName,
                    label: ids.technologyName,
                };
            })
        }
        console.log(newSite, "ohnewlogcheckerat556")
        if (newSite && newSite.length != 0 && technodata.length > 0) {
            let useSite = []


            newSite.projectbUniqueId.map((itte) => {
                console.log(itte, "itteitteitteitte")
                let sitelabel = {
                    label: itte.projectId,
                    value: itte.projectId,
                    disabled: true
                }
                multipleTechno.push(sitelabel)
                let defTech = []
                defTech = technodata.map((defaultTech) => {
                    return {
                        value: defaultTech.technologyName + "_CP_" + itte.pId,
                        label: defaultTech.technologyName,
                    };
                })
                multipleTechno.push(...defTech)
            })


            console.log(multipleTechno, "multipleTechnomultipleTechnomultipleTechno")



        }

        projectCodeArray = []
        projectCodeArray = state.OneCommonResponse.productCode



        if (projectCodeArray && projectCodeArray.length > 0) {
            projectCodeOption = projectCodeArray.map((item, index) => {


                console.log(item, SelselectProjectCode.indexOf(item.projectCode) >= 0, "itemitemitemitemitem", SelselectProjectCode)
                console.log(SelselectProjectCode, SelselectProjectCode.indexOf(item.projectCode) >= 0, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd, "abcd"); return item.projectCode == abcd.label }), "SelselectProjectCode")
                if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => { return item.projectCode == abcd.label }) == -1) {
                    selectProjectCode.push({
                        value: item.uniqueid,
                        label: item.projectCode,
                    })

                }
                return {
                    value: item.uniqueid,
                    label: item.projectCode,
                };
            })

            console.log(projectCodeArray, selectProjectCode, projectCodeOption, "selectProjectCode,projectCodeOption,")

        }
        let newPrjId = []
        newTtempArray = []
        projectIdArray = state.OneCommonResponse.productId

        console.log(projectIdArray, "projectIdArrayprojectIdArray")
        if (projectIdArray && projectIdArray.length > 0) {
            projectIdArray = projectIdArray.map((item, index) => {
                //  let selectOpt=[]
                console.log(ppProjectId, item, "ppProjectIditem.uniqueid codertest")
                // if (ppProjectId == item.uniqueid) {
                console.log("the data is =", item)
                let availableId = []
                let anyArry = item.data
                availableId = item.data.map((id) => {

                    console.log(id.projectIduniqueid, item, "availableIdavailableIddas")
                    return {
                        label: id.projectId,
                        value: id.uniqueid,
                        group: item.projectCode,
                        mainId: id.projectIduniqueid
                    }
                })

                console.log(availableId, "availableIdavailableId")

                newTtempArray.push({
                    label: item.projectCode,
                    value: item.projectCode,
                    disabled: true,
                })
                newTtempArray.push(...availableId)

                console.log(newTtempArray, "newTtempArraynewTtempArraynewTtempArraynewTtempArraynewTtempArraynewTtempArray")
                return (item)

            })
            console.log(newTtempArray, "newTtempArraynewTtempArraynewTtempArray")
            console.log("state.CommonResponse.technology=", newTtempArray)
        }

        console.log(projectIdOption, newTtempArray, "newTtempArrayprojectIdOptionprojectIdOption")
        let allAwaitedSite = state.CommonResponse.startAwaitedSite
        let tier = state.CommonResponse.listtier
        if (tier && tier.length != 0) {
            engineerArray = tier.map((item) => {
                return <option>{item.name}</option>;
            })
        }
        console.log("state.CommonResponse.technology=", state.CommonResponse.technology)
        console.log("siteArray =", technologyArray);
        return { siteArray, newsingleSite, newTtempArray, newPrjId, customSite, technologyArray, projectIdOption, projectCodeOption, engineerArray };
    });

    console.log(projectData, "projectDataprojectData")

    function checkErrorRaise() {
        if (Object.keys(errors).length == 0) {
            return false
        } else {
            return true
        }
    }

    console.log("the nbnacbbcdsbcjjbw=", newsingleSite)



    useEffect(() => {
        // setsingleSite({})
        // setProjectCode([])
        console.log("checker What happen")
        // dispatch(clearFetchSingleRecordAction())
        dispatch(UserListReducerAction(listUserApi_two));
        dispatch(AccountReducerAction(allTechnologyApi));
        dispatch(AccountReducerAction(listSiteId))
        dispatch(TierListReducerAction(managTMOEngineer))
    }, []);


    return (
        <Fragment>
            {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

            <div style={{ display: "block", width: "100%", marginTop: "-1.3rem" }}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mt-3">
                        <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
                            <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOneDetails">
                                    </h2>
                                    <div>
                                        <div className="accordion-body">
                                            <div className="form-row mt-2 p-2">



                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Site Id</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                            newsingleSite ? newsingleSite.ndResult ? newsingleSite.ndResult.siteId : "" : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Project Code</label>
                                                    <MultiSelect
                                                        className="text-dark"
                                                        hasSelectAll={false}
                                                        options={projectData.projectCodeOption}
                                                        onChange={findDProjectId}
                                                        value={selectProjectCode}
                                                    />
                                                    {/* <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                            customSite ? customSite.pC.join(", ") : ""
                                                        }
                                                    /> */}
                                                </div>

                                                <div className="form-group col-md-3 ">
                                                    <label >Project Id</label>
                                                    {console.log("newTtempArraynewTtempArray", projectData.newTtempArray, newTtempArray)}
                                                    <MultiSelect
                                                        className="text-dark"
                                                        hasSelectAll={false}
                                                        options={newTtempArray}
                                                        onChange={FetechAllDetail}
                                                        value={selectProjectId}
                                                    />
                                                    {/* <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                            customSite ? customSite.pI.join(", ") : ""
                                                        }
                                                    /> */}
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Account</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                            newsingleSite.ndResult ? newsingleSite.ndResult.account : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Market</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                            newsingleSite.ndResult ? newsingleSite.ndResult.market : ""
                                                        }
                                                    // {...register("market")}
                                                    />
                                                </div>
                                                {
                                                    dashboardType == "ixDashboard" ?
                                                        <>
                                                            <div className="form-group col-md-3">
                                                                <label for="exampleFormControlSelect1">Pre HW/Layer Configuration</label>
                                                                <input
                                                                    style={{ height: "32px" }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="input "
                                                                    readOnly
                                                                    value={
                                                                        newsingleSite.techtake ? newsingleSite.techtake.join(", ") : newsingleSite.preHWLayerConfiguration ? newsingleSite.preHWLayerConfiguration.values.join(",") : ""
                                                                    }
                                                                // {...register("market")}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="inputEmail4">Post HW/Layer Configuration</label>

                                                                {console.log(multipleTechno, tech, "multipleTechno,tech")}
                                                                <MultiSelect
                                                                    options={postTechno}
                                                                    onChange={ptechChangleHandler}
                                                                    value={pTech}
                                                                    required
                                                                    hasSelectAll={false}
                                                                />
                                                                {(checkErrorRaise() || errore) && tech.length == 0 && (
                                                                    <p className="error">Please Select Technology</p>
                                                                )}

                                                            </div>
                                                        </> :
                                                        <>
                                                            <div className="form-group col-md-3">
                                                                <label for="exampleFormControlSelect1">Selected Technology</label>
                                                                <input
                                                                    style={{ height: "32px" }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="input "
                                                                    readOnly
                                                                    value={
                                                                        newsingleSite.techtake ? newsingleSite.techtake.join(", ") : ""
                                                                    }
                                                                // {...register("market")}
                                                                />
                                                            </div>
                                                            {
                                                                newsingleSite.numberStatus < currentSiteStatus
                                                                    ?

                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="inputEmail4">Technology </label>

                                                                        {console.log(multipleTechno, tech, "multipleTechno,tech")}
                                                                        <MultiSelect
                                                                            options={multipleTechno}
                                                                            onChange={techChangleHandler}
                                                                            value={tech}
                                                                            required
                                                                            hasSelectAll={false}
                                                                        />
                                                                        {(checkErrorRaise() || errore) && tech.length == 0 && (
                                                                            <p className="error">Please Select Technology</p>
                                                                        )}

                                                                    </div>
                                                                    : ""
                                                            }
                                                        </>
                                                }


                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">
                                                        Planned SOW
                                                    </label>
                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            ?
                                                            <>

                                                                <MultiSelect
                                                                    options={mopcommonsowDetail}
                                                                    onChange={plannedSowChangleHandler}
                                                                    value={plannedSow}
                                                                    required
                                                                    hasSelectAll={true}
                                                                />

                                                                {(checkErrorRaise() || errore) && plannedSow.length == 0 && (
                                                                    <p className="error">Please Select Planned SOW</p>
                                                                )}
                                                            </>
                                                            :
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.sowList ? newsingleSite.sowList.values.join(",") : ""}
                                                            />
                                                    }
                                                    {/* {errors.planned  && <p className="error">{errors.planned.message}</p>} */}
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="appt">
                                                        Outage Approved MW Time(Start Time)
                                                    </label>

                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            // newsingleSite.RF_Approved_MW_Time

                                                            ? <>
                                                                <DatePicker
                                                                    className="form-control"
                                                                    showTimeSelect
                                                                    style={styles}
                                                                    selected={sstartTime}
                                                                    onChange={(e) => { setstartTime(e); }}
                                                                    showTimeSelectOnly
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={60}
                                                                    timeCaption="Time"
                                                                    dateFormat="HH:mm aa"
                                                                />

                                                                {(checkErrorRaise() || errore) && sstartTime == undefined && (
                                                                    <p className="error">Please Select Outage Approved MW Time</p>
                                                                )}
                                                            </> :
                                                            <input

                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.RF_Approved_MW_Time ? newsingleSite.RF_Approved_MW_Time.split(" ")[1] : ""}
                                                            />
                                                    }



                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="appt">
                                                        Outage Approved(Time it will take)
                                                    </label>
                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            ?
                                                            <>
                                                                <DatePicker
                                                                    className="form-control"
                                                                    showTimeSelect
                                                                    style={styles}
                                                                    selected={sassumeTime}
                                                                    onChange={(e) => { setassumeTime(e); }}
                                                                    showTimeSelectOnly
                                                                    timeFormat="HH:mm"

                                                                    timeIntervals={60}
                                                                    timeCaption="Hour"
                                                                    dateFormat="HH:mm"
                                                                />
                                                                {/* <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p> */}


                                                                {(checkErrorRaise() || errore) && sassumeTime == undefined && (
                                                                    <p className="error">Please Select Outage Approved MW Time</p>
                                                                )}
                                                            </>
                                                            : <input
                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.RF_Approved_Assume_Time}
                                                            />
                                                    }
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">
                                                        {" "}
                                                        Deployment Manager
                                                    </label>
                                                    <input style={{ height: "32px" }}
                                                        type="text"
                                                        {...register("tmoDeploymentManager", {
                                                            required: "Please select Deployment Manager"
                                                        })}
                                                        onBlur={(e) => { findengineer(e.target.value) }}
                                                        list="engOpen"
                                                        className="form-control"
                                                        ID="inputAddress"
                                                        autoComplete={"off"}
                                                        defaultValue={EngineerOpen}
                                                        placeholder="Select Deployment Manager"
                                                    />

                                                    <datalist id="engOpen">
                                                        {projectData.engineerArray}
                                                        <option>{"NA"}</option>
                                                    </datalist>
                                                    {errors.tmoDeploymentManager && (
                                                        <p className="error">{errors.tmoDeploymentManager.message}</p>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-3 ">
                                                    <label for="exampleFormControlSelect1">
                                                        CR No
                                                    </label>
                                                    <input className="form-control"
                                                        // list="cars"
                                                        //  value={projectCodeState}
                                                        style={{ height: "32px" }}
                                                        placeholder="CR+9 digits"
                                                        {
                                                        ...register("crNumber", {
                                                            required: "Please Enter CR Number",
                                                            pattern: {
                                                                value: /CR[0-9]{9}$/,
                                                                message: "Please Enter A Valid CR Number ( CR+9 digits )"
                                                            }

                                                            // required:"Please enter project code",
                                                            //   onChange:(e)=>{
                                                            //   setProjectCodeState(e.target.value)
                                                            //   setProjectCodeError('')
                                                            //   console.log("project Code ==jbjhbjhk",projectCodeError)
                                                            // }
                                                        })
                                                        }
                                                    />

                                                    {errors.crNumber && (
                                                        <p className="error">
                                                            {errors.crNumber.message}
                                                        </p>
                                                    )}
                                                    {/* <datalist id="cars">
                                                    </datalist> */}
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">
                                                        Service Affecting
                                                    </label>
                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            ? <>
                                                                <select
                                                                    style={{ height: "32px" }}
                                                                    className="form-control"
                                                                    id="exampleFormControlSelect1"
                                                                    {...register("serviceAffected", {
                                                                        required: "Please Select Service Affected"
                                                                    })}
                                                                >
                                                                    <option selected disabled value="">
                                                                        Select
                                                                    </option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                                {errors.serviceAffected && (
                                                                    <p className="error">{errors.serviceAffected.message}</p>
                                                                )}
                                                            </>
                                                            :
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.serviceAffected}
                                                            />
                                                    }
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <label for="exampleFormControlSelect1">
                                                        {" "}
                                                        Cabinet IDs
                                                    </label>
                                                    <input style={{ height: "32px" }}
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        ID="inputAddress"
                                                        placeholder="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
                                                    />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">
                                                        Outage Approval?

                                                    </label>
                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            ? <>
                                                                <select
                                                                    style={{ height: "32px" }}
                                                                    className="form-control"
                                                                    id="exampleFormControlSelect1"
                                                                    {...register("RF_Approval", {
                                                                        required: "Please Select Outage"
                                                                    })}
                                                                >
                                                                    <option selected>Pending</option>
                                                                    <option>Approved</option>
                                                                    <option>Not Approved</option>
                                                                </select>
                                                                {errors.RF_Approval && (
                                                                    <p className="error">{errors.RF_Approval.message}</p>
                                                                )}
                                                            </>
                                                            : <input
                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.RF_Approval}
                                                            />
                                                    }
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">
                                                        Day Or Night MOP
                                                    </label>
                                                    {
                                                        newsingleSite.numberStatus < currentSiteStatus
                                                            ? <>
                                                                <select
                                                                    style={{ height: "32px" }}
                                                                    className="form-control"
                                                                    id="exampleFormControlSelect1"
                                                                    {...register("dayNightMop", {
                                                                        required: "Please an option",
                                                                        onChange: () => getValues("dayNightMop") == "Day" ? setMopState(true) : setMopState(false)
                                                                    })}
                                                                >
                                                                    <option selected disabled value="">
                                                                        Select
                                                                    </option>
                                                                    <option value="Day">Day Mop</option>
                                                                    <option value="Night">Night Mop</option>
                                                                </select>
                                                                {errors.dayNightMop && (
                                                                    <p className="error">{errors.dayNightMop.message}</p>
                                                                )}
                                                            </>
                                                            :
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                className="form-control"
                                                                value={newsingleSite.dayNightMop}
                                                            />
                                                    }
                                                </div>


                                                <div className="form-group col-md-3" style={{ visibility: mopReason ? 'visible' : 'hidden' }}>
                                                    <label>Reason for Day Mop</label>
                                                    <textarea className="form-control"

                                                        {...mopReason ?
                                                            { ...register("dayMopReason", { required: "Enter Day MOP Reason", }) } :
                                                            {
                                                                ...register("dayMopReason", {
                                                                    required: false
                                                                })
                                                            }}>

                                                    </textarea>
                                                    {errors.dayMopReason && (
                                                        <p className="error">{errors.dayMopReason.message}</p>
                                                    )}
                                                </div>

                                                <div className="card" style={{ width: "100%", marginTop: "1rem" }}>
                                                    {console.log(DbName, "DbNamedkasd")}
                                                    {
                                                        DbName[1] && DbName[0] && DbName[2] ?
                                                            <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} />
                                                            : ""

                                                    }


                                                </div>

                                            </div>
                                            <div className="form-row p-2">



                                            </div>


                                        </div>


                                    </div>
                                </div>

                                <div className="py-3 d-flex justify-content-center align-items-center">
                                    {/* <button type="submit" className="btn btn-primary btn-xs " disabled={activeUrl != "gcLogin" || currentSiteStatus <= singleSite.numberStatus ? true : false}> */}

                                    {activeUrl != "gcLogin" || currentSiteStatus <= singleSite.numberStatus ? "" : <button type="submit" className="btn btn-primary btn-xs mx-2 ">Submit</button>}


                                    {activeUrl != "gcLogin" ? "" : 
                                    
                                    <button className="btn btn-danger btn-xs mx-2" onClick={() => gotoback()}>Back</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}
export default GCLogin;