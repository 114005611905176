import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRoleApi, listUserApi, getMarket, addAcccount, listOneUser, updateUser, listRole_two_tmobile, listTmobileUserApi, listRole_tmobile, getAllDropDownList, getAllDropDown } from '../../../../common/config';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, GetMarketReducerAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, getUserListAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, advancdExportOnecustomDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";

import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import Password from '../../ReusableComponents/password';
import CommonBulkUploader from '../../Custom/CommonBulkUploader';
const Tmobiledeployment = () => {

  let pStatusArry = []
  let mStatusArry = []
  // let editTrue=0
  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };

  let accountArry = []
  let userArry = []

  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
  const [show, setShow] = useState(false);
  const [closer, setCloser] = useState(false);

  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => {
    reset({})
    setShow(true)
    // setCloser(true)
  }



  const [modalCentered, setModalCentered] = useState(false);
  const [newAccount, setAccount] = useState([])
  const [editTrue, seteditTrue] = useState(0)
  const [updateTrue, setupdateTrue] = useState(0)
  const [formtype, addformtype] = useState('')
  const [tableRows,setRow] =useState([])
  const counter=useRef(1)

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );


  let oneaccount = {}


  // setAccount(oneaccount)
  //  console.log(oneaccount,"oneaccount")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: black
          }
        }
      }
    }
  })


  console.log()
  const updateHandler = (e) => {
    console.log("button update clicked", e)

    dispatch(addUserAction(addAcccount, e))


  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    dispatch(deleteAcccountReducerAction(deleteUser, e, "role"))
  }


  const editpopupHandler = (e) => {
    seteditTrue(1)
    reset({})
    console.log("button editpopupHandler clicked", e)
    dispatch(OneUserReducerAction(listOneUser + "/" + e.uniqueid))
  }

  const seteditdataHandler = (e) => {
    reset({})
    setupdateTrue(1)
    setValue("email", e.email, { shouldTouch: true });
    setValue("password", e.password, { shouldTouch: true });
    setValue("mobilenumber", e.mobilenumber, { shouldTouch: true });
    setValue("name", e.name, { shouldTouch: true });
    // console.log(e.$oid,"e.$oid")
    setValue("userrole", e.userroleuniqueid, { shouldTouch: true });
    setValue("accountId", e.accountuniqueid, { shouldTouch: true });
    setValue("groupId", e.groupId, { shouldTouch: true });
    setValue("name", e.name, { shouldTouch: true });
    setValue("uniqueid", e.uniqueid, { shouldTouch: true });
    setValue("marketId", e.marketId.$oid, { shouldTouch: true });


  }


  console.log(errors, "errors")



  const onSubmit = async (data) => {
    console.log(data, "dassdaas")
    data["crewLeadCompanyId"]=""
    data["vendorId"]=""
    data["from"]="Tmo"
    if (data.uniqueid != undefined) {
      dispatch(updateAcccountReducerAction(updateUser, data.uniqueid, data, "tmoile"))
    }
    else {
      dispatch(addUserAction(addUser, data, "tmobile"))

      console.log("amitdispatchcheck", )
    }
    // handleClose()
    
    dispatch(popUpUpdate({}))
    setCloser(true)

  }

  const dispatch = useDispatch();


  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwemnbxhkbsdwebdf=", state.OneCommonResponse.listuser)
    let onelistuser = state.OneCommonResponse.listuser

    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(UserListReducerAction(listTmobileUserApi))
        setShow(false)
        handleClose()
      }
      console.log(state.popUpState.popstatus.status == 409 , closer,"state.popUpState.popstatus.status == 409 && closer")
      if (state.popUpState.popstatus.status == 409) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(UserListReducerAction(listTmobileUserApi))
        setShow(false)
        handleClose()
      }
    }
    console.log(onelistuser.length, editTrue, "onelistuser")
    if (onelistuser.length>=1 && (editTrue ==  1 || updateTrue==0)) {
      oneaccount = onelistuser[0]
      console.log("vkjdfgtrvnrjt",onelistuser)
      console.log(oneaccount, "oneaccount")
      seteditTrue(0)
      seteditdataHandler(oneaccount)
    }



    let users = state.CommonResponse.listuser
    
    let singleDropDown=state.CommonResponse.singleDropDown
    let groupList=singleDropDown.map((onesingle)=>{
      return <option value={onesingle.uniqueId}>{onesingle.optionValue}</option>
    })

    let account = state.CommonResponse.accounts
    let index = 0
    if (users && users.length > 0 ) {
      userArry = users.map((item) => {

        // console.log("the siot erkwfbisdygcuisd==",item)
        index = index + 1
        return {

          sNo: index,
          name: item.name,
          mobilenumber: item.mobilenumber,
          email: item.email,
          userrole: item.userrole,
          accountName: item.accountName,
          marketName: item.marketName,
          password: item.password,
          group: item.groupName,
          accessType: item.Tmobileprofile?"Allow to edit access, Read only Access":"Read only Access",
          action: <div className="d-flex">
            <Link
              href="#"
              onClick={() => { editpopupHandler(item); reset({}); addformtype("Edit"); handleShow(); }}
              className="btn btn-primary shadow btn-xs sharp mr-1 editButton"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              href="#"
              onClick={(e) => deleteHandler(item.uniqueid)}
              className="btn btn-danger shadow btn-xs sharp deleteButton"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
          // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>

        }
      })

    }


    if (account.length != 0) {
      accountArry = account.map((item) => {
        return <option value={item.uniqueid}>{item.accountName}</option>
      })
    }


    let userroledata = state.CommonResponse.userrole
    console.log(state.CommonResponse, "state.CommonResponse")
    console.log(userroledata, "projectStatus")
    console.log("pStatusArrydata", pStatusArry.find(o => o.rolename === "MobileTeam Manager"))
    if (userroledata.length != 0) {
      pStatusArry = userroledata.map((item) => {
        // setValue("userrole", item.uniqueid)
        return <option value={item.uniqueid}>{item.rolename}</option>
      })

    }

    let marketroledata = state.CommonResponse.markets
    if (marketroledata.length != 0) {
      mStatusArry = marketroledata.map((item) => {
        return <option value={item.uniqueid}>{item.marketName}</option>
      })

    }
    let crewroledata = state.CommonResponse.crewCompany
  
    console.log(pStatusArry, "pStatusArry")

    return { userArry, accountArry, pStatusArry, mStatusArry, oneaccount, groupList }
  })

  console.log(data, "data")
  const noOfRow = data.accountArry.length
  // data.accountArry.push(
  //   {   
  //       sNo:noOfRow+1,
  //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
  //       edit : <button onClick={()=>addAccount()}>Add Account</button>

  //   }
  // )



  console.log("the siot erkwfbisdygcuisd==",data.userArry)
  useEffect(() => {
    dispatch(getUserListAction([]))
    dispatch(RoleReducerAction(listRole_tmobile))
    dispatch(AccountReducerAction(accountApi))
    dispatch(GetMarketReducerAction(getMarket))
    dispatch(UserListReducerAction(listTmobileUserApi))
    dispatch(AccountReducerAction(getAllDropDown+"/Group"))

  }, [])
  const columns = [
    {

      name: "sNo",
      label: "S.No.",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "mobilenumber",
      label: "Mobile Number",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })

      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },{
      name: "group",
      label: "Group",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "accountName",
      label: "Account",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "marketName",
      label: "Market",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },{
      name: "password",
      label: "Password",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "userrole",
      label: "User Role",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "accessType",
      label: "Profile",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    }

  ];


  let row = [];

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }

  const AddCustomButton = () => (
    <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
      <Button className="btn btn-primary btn-xs mx-2 py-2 my-3" onClick={() => { handleShow(); reset({}); addformtype("Add") }}>Add User</Button>
      
      <CommonBulkUploader typeForm={"T-Mobile"} classes="btn btn-primary btn-xs mx-2 py-2 my-3" shortCode={"TMobile"} afterLoad={listTmobileUserApi} />
      <Modal show={show} className="fade"  size="lg">
        <Modal.Header>
          <Modal.Title>{formtype} User</Modal.Title>
          <Button
                  onClick={() => handleClose()}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
        </Modal.Header>
        <Modal.Body >

          <div className="card " style={{ width: "100%" }}>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <Password/> */}
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Name
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"

                            {
                            ...register("name",{ required: "Name is required" })
                            }
                          />
                     {errors.name && (
                                <p className="error">
                                  {errors.name.message}
                                </p>
                              )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Mobile Number
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Mobile Number"

                            {
                            ...register("mobilenumber",
                            { 
                              required: "Reason is required",
                              minLength :{
                                value:10,
                                message:"Please enter 10 digit mobile number"
                              },
                              maxLength:{
                                values:10,
                                message:"Please enter 10 digit number"
                              }
                              
                            })
                            }
                          />
            {errors.mobilenumber && (
                                <p className="error">
                                  {errors.mobilenumber.message}
                                </p>
                              )}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Password
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Password"

                            {
                            ...register("password",{
                              required: "password is required",
                              minLength: {
                                value: 8,
                                message: "Please enter minimum 8 characters"
                              },
                              maxLength: {
                                value: 16,
                                message: "Only 16 characters are allowed"
                              }
                            })
                            }
                          />
                           {errors.password && (
                                <p className="error">
                                  {errors.password.message}
                                </p>
                              )}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Email
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Email"

                            {
                            ...register("email", {
                              required: "please enter email",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Entered value does not match email format"
                              }
                            })
                            }
                          />
        {errors.email && (
                                <p className="error">
                                  {errors.email.message}
                                </p>
                              )}
                        </div>


                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            User Role <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("userrole",{
                              required:"Please select user roles"
                            })}>

                            {data.pStatusArry}

                          </select>
                          {errors.userrole && <p className="error">{errors.userrole.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Account <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {
                            ...register("accountId",{
                              required:"Please select account"
                            })
                            }
                          >
                            <option disabled selected value={""}>Select</option>
                            {data.accountArry}
                          </select>
                          {errors.accountId && <p className="error">{errors.accountId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Group <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {
                            ...register("groupId",{
                              required:"Please select Group"
                            })
                            }
                          >
                            <option disabled selected value={""}>Select</option>
                            {data.groupList}
                          </select>
                          {errors.groupId && <p className="error">{errors.groupId.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Market <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("marketId",
                            {
                              required:"Please select market"
                            })}>


                            <option disabled selected value={""}>Select</option>
                            {data.mStatusArry}

                          </select>
                          {errors.marketId && <p className="error">{errors.marketId.message}</p>}
                        </div>

                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary btn-xs">
                    Submit
                  </button>
                  {/* <Button type='button' className='btn btn-primary  btn-xs '>Submit</Button> */}
                </form>
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </fregment>
  )
  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];





  return (
    <>





      <ThemeProvider theme={getMuiThemeCommon()}>

      <MUIDataTable
        title={"List Deployment Manager"}
        data={data.userArry}
        columns={columns}
        // options={customDataTableOptionObject(AddCustomButton,false,"")}
        options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"TMO_","",false,"TMO Deployment Name")}
      />
      </ThemeProvider>
    </>
  )
}

export default Tmobiledeployment