import { Hidden } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import PieScoreUpdated from '../Custom/ScoreCardComponent/PieScoreUpdated'
import Filter from '../Custom/ScoreCard/Filter'
import TopBoard from '../Custom/ScoreCard/TopBoard'
import DemoChart from '../Custom/ScoreCard/DemoChart'
import StackedBarChart from '../Custom/ScoreCard/StackedColumnChart'
import { useDispatch, useSelector } from 'react-redux'
import { VendorScoreCardReducerAction } from '../../../store/actions/createTicketManageFields'
import { VendorScoreCardApi, VendorScoreCardDashboard } from '../../../common/config'
import ChartGroup from '../Custom/ScoreCard/ChartGroup'
import ChartBasedFilter from '../Custom/ScoreCard/ChartBasedFilter'

const VendorScoreCard = () => {


  const [firstStepChart, setFirstStepChart] = useState(false)
  const [divBox, setDivBox] = useState("")
  const [filtering, setfiltering] = useState("")
  const [CBF, setCBF] = useState({
    "impact":"All",
    "ttStatus":"All",
    "serviceAffecting":"Both"
  })
  

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(VendorScoreCardReducerAction(VendorScoreCardDashboard))
  },[])


  // console.log(vendorScoreGraphList,"vendorScoreGraphList")
  return (
    <><div style={{ backgroundColor: "#343e59" }}>
      {/* <div>
      <br/>
      <br/>
      <br/>
        <div class="container-fluid mt-2 ">
          <div class="row">
            <div class="col-2  scorecard-bar" style={{ backgroundImage: "linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%)" }}>
              Trouble Ticket - 100
            </div>
            <div class="col-2 scorecard-bar" style={{ backgroundImage: "linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%)" }}>
              Punctuality - 100
            </div>
            <div class="col-2 scorecard-bar" style={{ backgroundImage: "linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%)" }}>
              Site Spilover - 100
            </div>
            <div class="col-2 scorecard-bar" style={{ backgroundImage: "linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%)" }}>
              Performance - 100
            </div>
            <div class="col-2 scorecard-bar" style={{ backgroundImage: "linear-gradient( 135deg, #FFD3A5 10%, #5961F9 100%)" }}>
              Site Downtime - 100
            </div>
          </div>
        </div>
      </div> */}

      <Filter filtering={filtering} setDivBox={setDivBox} setfiltering={setfiltering} />
      
      <br/>
      <br/>
      <TopBoard filtering={filtering} setDivBox={setDivBox} firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} />


      <ChartBasedFilter CBF={CBF} setCBF={setCBF} divBox={divBox} />



      {
        divBox!="" ? <div class="row">


        <ChartGroup filtering={filtering}/>

        
          {/* <div class="h-100"></div>
          <div class="col text-center "><StackedBarChart chartType={"bar"} minBarWidth={80} numDataPoints={2} data={{series:[],label:[]}} /></div>
          <div class="w-100"></div>
          <div class="col text-center "><StackedBarChart chartType={"bar"} minBarWidth={80} numDataPoints={2} data={{series:[],label:[]}} /></div>
          <div class="h-100"></div>
          <div class="col text-center "><StackedBarChart chartType={"bar"} minBarWidth={80} numDataPoints={2} data={{series:[],label:[]}} /></div> */}
        </div> : <></>
      }

      <br/>
      <br/>
      <br/>
    </div>
    </>
  )
}

export default VendorScoreCard