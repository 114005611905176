import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { socketbaseURL } from '../common/config';
import { useDispatch } from 'react-redux';
import { OneUpdategetRanDashboard, OneUpdatenotiied } from '../store/actions/createTicketManageFields';

const WebSocketComponent = () => {
  let socket;

  let dispatch=useDispatch()



  const [ac,setac]=useState()
  const [colo,setColo]=useState()
  
  // const socket;
  function callFunc(){
    const message = 'Helldasdaasdao from the client';
    socket.emit('message_from_client', message);
  }

  


  useEffect(() => {
     // Replace with your Flask server address

     console.log("websocketstart")
    socket = io.connect(socketbaseURL,{
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      console.log('websocketstart Connected to server');
    });

    socket.on('dbname', (data) => {
      console.log(data,"websocketstart colordata")
      setColo(data);
      // alert(data)


      dispatch(OneUpdategetRanDashboard(data))
    });



    socket.on('notification', (data) => {
      console.log(data,"websocketstart notification")
      setColo(data);
      // alert(data)


      dispatch(OneUpdatenotiied(data))
    });

    socket.on('disconnect', () => {
      console.log('websocketstart Disconnected from server');
    });

    console.log("websocket")
    

    // Example of sending a message to the server
    

    // return () => {
    //   socket.disconnect();
    // };
  }, [ac]);

  return (
    <></>
    // <div>
    //   <h1 style={{color:colo}}>WebSocket Example</h1>
    //   <p>Check the browser console for connection status.</p>

    //   <button onClick={(e)=>{
    //     callFunc()
    //   }}></button>
    // </div>
  );
};

export default WebSocketComponent;
