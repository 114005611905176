import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiixPreCheckTool, apiPreCheckTool, baseURL, check_sla_met_api, ixModule, managEngineer, OneSiteDatabase, projectCode, ranDashboard, runPostCheck, runPreCheck, singleProjectApi } from '../../../../common/config';
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, FetchSingleRecordAction, GetttProject, LCLranDashboardReducerAction, popUpUpdate, SaveLastSavedData, setLoaderAction, TierListReducerAction } from '../../../../store/actions/createTicketManageFields';
import { PDFUploadAction } from '../../../../store/actions/dashboardActions';
import CommonBulkUploader from "../../Custom/CommonBulkUploader";
import { ageofdata, ageofdatavalue, ageofdatavalueCheck, reformArray, toHoursAndMinutes } from "../../../../common/variables";
import FileSaver from 'file-saver';
import swal from "sweetalert";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment"
import SlaMetRemark from "../../Custom/Modal Body/SlaMetRemark";
import CommonModal from "../../Custom/CommonModal";
const PostCheck = () => {
  let dispatch = useDispatch()
  const history = useHistory();
  // let siteInfo = {}
  const [setdata, setsetdata] = useState(true);
  const [siteactiveCancel, setsiteactiveCancel] = useState(false);
  const [techactiveCancel, settechactiveCancel] = useState(false);
  let [pTech, setPTech] = useState([]);
  let postTechno=[]
  const url = window.location.href.split("/")
  let index = url.pop()

  let urlindexid = index
  let activeUrl = url.pop()
  let dashboardType=url.slice(-1)[0]
  const [PreCheckToolModal, setPreCheckToolModal] = useState(false);
  const [PdfUrl, setPdfUrl] = useState("");
  const [PdfWebUrl, setPdfWebUrl] = useState("");
  const [uploadedattachFile, setuploadedattachFile] = useState([]);
  const [fenddate, setfenddate] = useState();
  const [tsscript, settsscript] = useState();
  
  const [eroor, setError] = useState(false);

  const [checkNadate, setcheckenddate] = useState(true);
  const [EngineerOpen, setEngineerOpen] = useState("");
  // const [uploadedattachFile, attachFile] = useState([]);

  const [closer, setCloser] = useState(false);
  const [MoveCloser, setMoveCloser] = useState(false);
  const [MoveCloserSla, setMoveCloserSla] = useState(false);
  const [siteFile, uploadedFile] = useState([]);

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState();
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [formDataState, setformDataState] = useState();
  const [siteId, setsiteId] = useState(true);
  let fileParsed = {}
  const [one, setOne] = useState(true);
  const [onenTimer, setonenTimer] = useState(true);
  const [onennnTimer, setonennnTimer] = useState(true);

  const [oldonenTimer, setoldonenTimer] = useState(true);
  

  const [siteInfo, setsiteInfo] = useState({});
  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm();
  let [ppProjectId, setppProjctId] = useState('')


  let engineerArray = []


  const topageraiseTT = (siteDetaildata, page) => {
    if (siteDetaildata) {


      setoldonenTimer(true)
      console.log(getValues(),"getValuesgetValues")
      let oller=getValues()
      delete oller.postCheckEngineer
      let formAllData={
        forType:"Postcheck",
        data:oller
      }
      dispatch(SaveLastSavedData(formAllData))


      dispatch(GetttProject([]))

      dispatch(clearFetchSingleRecordAction())

      let data = {
        "siteUniqueId": siteDetaildata.siteInfo.dtSiteId,
        "ranId": index,
        "createFrom": "Pre Check",
        "creatorId": index,
        "projectUniqueId": siteDetaildata.siteInfo.dtprojectUniqueId,
        "index": 0
      }
      // history.push({
      //   pathname: "/PAG/trobleTicke/form/post",
      //   creator: data
      // })

      if(dashboardType=="ixDashboard"){
        history.push({
          pathname: `/PAG/trobleTicke/form/ixDashboard/${index}`
        })
      }
      else{
        history.push({
        pathname: `/PAG/trobleTicke/form/post/${index}`
      })
    }
    }
    console.log(siteDetaildata.siteInfo, "siteDetaildata")
    // }
    // if(page=="back"){
    //   history.goBack()
    // }
  }
  let alarmOption = ["Yes", "No", "NA"]
  let newAlarm;

  if (one) {
    newAlarm = alarmOption.map((item, index) => {
      return <option value={item}>{item}</option>
    })
    setOne(false)
    newAlarm.unshift(<option value=''>Select</option>)
  }
  const [dataSlaData, setdataSlaData] = useState(false);
  const [chackerSlaData, setchackerSlaData] = useState(false);

  const [dropDownAlarmObj, setdropDownAlarmObj] = useState({
    gsmStatus: newAlarm,
    umtsIPContact: newAlarm,
    lteIPContact: newAlarm,
    fiveGIPContact: newAlarm,
    bscIPContact: newAlarm,

    GSMAlarms: newAlarm,
    fiveGAlarms: newAlarm,
    umtsAlarms: newAlarm,
    lteAlarm: newAlarm,

  })

  // let dropDownAlarmObj = {
  //   gsmStatus: newAlarm,
  //   umtsIPContact: newAlarm,
  //   lteIPContact: newAlarm,
  //   fiveGIPContact: newAlarm,
  //   bscIPContact: newAlarm,

  //   GSMAlarms: newAlarm,
  //   fiveGAlarms: newAlarm,
  //   umtsAlarms: newAlarm,
  //   lteAlarm: newAlarm,

  // }

  const attachFile = (e) => {
    // let files=[]


    console.log(e.target.files[0], "files")

    // let files = []


    // for (let i = 0; i < e.target.files.length; i++) {
    //   files.push(e.target.files[i])
    // }
    setuploadedattachFile(e.target.files[0])



  };

  const topage = (page) => {
    if (page == "raiseTT") {
      history.push("/PAG/trobleTicke/form")
    }
    if (page == "back") {
      history.goBack()
    }
  }
  const userDetails = JSON.parse(localStorage.getItem("userDetails"))
  let siteDetail = useSelector((state) => {
    console.log("the url =", state)
    // let site = state.OneCommonResponse.singleSite
    let technodata = state.CommonResponse.technology

    if(oldonenTimer){
      setoldonenTimer(false)
      if(state.oldDataState.postcheckData){
        let listOldData=Object.keys(state.oldDataState.postcheckData)
        if(listOldData.length>0){
    
          // setValues()
          // console.log(state.oldDataState.precheckData,"oldDataStateprecheckData")
          if(state.oldDataState.postcheckData.data){
    
            console.log(state.oldDataState.postcheckData.data,"oldDataStateprecheckData")

            let oldPreDataObj=state.oldDataState.postcheckData.data
            // oldPreDataObj.map((oldPreDataObjitm)=>{
            //   console.log(oldPreDataObj,"oldPreDataObj")
            // })
            let listOldPreData=Object.keys(state.oldDataState.postcheckData.data)
            listOldPreData.map((oneitm)=>{
              console.log(oneitm,oldPreDataObj,oldPreDataObj[oneitm],`oldPreDataObj.${oneitm}`,`oldPreDataObj.oneitm`,"oldPreDataObj")
              setValue(oneitm,oldPreDataObj[oneitm])
            })
          }
          
        }
      }
    }
       
    // if(technodata && technodata.length>0){
    //     postTechno = technodata.map((ids) => {
          
    //         return {
    //           value: ids.technologyName ,
    //           label: ids.technologyName,
    //         };
    //     })
    // }
      if(technodata && technodata.length>0){
            postTechno = technodata.map((ids) => {
              
                return {
                  value: ids.technologyName ,
                  label: ids.technologyName,
                };
            })
        }

    // let siteby = dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite
    // // siteInfo = site
    // console.log(siteby, "sitebysiteby1")
    // if (siteby && siteby.length !=0  &&  onenTimer) {
    //   setonenTimer(false)
    //   if(siteby.postHWLayerConfiguration && siteby.postHWLayerConfiguration.values.length>0){
    //     let postTech=siteby.postHWLayerConfiguration.values.map((item)=>{
    //         return {
    //             label:item,
    //             value:item
    //         }
    //     })
    //     console.log("bfijerabgfiluadsgbre=",postTech)
    //     setPTech(postTech)
    // }
    //   console.log(ageofdatavalueCheck(siteby["RF_Approved_EndTime_Time"]), siteby["RF_Approved_EndTime_Time"], "ageofdatavalueCheck")
    //   if (ageofdatavalueCheck(siteby["RF_Approved_EndTime_Time"])) {
    //     setValue("activityWentOutOfApprovedWindow", "Yes")
    //     setValue("timeAfterApprovedWindow", ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[1] + ":" + ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[2])
    //   } else {
    //     setValue("activityWentOutOfApprovedWindow", "No")
    //   }
    //   if(siteby.troubleTickets && siteby.troubleTickets.length>0){
    //     setValue("ttOpen", "Yes")
    //   }else{
    //     setValue("ttOpen", "No")
    //   }

    //   let listTTUsed=siteby.troubleTickets ?siteby.troubleTickets.map((ontt)=>{
    //     return ontt.ttid
    //   }):[]

    //   console.log(listTTUsed,"listTTUsed")
      
    //   setValue("ttId", listTTUsed.join(", "))
    // }
    if (state.popUpState) {

      // if (state.popUpState.popstatus.status == 201 && MoveCloser) {
      //   console.log(state.popUpState.popstatus.data, "popstatus")
      //   setMoveCloser(false)
      //   dispatch(popUpUpdate({}));
      //   history.push("/PAG/ran/support/dashboard")
      // }


      // if(state.popUpState.popstatus.status == 201 && MoveCloserSla){
        
      //   dispatch(setLoaderAction(false))
      //   // history.push("/PAG/ran/support/dashboard")
      // }


      if (state.popUpState.popstatus.status == 200 && state.popUpState.popstatus.data.msg=="SLA Met Check" && MoveCloser) {
        // dispatch(setLoaderAction(true))
        // console.log(state.popUpState.popstatus,state.popUpState.popstatus.data.msg=="SLA Met Check", "popstatuspopstatuspopstatus")
        let slamentt = state.popUpState.popstatus.data.data
        dispatch(popUpUpdate({}));
        setMoveCloser(false)
        if (slamentt[1] == "No") {
          dispatch(setLoaderAction(false))
          setcommonModalBody(<SlaMetRemark formDataState={formDataState} setdataSlaData={setdataSlaData} setchackerSlaData={setchackerSlaData} selectedId={urlindexid} SLAType={"Post"} SLAMet={slamentt[1]} />)
          setcommonModalState(true)
          
        } else {
          if(dashboardType=="ixDashboard"){
            console.log("jhjdsadadasdab jhbvbuyvj",formDataState)
            dispatch(addUserAction(runPostCheck + index+"?ix=True", formDataState))
          }
          else{
            dispatch(addUserAction(runPostCheck + index, formDataState))
          }
          dispatch(popUpUpdate({}));
          setMoveCloserSla(true)
        }

        // history.push("/PAG/ran/support/dashboard")
      }


      if (state.popUpState.popstatus.status == 201 && MoveCloserSla) {

        dispatch(popUpUpdate({}));
        setMoveCloserSla(false)

        dispatch(setLoaderAction(false))
        // history.push("/PAG/ix/dashboard")
        if(dashboardType=="ixDashboard"){
          dispatch(AccountReducerAction(ixModule));
          history.push("/PAG/ix/dashboard")
        }
        else{
            
          let Filleddata=dispatch(LCLranDashboardReducerAction())
          dispatch(AccountReducerAction(ranDashboard+Filleddata,true));
          // dispatch(AccountReducerAction(ranDashboard,true));
          history.push("/PAG/ran/support/dashboard")
        }
      }
      // if (state.popUpState.popstatus.status == 200 && state.popUpState.popstatus.data.msg == "SLA Met Check" && MoveCloser) {
      //   dispatch(setLoaderAction(true))
      //   console.log(state.popUpState.popstatus.data.msg == "SLA Met Check", "popstatuspopstatuspopstatus")
      //   setMoveCloser(false)
      //   let slamentt = state.popUpState.popstatus.data.data
      //   dispatch(popUpUpdate({}));
      //   if (slamentt[1] == "No") {
      //     dispatch(setLoaderAction(false))
      //     setcommonModalBody(<SlaMetRemark formDataState={formDataState} setdataSlaData={setdataSlaData} setchackerSlaData={setchackerSlaData} selectedId={urlindexid} SLAType={"Post"} SLAMet={slamentt[1]} />)
      //     setcommonModalState(true)

      //   }
        
      //   // else {
      //   //   dispatch(popUpUpdate({}));
      //   //   // dispatch(addUserAction(runPostCheck + urlindexid, formDataState))
      //   //   dispatch(addUserAction(runPostCheck + urlindexid, formDataState))
      //   //   setMoveCloserSla(true)
      //   // }

      //   // history.push("/PAG/ran/support/dashboard")
      // }
      if (state.popUpState.popstatus.status == 200 && closer) {

        if (state.popUpState.popstatus.data.code == 200) {

          console.log(state.popUpState.popstatus.data, "popstatus")
          setCloser(false)
          // setPreCheckToolModal(false)
          // window.open(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)
          // setPdfUrl("/var/www/html/pag/pag-backend/flask_app_db/FlaskTool/folder/Download_TMO_Post/" + state.popUpState.popstatus.data)
          // // setPdfWebUrl(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)

          fileParsed = state.popUpState.popstatus.data
          // setfileParsed(state.popUpState.popstatus.data)
          console.log(fileParsed, "fileParsed")
          setsetdata(false)

          setValue("postBSCIpContact", fileParsed.twoG)
          setValue("postGSMAlarm", fileParsed.twoGAlarm)
          setValue("postUmtsIpContact", fileParsed.threeG)
          setValue("postUMTSlAlarm", fileParsed.threeGAlarm)
          setValue("postLTEIPContact", fileParsed.fourG)
          setValue("postLteAlarm", fileParsed.fourGAlarm)
          setValue("post5GIpContact", fileParsed.fiveG)
          setValue("post5GAlarm", fileParsed.fiveGAlarm)

          let filename = fileParsed.downloadPath.split("/").pop()
          let filePath = ""
          if(dashboardType=="ixDashboard"){
            filePath = baseURL + "/tmo_post_check_ix/downloads/" + filename
          }else{
            filePath = baseURL + "/tmo_post_check/downloads/" + filename
          }
          

          swal({
            title: "Do you want to download report?",
            text: "",
            icon: "",
            buttons: true,
            // dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              // <Link to={filePath}></Link>
              FileSaver.saveAs(filePath, filename)
              setPdfUrl(filePath)
              setPdfWebUrl(filePath)
            } else {
              setPdfWebUrl("")
              setPdfUrl(filePath)
            }
          })


          let newdropDown = {
            gsmStatus: '',
            umtsIPContact: '',
            lteIPContact: '',
            fiveGIPContact: '',
            bscIPContact: '',
            GSMAlarms: '',
            fiveGAlarms: '',
            umtsAlarms: '',
            lteAlarm: '',

          }

          newdropDown.bscIPContact = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.twoG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.fiveGIPContact = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.fiveG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.umtsIPContact = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.threeG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.lteIPContact = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.fourG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })

          newdropDown.GSMAlarms = alarmOption.map((item) => {
            console.log(item == fileParsed.twoGAlarm, "item == fileParsed.fourGAlarm")
            if (fileParsed && item == fileParsed.twoGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.fiveGAlarms = alarmOption.map((item) => {
            console.log(item == fileParsed.fiveGAlarm, "item == fileParsed.fourGAlarm")
            if (fileParsed && item == fileParsed.fiveGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.umtsAlarms = alarmOption.map((item) => {
            console.log(item == fileParsed.threeGAlarm, "item == fileParsed.fourGAlarm")
            if (fileParsed && item == fileParsed.threeGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.lteAlarm = alarmOption.map((item) => {
            console.log(item == fileParsed.fourGAlarm, "item == fileParsed.fourGAlarm")
            if (fileParsed && item == fileParsed.fourGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })

          console.log(newdropDown, "newdropDownnewdropDownnewdropDown")
          setdropDownAlarmObj(newdropDown)


          // console.log(state.popUpState.popstatus,"state.popUpState.popstatus")
          // setPdfUrl("/var/www/html/pag/pag-backend/flask_app_db/FlaskTool/folder/Download_TMO_Post/" + state.popUpState.popstatus.data)
          // setPdfWebUrl(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)

          dispatch(popUpUpdate({}));
          setPreCheckToolModal(false)
        } else {
          dispatch(popUpUpdate({}));
          swal({
            title: "Please Upload Files for " + siteId,
            text: "",
            icon: "",
            // dangerMode: true,
          })
        }
      }
    }
    console.log("the url =", state)
    let siteby = dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite
    // siteInfo = site
    console.log(siteby, "sitebysiteby1")
    if (siteby && siteby.length !=0  &&  onenTimer) {
      setonenTimer(false)
      if(siteby.postHWLayerConfiguration && siteby.postHWLayerConfiguration.values.length>0){
        let postTech=siteby.postHWLayerConfiguration.values.map((item)=>{
            return {
                label:item,
                value:item
            }
        })
        console.log("bfijerabgfiluadsgbre=",postTech)
        setPTech(postTech)
    }
      console.log(ageofdatavalueCheck(siteby["RF_Approved_EndTime_Time"]), siteby["RF_Approved_EndTime_Time"], "ageofdatavalueCheck")
      if (ageofdatavalueCheck(siteby["RF_Approved_EndTime_Time"])) {
        setValue("activityWentOutOfApprovedWindow", "Yes")
        setValue("timeAfterApprovedWindow", ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[1] + ":" + ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[2])
      } else {
        setValue("activityWentOutOfApprovedWindow", "No")
      }
      if(siteby.troubleTickets && siteby.troubleTickets.length>0){
        setValue("ttOpen", "Yes")
      }else{
        setValue("ttOpen", "No")
      }

      let listTTUsed=siteby.troubleTickets ?siteby.troubleTickets.map((ontt)=>{
        return ontt.ttid
      }):[]

      console.log(listTTUsed,"listTTUsed")
      
      setValue("ttId", listTTUsed.join(", "))


      // console.log(ageofdatavalue(siteDetail.siteInfo["RF_Approved_EndTime_Time"]),siteDetail.siteInfo["RF_Approved_EndTime_Time"],"timeAfterApprovedWindow")
      // setValue("timeAfterApprovedWindow",siteDetail.siteInfo["RF_Approved_EndTime_Time"]+""+ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[1]+":"+ageofdatavalue(siteby["RF_Approved_EndTime_Time"])[2])



      setsiteId(siteby.ndResult.siteId)
      setsiteInfo(siteby)
      console.log(siteby, "sitebysiteby")

    }

    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }


    let outage_find = state.CommonResponse.fetchedpdfdata
    console.log(outage_find, "outage_findoutage_findoutage_find")
    if (outage_find && outage_find.length != 0) {


      let fivec = toHoursAndMinutes(outage_find.fiveG)
      let twoc = toHoursAndMinutes(outage_find.twoG)
      let threec = toHoursAndMinutes(outage_find.threeG)
      let fourc = toHoursAndMinutes(outage_find.fourG)

      setValue("post5GDownTime", fivec[0])
      setValue("post5GDownTime1", fivec[1])
      setValue("postGsmDownTime", twoc[0])
      setValue("postGsmDownTime1", twoc[1])
      setValue("postUmtsDownTime", threec[0])
      setValue("postUmtsDownTime1", threec[1])
      setValue("postLteDownTime", fourc[0])
      setValue("postLteDownTime1", fourc[1])



    }

    console.log(outage_find, "outage_findoutage_find")


    
    // siteInfo = site

    console.log(typeof (site) == typeof ({}), setdata, onennnTimer, "setdata , onennnTimersetdata , onennnTimer")
    if (typeof (site) == typeof ({}) && setdata && onennnTimer) {
      let gsmMatch = false
      setonennnTimer(false)
      let bscIPContactMatch = false
      let umtsIPContactMatch = false
      let umtsAlarmsMatch = false
      let lteIPContactMatch = false
      let lteAlarmMatch = false
      let fiveGAlarmsMatch = false
      let fiveGIPContactMatch = false
      console.log("th e sit e is sit=", siteInfo)
      dropDownAlarmObj.GSMAlarms = alarmOption.map((item) => {
        if (siteInfo.postGSMAlarm && item == siteInfo.postGSMAlarm) {
          gsmMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (gsmMatch == false) {
        dropDownAlarmObj.GSMAlarms.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.bscIPContact = alarmOption.map((item) => {
        if (siteInfo.postBSCIpContact && item == siteInfo.postBSCIpContact) {
          bscIPContactMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (bscIPContactMatch == false) {
        dropDownAlarmObj.bscIPContact.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.umtsIPContact = alarmOption.map((item) => {
        if (siteInfo.postUmtsIpContact && item == siteInfo.postUmtsIpContact) {
          umtsIPContactMatch = true
          return <option selected value={item}>{item}</option>

        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (umtsIPContactMatch == false) {
        dropDownAlarmObj.umtsIPContact.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.umtsAlarms = alarmOption.map((item) => {
        if (siteInfo.postUMTSlAlarm && item == siteInfo.postUMTSlAlarm) {
          umtsAlarmsMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (umtsAlarmsMatch == false) {
        dropDownAlarmObj.umtsAlarms.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.lteIPContact = alarmOption.map((item) => {
        if (siteInfo.postLTEIPContact && item == siteInfo.postLTEIPContact) {
          lteIPContactMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (lteIPContactMatch == false) {
        dropDownAlarmObj.lteIPContact.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.lteAlarm = alarmOption.map((item) => {
        if (siteInfo.postLteAlarm && item == siteInfo.postLteAlarm) {
          lteAlarmMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (lteAlarmMatch == false) {
        dropDownAlarmObj.lteAlarm.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.fiveGIPContact = alarmOption.map((item) => {
        if (siteInfo.post5GIPContact && item == siteInfo.post5GIPContact) {
          fiveGIPContactMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (fiveGIPContactMatch == false) {
        dropDownAlarmObj.fiveGIPContact.unshift(<option selected value='' disabled>Select</option>)
      }
      dropDownAlarmObj.fiveGAlarms = alarmOption.map((item) => {
        if (siteInfo.post5GAlarm && item == siteInfo.post5GAlarm) {
          fiveGAlarmsMatch = true
          return <option selected value={item}>{item}</option>
        } else {
          return <option value={item}>{item}</option>
        }
      })
      if (fiveGAlarmsMatch == false) {
        dropDownAlarmObj.fiveGAlarms.unshift(<option selected value='' disabled>Select</option>)
      }
      // dropDownAlarmObj.fiveGAlarms=alarmOption.map((item)=>{
      //   if(siteInfo.post5GAlarm && item==siteInfo.post5GAlarm){
      //     return <option selected value={item}>{item}</option>
      //   }else{
      //     return <option  value={item}>{item}</option>
      //   }
      // })
    }
    return { siteInfo, engineerArray, tier }
  })

  let Precheckoperner = () => {
    setPreCheckToolModal(true)
  }


  const formatData = (input) => {
    if (input > 9) {
      return input;
    } else return `0${input}`;
  };
  const formatHour = (input) => {
    if (input > 12) {
      return input - 12;
    }
    return input;
  };
  const format24Hour = ({ dd, mm, yyyy, HH, MM, SS }) => {
    return (`${mm}/${dd}/${yyyy} ${HH}:${MM}`);
  };



  const closehandleCloseweather = () => {
    setPreCheckToolModal(false)
  };


  const submitHandler = async (e, check = null) => {
    e.preventDefault();

    if (check == "runRFDS") {
      console.log("inside RFDS", siteFile)
      // console.log("remarks =",remarks)
      console.log("file=", siteFile)
      let temp = []
      // temp.push(...siteFile)
      let form = new FormData();

      // for(let i=0;i<siteFile.length;i++){
      //   form.append('uploadFile',siteFile[i].file, siteFile[i].name)
      // }
      // form.append('uploadFile',siteFile)

      console.log(siteFile, siteFile.length);
      for (let i = 0; i < siteFile.length; i++) {
        form.append("all-files", siteFile[i]);
      }
      form.append("Other_Customer", true)
      form.append("siteId", siteId)
      console.log("file=", form)
      
      // if(dashboardType=="ixDashboard"){
      //   dispatch(PDFUploadAction(apiPreCheckTool, form, false))
      // }else{
      //   dispatch(PDFUploadAction(apiixPreCheckTool, form, false))
      // }

      console.log(dashboardType,"dashboardTypedashboardType")
      if(dashboardType=="ixDashboard"){
        dispatch(PDFUploadAction(apiixPreCheckTool, form, false))
      }else{
        dispatch(PDFUploadAction(apiPreCheckTool, form, false))
      }
      console.log("form data =", form)
      dispatch(popUpUpdate({}));
      setCloser(true)
    }
  };


  const findengineer = (id) => {
    console.log("findengineer", "findengineer",siteDetail.tier,siteDetail.tier.findIndex(finndName=>finndName.name==id), id)
    if(siteDetail.tier.findIndex(finndName=>finndName.name==id)!=-1){
      setError(false)
    }else{
      setError(true)
    }
    setValue("tsscripting", id)
    setEngineerOpen(id)
  }





  // setValue("postCheckEngineer",userDetails.name/)
  const onSubmitForm = async (data) => {

    if(eroor){
      return 
    }
    dispatch(popUpUpdate({}));
    setMoveCloser(true)
    
    console.log("the send data is =", data)
    let format;

    if (siteactiveCancel == false) {
      delete data["postsitecancelrollbackreason"]
    }
    if (checkNadate != true && fenddate == undefined) {
      return swal("Check NA Date or select Site UP Time");
    }
    if (checkNadate) {
      data['postSiteUpTime'] = "NA";
    } else {
      data['postSiteUpTime'] = moment(fenddate).format("MM/DD/YYYY HH:mm")
    }


    // data['postSiteUpTime'] = format24Hour(format);
    data['postSiteUpTimeNA'] = checkNadate;



    data["postCheckEngineer"] = userDetails.name
    data["post5GDownTime"] = data["post5GDownTime"] + ":" + data["post5GDownTime1"]
    data["postGsmDownTime"] = data["postGsmDownTime"] + ":" + data["postGsmDownTime1"]
    data["postUmtsDownTime"] = data["postUmtsDownTime"] + ":" + data["postUmtsDownTime1"]
    data["postLteDownTime"] = data["postLteDownTime"] + ":" + data["postLteDownTime1"]

    delete data["post5GDownTime1"]
    delete data["postGsmDownTime1"]
    delete data["postUmtsDownTime1"]
    delete data["postLteDownTime1"]


    data["PosttoolFile"] = PdfUrl


    let newform = new FormData();
    for (var key in data) {
      newform.append(key, data[key]);
    }

    newform.append("PosttoolFile", PdfUrl);
    if (uploadedattachFile) {
      newform.append("uploadFile", uploadedattachFile);
    }

    console.log("the send data is =", data)
    // dispatch(addUserAction(runPreCheck+siteDetail.siteInfo.ranuniqueId, data))    
    // dispatch(addUserAction(runPostCheck + index, newform))


    let newJson = {
      "parentId": urlindexid,
      "type": "Post"
    }
    
    if(dashboardType=="ixDashboard"){
      console.log("hbkwbcjkhwbbcj",pTech)
      console.log("nkqbwihqbddeqhbkwbcjkhwbbcj",reformArray(pTech))
      let actualList=reformArray(pTech)
      newform.append("postHWLayerConfigurationJSON",JSON.stringify(reformArray(pTech)))
    }
    setformDataState(newform)

    console.log("jhjb jhbvbuyvj",newform)
    if(dashboardType=="ixDashboard"){
      dispatch(addUserAction(check_sla_met_api+"?ix=True", newJson))
    }
    else{
      dispatch(addUserAction(check_sla_met_api, newJson))
    }// setMoveCloser(true)
  }

  console.log(errors, "errors")

  const uploadCsvFile = (e) => {
    let files = []

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log(...files)
    uploadedFile(files)

  };
const ptechChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setPTech(e);
};
  console.log(url.indexOf("postCheck")!=-1,activeUrl,url.includes("postCheck"), "siteDetailsiteDetailsiteDetailsiteDetail")
  useEffect(() => {
    // dispatch(FetchSingleRecordAction(ranDashboard, index))
    if(activeUrl == "postCheck"){
      dispatch(TierListReducerAction(managEngineer))
    }
    
  }, [dropDownAlarmObj])
  return (


    <>

      <Modal show={PreCheckToolModal} size="md">
        <Modal.Header
          variant="secondary "
          closeButton
          onClick={closehandleCloseweather}
        >
          <Modal.Title>Post Check Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={(e) => submitHandler(e, "runRFDS")} encType="multipart/form-data" className="mx-2">
            <div class="form-group col-md-12">
              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    onChange={uploadCsvFile}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>

                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
          </form>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary " onClick={closehandleCloseweather}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>



      {/* <CommonBulkUploader typeForm={"Outage"} shortCode={`outage_check_CP_${siteDetail.siteInfo && siteDetail.siteInfo.ndResult? siteDetail.siteInfo.ndResult.siteId : ''}`}/> */}

      {console.log(siteDetail, "siteDetailsiteDetail")}

      <form>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">SiteId</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputEmail4"
              name='siteid'
              readOnly

              // {...register("siteId")}
              value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.ndResult ? siteDetail.siteInfo.ndResult.siteId : '' : ''}
            />
          </div>
          {
                                                    dashboardType=="ixDashboard"?
                                                    <>
                                                      <div className="form-group col-md-4">
                                                    <label for="exampleFormControlSelect1">Pre HW/Layer Configuration</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.techtake ? siteDetail.siteInfo.techtake.join(", ") :siteDetail.siteInfo.preHWLayerConfiguration?siteDetail.siteInfo.preHWLayerConfiguration.values.join(","):"":""}

                                                    // {...register("market")}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                            <label htmlFor="inputEmail4">Post HW/Layer Configuration</label>

                                                            {/* {console.log(multipleTechno, tech, "multipleTechno,tech")} */}
                                                            <MultiSelect
                                                                options={postTechno}
                                                                onChange={ptechChangleHandler}
                                                                value={pTech}
                                                                required
                                                                hasSelectAll={false}
                                                            />
                                                            {/* {(checkErrorRaise() || errore) && tech.length == 0 && (
                                                                <p className="error">Please Select Technology</p>
                                                            )} */}

                                                     
                                                            {/* {(checkErrorRaise() || errore) && tech.length == 0 && (
                                                                <p className="error">Please Select Technology</p>
                                                            )} */}

                                                        </div>
                                                </>:
                                                <>
                                                {/* <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Selected Technology</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.techtake ? siteDetail.siteInfo.techtake.join(", ") : "" : ""}

                                                    // {...register("market")}
                                                    />
                                                </div> */}
                                              
                                                    <div className="form-group col-md-4">
                                                    <label htmlFor="inputPassword4">Technology</label>
                                                    <input style={{ height: "40px" }}
                                                      type="text"
                                                      className="form-control"
                                                      id="inputPassword4"
                                                      name='technology'
                                                      readOnly
                                                      value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.techtake ? siteDetail.siteInfo.techtake.join(", ") : "" : ""}
                                        
                                                    // {...register("tecnology")}
                                                    />
                                                  </div>
                                                
                                                </>
}
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputPassword4">Technology</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.techtake ? siteDetail.siteInfo.techtake.join(", ") : "" : ""}

            // {...register("tecnology")}
            />
          </div> */}



          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>
              Post Check Engineer</label>
            <input style={{ height: "40px" }} className='form-control' disabled name="Precheck Engineer" id="browser"
              value={userDetails.name}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Account</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.ndResult ? siteDetail.siteInfo.ndResult.account : "" : ""}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Market</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              value={siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.ndResult ? siteDetail.siteInfo.ndResult.market : "" : ""}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">GSM Downtime(If Any)</label>
            {console.log(siteDetail.siteInfo.numberStatus >= 8, "siteDetail.siteInfo.numberStatussiteDetail.siteInfo.numberStatus")}
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ?
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postGsmDownTime.split(":")[0]}
                  />
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postGsmDownTime.split(":")[1]}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>
              </div>
              :
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    {...register("postGsmDownTime", {
                      required: "Required HH",
                    })}
                  />
                  {errors.postGsmDownTime ? <p className="error">{errors.postGsmDownTime.message}</p> : ""}
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    {...register("postGsmDownTime1", {
                      required: "Required MM",
                    })}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>

              </div>
            }
          </div>
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">GSM Status</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              {...register("postGsmStatus", {
                required: "Required Gsm Status",
              })}

            />
            {errors.postGsmStatus ? <p className="error">{errors.postGsmStatus.message}</p> : ""}
          </div> */}


          <div className="form-group col-md-4">
            <label htmlFor="inputState">BSC IP Contact</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postBSCIpContact} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm'
                {...register("postBSCIpContact", {
                  required: "BSC IP Contact"
                })}
              >
                {dropDownAlarmObj.bscIPContact}
              </select>
              {errors.postBSCIpContact ? <p className="error">{errors.postBSCIpContact.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">

            <label htmlFor="inputState">GSM Alarm</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postGSMAlarm} />
            </> : <>

              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G IP Contact'
                {...register("postGSMAlarm", {
                  required: "GSM Alarm"
                })}
              >
                {
                  dropDownAlarmObj.GSMAlarms
                }
              </select>
              {errors.postGSMAlarm ? <p className="error">{errors.postGSMAlarm.message}</p> : ""}
            </>}
          </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">UMTS Downtime(If Any)</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ?
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postUmtsDownTime.split(":")[0]}
                  />
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postUmtsDownTime.split(":")[1]}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>
              </div>
              :
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    {...register("postUmtsDownTime", {
                      required: "Required HH",
                    })}
                  />
                  {errors.postGsmDownTime ? <p className="error">{errors.postGsmDownTime.message}</p> : ""}
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    {...register("postUmtsDownTime1", {
                      required: "Required MM",
                    })}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>

              </div>
            }
          </div>
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">GSM Status</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              {...register("postGsmStatus", {
                required: "Required Gsm Status",
              })}

            />
            {errors.postGsmStatus ? <p className="error">{errors.postGsmStatus.message}</p> : ""}
          </div> */}


          <div className="form-group col-md-4">
            <label htmlFor="inputState">UMTS IP Contact</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postUmtsIpContact} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm'
                {...register("postUmtsIpContact", {
                  required: "UMTS IP Contact"
                })}
              >
                {dropDownAlarmObj.umtsIPContact}
              </select>
              {errors.postUmtsIpContact ? <p className="error">{errors.postUmtsIpContact.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">

            <label htmlFor="inputState">UMTS Alarm</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postUMTSlAlarm} />
            </> : <>

              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G IP Contact'
                {...register("postUMTSlAlarm", {
                  required: "UMTS Alarm"
                })}
              >
                {
                  dropDownAlarmObj.umtsAlarms
                }
              </select>
              {errors.postUMTSlAlarm ? <p className="error">{errors.postUMTSlAlarm.message}</p> : ""}
            </>}
          </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">LTE Downtime(If Any)</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ?
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postLteDownTime.split(":")[0]}
                  />
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.postLteDownTime.split(":")[1]}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>
              </div>
              :
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    {...register("postLteDownTime", {
                      required: "Required HH",
                    })}
                  />
                  {errors.postGsmDownTime ? <p className="error">{errors.postLteDownTime.message}</p> : ""}
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    {...register("postLteDownTime1", {
                      required: "Required MM",
                    })}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postLteDownTime1.message}</p> : ""}
                </div>

              </div>
            }
          </div>
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">GSM Status</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              {...register("postGsmStatus", {
                required: "Required Gsm Status",
              })}

            />
            {errors.postGsmStatus ? <p className="error">{errors.postGsmStatus.message}</p> : ""}
          </div> */}


          <div className="form-group col-md-4">
            <label htmlFor="inputState">LTE IP Contact</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postLTEIPContact} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm'
                {...register("postLTEIPContact", {
                  required: "LTE IP Contact"
                })}
              >
                {dropDownAlarmObj.lteIPContact}
              </select>
              {errors.postLTEIPContact ? <p className="error">{errors.postLTEIPContact.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">

            <label htmlFor="inputState">LTE Alarm</label>
            {console.log(ageofdatavalueCheck("03/27/2023 17:00"), ageofdatavalueCheck("03/27/2023 21:00"), ageofdatavalue("03/27/2023 17:00"), siteDetail.siteInfo["RF_Approved_EndTime_Time"], "ageofdata(siteDetail.s")}
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='LTE Alarm' value={siteDetail.siteInfo.postLteAlarm} />
            </> : <>

              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G IP Contact'
                {...register("postLteAlarm", {
                  required: "LTE Alarm"
                })}
              >
                {
                  dropDownAlarmObj.lteAlarm
                }
              </select>
              {errors.postLteAlarm ? <p className="error">{errors.postLteAlarm.message}</p> : ""}
            </>}
          </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">5G Downtime(If Any)</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ?
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.post5GDownTime.split(":")[0]}
                  />
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    disabled
                    value={siteDetail.siteInfo.post5GDownTime.split(":")[1]}
                  />
                  {errors.postGsmDownTime1 ? <p className="error">{errors.postGsmDownTime1.message}</p> : ""}
                </div>
              </div>
              :
              <div className="form-row">
                <div className="col-md-3">

                  <input style={{ height: "40px" }} type="number" placeholder='HH' min={0} max={24} id="appt" name="appt"
                    className="form-control"
                    {...register("post5GDownTime", {
                      required: "Required HH",
                    })}
                  />
                  {errors.post5GDownTime ? <p className="error">{errors.post5GDownTime.message}</p> : ""}
                </div><span className='colontext'>&#58;</span>
                <div className="col-md-3">
                  <input style={{ height: "40px" }} type="number" placeholder='MM' min={0} max={59} id="appt" name="appt"
                    className="form-control"
                    {...register("post5GDownTime1", {
                      required: "Required MM",
                    })}
                  />
                  {errors.post5GDownTime1 ? <p className="error">{errors.post5GDownTime1.message}</p> : ""}
                </div>

              </div>
            }
          </div>
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">GSM Status</label>
            <input style={{ height: "40px" }} type="text" id="appt" name="appt"
              className="form-control"
              {...register("postGsmStatus", {
                required: "Required Gsm Status",
              })}

            />
            {errors.postGsmStatus ? <p className="error">{errors.postGsmStatus.message}</p> : ""}
          </div> */}


          <div className="form-group col-md-4">
            <label htmlFor="inputState">5G IP Contact</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" value={siteDetail.siteInfo.post5GIpContact} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control"
                {...register("post5GIpContact", {
                  required: "5G IP Contact"
                })}
              >
                {dropDownAlarmObj.fiveGIPContact}
              </select>
              {errors.post5GIpContact ? <p className="error">{errors.post5GIpContact.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">

            <label htmlFor="inputState">5G Alarm</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" value={siteDetail.siteInfo.postGSMAlarm} />
            </> : <>

              <select style={{ height: "40px" }} id="inputState" className="form-control"
                {...register("post5GAlarm", {
                  required: "5G Alarm"
                })}
              >
                {
                  dropDownAlarmObj.fiveGAlarms
                }
              </select>
              {errors.post5GAlarm ? <p className="error">{errors.post5GAlarm.message}</p> : ""}
            </>}
          </div>

        </div>

        {/* 
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">UMTS Downtime(If Any)</label>

            <div className="form-row">
              <div className="col-md-3">
                <input style={{ height: "40px" }} placeholder={"HH"} type="number" min={0} max={24} id="appt" name="appt"
                  className="form-control"
                  {...register("postUmtsDownTime", {
                    required: "UMTS Down Time HH"
                  })}
                />
                {errors.postUmtsDownTime ? <p className="error">{errors.postUmtsDownTime.message}</p> : ""}
              </div><span className='colontext'>&#58;</span>
              <div className="col-md-3">
                <input style={{ height: "40px" }} placeholder={"MM"} type="number" min={0} max={59} id="appt" name="appt"
                  className="form-control"
                  {...register("postUmtsDownTime1", {
                    required: "UMTS Down Time MM"
                  })}
                />
                {errors.postUmtsDownTime1 ? <p className="error">{errors.postUmtsDownTime1.message}</p> : ""}
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">UMTS IP Contact</label>
            <select style={{ height: "40px" }} id="appt" name="appt"
              className="form-control"
              {...register("postUmtsIpContact", {
                required: "UMTS Status"
              })}
            >   {
                dropDownAlarmObj.umtsIPContact
              }
            </select>
            {errors.postUmtsStatus ? <p className="error">{errors.postUmtsIpContact.message}</p> : ""}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputState">UMTS Alarm</label>
            <select style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm'
              {...register("postUMTSlAlarm", {
                required: "UMTS Alarm"
              })}
            >   {
                dropDownAlarmObj.umtsAlarms
              }
            </select>
            {errors.postUMTSlAlarm ? <p className="error">{errors.postUMTSlAlarm.message}</p> : ""}
          </div>

        </div> */}
        {/* <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">LTE Downtime(If Any)</label>
            <div className="form-row">
              <div className="col-md-3">
                <input style={{ height: "40px" }} type="number" placeholder={"HH"} min={0} max={23} id="appt" name="appt"
                  className="form-control"
                  step={0}
                  {...register("postLteDownTime", {
                    required: "LTE Down Time HH"
                  })}
                />
                {errors.postLteDownTime ? <p className="error">{errors.postLteDownTime.message}</p> : ""}
              </div><span className='colontext'>&#58;</span>
              <div className="col-md-3">
                <input style={{ height: "40px" }} type="number" placeholder={"MM"} min={0} max={59} id="appt" name="appt"
                  className="form-control"
                  {...register("postLteDownTime1", {
                    required: "LTE Down Time MM"
                  })}
                />
                {errors.postLteDownTime1 ? <p className="error">{errors.postLteDownTime1.message}</p> : ""}
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">LTE IP Contact</label>
            <select style={{ height: "40px" }} id="appt" name="appt"
              className="form-control"
              {...register("postLTEIPContact", {
                required: "LTE Status"
              })}
            >   {
                dropDownAlarmObj.lteIPContact
              }
            </select>
            {errors.postLteStatus ? <p className="error">{errors.postLTEIPContact.message}</p> : ""}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">LTE Alarm
            </label>
            <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE Alarm'
              {...register("postLteAlarm", {
                required: "LTE Alarm"
              })}
            >   {
                dropDownAlarmObj.lteAlarm
              }
            </select>

            {errors.postLteAlarm ? <p className="error">{errors.postLteAlarm.message}</p> : ""}
          </div>
        </div> */}
        {/* <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">5G Downtime(If Any)</label>
            <div className="form-row">
              <div className="col-md-3">
                <input style={{ height: "40px" }} type="number" placeholder={"HH"} min={0} max={23} id="appt" name="appt"
                  className="form-control"
                  {...register("post5GDownTime", {
                    required: "Required HH"
                  })}
                />
                {errors.post5GDownTime ? <p className="error">{errors.post5GDownTime.message}</p> : ""}
              </div><span className='colontext'>&#58;</span>
              <div className="col-md-3">
                <input style={{ height: "40px" }} type="number" placeholder={"MM"} min={0} max={59} id="appt" name="appt"
                  className="form-control"
                  {...register("post5GDownTime1", {
                    required: "Required MM"
                  })}
                />
                {errors.post5GDownTime1 ? <p className="error">{errors.post5GDownTime1.message}</p> : ""}
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">5G IP Contact</label>
            <select style={{ height: "40px" }} id="appt" name="appt"
              className="form-control"
              {...register("post5GIPContact", {
                required: "5G IP Contact"
              })}
            >
              {
                dropDownAlarmObj.fiveGIPContact
              }
            </select>
            {errors.post5GStatus ? <p className="error">{errors.post5GIPContact.message}</p> : ""}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">5G Alarm</label>
            <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
              {...register("post5GAlarm", {
                required: "5G Alarm"
              })}
            >   {
                dropDownAlarmObj.fiveGAlarms
              }
            </select>
            {errors.post5GAlarm ? <p className="error">{errors.post5GAlarm.message}</p> : ""}
          </div>
        </div> */}

        <div className="form-row">

          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Activity Status(Site Level)</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postActivityStatusSiteLevel} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("postActivityStatusSiteLevel", {
                  required: "Activity Status(Site Level)",
                  onChange: ((e) => { setsiteactiveCancel(e.target.value == "Cancelled" || e.target.value == "Rollback" ? true : false) })
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"Completed With No Alarm"}>Completed With No Alarm</option>
                <option value={"Completed With NSA Alarm"}>Completed With NSA Alarm</option>
                <option value={"Completed With SA Alarm"}>Completed With SA Alarm</option>
                <option value={"Not Completed"}>Not Completed</option>
                <option value={"No Show"}>No Show</option>
                <option value={"Cancelled"}>Cancelled</option>
                <option value={"Rollback"}>Rollback</option>
              </select>
              {errors.postActivityStatusSiteLevel ? <p className="error">{errors.postActivityStatusSiteLevel.message}</p> : ""}
            </>}
          </div>

          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">Activity Status(Site Level)</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postActivityStatusSiteLevel} />
            </> : <>

              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("postActivityStatusSiteLevel", {
                  required: "Activity Status(Site Level)"
                })}
              >

                <option value="" selected disabled>Select</option>
                <option value="Completed With No Alarm">Completed With No Alarm</option>
                <option value="Completed With NSA Alarm">Completed With NSA Alarm</option>
                <option value="Completed With SA Alarm">Completed With SA Alarm</option>
                <option value="Partially Completed">Partially Completed</option>
                <option value="Not Completed">Not Completed</option>
                <option value="No Show">No Show</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Rollback">Rollback</option>

              </select>

              {errors.postActivityStatusSiteLevel ? <p className="error">{errors.postActivityStatusSiteLevel.message}</p> : ""}

            </>}

          </div> */}
          {/* <div className="form-group col-md-4">
            <label htmlFor="inputCity">Activity Status(Technology Level)</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postActivityStatusTechnologyLevel} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("postActivityStatusTechnologyLevel", {
                  required: "Activity Status(Technology Level)",
                  onChange: ((e)=>{settechactiveCancel(e.target.value=="Cancelled" || e.target.value=="Rollback"?true:false)})
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"Completed With No Alarm"}>Completed With No Alarm</option>
                <option value={"Completed With NSA Alarm"}>Completed With NSA Alarm</option>
                <option value={"Completed With SA Alarm"}>Completed With SA Alarm</option>
                <option value={"Not Completed"}>Not Completed</option>
                <option value={"No Show"}>No Show</option>
                <option value={"Cancelled"}>Cancelled</option>
                <option value={"Rollback"}>Rollback</option>
              </select>
              {errors.postActivityStatusTechnologyLevel ? <p className="error">{errors.postActivityStatusTechnologyLevel.message}</p> : ""}
            </>}
          </div> */}
          {siteactiveCancel ?
            <div className="form-group col-md-4">
              <label htmlFor="inputCity">Cancelled/Rollback Reason</label>
              {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
                <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postsitecancelrollbackreason} />
              </> : <>
                <textarea style={{ height: "40px" }} type="text" className="form-control" id="inputCity" name='Precheck Alarm Issue'
                  {...register("postsitecancelrollbackreason", {
                    required: siteactiveCancel ? true : false
                  })}
                />
                {errors.postsitecancelrollbackreason ? <p className="error">Cancelled/Rollback Reason</p> : ""}
              </>}
            </div> : ""}
          {/* {techactiveCancel?
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Cancelled/Rollback Reason</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postCheckAlarmIssue} />
            </> : <>
              <textarea style={{ height: "40px" }} type="text" className="form-control" id="inputCity" name='Precheck Alarm Issue'
                {...register("posttechcancelrollbackreason", {
                  required: techactiveCancel?true:false
                })}
              />
              {errors.postCheckAlarmIssue ? <p className="error">Cancelled/Rollback Reason</p> : ""}
            </>}
          </div>:""} */}


          <div className="form-group col-md-4">
            <label htmlFor="inputCity">RET Validation</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.RETValidation} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("RETValidation", {
                  required: "RET Validation"
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"OK"}>OK</option>
                <option value={"NOK"}>NOK</option>
                <option value={"NA"}>NA</option>
              </select>
              {errors.RETValidation ? <p className="error">{errors.RETValidation.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Cell Validation</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.RETValidation} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("CellValidation", {
                  required: "Cell Validation"
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"OK"}>OK</option>
                <option value={"NOK"}>NOK</option>
                <option value={"NA"}>NA</option>
              </select>
              {errors.CellValidation ? <p className="error">{errors.CellValidation.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">SFP Validation</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.RETValidation} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("SFPValidation", {
                  required: "SFP Validation"
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"OK"}>OK</option>
                <option value={"NOK"}>NOK</option>
                <option value={"NA"}>NA</option>
              </select>
              {errors.SFPValidation ? <p className="error">{errors.SFPValidation.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">RTWP Validation</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.RETValidation} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("RTWPValidation", {
                  required: "RTWP Validation"
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"OK"}>OK</option>
                <option value={"NOK"}>NOK</option>
                <option value={"NA"}>NA</option>
              </select>
              {errors.RTWPValidation ? <p className="error">{errors.RTWPValidation.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">VSWR Validation</label>

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.RETValidation} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='5G Alarm'
                {...register("VSWRValidation", {
                  required: "VSWR Validation"
                })}
              >
                <option selected disabled value="">Select</option>
                <option value={"OK"}>OK</option>
                <option value={"NOK"}>NOK</option>
                <option value={"NA"}>NA</option>
              </select>
              {errors.VSWRValidation ? <p className="error">{errors.VSWRValidation.message}</p> : ""}
            </>}
          </div>
          
          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Postcheck Alarm Issue</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postCheckAlarmIssue} />
            </> : <>
              <textarea style={{ height: "40px" }} type="text" className="form-control" id="inputCity" name='Precheck Alarm Issue'
                {...register("postCheckAlarmIssue", {
                  required: "Check Alarm Issue"
                })}
              />
              {errors.postCheckAlarmIssue ? <p className="error">{errors.postCheckAlarmIssue.message}</p> : ""}
            </>}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputState">Was TS or Scripting was Required</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.wasTSOrScriptingWasRequired} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='GSM Alarm'
                {...register("wasTSOrScriptingWasRequired", {
                  required: "Was TS Or Scripting Was Required",
                  onChange: (e) => {
                    findengineer("")
                    setError(e.target.value=="Yes"?true:false)
                    
                    console.log(e.target.value,eroor, "e.target.value")
                    settsscript(e.target.value)
                  }
                })}

              >
                <option selected disabled value="">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
              {errors.wasTSOrScriptingWasRequired ? <p className="error">{errors.wasTSOrScriptingWasRequired.message}</p> : ""}
            </>}
          </div>

          {tsscript == "Yes" || siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 && siteDetail.siteInfo.wasTSOrScriptingWasRequired ?
            <div className="form-group col-md-4">
              <label for="exampleFormControlSelect1">
                {" "}
                Engineer responisble for TS or Scripting
              </label>

              {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
                <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.tsscripting} />
              </> : <>
                <input style={{ height: "32px" }}
                  type="text"
                  {...register("tsscripting", {
                    required: "Please select Engineer responisble for TS or Scripting"
                  })}
                  onBlur={(e) => { findengineer(e.target.value) }}
                  list="engOpen"
                  className="form-control"
                  ID="inputAddress"
                  autoComplete={"off"}
                  defaultValue={EngineerOpen}
                  placeholder="Select Engineer"
                />

                <datalist id="engOpen">
                  {siteDetail.engineerArray}
                </datalist>


                {eroor && (
                  <p className="error">Please select Engineer responisble for TS or Scripting</p>
                )}
              </>}
            </div> : ""}

          <div className="form-group col-md-4">
            <label htmlFor="inputState">TT Open</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.ttOpen} />
            </> : <>
              <select style={{ height: "40px" }} id="inputState" className="form-control" name='GSM Alarm'
                {...register("ttOpen", {
                  required: "TT Open"
                })}
                disabled
              >
                <option selected disabled value="">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
              {errors.ttOpen ? <p className="error">{errors.ttOpen.message}</p> : ""}

            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">TT Id(s)</label>
            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.ttId} />
            </> : <>
              <input style={{ height: "40px" }}
                type="text"
                className="form-control"
                id="inputPassword4"
                name='Schedule Id'
                readOnly
                {...register("ttId", {
                })}
              />
              {errors.ttId ? <p className="error">{errors.ttId.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">Site UP Time</label>
            {/* <select style={{ height: "40px" }} id="inputState" className="form-control" name='GSM Alarm'
              {...register("postSiteUpTime", {
                required: "Post Site Up Time"
              })}
            >
              <option selected disabled value="">select</option>
              <option>yes</option>
              <option>No</option>
            </select> */}

            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postSiteUpTime} />
            </> : <>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <DatePicker
                    className="form-control"
                    selected={fenddate}
                    disabled={checkNadate}
                    onChange={(e) => {
                      setfenddate(e);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeCaption="Time"
                    placeholderText={checkNadate ? "NA" : "MM/DD/YYYY HH:MM"}
                    dateFormat="MM/dd/yyyy hh:mm aa"
                    minDate={new Date()}
                  />
                </div>
                <div className="form-group col-md-1">
                  <input type='checkbox' checked={checkNadate} onClick={(e) => { setfenddate(); setcheckenddate(e.target.checked) }} />
                </div>
                <div className="form-group col-md-3">
                  <label>Set As NA</label>
                </div>

              </div>







              {errors.postSiteUpTime ? <p className="error">{errors.postSiteUpTime.message}</p> : ""}
            </>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">Activity went out of approved window?</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.activityWentOutOfApprovedWindow} />
            </> : <>

              <input style={{ height: "40px" }}
                type="text"
                className="form-control"
                id="inputPassword4"
                name='Schedule Id'
                readOnly
                {...register("activityWentOutOfApprovedWindow")}
              />
              {/* <select style={{ height: "40px" }} id="inputState" className="form-control" name='GSM Alarm' readOnly
                {...register("activityWentOutOfApprovedWindow", {
                  required: "Activity Went Out Of Approved Window"
                })}
              >
                <option selected disabled value="">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select> */}
              {errors.activityWentOutOfApprovedWindow ? <p className="error">{errors.activityWentOutOfApprovedWindow.message}</p> : ""}

            </>}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputState">Time after approved window</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.timeAfterApprovedWindow} />
            </> : <>
              <input style={{ height: "40px" }}
                type="text"
                className="form-control"
                id="inputPassword4"
                name='Schedule Id'
                readOnly
                {...register("timeAfterApprovedWindow")}
              />
              {errors.timeAfterApprovedWindow ? <p className="error">{errors.timeAfterApprovedWindow.message}</p> : ""}
            </>}
          </div>



          {
            PdfWebUrl != "" ?
              <>
                <div class="form-group col-md-2">
                  {siteInfo && siteInfo.numberStatus == 7
                    ?
                    <div>

                      Upload File:
                      <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                        <input
                          type="file"
                          className="form-control btn btn-primary "
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          aria-label="Upload"
                          name="file"
                          onChange={attachFile}
                        //   {...register("logInTimePreCheckDelivered")}
                        />
                        <i class="fa fa-cloud-upload"></i> Choose File
                      </label>
                      <label>{uploadedattachFile.length == 0 ? "No Files Selected" : "1 files selected"}</label>
                    </div>
                    : ""}
                </div>
              </>
              : ""
          }

          <div className="form-group col-md-8">
            <label htmlFor="inputState">Post Activity Notes</label>


            {siteDetail && siteDetail.siteInfo && siteDetail.siteInfo.numberStatus >= 8 ? <>
              <input style={{ height: "40px" }} id="inputState" className="form-control" name='UMTS Alarm' value={siteDetail.siteInfo.postActivityNotes} />
            </> : <>
              <textarea style={{ height: "40px" }}
                type="text"
                className="form-control"
                id="inputPassword4"
                name='Schedule Id'
                {...register("postActivityNotes")}
              /></>}
          </div>
        </div>

        {activeUrl == "postCheck" && siteInfo && siteInfo.numberStatus == 7 ?
          <div className='text-center mt-3'>
            <button type="button" onClick={handleSubmit(onSubmitForm)} className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
            <button type="button" onClick={() => {
              Precheckoperner()
            }} className="btn btn-primary btn-xs  mx-1">
              Postcheck Tool
            </button>
            {/* <CommonBulkUploader typeForm={"Outage"} shortCode={`outage_check_CP_DA04467S`} btnname={"Calculate Down Time"} classes={"btn btn-primary btn-xs  mx-1"} /> */}
            <CommonBulkUploader typeForm={"Outage"} shortCode={`outage_check_CP_${siteDetail ? siteDetail.siteInfo && siteDetail.siteInfo.ndResult ? siteDetail.siteInfo.ndResult.siteId : '' : ''}`} btnname={"Calculate Down Time"} classes={"btn btn-primary btn-xs  mx-1"} />

            <button type="button" onClick={() => topageraiseTT(siteDetail, "raiseTT")} className="btn btn-primary btn-xs  mx-1">
              Raise Trouble Ticket
            </button>
            <button type="button" onClick={() => topage("back")} className="btn btn-primary btn-xs  mx-1">
              Back
            </button>



          </div>
          : ""
        }
      </form>


      <CommonModal
        modalSize={"md"}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
    </>

  )
}

export default PostCheck