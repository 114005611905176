import React, { useEffect, useState, ComponentType, type, useRef } from "react";
import '../../../css/muiDatatable.css'
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";
import DatePicker from "react-datepicker";
import { Row, Col, Card, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import swal from "sweetalert";
import moment from "moment";
import CommonModal from "../Custom/CommonModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  google_map_api,
  center,
  dbdatetodate,
  customDataTableOptionObject,
  ageofdatabytwo,
  ageofdata,
  ageofdatalistnext,
  getMuiThemeCommon,
  moment_common_full_date_format,
  dbdatetomoment,
  getdatedatalistnext,
  getProgressBar,
  wrapText,
} from "../../../common/variables";
import {
  AccountReducerAction,
  addUserAction,
  assigningEngineerAction,
  clearFetchSingleRecordAction,
  clearGetSingleRanProject,
  clearIxDashboard,
  clearIxSingleSite,
  ClearProductCode,
  clearProjectId,
  deleteAcccountReducerAction,
  FetchSingleRecordAction,
  GetennoProject,
  getFilterDropDown,
  GetTimeLineAction,
  LCLixDashboardReducerAction,
  popUpUpdate,
  ResetLCLixDashboardfilter,
} from "../../../store/actions/createTicketManageFields";
import {
  approveOrDisapprove,
  getAllDropDown,
  listSiteId,
  ranDashboard,
  singleProjectApi,
  timelineApi,
  reqPost,
  reqPreCheckCancel,
  ranDashboardTimeLine,
  reqPostCheckCancel,
  nestingOurApi,
  ranDeleteData,
  changeButtonStatus,
  ixDasboard,
  ixModule,
  ixValidation,
  ixSiteStatus,
  ixDeleteData,
  commonfilters,
} from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import NestingTimeComponent from "../Custom/Modal Body/NestingTimeComponent";
import AssignedEngineer from "../Custom/Modal Body/AssignedEngineer";

import Add from "../../../icons/Svg/Add";
import RanMapView from "../Custom/Modal Body/RanMapView";
import ManageProjectDetail from "../Custom/Modal Body/ManageProjectDetail";
import { colorCodeObj, colors, RF_Approval_color_code } from "../../../common/common_color_code";
import { SortOrder } from "react-data-table-component";
import ChatBox from "../../layouts/ChatBox";
import { white } from "material-ui/styles/colors";
import CommonBulkUploaderModal from "../Custom/CommonBulkUploaderModal";
import RiotModalBody from "../Custom/Modal Body/RiotModalBody";
import LockUnlockModalBody from "../Custom/Modal Body/LockUnlockModalBody";
import { useForm } from "react-hook-form";
import IxFrom from "./IXFrom"
import FilterData from "../../../icons/Svg/FilterData";
import IXFilter from "../Custom/Modal Body/IXFilter";
const IXDashboard = () => {
  const { register, setValue, handleSubmit, setError, formState: { errors } , reset} = useForm({
    criteriaMode: 'all',

  });
  const preValSiteUId=useRef("")

  const [preVal, setPreVal] = useState(false)
  const [exportApiData, setexportApiData] = useState("");
  const [ResetFilter, setResetFilter] = useState(false);
  const [OneTimere, setOneTimere] = useState(false);
  
  const [postVal, setPostVal] = useState(false)
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [nestingTiime, setNestingTime] = useState(false);
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false);
  const [reqPreModal, setReqPreModal] = useState(false);
  const [siteDetail, setSiteDetail] = useState({});
  const [siteUId, setSiteId] = useState();
  const [SiteNId, setSiteNId] = useState();
  const history = useHistory();
  const [CommonBulkUploaderModalState, setCommonBulkUploaderModalState] = useState(false);

  const [CommonBulkUploaderModalName, setCommonBulkUploaderModalName] = useState("");
  const [CommonBulkUploaderModalapiurlState, setCommonBulkUploaderModalapiUrlState] = useState("");
  const [CommonBulkUploaderModaldataurlState, setCommonBulkUploaderModaldataState] = useState("");
  const [showDatePicker, setDatePicker] = useState(false);
  const [closer, setCloser] = useState(false);
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [fstartdate, setfstartdate] = useState();
  const [popissue, setpopissue] = useState(false);
  const [fenddate, setfenddate] = useState();
  const [filteredData, setfilteredData] = useState([])
  const [filliopen, setfilliopen] = useState(false)
  const [toggle, setToggle] = useState("");
  const [openMsg, setOpenMsg] = useState(false)
  
  // const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [fullUrL, setfullUrL] = useState("");
  const [commonModalBodyFilter, setcommonModalBodyFilter] = useState("");
  const [commonModalStateFilter, setcommonModalStateFilter] = useState(false);
  const [commonModalHeaderFilter, setcommonModalHeaderFilter] = useState("");
  const [commonModalSizeFilter, setcommonModalSizeFilter] = useState(false);
  const [commonModalTitleFilter, setcommonModalTitleFilter] = useState("");


  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const url = window.location.href.split("/")
  let dashboardType=url.slice(-1)[0]
  useEffect(() => {
    if(true){
      reset()
    }
  } , [preVal, postVal])

  const handleSubmitPre = (data) => {
    console.warn("the pre vailidation data",data)
    data["preValidation"]=1
    dispatch(addUserAction(ixValidation+preValSiteUId.current,data))    
    setCloser(true);
    dispatch(popUpUpdate({}));


  }
  const handleSubmitPost = (data) => {
    console.warn(data)
    data["postValidation"]=1
    dispatch(addUserAction(ixValidation+preValSiteUId.current,data))
    setCloser(true);
    dispatch(popUpUpdate({}));
  }
  const setPreValidation = (id) => {
    preValSiteUId.current=id
    setPreVal(prev => !prev)
  }

  const setPostValidation = (id) => {
    preValSiteUId.current=id
    setPostVal(prev => !prev)
  }

  const CommonBulkUploaderModalFunction = (path, heading, uniqueId) => {
    setCommonBulkUploaderModalState(true)
    setCommonBulkUploaderModalName(heading)
    setCommonBulkUploaderModaldataState(path + "__" + uniqueId)

    dispatch(popUpUpdate({}))

  }

  const onNote = (engineer, siteId, ranSupportId) => {
    onClick("chatbox")
    // console.log("the set data =",{assignedEngineer:engineer,siteId})
    dispatch(assigningEngineerAction({ assignedEngineer: engineer, siteId, ranSupportId }))
  }

  console.log(history, "history");

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const [uniqueId, setUniqueId] = useState();
  const [nestDetail, setnestDetail] = useState({
    ranId: "",
    siteId: "",
    preReq: "",
    nestHour: "",
    lastNestTime: "",
  });
  const dispatch = useDispatch();
  let getMuiTheme = () =>
    createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "line-height": "1 !important",
              padding: "6px 0px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // maxHeight: 45,
              // fontSize: 13,
              // overflowY: "auto",
              // padding: "0px 2px !important",
              overflow: "scroll",
              // margin: "auto"

              maxHeight: 45,
              fontSize: 13,
              overflowY: "auto",
              overflowX: "auto",
              padding: "3px",
              margin: "auto",
              textAlign: "center",
              width: "max-content",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: '#f00',

        //     },
        //   },
        // },
        MuiTableCell: {
          styleOverrides: {
            head: {
              maxHeight: 20,
              fontSize: 15,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              "& .MuiToolbar-root": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    });


  const owneroperner = (type, name, email, vendor, company, mobile) => {
    return (
      <>
        <div >
          <table
            style={{ height: "auto", "max-height": "375px" }}
            className="table "
          >
            <tbody>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Type
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {type}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Name
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {name}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Email
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {email}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Contact No
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {mobile}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Vendor
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {vendor}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                  }}
                >
                  GC Company
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {company}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
  const reqPostCheck = (e) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("the will dele=", willDelete);

        dispatch(popUpUpdate({}));
      
        dispatch(AccountReducerAction(reqPost + e+"?ix=True"));
        dispatch(AccountReducerAction(ixModule));
        swal("Post Check Requsted", {
          icon: "success",
        });

        setCloser(true);
      }
    });
  };
  const setDateFilter = (e) => {
    e.preventDefault();

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
    if (fstartdate > fenddate) {
      swal("Oops", "End Date is greater than Start Date", "info");
      return;
    }
    // dateRange[0].startDate = formatDate(dateRange[0].startDate);
    // dateRange[0].endDate = formatDate(dateRange[0].endDate);
    // console.log("the filtered data=", dateRange);
    let start = moment(fstartdate).format("MM-DD-YYYY")
    let end = moment(fenddate).format("MM-DD-YYYY")
    //     formatDate(fstartdate);
    // let end = formatDate(fenddate);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");
    // if (currentUrl.includes("?")) {
    //   dispatch(
    //     AccountReducerAction(
    //       ranDashboard +
    //       "?" +
    //       urlArray.pop() +
    //       "&start=" +
    //       start +
    //       "&end=" +
    //       end
    //     )
    //   );
    // } else {
    // dispatch(
    //   AccountReducerAction(ixModule + "?start=" + start + "&end=" + end)
    // );
    // }

    setResetFilter(true)

    setexportApiData(ixModule + "?start=" + moment(fstartdate).format("MM/DD/YYYY") + "&end=" + moment(fenddate).format("MM/DD/YYYY"))
    
    let Filleddata=dispatch(LCLixDashboardReducerAction({dateUrl:`start=${start}&end=${end}`}))
    console.log(Filleddata,"FilleddataFilleddata")
    dispatch(AccountReducerAction(ixModule + Filleddata,true));
    


  };
  const ApprovalHandler = (item) => {
    console.log("the item ApprovalHandler is =.", item);
    dispatch(
      addUserAction(approveOrDisapprove + item.ranSupportId, {
        status: "Approve",
      })
    );
  };

  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    dispatch(popUpUpdate({}));
    setCloser(true);
    setpopissue(true);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };
  const cancelPreCheckReq = (id) => {

    swal({
      title: "Alert",
      text: "Are you sure to cancel request?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((whatClick) => {
      if (whatClick) {
        
        dispatch(AccountReducerAction(reqPreCheckCancel + id+"?ix=True"));
      }
    })

  };

  const cancelPostCheckReq = (id) => {

    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((truecond) => {
      if (truecond) {
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(reqPostCheckCancel + id));
        setCloser(true)
      }
    });
  };

  const startcalltest = (dtSiteId, dtprojectUniqueId, id) => {


    dispatch(GetennoProject([]))
    dispatch(clearFetchSingleRecordAction())
    dispatch(clearIxSingleSite())

    

    // dispatch(AccountReducerAction(reqPostCheckCancel + id))
    let data = {
      siteUniqueId:   dtSiteId,
      ranId: id,
      createFrom: "Ran Support",
      creatorId: id,
      projectUniqueId: dtprojectUniqueId,
      index: 0,
    };
    console.log(",mbvskcjsd bcljvfdvef=-",id)
    dispatch(FetchSingleRecordAction(ixModule, id));
    history.push({
      pathname: "/PAG/E911CallTest/form/ixCallTest/" + id,
      creator: data,
    });
  };
  const rejectHandler = (item) => {
    dispatch(
      addUserAction(approveOrDisapprove + item.ranSupportId, {
        status: "Not Approve",
      })
    );
  };

  // let newObject={}

  let columns = [
    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    {
      name: "ranStatusLinkClone",
      label: "Site Status",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            display: "none",

            whiteSpace: "wrap",
            padding: "1px 1px",
            // alignItem: "center",
            position: "sticky",
            zIndex: 101,
            // justifyContent: "center",
            background: "#143b64",
            color: "white",
            left: "202px",
            "min-width": "150px",
            "max-width": "150px",
            // width:"125px"
          },
        }),
        setCellProps: () => ({
          style: {
            outline: "1.5px solid black",
            whiteSpace: "wrap",
            display: "none",
            overflow: "hidden",
            minWidth: "150px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },

    {
      name: "ranStatusLink",
      label: "Site Status",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            padding: "1px 1px",

            // alignItem: "center",
            position: "sticky",
            zIndex: 101,
            // justifyContent: "center",
            background: "#143b64",
            color: "white",
            left: "206px",
            "min-width": "236px",
            "max-width": "236px",
            outline: "1.5px solid black"
            // width:"125px"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            overflow: "hidden",
            minWidth: "236px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "206px",
          },
        }),
      },
    },

    {
      name: "serviceAffected",
      label: "Service Affected",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",

            // overflowX: "hidden",
            background: "#143b64",
            padding: "1px 1px",
            alignItem: "center",
            position: "sticky",
            left: "440px",
            zIndex: 101,
            color: "white",

          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            textAlign: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "440px",
          },
        }),
      },
    },
    // {
    //   name: "RF_ApproinvgClone",
    //   label: "Outage Approval",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // overflowX: "hidden",

    //         background: "#143b64",
    //         padding: "1px 1px",
    //         alignItem: "center",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 101,
    //         color: "white",
    //         left: "600px",
    //         "min-width": "100px",
    //         "max-width": "120px"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //         position: "sticky",
    //         zIndex: 101,
    //         left: "600px",
    //       },
    //     }),
    //   },
    // },
    {
      name: "RF_Approinvg",
      label: "Outage Approval",
      sortable: true,
      options: {
        filter: false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            outline: "1.5px solid black",
            // overflowX: "hidden",
            background: "#143b64",
            padding: "1px 1px",

            position: "sticky",
            background: "White",
            zIndex: 101,
            color: "white",
            left: "513px",
            "min-width": "100px",
            "max-width": "120px"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            outline: "1.5px solid black",
            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
            position: "sticky",
            zIndex: 99,
            left: "513px",
          },
        }),
      },
    },
    {
      label: "Site Id",
      name: "siteIdClone",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: "sticky",
            outline: "1.5px solid black",
            display: "none",

            left: "545px",
            zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white", "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            position: "sticky",
            outline: "1.5px solid black",
            display: "none",
            left: "545px",
            zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Site Id",
      name: "siteIdRow",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: "sticky",
            outline: "1.5px solid black",
            left: "611px",
            zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            position: "sticky",

            outline: "1.5px solid black",
            left: "611px",
            zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Chat",
      name: "chatting",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: "sticky",
            outline: "1.5px solid black",
            left: "701px",
            zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            position: "sticky",

            outline: "1.5px solid black",
            left: "701px",
            zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Pre RIOT",
      name: "preRiot",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline: "1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline: "1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      name: "preValidationbtn",
      label: "Pre Validation",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      label: "FE Login",
      name: "feLogin",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "FE Login Time",
      name: "feLoginTime",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Integration Login",
      name: "integrationLogin",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Integration Login Time",
      name: "integrationLoginTime",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Assigned Engineer",
      name: "assigningEngineerClone",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            display: "none",
            outline: "1.5px solid black",
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            "min-width": "160px",
            "max-width": "180px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            display: "none",
          }
        })
      },
    },
    {
      label: "Integration Engineer",
      name: "assigningEngineer",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            outline: "1.5px solid black",
            background: "#143b64",
            "min-width": "160px",
            "max-width": "180px",
            color: "white",
          },
        }),
      },
    },
    // {
    //   label: "Outage Approved MW Time",
    //   name: "TMOOutageApprovedMWTime",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         background: "#143b64",
    //         color: "white", "min-width": "150px",
    //         outline: "1.5px solid black",
    //         "max-width": "160px"
    //       },
    //     }),
    //   },
    // },
    {
      name: "nestingTime",
      label: "Nesting Time",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            textAlign: "center",
            overflow: "hidden",
            minWidth: "2px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "progress",
      label: "Progress",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            outline: "1.5px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "requestForm",
      label: "Schdule Source",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            padding: "1px 1px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            textAlign: "center",
            overflow: "hidden",
            minWidth: "2px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "projectCode",
      label: "Project Code",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            outline: "1.5px solid black",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "pprogramnamerogramname",
      label: "Program Name",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            outline: "1.5px solid black",
            background: "#143b64",
            color: "white",
            minWidth: "180px",
            width: "180px",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "180px",
            width: "180px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      
      name: "preTech",
      label: "Pre HW/Layer Configuration",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            overflow: "auto",
            background: "#143b64",
            margin: "auto",
            outline: "1.5px solid black",
            "min-width": "350px",
            "max-width": "400px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "auto",
            minWidth: "200px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "postTech",
      label: "Post HW/Layer Configuration",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            overflow: "auto",
            background: "#143b64",
            margin: "auto",
            outline: "1.5px solid black",
            "min-width": "350px",
            "max-width": "400px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "auto",
            minWidth: "200px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "crew",
      label: "Crew",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            alignItem: "center",
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },

    // {
    //   name: "gcInfo",
    //   label: "Gc Information",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         background: "#143b64",
    //         outline:"1.5px solid black",
    //         color: "white",
    //         minWidth: "50px",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         textAlign: "center",
    //         minWidth: "50px",
    //       },
    //     }),
    //   },
    // },
    {
      name: "gcType",
      label: "Gc Type",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "35px",
          },
        }),
        setCellProps: () => ({
          style: {
            textAlign: "center",
            minWidth: "35px",
          },
        }),
      },
    },
    {
      name: "gcCompany",
      label: "GC Company",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "GCVendor",
      label: "GC Vendor",
      sortable: true,
      options: {
        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            outline: "1.5px solid black",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",
            outline: "1.5px solid black",

            background: "White",
          },
        }),
      },
    },
    {
      name: "GCName",
      label: "GC Name",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",

            outline: "1.5px solid black",
            "min-width": "100px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",



            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "GCEmail",
      label: "GC Email",
      customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            color: "white",
            "min-width": "160px",
            outline: "1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            outline: "1.5px solid black",



            background: "White",
          },
        }),
      },
    },
    {
      name: "GCContact",
      label: "GC Contact",
      sortable: true,
      options: {
        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "ttIds",
      label: "Mpulse TT_Id",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
            minWidth: "250px",
            outline: "1.5px solid black",
            borderRight: "2px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "pierttIds",
      label: "PIERTT Id",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth: "250px",
            outline: "1.5px solid black",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline: "1.5px solid black",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "crNo",
      label: "Cr Number",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            color: "white",
            outline: "1.5px solid black",
            "min-width": "100px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",
            outline: "1.5px solid black",

            background: "White",
          },
        }),
      },
    },
    {
      name: "crStatus",
      label: "Cr Status",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            padding: "1px 1px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline: "1.5px solid black",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "pSow",
      label: "Planned SOW",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "200px",
            "max-width": "240px",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "dayNightMop",
      label: "Day/Night MOP",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            outline: "1.5px solid black",
            alignItem: "center",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "dayMopReasonModal",
      label: "Day MOP Reason",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            outline: "1.5px solid black",
          },
        }),
      },
    },
    {
      name: "mPrecheckReq",
      label: "Manual PreCheck Request",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            outline: "1.5px solid black",
            minWidth: "140px",
            color: "white",
          },
        }),
      },
    },
    {
      name: "LTPCR",
      label: "Login Time PreCheck Request(mobile)",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",

            color: "white",
          },
        }),
      },
    },
    {
      name: "preCheck",
      label: "Pre-Checks",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            color: "white",
            outline: "1.5px solid black",
            minWidth: "130px",
          },
        }),
      },
    },
    {
      name: "LTPCD",
      label: "Login Time Precheck Delivered",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "RIOT",
      label: "RIOT",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "CTA",
      label: "Call Test Activity",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "speedTest",
      label: "Speed Test",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            // outline: "1.5px solid black",
            color: "white",
            // minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "no-wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            // outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "CBN",
      label: "CBN Validation",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    // {
    //   name: "LURequest",
    //   label: "Lock/Unlock Requst",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         background: "#143b64",
    //         outline: "1.5px solid black",
    //         color: "white",
    //         minWidth: "10px",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "10px",
    //         padding: "1px 1px",
    //         outline: "1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    // {
    //   name: "NestExtend",
    //   label: "Nest Extend",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         background: "#143b64",
    //         outline:"1.5px solid black",
    //         color: "white",
    //         minWidth: "10px",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "10px",
    //         padding: "1px 1px",
    //         outline:"1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    {
      name: "AdHOC",
      label: "AdHOC Request",
      sortable: true,
      options: {

        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    // {
    //   name: "ODR",
    //   label: "On Demand Requst",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         background: "#143b64",
    //         outline:"1.5px solid black",
    //         color: "white",
    //         minWidth: "10px",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "10px",
    //         padding: "1px 1px",
    //         outline:"1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    {
      name: "RSSI",
      label: "RSSI Validation",
      sortable: true,
      options: {

        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            minWidth: "10px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "10px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      label: "Post Validation",
      name: "postValidationbtn",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline: "1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "120px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline: "1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      name: "postCh",
      label: "Manual Postcheck Request",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            outline: "1.5px solid black",
            minWidth: "200px",
            color: "white",
          },
        }),
      },
    },
    // {
    //   name: "equipment",
    //   label: "Equipment",
    //   sortable: true,
    //   options: {
    //     display:false,
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         outline: "1.5px solid black",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //   },
    // },


    // {
    //   name: "rfMWTime",
    //   label: "Rf Approval MW Time",
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         minWidth: "110px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //   },
    // },
    {
      name: "LTPOR",
      label: "Logout Time Postcheck Request",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            "min-width": "190px",
            outline: "1.5px solid black",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "postChecks",
      label: "Post Checks",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "LTPOD",
      label: "Logout Time Postcheck Delivered",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            outline: "1.5px solid black",
            background: "#143b64",
            padding: "1px 1px",
            minWidth: "160px",
            color: "white",
          },
        }),
      },
    },
    {
      label: "FE Logout",
      name: "feLogout",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "FE Logout Time",
      name: "feLogoutTime",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Integration Logout",
      name: "integrationLogout",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      label: "Integration Logout Time",
      name: "integrationLogoutTime",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            // position: "sticky",
            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 101,
            // background: "white",

            alignItem: "center",
            background: "#143b64",
            color: "white",
            "min-width": "90px",
            "max-width": "140px"
          },
        }),
        setCellProps: () => ({
          style: {
            // position: "sticky",

            // outline:"1.5px solid black",
            // left: "701px",
            // zIndex: 99,
            background: "white",

            alignItem: "center",
            background: "white"
          }
        })
      },
    },
    {
      name: "",
      label: "Pier Updated",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "siteType",
      label: "Site Type",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline: "1.5px solid black",
            color: "white",
            "min-width": "140px",
            "max-width": "180px"
          },
        }),
      },
    },
    {
      name: "delete",
      label: "Delete",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "ranSupportId",
      label: "ranSupportId",
      sortable: true,
      options: {
        display: false,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },



  ];

  <th scope="col">CX Crew Vendor</th>;
  let cxTableHeader = [
    "Crew Type",
    "Crew Vendor",
    "Crew Lead Contact #",
    "Crew Lead Name",
    "Crew Lead Company",
    "Crew Lead E-mail ID",
  ];
  let ixTableHeader = [
    "Crew Type",
    "Crew Vendor",
    "Crew Lead Contact #",
    "Crew Lead Name",
    "Crew Lead Company",
    "Crew Lead E-mail ID",
    "Crew Type"
  ];
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];


  // console.log("fake console for create git commit")

  let excelData = [];
  let datamark = [];
  let siteStatus = [];
  let nestingTimeHandler = (item) => {
    dispatch(FetchSingleRecordAction(nestingOurApi, item.ranSupportId));
    setnestDetail({
      ranId: item.ranSupportId,
      siteId: item.siteId,
      preReq: item.preCheckRequest,
      nestHour: item.nestingTime,
      lastNestTime: item.lastNestEnd,
    });
    console.log("the response id=", id);
    setNestingTime(true);
    setCloser(true)

    dispatch(popUpUpdate({}));
  };
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {
    console.log("openModal", data, reqPreModal);
    if (data == "reqPreCheck") {
      setReqPreModal(true);
      console.log("the req premodal =", data);
      setUniqueId(id);
      dispatch(popUpUpdate({}));
      setCloser(true);
    } else if (id == null) {
      setCrewModal(true);
      setSiteDetail(data);
      console.log("th dta is  =", reqPreModal);
      console.log("the main data is =", siteDetail);
    }
  }
  let checkRanStatus = [
    "Login Awaited - CR Approved",
    "Login Awaited - CR Pending",
    "Login Request - CR Approval Requested",
  ];
  const filterdate = () => {
    console.log(fstartdate, fenddate, "fstartdate,fenddate");

    if (fenddate && fstartdate) {
      dispatch();
      // MopDatabaseAction(
      //   `${mopDataApi}?start=${moment(fstartdate).format(
      //     "MM/DD/YYYY"
      //   )}&end=${moment(fenddate).format("MM/DD/YYYY")}`
      // )

      return;
    }

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
  };

  function showDetails(id) {

    dispatch(clearGetSingleRanProject())
    setXlShow(true);
    setSiteNId(id.siteId)
    setSiteId(id.ranSupportId);
    console.log("dfghedrhetsetghrrtd", id);
    dispatch(GetTimeLineAction(ranDashboardTimeLine+"?ix=True", id.ranSupportId));
    dispatch(FetchSingleRecordAction(ixModule, id.ranSupportId));
  }
  const deleteRanData = (id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteAcccountReducerAction(ixDeleteData, id));
      }
    });
  };

  const requestByButton = (id, type) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(addUserAction(changeButtonStatus +"?ix=True", {
          Id: id,
          buttonType: type,
        }));



      }
    });
  };

  
  const callForSpeedTest = (url, uniqueId,type,smart) => {
    console.log("callForSpeedTest","callForSpeedTest")
    dispatch(GetennoProject([]))
    dispatch(clearFetchSingleRecordAction())
    dispatch(clearIxSingleSite())
    if(smart){
      dispatch(FetchSingleRecordAction(url, uniqueId))
    }
    if(type){
      history.push(`${url}${uniqueId}`)
    }
  }

  const callForNextPage = (url, uniqueId,type,smart) => {
    console.log(url, uniqueId,type,smart,"callForNextPage")
    if(smart){
      dispatch(FetchSingleRecordAction(url, uniqueId))
    }
    if(type){
      history.push(`${url}${uniqueId}`)
    }
  }
  const rerequestByButton = (id, type) => {
    swal({
      title: "Are you sure to reRequest " + type + "?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(addUserAction(changeButtonStatus+"?ix=True", {
          Id: id,
          buttonType: type,
          rerequest: true
        }));



      }
    });
  };

  const openOntheFlyForm = (too, id) => {
    dispatch(clearFetchSingleRecordAction())
    dispatch(ClearProductCode())
    dispatch(clearProjectId())
    dispatch(clearGetSingleRanProject())
    dispatch(clearIxSingleSite())
    //  
    history.push("/PAG/ran/OntheFlyForm/ixDashboard/" + too + "/" + id)
  };



  const count = useSelector((state) => {
    console.log("the state is=", state);
    let sitesdata = state.CommonResponse.listIXData;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        let res=sitesdata.slice(1)
        console.log("the log =",res)
        res.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }
    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status, closer, state.popUpState.popstatus.status == 201 && closer, "popUpStatestatus")
      console.log(reqPreModal, "openModal");

      // ||(state.popUpState.popstatus.status == 200 && closer)
      if ((state.popUpState.popstatus.status == 201 && closer)) {
        console.log(reqPreModal, "openModal");
        setCloser(false);
        setReqPreModal(false);
        setcommonModalState(false)
        setNestingTime(false)
        if(preVal){
          setPreVal(false)
        }
        if(postVal){
          setPostVal(false)
        }
        
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(ixModule));
      }
    }
    let ranSiteStatus = state.CommonResponse.ranSiteStatus;
    console.log("the ran site status =",state)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      siteStatus = ranSiteStatus;
    }
    // console.log(mark, "mark");
    
    // if (Object.keys(state.CommonResponse.listIXData).length > 0) {
      if (state.CommonResponse?.listIXData.length > 0) {
        console.log("the ran site status =", state.CommonResponse.listIXData.slice(0,1));
        rowData = state.CommonResponse.listIXData.map((item, index) => {
          var now = moment()
          let newscheduleDate = dbdatetomoment(getdatedatalistnext(item["scheduleDate"]))
          if (item.cxCrewId && item.cxCrewId.name) {
            item["gcType"] = item["cxCrewId"]["crewType"];
            item["GCContact"] = item["cxCrewId"]["mobilenumber"];
            item["GCName"] = item["cxCrewId"]["name"];
            item["GCVendor"] = item["cxCrewId"]["vendor"];
            item["gcCompany"] = item["cxCrewId"]["crewLeadCompanyId"];
            item["GCEmail"] = item["cxCrewId"]["email"];
          }

          if (item.ixCrewId && item.ixCrewId.name) {
            item["gcType"] = item["ixCrewId"]["crewType"];
            item["GCContact"] = item["ixCrewId"]["mobilenumber"];
            item["GCName"] = item["ixCrewId"]["name"];
            item["GCVendor"] = item["ixCrewId"]["vendor"];
            item["gcCompany"] = item["ixCrewId"]["crewLeadCompanyId"];
            item["GCEmail"] = item["ixCrewId"]["email"];
          }

          item["RF_ApproinvgClone"] = item.RF_Approval
          item["scheduleDate"] = getdatedatalistnext(item["scheduleDate"]);
          item["RF_Approinvg"] = (
            <p className={`badge ${RF_Approval_color_code[item["RF_Approval"]]} p-2`}>
              {item.RF_Approval}
            </p>
          )
          // item["ranStatusLinkClone"] = item["ranStatus"]=="Post-Check Completed - Validation ongoing"?"Post-Check Completed":item["ranStatus"]=="Post-check Released with TT Opened and Nest Extended"?"TT Opened and Nest Extended":item["ranStatus"]
          item["ranStatusLinkClone"] = item["ranStatus"]

          item["ranStatusLink"] = <Link className="text-black" onClick={(e) => { openOntheFlyForm("gcLogin", item.ranSupportId) }}><button className={`badge py-1 badge-custom-size ${colors[item["ranStatus"]]}`} style={{ backgroundColor: item["ranColor"], color: item["ranfontColor"] }}>{item["ranStatus"]}</button></Link>

          // if (item["ranBucket"] == "Scheduled" && item["ranStatus"] == "Login Awaited - No Show") {
          //   item["ranStatusLink"] = <Link className="text-black" onClick={(e)=>{openOntheFlyForm("gcLogin",item.ranSupportId)}}><button className={`badge py-1 badge-custom-size loginAwaitedNoShow`}  >{item["ranStatus"]}</button></Link>
          // }
          // else if(item["ranStatus"]=="Post-Check Completed - Validation ongoing"){
          //   item["ranStatusLink"] = <Link className="text-black" onClick={(e)=>{openOntheFlyForm("gcLogin",item.ranSupportId)}}><button  className={`badge py-1 badge-custom-size ${colors[item["ranStatus"]]}`}  >{item["ranStatus"]=="Post-Check Completed - Validation ongoing"?"Post-Check Completed":item["ranStatus"]}</button></Link> 
          // }
          // else if(item["ranStatus"]=="Post-check Released with TT Opened and Nest Extended"){
          //   item["ranStatusLink"] = <Link className="text-black" onClick={(e)=>{openOntheFlyForm("gcLogin",item.ranSupportId)}}><button  className={`badge py-1 badge-custom-size ${colors[item["ranStatus"]]}`}  >{item["ranStatus"]=="Post-check Released with TT Opened and Nest Extended"?"TT Opened & Nest Extended":item["ranStatus"]}</button></Link> 
          // }
          // else {
          //   item["ranStatusLink"] = <Link className="text-black" onClick={(e)=>{openOntheFlyForm("gcLogin",item.ranSupportId)}}><button className={`badge py-1 badge-custom-size ${colorCodeObj[item["ranBucket"]]}`}  >{item["ranStatus"]}</button></Link>
          // }

          item['chatting'] = <Dropdown as="li" className="nav-item notification_dropdown">
            <Dropdown.Toggle
              variant=""
              className="nav-link bell bell-link i-false btn-xs badge"
              style={{ "width": "40px", backgroundColor: "#00ffff", color: "black" }}
            
              onClick={() => {
                let id=item.siteId ||  item.ndResult?item.ndResult.siteId:""
                console.log("njhvubfierugfre=",id)
                onNote(item["assignedEngineer"], id,null)}
              }
            >
              {/* Chat<span className="w-5">&emsp;</span><span  style={{borderRadius: "50%",background :white,padding:" 1px 4px",border: "1px solid black"}}>9</span> */}
              Chat
              {/* <span style={{"width":"40px",backgroundColor:"#00ffff",}} className="badge " >Chat</span>
              Chat<span className="w-5">&emsp;</span><span  style={{borderRadius: "50%",background :white,padding:" 1px 4px",border: "1px solid black"}}>9</span> */}
            </Dropdown.Toggle>
          </Dropdown>
          console.log("jbhvclbjasucgefe=====",item.RF_Approval && item.numberStatus==3)

          

      
      //  FRESH COMMENT 

      console.log(newscheduleDate.isSameOrBefore(now),item.RF_Approval,item.numberStatus,item["PreRIOT_status"],"newscheduleDate.isSameOrBefore")
      if(item["PreRIOT_status"]==2){

        item["preRiot"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) && item.numberStatus==3 ? (
                    <button
                      className="badge badge-warning p-1"
                      style={{ minWidth: "100px","backgroundColor":"rgb(0, 176, 240)"}}
                      onClick={() =>
                        CommonModalFun(
                          <RiotModalBody siteIdData={ item.siteId ?item.siteId :(item.ndResult? item.ndResult.siteId:"")} preRiot={"preRiot"} ranIdData={item.ranSupportId} from={"IX Dashboard"}/>,
                          "RIOT Test",
                          "RIOT Test"
                        )
                      }
                    >
                      Requested RIOT Test
                    </button>
                  ) : (
                    ""
                  );
      }
      else if(item["PreRIOT_status"]==3){
        item["preRiot"] = 
        userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) && item.numberStatus==3 ? (
          <button
          className="badge badge-success p-1"
            style={{ minWidth: "100px","backgroundColor":"rgb(0, 176, 240)"}}
            onClick={() =>
              {
                if(item["numberStatus"]<5){
              CommonModalFun(
                <RiotModalBody siteIdData={ item.siteId ?item.siteId :(item.ndResult? item.ndResult.siteId:"")} preRiot={"preRiot"} ranIdData={item.ranSupportId} from={"IX Dashboard"}/>,
                "RIOT Test",
                "RIOT Test"
              )
                }
              }
            }
          >
            Completed
          </button>
        ) : (
          <button
        className="badge badge-success p-1"
        style={{ minWidth: "100px" }}
       
      >
        Completed
      </button>
        );
      }

      else{

        item['preRiot'] = newscheduleDate.isSameOrBefore(now) && item.RF_Approval == "Approved" && item.numberStatus==3? (
          <button
            className="badge badge-warning p-1"
            style={{ minWidth: "100px" }}
            onClick={() =>
              requestByButton(item.ranSupportId, "PreRIOT")
            }
          >
            Request RIOT Test
          </button>
        ) : (
          item.numberStatus!=3?
          <button
          className={`badge ${item.preRiot==2?"badge-success":item.preRiot==3?"badge-primary":"badge-warning"}`}
          style={{ minWidth: "100px" }}
        >
          {item.preRiot==2?"Requested":item.preRiot==3?"Completed":"Request"}
        </button>:""
        )
      }

        
          item['preValidationbtn'] = <button onClick={
            ()=>{
              if(item.numberStatus==3){
                setPreValidation(item.ranSupportId)
              }
            }
          } className={`badge ${item.preValidation==1?"badge-primary":"badge-warning"}`}>{item.preValidation==1?"Validated":"Pre Validation"}</button>
          item['feLogin'] = <button onClick={()=>{callForNextPage("/PAG/ix/module/FLogin/",item["ranSupportId"],item.FEnStatus==0)}} className={`badge ${item.FEnStatus>=1?"badge-primary":"badge-warning"}`}>FE Login</button>
          item['integrationLogin'] = <button onClick={()=>{callForNextPage("/PAG/ix/module/ILogin/",item["ranSupportId"],item.InStatus==0)}} className={`badge ${item.InStatus>=1?"badge-primary":"badge-warning"}`}>Integration Login</button>
          item['feLoginTime'] = getdatedatalistnext(item?.FLogin_timeStamp) ?? "";
          item['integrationLoginTime'] =  getdatedatalistnext(item?.ILogin_timeStamp) ?? "";

          // <Link to={`/PAG/ix/module/FLogin/${item["ranSupportId"]}`} className={`badge ${item.FEnStatus==1?"badge-primary":"badge-warning"}`}>FE Login</Link>
          // item['integrationLogin'] = <Link to={`/PAG/ix/module/ILogin/${item["ranSupportId"]}?`} className={`badge ${item.InStatus==1?"badge-primary":"badge-warning"}`}>Integration Login</Link>
          
            item['feLogoutTime'] = getdatedatalistnext(item?.FLogout_timeStamp) ?? "";
            item['feLogout'] = <button onClick={()=>{callForNextPage("/PAG/ix/module/FLogout/",item["ranSupportId"],item.FEnStatus==1)}} className={`badge ${item.FEnStatus>=2?"badge-primary":"badge-warning"}`}>FE Logout</button>
            item['integrationLogout'] = <button onClick={()=>{callForNextPage("/PAG/ix/module/ILogout/",item["ranSupportId"],item.InStatus==1)}} className={`badge ${item.InStatus>=2?"badge-primary":"badge-warning"}`}>Integration Logout</button>
            item['integrationLogoutTime'] = getdatedatalistnext(item?.ILogout_timeStamp) ?? "";
        
          // if(item["numberStatus"]==6){ 
            item['speedTest'] = <button onClick={()=>{callForSpeedTest("/PAG/SpeedTest/form/ixSpeedTest/",item["ranSupportId"],(item["numberStatus"]==6 || item["numberStatus"]==7),false)}} className={`badge ${item.speedTestStatus==1?"badge-primary":"badge-warning"}`}>Speed Test</button>
            // <Link to={`/PAG/speedTest/form/ixSpeedTest/${item["ranSupportId"]}`}className='badge badge-primary' onClick={()=>{}}>Speed Test</Link>
            // item['feLogout'] = <Link to={`/PAG/ix/module/FLogout/${item["ranSupportId"]}`} className="badge-primary badge">FE Logout</Link>
            // item['integrationLogout'] = <Link to={`/PAG/ix/module/ILogout/${item["ranSupportId"]}`} className="badge-primary badge">Integration Logout</Link>
            // item['postValidation'] = <button onClick={setPostValidation} className='badge badge-primary'>Post Validation</button>

            item['postValidationbtn'] = <button onClick={
              ()=>{
                if(item.numberStatus==6){
                  setPostValidation(item.ranSupportId)
                }
              }
            } className={`badge ${item.postValidation==1?"badge-primary":"badge-warning"}`}>{item.postValidation==1?"Validated":"Post Validation"}</button>
            
          // }
          const myMomentObject = dbdatetomoment(getdatedatalistnext(item["scheduleDate"]))
          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()

          // console.log("i am caling", item["scheduleDate"], myMomentObject.startOf("week").isoWeekday(1).format("DD/MM/YYYY"), myMomentObject.toDate(), myMomentObject);
          item["action"] = (
            <>
              <button className="btn btn-primary shadow btn-xs sharp mr-1">
                <i className="fa fa-pencil"></i>
              </button>
              <button className="btn btn-danger shadow btn-xs sharp">
                <i className="fa fa-trash"></i>
              </button>
            </>
          );
          console.log("the site id is=",item)
          item["5GId"] = "";
          item["LTEId"] = "";
          item["UMTSId"] = "";
          item["mixedModeId"] = "";
          item["siteIdRow"] = (
            <p className="text-primary custom-cursor" onClick={() => showDetails(item)}>
              {item.ndResult.siteId || item.siteId}
            </p>
          );
          item["siteIdClone"] = item.siteId
          let pc = []
          let programname = []
          if(item.projectbUniqueId && item.projectbUniqueId.length>0){
            item.projectbUniqueId.map((item) => {
              if (pc.indexOf(item.projectCode) == -1) {
                pc.push(item.projectCode)

                if(programname.indexOf(item.ProgramName)){
                  programname.push(item.ProgramName)
                }
              }
            });
          }
          item["projectCode"] = pc.join(", ")
          item["programname"] =(
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"
              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                    }}
                    className="CommonModal"
                  >
                    {programname.join(", ")}
                  </p>,
                  "Program Name",
                  "Program Name"
                )
              }
            >
              {wrapText(programname.join(", "))}
            </p>
          ); 
          
          
          
          
          item["crNo"] = item.crNumber;
          // console.log(item, "error checker")
          // item["siteType"] = item.siteType;
          
          item["siteType"] = item.dtResult.siteType;
          if (item["nestingTime"]) {
            let cTime = new Date();
            let sdate = moment(new Date(item["mop9StartTime"])).format(
              "MM/DD/YYYY"
            );
            // console.log("the date is =", sdate);
            // console.log(
            //   "the date is =",
            //   moment(item["mopStartTime"]).format("MM/DD/YYYY")
            // );
            // console.log("the date is =",item["mopDownTime"])
            // console.log("the real data=",cTime.getHours())
            // console.log("the real datasdasa=",cTime.getDate())
            // console.log("the real datdadasdasdasdaada=", sdate.getDate())

            // console.log("the date is =",item["mopDownTime"].getDate())
            // if(sdate.getMonth()==cTime.getMonth() && sdate.getDate()==cTime.getDate() && cTime.getHours()>=sdate.getHours() ){
            //   let remainingTime =sdate.getHours()>=cTime.getHours()
            //   console.log("the difference is =",remainingTime)
            //   item['progress']=<div style={{ width: 40, height: 40 }}><CircularProgressbar value={remainingTime/100} maxValue={1} text={`${remainingTime}%`} /></div>
            // }
            console.log(
              "the real time=",
              new Date(item["mopDownTime"]).getTime()
            );
            console.log(
              ageofdatalistnext(item.lastNestEnd),
              item.lastNestEnd,
              "age"
            );
            let btime = ageofdatalistnext(item.lastNestEnd);

            let busetime = 0;
            if (btime) {
              busetime = btime[3];
            }
            console.log(
              item.nestingTime, "item.nestingTime",
              busetime, "busetime",
              item.nestingTime - busetime / item.nestingTime, "item.nestingTime - busetime / item.nestingTime",
              item.nestingTime - busetime, "item.nestingTime - busetime",
              item.nestingTime, "item.nestingTime",
              +item.nestingTime - (busetime / +item.nestingTime) * 100,
              "percent"
            );




            console.log("item==>", item)






            let initial_value = item.nestingTime - busetime;
            let totalValue = item.nestingTime;
            let bluestyles = buildStyles({
              pathColor: "#143b64",
              textColor: '#143b64',
              trailColor: '#143b64',
              backgroundColor: '#143b64'
            });
            let orangestyles = buildStyles({
              pathColor: "#f16722",
              textColor: '#f16722',
              trailColor: '#f16722',
              backgroundColor: '#f16722',
            });
            let yellowStyles = buildStyles({
              pathColor: "#d07407",
              textColor: '#d07407',
              trailColor: '#d07407',
              backgroundColor: '#d07407',
            });
            let redstyles = buildStyles({
              pathColor: "#ff0000",
              textColor: '#ff0000',
              trailColor: '#ff0000',
              backgroundColor: '#ff0000',
            });

            let greenstyles = buildStyles({
              pathColor: "#0d7504",
              textColor: '#0d7504',
              trailColor: '#0d7504',
              backgroundColor: '#0d7504',
            });

            let applyStyle = bluestyles;
            var proper = ((initial_value / totalValue) * 100).toFixed()
            if (item["postCheckDelivered"]) {
              applyStyle = greenstyles
            } else {
              if (item.nestingTime - busetime > 2) {
                console.log(item.nestingTime, busetime, "blue color")
                applyStyle = bluestyles
              }
              if (item.nestingTime - busetime == 1 || item.nestingTime - busetime == 2) {
                console.log(item.nestingTime, busetime, "yellow color")
                applyStyle = yellowStyles
              }
              if (item.nestingTime - busetime == 0 || item.nestingTime - busetime < 0) {
                console.log(item.nestingTime, busetime, "redstyles color")
                applyStyle = redstyles
              }
            }

            console.log(proper, item["postCheckDelivered"], "proper")
            const pgbar = getProgressBar(getdatedatalistnext(item.preCheckRequest), getdatedatalistnext(item.lastNestEnd))

            let perpg = 0
            if (pgbar[0] > 100) {
              perpg = 100
            } else if (pgbar[0] < 0) {
              perpg = 0
            } else {
              perpg = pgbar[0]
            }


            if (item["postCheckDelivered"]) {
              applyStyle = greenstyles
              perpg = 100
            } else {
              if (pgbar[1] > 2) {
                console.log(item.nestingTime, busetime, "blue color")
                applyStyle = bluestyles
              }
              if (pgbar[1] == 1 || pgbar[1] == 2) {
                console.log(item.nestingTime, busetime, "yellow color")
                applyStyle = yellowStyles
              }
              if (pgbar[1] == 0 || pgbar[1] < 0) {
                console.log(item.nestingTime, busetime, "redstyles color")
                applyStyle = redstyles
              }
            }
            item["progress"] = (
              <div
                className={"custom-cursor"}
                style={{ width: 35, height: 35 }}
                onClick={() => nestingTimeHandler(item)}
              >

                <CircularProgressbar
                  value={perpg}
                  maxValue={100}
                  styles={applyStyle}
                  text={`${perpg}%`}
                />


              </div>
            );

          }
          let ttidsData =''
          let pierttidsData=''
          if(item.troubleTickets && item.troubleTickets.length>0){
            ttidsData= item.troubleTickets.map((itemInner) => {
              return itemInner.ttid;
            });
  
            item["ttIds"] = ttidsData.join(", ");
  
  
            pierttidsData = item.troubleTickets.map((itemInner) => {
              return itemInner.tmoPierTtid;
            });
            item["pierttIds"] = pierttidsData.join(", ");

          }

          

          // item["TMOOutageApprovedMWTime"]=item["RF_Approved_MW_Time"]?item["RF_Approved_MW_Time"].split(" ")[1]:""+" to "+item["RF_Approved_EndTime_Time"]?item["RF_Approved_EndTime_Time"].split(" ")[1]:""

          // item["TMOOutageApprovedMWTime"]=item["RF_Approved_MW_Time"]?item["RF_Approved_MW_Time"].split(" ")[1]:""+" to "+item["RF_Approved_EndTime_Time"]?item["RF_Approved_EndTime_Time"].split(" ")[1]:""
          item["TMOOutageApprovedMWTime"] = item["RF_Approved_MW_Time"] && item["RF_Approved_EndTime_Time"] ? getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1] : ""
          // item['siteId'] = <a href={'/PAG/ran/support/dashboard/'+item.newuniqueId} target="_blank">{item.siteId}</a>
          // console.log("item is =", item["technology"]);
          // item['technology']=''
          item["account"] = item.account;
          item["crew"] = (
            <button
              className="badge backgroundPurple p-1"
              onClick={() => openModal(item)}
            >
              Crew
            </button>
          );

          item["gcInfo"] = (
            <button
              className="badge backgroundPurple p-1"
              onClick={() => CommonModalFun(
                owneroperner(item["gcType"], item["GCName"], item["GCEmail"], item["GCVendor"], item["gcCompany"], item["GCContact"]),
                "GC Information",
                "GC Information"
              )}
            >
              GC Information
            </button>
          );

          if (item["mopStartTime"] && item["mopEndTime"]) {
            let mopSTime = item["mopStartTime"].split(" ").pop();
            let mopETime = item["mopEndTime"].split(" ").pop();
            item["rfMWTime"] = mopSTime + " to " + mopETime;
          }

          if (item["ranBucket"] == "In Progress") {
            item["status"] = (
              <p
                className=" text-white py-2 rounded"
                style={{
                  fontSize: "10px",
                  lineHeight: "13px",
                  backgroundColor: "#935ab7",
                }}
                onClick={(e) => { openOntheFlyForm(item.ranSupportId) }}
              >{item.ranStatus}</p>
            );
          } else if (item["ranBucket"] == "Scheduled") {
            item["status"] = (
              <p
                className=" text-white py-2 rounded"
                style={{
                  fontSize: "10px",
                  lineHeight: "13px",
                  backgroundColor: "orange",
                  marginBottom: "0px",
                }}
                onClick={(e) => { openOntheFlyForm(item.ranSupportId) }}
              >{item.ranStatus}</p>
            );
          } else if (item["ranBucket"] == "In Progress - Hold") {
            item["status"] = (
              <p
                className="bg-primary text-white py-2 rounded"
                style={{ fontSize: "10px", lineHeight: "13px" }}
                onClick={(e) => { openOntheFlyForm(item.ranSupportId) }}
              >{item.ranStatus}</p>
            );
          } else {
            item["status"] = (
              <p
                className="bg-success text-white py-2 rounded"
                style={{ fontSize: "10px", lineHeight: "13px" }}
                onClick={(e) => { openOntheFlyForm(item.ranSupportId) }}
              >{item.ranStatus}</p>
            );
          }

          // && item.scheduleDate

          
          console.log("cksbchverubvdfjb vgf=",newscheduleDate)
          console.log(now.format("DD/MM/YYYY HH:mm"), item["scheduleDate"], getdatedatalistnext(item["scheduleDate"]), newscheduleDate.isSameOrBefore(now), "myMomentObjectmyMomentObject")
          item["pUniqueIdDetails"]=""
          //Planned sow
          if (item.numberStatus == 2) {
            item["crStatus"] = (
              <div className="d-flex">
                <button
                  className="btn btn-success shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center"
                  onClick={() => ApprovalHandler(item)}
                >
                  <i className="fa fa-check"></i>
                </button>
                <button
                  className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"
                  onClick={() => rejectHandler(item)}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
            );
          // } else if (item.RF_Approval == "Approved"  && item.numberStatus == 3 && item["PreRIOT_status"]==3) {
          // } else if (item.RF_Approval == "Approved"  && item.numberStatus == 3 && item["FEnStatus"]==1 && item["InStatus"]==1) {
          } else if (item.RF_Approval == "Approved"  && item.numberStatus == 3 && item["InStatus"]==1) {
            item["mPrecheckReq"] = userDetails.rolename !== "GC Login" ? (<button
              className="badge badge-warning p-1"
              onClick={() => openModal("reqPreCheck", item.ranSupportId)}
            >
              Req Precheck
            </button>
            ) : (
              ""
            );
          } else if (item.ranStatus == "Login Awaited - CR Approved" || item.ranStatus == "Login Request - CR Approval Requested") {
            item["crStatus"] = (
              <p className="">Approved</p>
              // <p className="badge badge-success p-1">Approved</p>
            );
          } else if (item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) && item.numberStatus == 5) {
            item["preCheck"] =
              userDetails.rolename !== "GC Login" ? (
                <button onClick={(e) => { openOntheFlyForm("preCheck", item.ranSupportId) }} className="badge badge-warning p-2">
                  {/* <Link
                    className="text-black"
                    
                    to={"/PAG/ran/OntheFlyForm/preCheck/" + item.ranSupportId}
                  > */}
                  Run-PreCheck
                  {/* </Link> */}
                </button>
              ) : (
                <button className="badge badge-warning p-1">
                  <p className="text-black">Run-PreCheck</p>
                </button>
              );
            // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
            item["mPrecheckReq"] =
              userDetails.rolename !== "GC Login" ? (
                <button
                  className="badge badge-danger p-1"
                  onClick={() => cancelPreCheckReq(item.ranSupportId)}
                >
                  Cancel
                </button>
              ) : (
                ""
              );
          } else if (item.RF_Approval == "Approved" && item.numberStatus == 6) {
            item["preCheck"] = (
              <p
                className="badge badge-success p-2"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                Pre Check Completed
              </p>
            );
            item["mPrecheckReq"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postCh"] = (
              <p
                className="badge badge-secondary p-1"
                onClick={() => reqPostCheck(item.ranSupportId)}
              >
                Req Postcheck
              </p>
            );
          } else if (item.RF_Approval == "Approved" && item.numberStatus == 7) {
            item["preCheck"] = (
              <p
                className="badge badge-success p-2"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                Pre Check Completed
              </p>
            );
            // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
            item["mPrecheckReq"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postCh"] = (
              <p
                className="badge badge-danger p-1"
                onClick={() => cancelPostCheckReq(item.ranSupportId)}
              >
                Cancel
              </p>
            );
            item["postChecks"] = (
              <p onClick={(e) => { openOntheFlyForm("postCheck", item.ranSupportId) }} className="badge badge-warning p-1">
                {/* <Link
                  className="text-white"
                  to={"/PAG/ran/OntheFlyForm/postCheck/" + item.ranSupportId}
                > */}
                Run PostCheck
                {/* </Link> */}
              </p>
            );
          } else if (item.RF_Approval == "Approved" && item.numberStatus == 8) {
            item["preCheck"] = (
              <p
                className="badge badge-success p-2 "
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                Pre Check Completed
              </p>
            );
            // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
            item["mPrecheckReq"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postCh"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postChecks"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                Post-Check Completed

              </p>
            );
          } else if (item.RF_Approval == "Approved" && (item.numberStatus == 22 || item.numberStatus == 23)) {
            item["preCheck"] = (
              <p
                className="badge badge-success p-2 "
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                Pre Check Completed
              </p>
            );
            // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
            item["mPrecheckReq"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postCh"] = (
              <p
                className="badge badge-success p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >

              </p>
            );
            item["postChecks"] = (
              <p
                className="badge badge-danger p-1"
                style={{ fontSize: "10px", lineHeight: "13px" }}
              >
                {item["ranStatus"]}

              </p>
            );
          }

          console.log("ksbjdkuchjbfvlhuefov=",newscheduleDate.isSameOrBefore(now))





          // if (item.ranStatus == "Login Request - CR Approval Requested") {
          //   item["crStatus"] = (
          //     <div className="d-flex">
          //       <button
          //         className="btn btn-success shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center"
          //         onClick={() => ApprovalHandler(item)}
          //       >
          //         <i className="fa fa-check"></i>
          //       </button>
          //       <button
          //         className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"
          //         onClick={() => rejectHandler(item)}
          //       >
          //         <i className="fa fa-times"></i>
          //       </button>
          //     </div>
          //   );
          // } else if (item.RF_Approval == "Approved" && item.ranStatus == "Login Awaited - CR Approved") {
          //   item["mPrecheckReq"] =
          //     userDetails.rolename !== "GC Login" ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         onClick={() => openModal("reqPreCheck", item.ranSupportId)}
          //       >
          //         Req Precheck
          //       </button>
          //     ) : (
          //       ""
          //     );
          // } else if (
          //   item.ranStatus == "Login Awaited - CR Approved" ||
          //   item.ranStatus == "Login Request - CR Approval Requested"
          // ) {
          //   item["crStatus"] = (
          //     <p className="">Approved</p>
          //     // <p className="badge badge-success p-1">Approved</p>
          //   );
          // } else if (item.RF_Approval == "Approved" && item.ranStatus == "Pre-check Ongoing") {
          //   item["preCheck"] =
          //     userDetails.rolename !== "GC Login" ? (
          //       <button className="badge badge-warning p-2">
          //         <Link
          //           className="text-black"
          //           to={"/PAG/ran/OntheFlyForm/preCheck/" + item.ranSupportId}
          //         >
          //           Run-PreCheck
          //         </Link>
          //       </button>
          //     ) : (
          //       <button className="badge badge-warning p-1">
          //         <p className="text-black">Run-PreCheck</p>
          //       </button>
          //     );
          //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
          //   item["mPrecheckReq"] =
          //     userDetails.rolename !== "GC Login" ? (
          //       <button
          //         className="badge badge-danger p-1"
          //         onClick={() => cancelPreCheckReq(item.ranSupportId)}
          //       >
          //         Cancel
          //       </button>
          //     ) : (
          //       ""
          //     );
          // } else if (item.RF_Approval == "Approved" && item.ranStatus == "Nest Active") {
          //   item["preCheck"] = (
          //     <p
          //       className="badge badge-success p-2"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >
          //       Pre Check Completed
          //     </p>
          //   );
          //   item["mPrecheckReq"] = (
          //     <p
          //       className="badge badge-success p-1"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >

          //     </p>
          //   );
          //   item["postCh"] = (
          //     <p
          //       className="badge badge-secondary p-1"
          //       onClick={() => reqPostCheck(item.ranSupportId)}
          //     >
          //       Req Postcheck
          //     </p>
          //   );
          // } else if (item.RF_Approval == "Approved" && item.ranStatus == "Post-check Ongoing") {
          //   item["preCheck"] = (
          //     <p
          //       className="badge badge-success p-2"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >
          //       Pre Check Completed
          //     </p>
          //   );
          //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
          //   item["mPrecheckReq"] = (
          //     <p
          //       className="badge badge-success p-1"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >

          //     </p>
          //   );
          //   item["postCh"] = (
          //     <p
          //       className="badge badge-danger p-1"
          //       onClick={() => cancelPostCheckReq(item.ranSupportId)}
          //     >
          //       Cancel
          //     </p>
          //   );
          //   item["postChecks"] = (
          //     <p className="badge badge-success p-1">
          //       <Link
          //         className="text-white"
          //         to={"/PAG/ran/OntheFlyForm/postCheck/" + item.ranSupportId}
          //       >
          //         Run PostCheck
          //       </Link>
          //     </p>
          //   );
          // } else if (
          //   item.RF_Approval == "Approved" && item.ranStatus == "Post-Check Completed - Validation ongoing"
          // ) {
          //   item["preCheck"] = (
          //     <p
          //       className="badge badge-success p-2 "
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >
          //       Pre Check Completed
          //     </p>
          //   );
          //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
          //   item["mPrecheckReq"] = (
          //     <p
          //       className="badge badge-success p-1"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >

          //     </p>
          //   );
          //   item["postCh"] = (
          //     <p
          //       className="badge badge-success p-1"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >

          //     </p>
          //   );
          //   item["postChecks"] = (
          //     <p
          //       className="badge badge-success p-1"
          //       style={{ fontSize: "10px", lineHeight: "13px" }}
          //     >
          //       Post-Check completed

          //     </p>
          //   );
          // }

          // if (item.ctaenable == 1) {
          //   if (item.ennoSubmit) {
          //     item["CTA"] = (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //       >
          //         Completed
          //       </button>
          //     );
          //   } else {
          //     item["CTA"] =
          //       userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          // <button
          //   className="badge badge-warning p-1"
          //   style={{ minWidth: "100px" }}
          //   onClick={() =>
          //     startcalltest(
          //       item.dtSiteId,
          //       item.dtprojectUniqueId,
          //       item.ranSupportId
          //     )
          //   }
          // >
          //   Start Call Test
          // </button>
          //       ) : (
          //         ""
          //       );
          //   }
          // } else {
          //   item["CTA"] = "NA";
          // }


          // if (item.ctaenable == 1) {
          //   if (item.ennoSubmit) {
          //     item["CBN"] = (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //       >
          //         Completed
          //       </button>
          //     );
          //   } else {
          //     item["CBN"] =
          //       userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //         <button
          //           className="badge badge-warning p-1"
          //           style={{ minWidth: "100px" }}
          //           onClick={() =>
          //             CommonBulkUploaderModalFunction("CBN_Validation","CBN Logs",item.ndResult.siteId+"__"+item.ranSupportId)
          //           }
          //         >
          //           Start CBN Test
          //         </button>
          //       ) : (
          //         ""
          //       );
          //   }
          // } else {
          //   item["CBN"] = "NA";
          // }
          // item["RIOT"]=
          // switch(item.RIOT_status){
          //   case 1:return <button
          //     className="badge badge-warning p-1"
          //     style={{ minWidth: "100px" }}
          //     onClick={() =>
          //       CommonModalFun(
          //         <RiotModalBody siteIdData={item.ndResult.siteId} ranIdData={item.ranSupportId}/>,
          //         "RIOT Test",
          //         "RIOT Test"
          //       )
          //     }
          //   >
          //     Request RIOT Test
          //   </button>

          //   case 2:return <button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>
          //   case 3:return <button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>
          //   case 4:return <button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>
          // }

          if((item.numberStatus == 6 || item.numberStatus == 7) && item.RIOT_status != 0){
            console.log("riot 1")
            if (item.RIOT_status == 1) {
              console.log("riot 2")
              item["RIOT"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                      className="badge badge-warning p-1"
                      style={{ minWidth: "100px" }}
                      onClick={() =>
                        requestByButton(item.ranSupportId,"RIOT")
                      }
                    >
                      Request RIOT Test
                    </button>
                  ) : (
                    ""
                  );
            }
            else if (item.RIOT_status == 2) {
              console.log("riot 3")
              item["RIOT"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                      className="badge badge-warning p-1"
                      style={{ minWidth: "100px","backgroundColor":"rgb(0, 176, 240)"}}
                      onClick={() =>
                        CommonModalFun(
                          <RiotModalBody siteIdData={ item.siteId || item.ndResult? item.ndResult.siteId:""} ranIdData={item.ranSupportId} from={"IX Dashboard"}/>,
                          "RIOT Test",
                          "RIOT Test"
                        )
                      }
                    >
                      Requested RIOT Test
                    </button>
                  ) : (
                    ""
                  );
            }
            else if (item.RIOT_status == 3) {
              console.log("riot 4")

              // // rerequestByButton
              // if(item.RIOT_status == 3)
              item["RIOT"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                      className="badge badge-success p-1"
                      style={{ minWidth: "100px" }}
                      onClick={() =>
                        rerequestByButton(item.ranSupportId,"RIOT")
                      }
                    >
                      Completed
                    </button>
                  ) : (
                    ""
                  );
              // item["RIOT"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
            }
          }
          
          else if(item.RIOT_status == 0 ){
            console.log("riot 5")
            item["RIOT"] = "NA"
          }

          else if((item.RIOT_status == 1 || item.RIOT_status == 2) && item.numberStatus == 8 ){
            console.log("riot 5")
            item["RIOT"] = "NA"
          }


          else if(item.numberStatus == 8 && item.RIOT_status == 3 ){
            console.log("riot 6")
            item["RIOT"] = <button className="badge badge-success p-1" style={{ minWidth: "100px" }}> Completed </button>
          }
// fresh comment
          // if (item.numberStatus == 6 && item.RIOT_status == 1) {
          //   item["RIOT"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           requestByButton(item.ranSupportId, "RIOT")
          //         }
          //       >
          //         Request RIOT Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.RIOT_status == 2) {
          //   item["RIOT"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px", "backgroundColor": "rgb(0, 176, 240)" }}
          //         onClick={() =>
          //           CommonModalFun(
          //             <RiotModalBody siteIdData={item.siteId || item.ndResult?item.ndResult.siteId:""} ranIdData={item.ranSupportId} />,
          //             "RIOT Test",
          //             "RIOT Test"
          //           )
          //         }
          //       >
          //         Requested RIOT Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.RIOT_status == 3) {

          //   // rerequestByButton

          //   item["RIOT"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           rerequestByButton(item.ranSupportId, "RIOT")
          //         }
          //       >
          //         Completed
          //       </button>
          //     ) : (
          //       ""
          //     );
          //   // item["RIOT"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
          // }

// fresh comment
          // if (item.RIOT_status == 3 && item.CallTest_status == 1) {
          //   item["CTA"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           requestByButton(item.ranSupportId, "CallTest")
          //         }
          //       >
          //         Request Call Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.CallTest_status == 2) {
          //   item["CTA"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px", "backgroundColor": "rgb(0, 176, 240)" }}
          //         onClick={() =>
          //           startcalltest(
          //             item.dtSiteId,
          //             item.dtprojectUniqueId,
          //             item.ranSupportId
          //           )
          //         }
          //       >
          //         Start Call Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.CallTest_status == 3) {

          //   item["CTA"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           rerequestByButton(item.ranSupportId, "CallTest")
          //         }
          //       >
          //         Completed
          //       </button>
          //     ) : (
          //       ""
          //     );
          //   // item["CTA"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
          // }
          if((item.numberStatus == 6 || item.numberStatus == 7) && item.CallTest_status != 0 ){

            if (item.RIOT_status == 3 && item.CallTest_status == 1) {
              item["CTA"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                      className="badge badge-warning p-1"
                      style={{ minWidth: "100px" }}
                      onClick={() =>
                        requestByButton(item.ranSupportId,"CallTest")
                      }
                    >
                      Request Call Test
                    </button>
                  ) : (
                    ""
                  );
            }else if(item.CallTest_status == 2) {
              item["CTA"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                      className="badge badge-warning p-1"
                      style={{ minWidth: "100px","backgroundColor":"rgb(0, 176, 240)"}}
                      onClick={() =>
                        startcalltest(
                          item.ndResult.siteUniqueId,
                          item.ndResult.pId,
                          item.ranSupportId
                        )
                      }
                    >
                      Start Call Test
                    </button>
                  ) : (
                    ""
                  );
            }else if (item.CallTest_status == 3) {
              
              item["CTA"] =
                userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                  <button
                    className="badge badge-success p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      rerequestByButton(item.ranSupportId,"CallTest")
                    }
                  >
                    Completed
                  </button>
                ) : (
                  ""
                );
              // item["CTA"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
            }
          }
          else if(item.CallTest_status == 0 ){
            item["CTA"] = "NA"
          }
          
          else if((item.CallTest_status == 1 || item.CallTest_status == 2) && item.numberStatus == 8 ){
            console.log("riot 5")
            item["CTA"] = "NA"
          }

          else if(item.numberStatus >= 8 && item.CallTest_status == 3 ){
            item["CTA"] = <button className="badge badge-success p-1"> Completed </button>
          }


          // if (item.CallTest_status == 3 && item.CBN_status == 1) {
          //   item["CBN"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           requestByButton(item.ranSupportId, "CBN")
          //         }
          //       >
          //         Request CBN Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.CBN_status == 2) {
          //   item["CBN"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-warning p-1"
          //         style={{ minWidth: "100px", "backgroundColor": "rgb(0, 176, 240)" }}
          //         onClick={() =>
          //           CommonBulkUploaderModalFunction("CBN_Validation", "CBN Logs", item.siteId + "__" + item.ranSupportId)
          //         }
          //       >
          //         Requested CBN Test
          //       </button>
          //     ) : (
          //       ""
          //     );
          // }
          // if (item.CBN_status == 3) {

          //   item["CBN"] =
          //     userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //         onClick={() =>
          //           rerequestByButton(item.ranSupportId, "CBN")
          //         }
          //       >
          //         Completed
          //       </button>
          //     ) : (
          //       ""
          //     );
          //   // item["CBN"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
          // }
          // } 
          // else {
          //   item["RIOT"] = "NA";
          // }
          if((item.numberStatus == 6 || item.numberStatus == 7) && item.CBN_status != 0){

            if (item.RIOT_status == 3 && item.CBN_status == 1) {
              item["CBN"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      requestByButton(item.ranSupportId,"CBN")
                    }
                  >
                    Request CBN Test
                  </button>
                  ) : (
                    ""
                  );
            }
            else if (item.CBN_status == 2) {
              item["CBN"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px","backgroundColor":"rgb(0, 176, 240)"}}
                    onClick={() =>
                      CommonBulkUploaderModalFunction("CBN_Validation","CBN Logs",(item.siteId || item.ndResult?item.ndResult.siteId:"")+"__"+item.ranSupportId)
                    }
                  >
                    Requested CBN Test
                  </button>
                  ) : (
                    ""
                  );
            }
            else if (item.CBN_status == 3) {

              item["CBN"] =
                  userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                    <button
                    className="badge badge-success p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      rerequestByButton(item.ranSupportId,"CBN")
                    }
                  >
                    Completed
                  </button>
                  ) : (
                    ""
                  );
              // item["CBN"] = (<button className="badge badge-success p-1"style={{ minWidth: "100px" }}>Completed</button>);
            }
          }
          else if(item.CBN_status == 0 ){
            item["CBN"] = "NA"
          }
          
          else if((item.CBN_status == 1 || item.CBN_status == 2) && item.numberStatus == 8 ){
            console.log("riot 5")
            item["CBN"] = "NA"
          }

          else if(item.numberStatus >= 8 && item.CBN_status == 3 ){
            item["CBN"] = <button className="badge badge-success p-1"> Completed </button>
          }



          if (item.LU_status == 1) {
            item["LURequest"] =
              userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                <button
                  className="badge badge-warning p-1"
                  style={{ minWidth: "100px" }}
                  onClick={() =>
                    CommonModalFun(
                      <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />,
                      "Lock/Unlock Request",
                      "Lock/Unlock Request"
                    )
                  }
                >
                  Lock/Unlock Request
                </button>
              ) : (
                ""
              );
          }
          else if (item.LU_status == 2) {
            item["LURequest"] =
              userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                <button
                  className="badge p-1"
                  style={{ minWidth: "100px", "backgroundColor": "rgb(0, 176, 240)" }}
                  onClick={() =>
                    CommonModalFun(
                      <LockUnlockModalBody LU_Id={item.oLU_Id} apiType={"response"} oldData={item.last_lockunlock_ran_data} siteIdData={item.siteId} ranIdData={item.ranSupportId} />,
                      "Lock/Unlock Request",
                      "Lock/Unlock Request"
                    )
                  }
                >
                  Lock/Unlock Requested
                </button>
              ) : (
                ""
              );
          }
          else if (item.LU_status == 3) {
            item["LURequest"] = (<button className="badge badge-success p-1" style={{ minWidth: "100px" }}>Completed</button>);
          }

          // if (item.ctaenable == 1) {
          //   if (item.ennoSubmit) {
          //     item["LURequest"] = (
          //       <button
          //         className="badge badge-success p-1"
          //         style={{ minWidth: "100px" }}
          //       >
          //         Completed
          //       </button>
          //     );
          //   } else {
          //     item["LURequest"] =
          //       userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
          //         <button
          //           className="badge badge-warning p-1"
          //           style={{ minWidth: "100px" }}
          //           onClick={() =>
          //             CommonModalFun(
          //               <LockUnlockModalBody apiType={"request"} siteIdData={item.ndResult.siteId} ranIdData={item.ranSupportId}/>,
          //               "Lock/Unlock Request",
          //               "Lock/Unlock Request"
          //             )
          //           }
          //         >
          //           Lock/Unlock Request
          //         </button>
          //       ) : (
          //         ""
          //       );
          //   }
          // } else {
          //   item["LURequest"] = "NA";
          // }

          if (item.ctaenable == 1) {
            if (item.ennoSubmit) {
              item["NestExtend"] = (
                <button
                  className="badge badge-success p-1"
                  style={{ minWidth: "100px" }}
                >
                  Completed
                </button>
              );
            } else {
              item["NestExtend"] =
                userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                  <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      startcalltest(
                        item.dtSiteId,
                        item.dtprojectUniqueId,
                        item.ranSupportId
                      )
                    }
                  >
                    Nest Extend
                  </button>
                ) : (
                  ""
                );
            }
          } else {
            item["NestExtend"] = "NA";
          }

          if (item.ctaenable == 1) {
            if (item.ennoSubmit) {
              item["AdHOC"] = (
                <button
                  className="badge badge-success p-1"
                  style={{ minWidth: "100px" }}
                >
                  Completed
                </button>
              );
            } else {
              item["AdHOC"] =
                userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                  <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      startcalltest(
                        item.dtSiteId,
                        item.dtprojectUniqueId,
                        item.ranSupportId
                      )
                    }
                  >
                    AdHOC
                  </button>
                ) : (
                  ""
                );
            }
          } else {
            item["AdHOC"] = "NA";
          }

          if (item.ctaenable == 1) {
            if (item.ennoSubmit) {
              item["ODR"] = (
                <button
                  className="badge badge-success p-1"
                  style={{ minWidth: "100px" }}
                >
                  Completed
                </button>
              );
            } else {
              item["ODR"] =
                userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                  <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      startcalltest(
                        item.dtSiteId,
                        item.dtprojectUniqueId,
                        item.ranSupportId
                      )
                    }
                  >
                    On Dmand Request
                  </button>
                ) : (
                  ""
                );
            }
          } else {
            item["ODR"] = "NA";
          }

          if (item.ctaenable == 1) {
            if (item.ennoSubmit) {
              item["RSSI"] = (
                <button
                  className="badge badge-success p-1"
                  style={{ minWidth: "100px" }}
                >
                  Completed
                </button>
              );
            } else {
              item["RSSI"] =
                userDetails.rolename !== "GC Login" && item.RF_Approval == "Approved" && newscheduleDate.isSameOrBefore(now) ? (
                  <button
                    className="badge badge-warning p-1"
                    style={{ minWidth: "100px" }}
                    onClick={() =>
                      startcalltest(
                        item.dtSiteId,
                        item.dtprojectUniqueId,
                        item.ranSupportId
                      )
                    }
                  >
                    RSSI
                  </button>
                ) : (
                  ""
                );
            }
          } else {
            item["RSSI"] = "NA";
          }



          // dasdas

          item["LTPCR"] = (
            <p>
              {item.preCheckRequest ? getdatedatalistnext(item.preCheckRequest) : ""}
            </p>
          );

          item["assigningEngineerClone"] = item['assignedEngineer'] ? item['assignedEngineer'] : ""
          item["assigningEngineer"] = (
            <div
              className=""
              onClick={() =>
                userDetails.rolename == userDetails.rolename ?
                  CommonModalFun(
                    <AssignedEngineer mopRFSiteStatus={item.mopRFSiteStatus} from={"IX Dashboard"} unique_id_up={item.ranSupportId} />,
                    "Assign Engineer",
                    "Assign Engineer"
                  )
                  : ''
              }
            >
              {/* {userDetails.rolename != "PAG Admin" ? item['goNoGoRemark'] ? <p>{item['goNoGoRemark']}</p> : "" : ""} */}
              {userDetails.rolename == userDetails.rolename  && item.numberStatus < 8?
                item['assignedEngineer'] ? <><p style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} >{item['assignedEngineer']}</p></> :
                  <p className={'badge badge-primary fitContent'}><Add /></p> : item['assignedEngineer']}
            </div>)


          item["LTPCD"] = (
            <p>
              {item.preCheckDelivered
                ? getdatedatalistnext(item.preCheckDelivered)
                : ""}
            </p>
          );
          item["LTPOR"] = (
            <p>
              {item.postCheckRequest ? getdatedatalistnext(item.postCheckRequest) : ""}
            </p>
          );
          item["LTPOD"] = (
            <p>
              {item.postCheckDelivered
                ? getdatedatalistnext(item.postCheckDelivered)
                : ""}
            </p>
          );


          item["delete"] =
            checkRanStatus.indexOf(item["ranStatus"]) != -1 ? (
              <Link
                href="#"
                className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center p-2"
                onClick={() => deleteRanData(item.ranSupportId)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            ) : (
              ""
            );

          // if(item.ranStatus !="Login Awaited - CR Approved" && item.ranStatus !="Login Awaited - CR Approved")
          // else if(item.)
          let pSow = "";
          let pSowArray = item["sowList"]?item["sowList"].values:"";
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + " , ";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow);
          item["pSow"] = pSow;
          //Technology
          let tec = "";
          let techArray = item["technology"]?item["technology"].values :[];
          if (techArray && techArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", techArray.length);
            for (let i = 0; i < techArray.length; i++) {
              console.log("sgrthdhr", techArray[i]);
              tec += techArray[i] + " , ";
            }
            //  item['technology']=tec
          }
          console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["temp"] = tec;
          item["dayMopReasonModal"] =
            (
              <p
                className={"text-black my-0 modalHoverClass"}
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["dayMopReason"]}
                    </p>,
                    "Comment",
                    "Day Mop Reason"
                  )
                }
              >
                {wrapText(item["dayMopReason"])}
              </p>
            );
          // excelData=columns.map((oneColumn)=>
          // let excelObject={}
          // for(let i=0;i<columns.length;i++){

          //   excelObject[columns[i].label]='item['+columns[i].name + ']'

          // }
          // excelData.push(excelObject)

          // console.log("the excel data is =",excelData)
          // for(const innerkey in item){
          //   if(Array.isArray(item[innerkey] )){
          //     console.log("the value is =",innerkey ," thvalue is =",item[innerkey])
          //     item[innerkey]=""
          //   }
          //   else{
          //     console.log("the value is after else part=",innerkey," thvalue is =",item[innerkey])
          //   }
          // }
          // item["ixCrewDetails"]=''
          
          return item;
        });
        excelData = state.CommonResponse.listIXData.map((item) => {
          let tec = "";
          let techArray = item["techtake"] ? item["techtake"] : item.preHWLayerConfiguration?item.preHWLayerConfiguration.values:[];
          // if (techArray && techArray.length > 0) {
          //   //  item['technology'].forEach((item)=>{
          //   //   tec +=item.label +','
          //   //  })
          //   console.log("sgrthdhr", techArray.length);
          //   for (let i = 0; i < techArray.length; i++) {
          //     console.log("sgrthdhr", techArray[i]);
          //     tec += techArray[i] + ", ";
          //   }
          //   //  item['technology']=tec
          // }
          // console.log("item is tec =", tec);

          // //  console.log("item is =",item['technology'])
          // // item['technology']=tec
          item["preTech"] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"
              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                    }}
                    className="CommonModal"
                  >
                    {techArray.join(", ")}
                  </p>,
                  "Pre HW/Layer Configuration",
                  "Pre HW/Layer Configuration"
                )
              }
            >
              {wrapText(techArray.join(", "))}
            </p>
          ); 
          item["postTech"]=item.postHWLayerConfiguration?item.postHWLayerConfiguration.values?item.postHWLayerConfiguration.values.join(","):"":""
          let pSow = "";
          let pSowArray = item["sowList"]?item["sowList"].values:"";
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow);
          item["pSow"] = pSow;
          if (item["mopStartTime"] && item["mopEndTime"]) {
            let mopSTime = item["mopStartTime"].split(" ").pop();
            let mopETime = item["mopEndTime"].split(" ").pop();
            item["rfMWTime"] = mopSTime + " to " + mopETime;
          }
          let callActivity = "";
          if (item.ctaenable == 1) {
            if (item.ennoSubmit) {
              callActivity = "Completed";
            } else {
              callActivity = "Pending";
            }
          } else {
            callActivity = "N/A";
          }
          // let excelObject = {
          //   "Scheduled Date": item["scheduleDate"],
          //   "Week No.": item["scheduleWeek"],
          //   "ENTRY MODE": item["ranStatus"],
          //   "Service Affecting": item["serviceAffected"],
          //   // "Outage Approval?": item[''],
          //   "Site ID": item.ndResult.siteId,
          //   "Assigned Engineer": item["assignedEngineer"],
          //   // "Rf Approval MW Time": item["rfMWTime"],
          //   "Outage Approved MW Time": item["TMOOutageApprovedMWTime"],
          //   "Nesting Time": item["nestingTime"],
          //   // "Progress": item[''],
          //   "Schedule Source": item["requestForm"],
          //   "Project Code": item.ndResult.projectCode,
          //   "Technology": item["temp"],
          //   "GC Type": item["gcType"],
          //   "GC Vendor": item["GCVendor"],
          //   "GC Company": item["gcCompany"],
          //   "GC Name": item["GCName"],
          //   "GC Email": item["GCEmail"],
          //   "GC Contact": item["GCContact"],
          //   "TT-Id": item["ttId"],
          //   "PIERTT-Id": item["tmoPierTtid"],
          //   "CR No.": item["crNumber"],
          //   "Planned SoW": item["pSow"],
          //   "Day MOP or Night MOP": item["dayNightMop"],
          //   "Day MOP Reason": item["dayMopReason"],
          //   // "Manual Precheck Request": item[''],
          //   "Login Time PreChecks Requested": item["preCheckRequest"],
          //   "PRE-CHECKs": item["numberStatus"] == 5 ? "Completed" : "",
          //   "Log in time PreCheck Delivered": item["preCheckDelivered"],
          //   // "Crew": item[''],

          //   "Call Test Activity": callActivity,
          //   // "Manual Postcheck Request": item[''],
          //   "Logout Time PostChecks Requested": item["preCheckRequest"],
          //   // "Post Checks": item[''],
          //   "Log out time Postchecks delivered": item["preCheckDelivered"],
          //   "Pier Updated": item[""],
          //   "Site Type": "",
          // };

          let excelObject = {
            "Scheduled Date": item["scheduleDate"],
            "Week #.": item["scheduleWeek"],
            "Site Status": item["ranStatus"],
            "Service Affecting": item["serviceAffected"],
            "Outage Approval?": item['RF_Approval'],
            "Site ID": item.siteId,
            "Assigned Engineer": item["assignedEngineer"],
            // "Rf Approval MW Time": item["rfMWTime"],
            "Outage Approved MW Time": item["TMOOutageApprovedMWTime"],
            "Nesting Time": item["nestingTime"],
            // "Progress": item[''],
            "Schedule Source": item["requestForm"],
            "Project Code": item.projectCode,
            "Technology": item["temp"],
            "GC Type": item["gcType"],
            "GC Company": item["gcCompany"],
            "GC Vendor": item["GCVendor"],

            "GC Name": item["GCName"],
            "GC Email": item["GCEmail"],
            "GC Contact": item["GCContact"],
            "Mpulse TT-Id": item["ttIds"],
            "PIERTT-Id": item["pierttIds"],
            "CR Number": item["crNumber"],
            "CR Status": item.ranStatus == "Login Request - CR Approval Requested" ? "Approve" : "Approved",
            "Planned SoW": item["pSow"],
            "Day MOP or Night MOP": item["dayNightMop"],
            "Day MOP Reason": item["dayMopReason"],
            // "Manual Precheck Request": item[''],
            "Login Time PreChecks Requested": item["preCheckRequest"],
            "PRE-CHECKs": item["numberStatus"] == 5 ? "Completed" : "",
            "Log in time PreCheck Delivered": item["preCheckDelivered"],
            // "Crew": item[''],

            "Call Test Activity": callActivity,
            // "Manual Postcheck Request": item[''],
            "Equipment": item["equipment"],
            "Logout Time PostChecks Requested": item["preCheckRequest"],
            // "Post Checks": item[''],
            "Log out time Postchecks delivered": item["preCheckDelivered"],
            "Pier Updated": item[""],
            "Site Type": item["siteType"],
          };//Ran Dashboard




          // let excelObject = {
          //   "Schedule Date": item["mopStartTime"],
          //   "Main Id": item.ndResult.siteId,
          //   "Service Affected": item["serviceAffected"],
          //   "Technology": item["temp"],
          //   "Project Code": item["projectCode"],
          //   "RAN Bucket": item["ranBucket"],
          //   "Cr Number": item["crNo"],
          //   "Cr Status": item["crStatus"],
          //   "Planned SOW": item["pSow"],
          //   "RAN Status": item["ranStatus"],
          //   "Equipment": item["equipment"],
          //   "Day or Night MOP": item["dayNightMop"],
          //   "Day MOP Reason": item["dayMopReason"],
          //   "Rf Approval MW Time": item["rfMWTime"],
          //   // "Manual PreCheck Request": item["mPrecheckReq"],
          //   "Login Time PreCheck Request(mobile)": dbdatetodate(item.preCheckRequest),
          //   "Pre-Checks": '',// item["preCheck"],
          //   "Login Time Precheck Delivered": item[""],// item["LTPCD"],
          //   "Nesting Time": item["mopDownTime"],
          //   // "Progress": item["progress"],
          //   "Crew": "",// item["crew"],
          //   "Call Test Activity": item[""],// item["CTA"],
          //   "Manual Postcheck Request": item[""],// item["postCh"],
          //   "Logout Time Postcheck Request": item[""],// item["LTPOR"],
          //   "Post Checks": item[""],// item["postChecks"],
          //   "Logout Time Postcheck Delivered": item[""],// item["LTPOD"],
          //   "Pier Updated": item[""],
          //   // "Site Type": item.ndResult.siteType
          // }
          return excelObject;
        });


        console.log("jdbcjhbsdaikc", state);
        // const rowDataOne = state.CommonResponse.listIXData;
        // if (rowDataOne > 0) {
        //   Object.keys(rowDataOne).map((key, index) => {
        //     console.log(key, "key");
        //     columns.push({
        //       name: key,
        //       selector: key,
        //       sortable: true,
        //     });
        //   });
        // }
      }
    // }

    // console.log("columnsrowData",rowData, state.sites.sites.TotalTicket);

    // let keyValues=Object.keys(columns)
    // let tableArrray=[]
    // console.log("the keys is -=",keyValues)
  //   for(let j=0;j<rowData.length;j++){
  //     for(let i=0;i<keyValues.length;i++){
  //          let newObject={}      
  //       // if(keyValues[i].name==rowData[i][keyValues[i].name]){
  //         newObject[keyValues[i].name]=rowData[j][keyValues[i].name]
  //         tableArrray.push(newObject)
  //       // }
  //       console.log("the table format=",tableArrray)
  //   }
  // }
    return { rowData, columns, mark, siteStatus };
  });
console.log("the row data is =",rowData)
  // console.log()
  const options = {
    download: false,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    responsive: true,
  };

  const mapStyles = {
    width: "100%",
    height: "100%",
  };


  // const ClearFilter = () => {
  //   console.log("ClearFilter", "ClearFilter");
  //   setfstartdate();
  //   setfenddate();

  //   dispatch(AccountReducerAction(ixModule));
  // };

  const ClearFilter = () => {
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();
    setOneTimere(true)
    setResetFilter(true)
    setexportApiData(ixDasboard)
    setFilterBadge()
    let Filleddata=dispatch(ResetLCLixDashboardfilter())
    dispatch(AccountReducerAction(ixDasboard+Filleddata,true,true));
  };

  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value);
  };

  
  function filterhandler() {
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(commonfilters))
    // dispatch(AccountReducerAction(ranfilters))
    setcommonModalStateFilter(true)
    setcommonModalSizeFilter("xl")
    setcommonModalBodyFilter(<IXFilter setfullUrL={setfullUrL} setcommonModalState={setcommonModalStateFilter} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitleFilter("IX Filters")
    setcommonModalHeaderFilter("IX Filter")
  }

  const AddCustomButton = () => (
    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>


      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ix/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>

      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",

          margin: "18px 10px auto 10px"
        }}
      >
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          popperPlacement='left'
          selected={fstartdate}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          popperPlacement='left'
          selected={fenddate}
          onChange={(e) => {
            setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>
      {/* {userDetails.rolename !== "GC Login" && (
        <div
          style={{
            marginTop: "17px",
            borderRadius: "10px",
            padding: "12px",
            height: "38px",
          }}
          className="btn-xs  btn-primary d-flex justify-content-center align-items-center"
          onClick={() => history.push("/PAG/RanSupportticket")}
        >
          On The Fly
        </div>
      )} */}


      {userDetails.rolename !== "GC Login" && (
        <button
          className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
          data-toggle="modal"
          data-target="#exampleModall"
          onClick={() => setMapModal(true)}
        >
          Map View
        </button>
      )}

      <ExportToExcel
        apiData={excelData}
        fileName={"ixDashboard_" + moment(new Date()).format("MMDDYYYY")}
      />
    </fregment>
  );
  // console.log("yhe row data isn=", rowData);
  const addCustomButtons = () => (
    <ExportToExcel
      apiData={excelData}
      fileName={"IXDashboard_" + moment(new Date()).format("MM/DD/YYYY")}
    />
  );
  let filteredDataii = [];
  function addtomap(action, tableState) {
    console.log(tableState.columns.findIndex, tableState.columns.findIndex(element => element.name == "ranSupportId"), tableState, "e.tableState")
    console.log(tableState, "tableStateinrandashboard")

    let filli = tableState.displayData.map((e) => {
      console.log(e, "e.data")
      filteredDataii.push("hello")


      return e.data[tableState.columns.findIndex(element => element.name == "ranSupportId")]
    })

    if (filliopen) {
      console.log(filli, "fillifilli")
      setfilteredData(filli)
      setfilliopen(false)
    }

  }
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  console.log("the excel data is =", excelData);
  useEffect(() => {
    dispatch(clearIxDashboard())
    // dispatch(clearIxDashboard())
    dispatch(AccountReducerAction(ixModule));
    dispatch(AccountReducerAction(ixSiteStatus));
    dispatch(AccountReducerAction(getAllDropDown));
    dispatch(AccountReducerAction(listSiteId));

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);

  const sortedOrder = {
    // name: 'scheduleDate',
    // direction: 'desc'
  }
  return (
    <>
      {/* Independent modal */}

      {/* <CustomeModal
        xlShow={mapModal}
        setXlShow={setMapModal}
        Body={MapView}
        modalSize={"xm"}
      /> */}


      <CustomeModal
        xlShow={mapModal}
        setXlShow={setMapModal}
        Body={RanMapView}
        filteredMapData={filteredData}
        Header={"MAP View"}
        modalSize={"xm"}
      />
      <CommonModal
        modalSize={commonModalSizeFilter}
        setXlShow={setcommonModalStateFilter}
        xlShow={commonModalStateFilter}
        Body={commonModalBodyFilter}
        Header={commonModalHeaderFilter}
        Title={commonModalTitleFilter}
      ></CommonModal>

      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>

      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setPreValidation}
        xlShow={preVal}
        Body={<div>
          <form onSubmit={handleSubmit(handleSubmitPre)} className='form-row'>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PreVSCFAvailability")
                } />
              <label className="ml-2">SCF Availability</label>
            </div>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PreVRfdsHwCheck")
                }
              />
              <label className="ml-2">RFDS - HW check</label>
            </div>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PreVPortMatrixConnectionCheck")
                }
              />
              <label className="ml-2"> Port Matrix - Connection Check</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PreVRemoteConnectivityParametersCheck")
                }
              />
              <label className="ml-2"> Remote connectivity Parameters Check</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PreVPlanVerification")
                }
              />
              <label className="ml-2">Plan verification</label>
            </div>
            <div className="col-md-8" style={{ display: "flex", flexDirection: "column", marginTop: '1rem' }}>
              <label>Remarks</label>
              <textarea maxlength="500" rows="4" cols="50" style={{ padding: '10px', borderWidth: '1px', borderStyle: 'solid', width: '100%', marginTop: '0.6rem' }}
                {
                ...register("preValidationRemarks")
                }
              />
            </div>
            {/* <div className="form-group col-md-3">
            <label>SSV Status</label>
            <select className='form-group'
            {
                ...register("ssvStatus")
            } >
            <option selected disabled value={''}>Select</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Submitted"}>Submitted</option>
            <option value={"Approved"}>Approved</option>
            </select>
        </div> */}
            <div className="col-md-12 mt-4" style={{ display: "content", display: 'flex', justifyContent: 'center' }}>
              <button type='submit' className='badge mx-auto badge-success'>Submit</button>
            </div>
          </form>
        </div>}
        Header={'Pre Validation'}
        Title={'Pre Validation'}
      ></CommonModal>

      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setPostValidation}
        xlShow={postVal}
        Body={<div>
          <form onSubmit={handleSubmit(handleSubmitPost)} className='form-row'>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVSectorSwap")
                } />
              <label className="ml-2">Sector Swap</label>
            </div>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVFiberSwap")
                }
              />
              <label className="ml-2">Fiber Swap</label>
            </div>
            <div className="col-md-4 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVSyncSetting")
                }
              />
              <label className="ml-2">Sync Setting</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVRETValidation")
                }
              />
              <label className="ml-2"> RET validation</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVVSWR")
                }
              />
              <label className="ml-2"> VSWR</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVRTWP")
                }
              />
              <label className="ml-2"> RTWP</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVSFP")
                }
              />
              <label className="ml-2"> SFP</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVallPlanExecuted")
                }
              />
              <label className="ml-2">All Plan executed</label>
            </div>
            <div className="col-md-4 mt-3 d-flex align-items-center">

              <input type="checkbox"
                {
                ...register("PostVEAC")
                }
              />
              <label className="ml-2" >EAC</label>
            </div>
            <div className="col-md-8" style={{ display: "flex", flexDirection: "column", marginTop: '1rem' }}>
              <label>Remarks</label>
              <textarea maxlength="500" rows="4" cols="50" style={{ padding: '10px', borderWidth: '1px', borderStyle: 'solid', width: '100%', marginTop: '0.6rem' }}
                {
                ...register("postValidationRemarks")
                }
              />
            </div>
            {/* <div className="form-group col-md-3">
            <label>SSV Status</label>
            <select className='form-group'
            {
                ...register("ssvStatus")
            } >
            <option selected disabled value={''}>Select</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Submitted"}>Submitted</option>
            <option value={"Approved"}>Approved</option>
            </select>
        </div> */}
            <div className="col-md-12 mt-4" style={{ display: "content", display: 'flex', justifyContent: 'center' }}>
              <button type='submit' className='badge mx-auto badge-success'>Submit</button>
            </div>
          </form>
        </div>}
        Header={'Post Validation'}
        Title={'Post Validation'}
      ></CommonModal>

      <Modal
        size="xl"
        show={xlShow}
        onHide={() => setXlShow(false)}
        aria-labelledby="example-modal-sizes-title-xl"
        backdrop="static"
      >

        {console.log(siteUId, "siteUId", siteUId)}
        <Modal.Header closeButton className="CommonModalHeader">
          <Modal.Title id="example-modal-sizes-title-xl" >
            <h4 className="CommonModalHeaderHeading" style={{"width":"100%"}}><p>Site  - {SiteNId}</p></h4>
            <button className="close CommonModalClose" onClick={() => setXlShow(false)}><span className="CommonModalClose">&times;</span></button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >

          {/* <div className='mt-2'> */}
          {/* <SingleProject id={siteUId} /> */}
          {/* </div>  */}

          {/* <Row>
            <Col xl={8}>
              <Card>
                /<Card.Header>
                  <Card.Title>Summary</Card.Title>
                </Card.Header>
                <Card.Body>
         
                  <div className='default-tab'>
                    <Tabs
                      defaultActiveKey="ran"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="site" title="Site Details">

                        <ProjectData id={siteUId} />


                      </Tab> */}

          {/* Site view start */}
          {/* <Tab eventKey="ran" title="RAN Historical" >
                        <RanHistorical id={siteUId} />
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
        


          </Row> */}

          <ManageProjectDetail />

        </Modal.Body>
      </Modal>


      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      {/*modal section closed*/}

      {count.siteStatus.length > 0 && userDetails.rolename == userDetails.rolename ? (
        // <RanSiteTracker from={"ix"} addClass={"ransiteTracker"} siteStatus={count.siteStatus} />
        <RanSiteTracker from={"ix"} addClass={"ransiteTracker"} siteStatus={count.siteStatus} setReset={setResetFilter} featureExport={setexportApiData} />
      ) : (
        <p>Please wait</p>
      )}

      <div className="row">
        {/* <button variant="float-right" className="btn btn-primary btn-xs mx-2 px- my-3 float-right " onClick={() => setDatePicker(!showDatePicker)}><i class="fa fa-filter mx-2"></i>Filter</button> */}
        <div
          className={
            accordianState
              ? "col-12 card px-3 pt-3 pb-0 showAccordian"
              : "col-12 card px-3 pt-3 pb-0 hideAccordian"
          }
          style={{ display: accordianState ? "block" : "none" }}
        >
          <form
            onSubmit={submitHandler}
            encType="multipart/form-data"
            className="mx-2"
          >
            <div className="input-group mb-3 fileSection">
              <div>
                <label class="custom-file-upload btn btn-outline-info btn-xs col-md-12">
                  <input
                    type="file"
                    className="form-control btn btn-primary btn-xs"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    onChange={uploadCsvFile}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                {/* <label>Remarks</label>
              <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}
              </div>

              <button
                className="btn btn-outline-primary btn-xs col-xm-12"
                type="submit"
                id="inputGroupFileAddon04"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        {/*Date Filter */}

        {/* <div
          className="col-12"
          style={{ display: showDatePicker ? "block" : "none" }}
        >
          <div className="card">
            <div className="card-header bg-primary">
              <div className="card-title">
                <i class="fa fa-filter mx-2"></i>
                Filter
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={(e) => setDateFilter(e)}>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                />
                <Button type="submit" className="btn-info btn-xl ml-2">
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div> */}

        <div className="card col-12" style={{ bottom: "0px" }}>
          {/* Modal */}
          {crewModal ? (
            <CustomeModal
              style={{ background: "#143b64" }}
              xlShow={crewModal}
              setXlShow={setCrewModal}
              Header={"Crew Detail"}
              Body={CustomTable}
              callFunction={openOntheFlyForm}
              tableHeader={cxTableHeader}
              tableHeaderSecond={ixTableHeader}
              siteDetail={siteDetail}
            />
          ) : nestingTiime ? (
            <CustomeModal
              xlShow={nestingTiime}
              setXlShow={setNestingTime}
              Header={"Nesting Time"}
              Body={NestingTimeComponent}
              modalSize="xl"
              nestDetail={nestDetail}
            />
          ) : (
            <CustomeModal
              xlShow={reqPreModal}
              setXlShow={setReqPreModal}
              Header={"Request Precheck"}
              Body={RequestPreCheck}
              uniqueId={uniqueId}
            />
          )}


          <CommonBulkUploaderModal typeForm={CommonBulkUploaderModalName} from={ranDashboard} fileshow={CommonBulkUploaderModalState} shortCode={CommonBulkUploaderModaldataurlState} setfileShow={setCommonBulkUploaderModalState} formIx={"True"}></CommonBulkUploaderModal>

          {console.log(count.rowData, columns, "count.rowData")}
          <div style={{ marginTop: "-40px" }}>
            {" "}
            <ThemeProvider theme={getMuiThemeCommon()}>
              <MUIDataTable
                data={Array.isArray(rowData) ? rowData : []}
                columns={columns}
                options={customDataTableOptionObject(AddCustomButton, false, "ranDashbord", setfilliopen, addtomap, sortedOrder)}
              />
            </ThemeProvider>
          </div>

          {/* <DataTableExtensions 
						
						columns={columns}
						data={rowData}
						exportHeaders={true}
						
						>
						<DataTable
              columns={count.columns}
              data={count.rowData}
							theme='solarized'
							defaultSortAsc={false}
							pagination
							highlightOnHover
						/>
						</DataTableExtensions> */}
        </div>
      </div>
    </>
  );
};

export default IXDashboard;
