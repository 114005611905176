import React, { useEffect, useState, ComponentType, type } from "react";
import { useDispatch, useSelector } from "react-redux";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import { mopDashSiteStatus, ranSiteStatus, ttDashSiteStatus } from "../../../common/config";
import { AccountReducerAction } from "../../../store/actions/createTicketManageFields";
import { Tab, Tabs } from "react-bootstrap";
import Mop from "../Mop/Mop";


const ProjectList = () => {



    const dispatch = useDispatch();


    const [ResetFilter, setResetFilter] = useState(false);
    const [fullUrL, setfullUrL] = useState('');
    
    const [exportApiData, setexportApiData] = useState("");
    const [counter, setcounter] = useState("");
    
    const count = useSelector((state) => {

        let ranSiteStatus = state.CommonResponse.ranSiteStatus;
        // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)

        let mopsiteStatus = state.CommonResponse.mopSiteStatus;
        let ttSiteStatus = state.CommonResponse.ttSiteStatus;


        return { ranSiteStatus,mopsiteStatus,ttSiteStatus };
    })



    useEffect(() => {

        // dispatch(AccountReducerAction(mopDashSiteStatus))
        // dispatch(AccountReducerAction(ttDashSiteStatus))
        // dispatch(AccountReducerAction(ranSiteStatus));
    }, [counter])

    return (
        <>


            {/* <Tabs
                // defaultActiveKey={accordianOperner}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e) => {
                    // accordianchange(e)
                }}
            >
                <Tab eventKey="MOP" title="MOP DASHBOARD">
                    <div className="accordion-body">

                        <Mop></Mop>


                    </div>
                </Tab>
                <Tab eventKey="RAN" title="RAN DASHBOARD">
                    <div className="accordion-body">
                        <RanSiteTracker from={"ran"} addClass={"ransiteTracker"} siteStatus={count.ranSiteStatus} setReset={setResetFilter} featureExport={setexportApiData} />
                    </div>
                </Tab>
                <Tab eventKey="TT" title="TT DASHBOARD">
                    <div className="accordion-body">
                        <RanSiteTracker from={"tt"} addClass={"ttsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.ttSiteStatus} setReset={setResetFilter} featureExport={setexportApiData}/>
                    </div>
                </Tab>
                <Tab eventKey="enno" title="E911 DASHBOARD">
                    <div className="accordion-body">
                        <RanSiteTracker from={"tt"} addClass={"ttsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.ttSiteStatus} setReset={setResetFilter} featureExport={setexportApiData}/>
                    </div>
                </Tab>
                <Tab eventKey="rtt" title="RTT DASHBOARD">
                    <div className="accordion-body">
                        <RanSiteTracker from={"tt"} addClass={"ttsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.ttSiteStatus} setReset={setResetFilter} featureExport={setexportApiData}/>
                    </div>
                </Tab>
                <Tab eventKey="rfp" title="RF PERFORMANCE">
                    <div className="accordion-body">
                        <RanSiteTracker from={"tt"} addClass={"ttsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.ttSiteStatus} setReset={setResetFilter} featureExport={setexportApiData}/>
                    </div>
                </Tab>
                <Tab eventKey="72hr" title="72 HOURS">
                    <div className="accordion-body">
                        <RanSiteTracker from={"tt"} addClass={"ttsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.ttSiteStatus} setReset={setResetFilter} featureExport={setexportApiData}/>
                    </div>
                </Tab>
            </Tabs> */}
            {/* {count.siteStatus.length > 0 && userDetails.rolename == userDetails.rolename ? ( */}


            {/* ) : ( 
        <p></p>
      )} */}
        </>
    );
};

export default ProjectList
