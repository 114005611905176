import React from "react";
import Modal from 'react-bootstrap/Modal';
import MapView from "./Modal Body/MapView";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";

const CommonModal = ({ modalSize, xlShow, setXlShow, Header, Body, Title,isOverflow=false }) => {
  const history = useHistory()
  return (
    <Modal  
    backdrop="static"
      className="modal-content-h-80"
      style={{ border : "1px solid red" }}
      // size={modalSize}
      size={modalSize || "xl"} 
      show={xlShow}
      onHide={() => {setXlShow(false)}}
      aria-labelledby="example-modal-sizes-title-xl"
    >
      <Modal.Header className="CommonModalHeader" style={{    padding: "0.3rem 1.875rem"}}>
        <Modal.Title id="example-modal-sizes-title-xl ">
          <h5 className="CommonModalHeaderHeading p-0">{Header}</h5>
          <Button
            variant=""
            className="close CommonModalClose"
            onClick={() => {setXlShow(false)}}
          >
            <span className="CommonModalClose">&times;</span>
          </Button>
        </Modal.Title>
      </Modal.Header>
      {/* <div className="CommonModalTitleSection">
        <h4 className="CommonModalTitle p-1">{Title}</h4>
      </div> */}
      <Modal.Body className={isOverflow?"CommonModalBody mt-2":`CommonModalBody mt-2 CommonModal`}>

        {/* <div  className="mt-2 CommonModal"> */}
          {Body}
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
