import React, { Component, useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { common } from '../../../../services/AdminService';
import { VendorScoreCardApi, firstNest, ranfilters, scoreCard, scorecardpiefilters, secondNest, secondStage } from '../../../../common/config';
import { AccountReducerAction, AccountReducerActionWithoutLoad, ChartStageWise, VendorScoreCardReducerAction, funVendorScoreCard, getBarChartData, setLoaderAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from "react-datepicker";
//var CanvasJSReact = require('@canvasjs/react-charts');
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import swal from "sweetalert";
import moment from "moment";
import { MultiSelect } from 'react-multi-select-component';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const ChartBasedFilter = ({ CBF, divBox, setCBF }) => {

    const dispatch = useDispatch()


    const [legend, setLegend] = useState([])
    const [filterBadges, setFilterBadges] = useState([])
    const [globalFilter, setGlobalFilter] = useState({
        "impact": [],
        "ttStatus": [],
        "serviceAffected": []
    })
    const [fstartdate, setfstartdate] = useState();
    const [fenddate, setfenddate] = useState();
    let [openFilter, setFilter] = useState(false)
    let [SecondGraph, setSecondGraph] = useState([])
    let [AccountGraph, setAccountGraph] = useState("")
    let compOptionArry = []
    let marketArry = []
    let typeOfWorkArry = []
    let vendorsArry = []
    let psowArry = []
    let siteID = []
    let assignedEngineer = []
    let dayNightMop = []

    const [options, setoptions] = useState({
        animationEnabled: true,
        exportEnabled: false,
        theme: "light1", // "light1", "dark1", "dark2"
        title: {
            text: "SCORECARD"
        },
        data: [{
            type: "pie",
            indexLabel: "{label}: {y}%",
            startAngle: 0,
            dataPoints: []
        }]
    })


    const count = useSelector((state) => {
        console.log("the state is =", state);
        let filterObj = state.OneCommonResponse.filterDropDown[divBox]
        // console.warn("jxhbkjvbjacvkecfrwgvw=", filterObj)
        console.log("jxhbkjvbjacvkecfrwgvw=", divBox, filterObj)
        if (filterObj && Object.keys(filterObj).length > 0) {

            typeOfWorkArry = filterObj?.ServiceImpact ? filterObj?.ServiceImpact.map((item, index) => {
                return <option value={item}>{item}</option>
            }) : []
            psowArry = filterObj?.ttStatus ? filterObj?.ttStatus.map((item, index) => {
                return <option value={item}>{item}</option>
            }) : []
            vendorsArry = filterObj?.serviceAffecting ? filterObj?.serviceAffecting.map((item, index) => {
                return <option value={item}>{item}</option>
            }) : []
            // siteID = filterObj?.siteIdes.map((item, index) => {
            //     return <option value={item}>{item}</option>
            // })
            // dayNightMop = filterObj.serviceAffecting.map((item, index) => {
            //     return <option value={item}>{item}</option>
            // })
            // assignedEngineer = filterObj.assignedEngineer.map((item, index) => {
            //     if (index == 0) {
            //         return <option disabled selected value={""}>Select</option>
            //     }
            //     return <option value={item}>{item}</option>
            // })
        }

        // typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
        // siteID.unshift(<option disabled selected value={""}>Select</option>)
        // dayNightMop.unshift(<option disabled selected value={""}>Select</option>)
        // assignedEngineer.unshift(<option disabled selected value={""}>Select</option>)
        // psowArry.unshift(<option disabled selected value={""}>Select</option>)


        console.log(compOptionArry, "compOptionArrycompOptionArrycompOptionArry")

        let dropDown = {
            // "siteId": { "label": "Site ID", "values": siteID, "dType": "S" },
            // "crewDataResult.crewLeadCompanyId": { "label": "GC Company", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={compOptionArry} onChange={(e) => { handleChange("company", e) }} value={globalFilter["ServiceImpact"]} /></>, "dType": "S" },
            // "crewDataResult.vendorId": { "label": "Hard Cost Vendor", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={vendorsArry} onChange={(e) => { handleChange("vendor", e) }} value={globalFilter["ttStatus"]} /></>, "dType": "S" },
            // "market": { "label": "Market", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={marketArry} onChange={(e) => { handleChange("market", e) }} value={globalFilter["serviceAffecting"]} /></> },
            "requestForm": {
                "label": "Service Impact", "values": <select defaultValue={CBF.serviceImpact} onChange={(e) => {
                    setCBF((prev) => {
                        return {
                            ...prev,
                            "impact": e.target.value
                        }
                    })


                    const swdata = {
                        ...CBF,
                        "impact": e.target.value
                    };

                    const params = new URLSearchParams(swdata);
                    const queryString = params.toString();
                    console.log(queryString);



                    dispatch(funVendorScoreCard([]))
                    dispatch(VendorScoreCardReducerAction(VendorScoreCardApi + "Trouble Ticket" + "/" + "T-Mobile?"+queryString))
                }}>{typeOfWorkArry}</select>


            },
            "plannedSow": {
                "label": "TT Status", "values": <select defaultValue={CBF.ttStatus} onChange={(e) => {
                    setCBF((prev) => {
                        return {
                            ...prev,
                            "ttStatus": e.target.value
                        }
                    })

                    const swdata = {
                        ...CBF,
                        "ttStatus": e.target.value
                    };

                    const params = new URLSearchParams(swdata);
                    const queryString = params.toString();
                    console.log(queryString);



                    dispatch(funVendorScoreCard([]))
                    dispatch(VendorScoreCardReducerAction(VendorScoreCardApi + "Trouble Ticket" + "/" + "T-Mobile?"+queryString))

                }}>{psowArry}</select>
            },
            "dayNightMop": {
                "label": "Service Affecting", "values": <select defaultValue={CBF.serviceAffected} onChange={(e) => {
                    setCBF((prev) => {
                        return {
                            ...prev,
                            "serviceAffected": e.target.value
                        }
                    })

                
                    const swdata = {
                        ...CBF,
                        "serviceAffected": e.target.value
                    };

                    const params = new URLSearchParams(swdata);
                    const queryString = params.toString();
                    console.log(queryString);



                    dispatch(funVendorScoreCard([]))
                    dispatch(VendorScoreCardReducerAction(VendorScoreCardApi + "Site Downtime" + "/" + "T-Mobile?"+queryString))

                }}>{vendorsArry}</select>
            },
            // "assignedEngineer": { "label": "Assigned Engineer", "values": <select>{assignedEngineer}</select>, "dType": "S" }
        }



        return ({ filterObj, dropDown })
    })


    console.log(count, "cmpcountcountcount")

    // useEffect(() => {
    //     dispatch(AccountReducerActionWithoutLoad(scorecardpiefilters, false))
    //     let dataArr = []
    //     dispatch(setLoaderAction(true))
    // }, [props.filter])





    const SetResetFilterGlobal = (e) => {
        e.preventDefault();

        console.log("fstartdate", fstartdate, "fstartdate")


        setGlobalFilter({
            "company": [],
            "vendor": [],
            "market": []
        })

        setFilterBadges([])

        setfenddate()
        setfstartdate()


        // setFilter(false)
        // props.setType(false)
        // props.setStageTwo(false)



        dispatch(getBarChartData({}))
    }
    const SetFilterGlobal = (e) => {
        e.preventDefault();

        console.log("fstartdate", fstartdate, "fstartdate")


        if (fstartdate != undefined && fenddate == undefined) {
            swal("Oops", "Please select End Date", "info");
            return;
        }
        if (fenddate != undefined && fstartdate == undefined) {
            swal("Oops", "Please select Start Date", "info");
            return;
        }


        console.log(globalFilter, "globalFilter")
        // dateRange[0].startDate = formatDate(dateRange[0].startDate);
        // dateRange[0].endDate = formatDate(dateRange[0].endDate);
        // console.log("the filtered data=", dateRange);


        let badgemaker = []

        let finalcompany = globalFilter["company"].map((itm) => {

            badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
            return encodeURIComponent(itm.value)
        })
        let finalvendor = globalFilter["vendor"].map((itm) => {
            badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
            return encodeURIComponent(itm.value)
        })
        let finalmarket = globalFilter["market"].map((itm) => {
            badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
            return encodeURIComponent(itm.value)
        })




        console.log(finalcompany.join("_filter_"), finalvendor.join("_filter_"), finalmarket.join("_filter_"), "finalcompany,finalvendor,finalmarket")

        let strFilter = ""


        if (finalcompany.length > 0) {
            let newstrFilter = (strFilter != "" ? "&" : "?") + "company=" + finalcompany.join("_filter_")
            strFilter = strFilter + newstrFilter
        }

        if (finalvendor.length > 0) {
            let newstrFilter = (strFilter != "" ? "&" : "?") + "vendor=" + finalvendor.join("_filter_")
            strFilter = strFilter + newstrFilter
        }

        if (finalmarket.length > 0) {
            let newstrFilter = (strFilter != "" ? "&" : "?") + "market=" + finalmarket.join("_filter_")
            strFilter = strFilter + newstrFilter
        }


        console.log(strFilter, "strFilterstrFilter")



        if (fstartdate != undefined && fenddate != undefined) {
            let start = moment(fstartdate).format("MM-DD-YYYY");
            let end = moment(fenddate).format("MM-DD-YYYY");
            let newstrFilter = (strFilter != "" ? "&" : "?") + "start=" + start + "&end=" + end
            strFilter = strFilter + newstrFilter

            badgemaker.push(<p className='badge badge-info'>{moment(fstartdate).format("MM/DD/YYYY")}</p>)
            badgemaker.push(<p className='badge badge-info'>{moment(fenddate).format("MM/DD/YYYY")}</p>)

        }
        setFilterBadges(badgemaker)


        console.log("finalApiCallerUrl", strFilter)

        // props.setFilter(strFilter)
        let currentUrl = window.location.href;
        // props.setType(false)


        dispatch(getBarChartData({}))
    };
    let currentDate = new Date()
    let previousDate = currentDate
    previousDate.setMonth(currentDate.getMonth() - 1)
    let handleChange = (dropDownName, e) => {


        console.log(dropDownName, "globalFilter")


        setGlobalFilter({
            ...globalFilter,
            [dropDownName]: e
        })

    }


    console.log(globalFilter, "globalFilter")


    const dateMyStyle = {
        border: "2px solid #1d548f !important",
        height: "24px",
        borderRadius: "4px !important"
    }

    let index = 0
    return (
        <>
            {filterBadges?.length > 0 ? <p style={{ color: "black", border: "0px solid black" }} className={"badge"}>Selected Filter : &nbsp;&nbsp;</p> : ""}{filterBadges}

            <div className='row'>
                {
                    divBox == "Trouble Ticket" && <div className='col-md-2'>
                        <label style={{ color: "white" }}>{count.dropDown["requestForm"]["label"]}</label>
                        {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"market"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                        {/* <datalist id={"market"}> */}
                        {count.dropDown["requestForm"]["values"]}
                        {/* </datalist> */}

                    </div>
                }

                {
                    divBox == "Trouble Ticket" && <div className='col-md-2'>
                        <label style={{ color: "white" }}>{count.dropDown["plannedSow"]["label"]}</label>
                        {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"vendor"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                        {/* <datalist id={"vendor"}> */}
                        {count.dropDown["plannedSow"]["values"]}
                        {/* </datalist> */}



                    </div>
                }

                {
                    divBox == "Site Downtime" && <div className='col-md-2'>
                        <label style={{ color: "white" }}>{count.dropDown["dayNightMop"]["label"]}</label>
                        {/* <input className='form-control' onChange={(e) => handleChange("company", e)} list={"companny"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                        {/* <datalist id={"companny"}> */}
                        {count.dropDown["dayNightMop"]["values"]}
                        {/* </datalist> */}

                    </div>
                }


                {/* {

                    ["Site Downtime","Trouble Ticket"].indexOf(divBox) != -1 && <> 
                    <div className='col-md-1.5'>
                        <button
                            className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "40px!important;" }}
                            // onClick={() => setDatePicker(!showDatePicker)}
                            onClick={(e) => SetFilterGlobal(e)}
                        >
                            <i class="fa fa-filter mx-2"></i>Filter
                        </button>

                    </div>


                        <div className='col-md-1.5'>
                            <button
                                className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                                style={{ marginTop: "40px!important;" }}
                                // onClick={() => setDatePicker(!showDatePicker)}
                                onClick={(e) => SetResetFilterGlobal(e)}
                            >
                                <i class="fa fa-filter mx-2"></i>Reset
                            </button>

                        </div>
                    </>
                } */}

            </div>
        </>
    );
};


export default ChartBasedFilter




