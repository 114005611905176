import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';
import ReCAPTCHA from "react-google-recaptcha";
//
import logo from '../../images/mobilecomm_logo.png'
import mpag_logo from '../../images/mpag_Logo.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";

function Login(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false)

  function onCaptchChange(value) {
    setIsChecked(Boolean(value))
    console.log(Boolean(value))
  }

  function onLogin(e) {
    e.preventDefault();

    if(!isChecked){
    alert("Please check the I'm not a robot")
     return
     }
    let error = false;
    const errorObj = { ...errorsObj };

    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  return (
    <div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <div className="login-wrapper">
        <div className="login-aside-left" style={{ backgroundImage: "url(" + login + ")" }}>
          <a href="https://www.mcpsinc.com/" target='_blank' className="login-logo">
            <img className='mpulse_Logo' src={mpag_logo} alt="" />
            <img className='mobileComm_Logo' src={logo} alt="" />
          </a>
          <div className="login-description">
            {/* <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                        </ul> */}
            <div style={{ position: "relative", zIndex: 999, "justify-content": "center" }} className="d-flex align-items-center nowrap py-4">
              <small> <a href="https://www.mcpsinc.com/privacy-policy" target="_blank" className="text-black mr-4">Privacy Policy</a></small>
              {/* <small> <a href="/login" className="text-black" target="_blank">Contact</a></small>    */}
              <small className="text-black" >Copyright © MobileComm Professionals Inc 2023</small>
            </div>

          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-white mb-1">Welcome to MPAG</h3>
                        <p className="">Sign in by entering information below</p>
                      </div>
                      {props.errorMessage && (
                        <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={onLogin}>
                        <div className="form-group">
                          <label className="mb-2  text-white">
                            <strong>Email</strong>
                          </label>
                          <input type="email" className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                          <label className="mb-2 text-white " ><strong>Password</strong></label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) =>
                              setPassword(e.target.value)
                            }
                          />
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                        </div>
                        {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox ml-1 ">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="basic_checkbox_1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="basic_checkbox_1"
                                        >
                                          Remember my preference
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}
                       <div style={{ paddingLeft : "0.4rem" , paddingTop : "1rem"}}>
                       <ReCAPTCHA
                          sitekey="6Lc3uMklAAAAAKPby36s68rbvyLKTs14H8Lab6Oy"
                          onChange={onCaptchChange}
                        />
                       </div>
                        <div className="text-center mt-4">
                          <button
                            style={{ backgroundColor: "#999999", color: "white" }}
                            type="submit"
                            className="btn  btn-block  "
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                      {/* <div className="new-account mt-2">
                                  <p className="">
                                    Don't have an account?{" "}
                                    <Link className="text-primary" to="./page-register">
                                      Sign up
                                    </Link>
                                  </p>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);