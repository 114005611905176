import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FetchSingleRecordAction, addUserAction } from '../../../../store/actions/createTicketManageFields';
import { LSSVDashboardAPI, SSVDashboardAPI } from '../../../../common/config';
const moment = require('moment');
const SSSCOP = (props) => {
  const dispatch = useDispatch()
  const styles = {
    width: "400px",
  };

  let [SSSCOPSubmitDate, setSSSCOPSubmitDate] = useState()
  let [SSSCOPApprovalDate, setSSSCOPApprovalDate] = useState()
  let [AssignPODate, setAssignPODate] = useState()
  let [statusforremark, setstatusforremark] = useState("")
  let count = useRef(1)

  const { register, getValues, setValue, handleSubmit, setError, formState: { errors } } = useForm()
  const option = useSelector((state) => {
    let fetchData = state.OneCommonResponse.singleIXSite
    if (fetchData && Object.keys(fetchData).length > 0) {
      if (count.current == 1) {
      //   if (fetchData.SSSCOPSubmitDate) {
      //     setSSSCOPSubmitDate(new Date(fetchData.SSSCOPSubmitDate))
      //   }
      //   if (fetchData.SSSCOPApprovalDate) {
      //     setSSSCOPApprovalDate(new Date(fetchData.SSSCOPApprovalDate))
      //   }
      //   if (fetchData.AssignPODate) {
      //     setAssignPODate(new Date(fetchData.AssignPODate))
      //   }

        setstatusforremark(fetchData.SSSCOPStatus)
        
        setValue("SSSCOPStatus", fetchData.SSSCOPStatus, { shouldTouch: true, shouldValidate: true })
        setValue("RemarksLSSVSSSCOPStatus", fetchData["RemarksLSSVSSSCOPStatus"])
        // setValue("SSSCOPStatus", fetchData.SSSCOPStatus)
        count.current = 2
      }
    }

  })
  const onSubmit = (data) => {
    // data["SSSCOPSubmitDate"] = moment(SSSCOPSubmitDate).format("MM/DD/YYYY")
    // data["SSSCOPApprovalDate"] = moment(SSSCOPApprovalDate).format("MM/DD/YYYY")
    // data["AssignPODate"] = moment(AssignPODate).format("MM/DD/YYYY")

    data["SSVStatus"]=data["SSSCOPStatus"]
    data["ssvapprovedallowed"]=true
    console.log("tkljlkjlkjlkjhe data is knbjkdhjebvevker=", data)
    
    if(props.from=="ssv"){
      dispatch(addUserAction(SSVDashboardAPI + "/" + props.id, data))
    }
    
    if(props.from=="lssv"){
      dispatch(addUserAction(LSSVDashboardAPI + "/" + props.id, data))
    }
  }
  useEffect(() => {

    // from={"ssv"}
    if(props.from=="ssv"){
      dispatch(FetchSingleRecordAction(SSVDashboardAPI, props.id))
    }
    
    if(props.from=="lssv"){
      dispatch(FetchSingleRecordAction(LSSVDashboardAPI, props.id))
    }
  }, [])
  return (
    <form className='row' onSubmit={handleSubmit(onSubmit)}>
      {/* <div className='form-group  col-6'>
        <label>SSS COP Submit Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={SSSCOPSubmitDate}
          onChange={(e) => {
            setSSSCOPSubmitDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
      </div> */}

      {/* <div className='form-group  col-6'>
        <label>SSS COP Approval Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={SSSCOPApprovalDate}
          onChange={(e) => {
            setSSSCOPApprovalDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
      </div> */}
      <div className='form-group  col-12'>
        <label>{props.valueFrom} COP Status</label>
        <select className='form-control' {...register("SSSCOPStatus", {
          required: "Status is Required",
          onChange:(e)=>{
            setstatusforremark(e.target.value)
          }
        })} >
          <option value="Approved">Approve</option>
          <option value="Rejected">Reject</option>
          <option value="Pending">Pending</option>
          {/* <option value="Pass">Pass</option>
          <option value="Fail">Fail</option>
          <option value="Warning">Warning</option>
           */}
        </select>

        {errors.SSSCOPStatus && (
          <p className="error">
            {errors.SSSCOPStatus.message}
          </p>
        )}

        {/* <input type="text" className="form-control" {...register("SSSCOPStatus")} /> */}
      </div>
      <div className='form-group  col-12'>
        <label>Remarks</label>
        {/* <label>Comments - NNP & SSS (Date, issue & action item)</label> */}
        <input type="text" className="form-control" {...register("RemarksLSSVSSSCOPStatus", {
          required: statusforremark=="Rejected"?"Remarks is required":false
        })} />

        {errors.RemarksLSSVSSSCOPStatus && (
          <p className="error">
            {errors.RemarksLSSVSSSCOPStatus.message}
          </p>
        )}
      </div>
      {/* <div className='form-group  col-6'>
        <label>Assign/PO Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={AssignPODate}
          onChange={(e) => {
            setAssignPODate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
      </div> */}
      <div className='col-12 d-flex justify-content-center'>
        <button className="btn btn-primary btn-xs py-2 px-4 mt-2" type='submit'>
          Submit
        </button>
      </div>
    </form>
  )
}

export default SSSCOP