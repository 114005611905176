require('dotenv').config()

// export const baseURL= `https://pagapi.fourbrick.in`;
// export const socketbaseURL= `https://pagapi.fourbrick.in`;
// export const baseURL= `http://127.0.0.1:8095`;
//  export const baseURL= `http://ixapi.fourbrick.in`   

export const baseURL= `https://demoapi.mpulsenet.com/`;
export const socketbaseURL= `https://demoapi.mpulsenet.com`;

export const getToken = "/sendUser/data"
export const projectBucketApi="/PAG/listBucket"

export const projectStatusApi="/PAG/listStatus"

export const accountApi ="/PAG/account"

export const addAcccount ="/PAG/account"

export const deleteAcccount ="/PAG/account"

export const crewCompanyApi ="/PAG/crewCompany"

export const addCrewCompany ="/PAG/crewCompany"

export const deleteCrewCompany ="/PAG/crewCompany"

export const deleteUser ="/deleteUser"

export const listUserApi ="/listUser?userrole=Cluster Engineer,FOPS Manager,FOPS Engineer,RF Engineer,Site Development Manager"

// export const listUserApi_two ="/listUser?userrole=Vendor (ASP) IX Field Engineer Company,Vendor (SP) CX Tower Crew Company,Vendor (SP) CX Crew Manager,Vendor (ASP) IX Crew Manager"
 
export const listUserApi_two ="/listUser?userrole=Tower Crew,GC Login"

// export const listTmobileUserApi ="/listUser?userrole=MobileTeam Manager"


export const listTmobileUserApi ="/listUser?userrole=RF Engineer,FOPS,Development,PAG Admin,PAG Engineer,No MOP Access,MobileTeam Manager,TruNorthe   "

export const listRoleApi ="/listRole"

export const listRole_twoApi ="/listRole_two"


export const listRole_two_tmobile ="/listRole_two_tmobile"

export const listRole_tmobile ="/listRole_tmobile"

export const ranDeleteData ="/PAG/operation_ran/"
export const ixDeleteData ="/PAG/operation_ix"



export const addUser ="/addUser"

export const updateUser ="/updateUser"

export const listOneUser ="/listOneUser"

export const getMarket  ="/PAG/market"

export const addMarketApi ="/PAG/market"

export const deleteMarket ="/PAG/market"

export const fetchFilterUser ="/filterlistUser"

export const allTechnologyApi   ="/PAG/technology"

export const allEquipmentApi ="/PAG/equipment"

export const deleteOssApi   ="/PAG/oss"

export const vendorApi ="/PAG/vendor"

export const MOPInstructionApi ="/PAG/MOPInstruction"

export const timelineApi ="/PAG/site_logs/"

export const ranDashboardTimeLine ="/PAG/ran_site_logs/"

export const addVendor ="/PAG/vendor"

export const deleteVendor ="/PAG/vendor"
export const ossApi="/PAG/oss"

export const ossAddApi ="/PAG/oss"

export const singleProjectApi="/PAG/OneNetworkDatabase"

export const ranAddSupport   ="/PAG/multi_onthefly"

export const updateranticket   ="/PAG/multi_updateranticket"

export const troubleTicketApi   ="/PAG/troubleTicket"

export const enooaddApi ="/PAG/enoo"
export const listennoTicketApi ="/PAG/listenno"

export const listtroubleTicketApi ="/PAG/listTroubleTicket"
export const ranDashboard ="/PAG/RanDashboard"
export const OneRanTech ="/PAG/OneRanTech"

export const ranSpilOver ="/PAG/RanSpilOver"
export const ranNesting_report ="/PAG/ranNesting/report"
export const ranNesting_tracker ="/PAG/ranNesting/tracker"



export const mopSiteStatus="/PAG/changemopProcessSiteStatus"

export const goNoGoAction="/PAG/goNoGo/change"


export const rfApprovalActionChange="/PAG/rfApprovalActionChange"


// test build

export const ttcmts="/PAG/ttcmts"

export const trafficCheckCmts="/PAG/trafficCheckCmts"

export const addFDatatoran="/PAG/addFDatatoran"

export const addRiotData="/PAG/addRiotData"

export const addLockUnlockData="/PAG/Lock_Unlock"

export const ranSiteStatus ="/PAG/ran/siteStatus"

export const ixSiteStatus ="/PAG/ix/siteStatus"

export const mopDashSiteStatus ="/PAG/mop/siteStatus"

export const ttDashSiteStatus ="/PAG/tt/siteStatus"
    
export const networkDashbordSiteHistory ="/PAG/grouplistStatus"

export const listSiteId ="/PAG/listSite"
export const ranlistSite ="/PAG/ranlistSite"
export const siteDblistSiteId ="/PAG/sitedblistSite"

export const crNumberList ="/PAG/crnumber_op"

export const oneRfdsdata ="/PAG/rfdsList"

export const addCrNumber  ="/PAG/crnumber"

export const approveOrDisapprove ="/PAG/crnumber_op/"

export const changeButtonStatus ="/PAG/changeButtonStatus"

export const ranTracker ="/PAG/RanTracker"

export const mailSender ="/mailSender"

export const reqPreCheck   ="/PAG/manual/requestPreCheck/"

export const reqPreCheckCancel ="/PAG/cancel/requestPreCheck/"


export const reqPostCheckCancel ="/PAG/cancel/requestPostCheck/"

export const reqPost  ="/PAG/requestPostCheck/"

export const  runPreCheck ="/PAG/prechecking/"

export const getAllDropDown ="/PAG/manage/dropDowns"



export const check_sla_met_api ="/PAG/check_sla_met_api"





export const getAllDropDownList ="/PAG/manage/dropDownlist"



export const runPostCheck ="/PAG/runpostCheck/"

export const  troubleTicket ="/PAG/manage/troubleTicket"

export const  projectCode ="/PAG/OneSiteProjectCode"

export const  ranOneSiteProjectCode ="/PAG/ranOneSiteProjectCode"

export const  networkOneSiteDatabaseprojectId ="/PAG/networkOneSiteDatabaseprojectId"

export const  NetworkDatabaseUrl ="/PAG/NetworkDatabase"

export const  OneSiteDatabaseProjectCode ="/PAG/OneSiteDatabaseProjectCode"

export const  OneSiteDatabase ="/PAG/OneSiteDatabase"

export const multiRFDS ="/multi_pdf_upload"

export const CsvUpload ="/csv_upload"

export const auth_pdf_upload ="/auth_pdf_upload"

export const ChunksCsvUpload ="/chunks/csv_upload"



export const singleRFDS ="/pdf_upload"

export const groupByoss="/PAG/groupByoss"

export const  updateGCpagdata= "/updateGCpagdata/"
export const  updatemop= "/updatemop/"
export const  copymop= "/copymop/"

export const  mopAssigner= "/mopAssigner/"
export const  updatefops= "/updatefops/"
export const  updatedevelopment= "/updatedevelopment/"
export const  updaterfengineer= "/updaterfengineer/"

export const  dynamic_dashboard= "/dashboard"

export const  networkDBLoggger= "/networkDBLoggger"

export const  networkDBMerger= "/networkDBMerger"

export const mopDataApi ="/listMops"

export const bulkUpload ="/updatebulkrfengineer"


export const sla_remarks_add ="/PAG/sla_remarks_add"

export const OneSiteDatabaseProjectId ="/PAG/OneSiteDatabaseprojectId"

export const ranOneSiteDatabaseprojectId ="/PAG/ranOneSiteDatabaseprojectId"

export const tierapi ="/PAG/tier"

export const managEngineer ="/PAG/manageEngineer"

export const managTMOEngineer ="/PAG/tmoManager"


export const ranSla ="/PAG/RanSla"

export const apiPreCheckTool ="/tmoposttool"
export const apiixPreCheckTool ="/tmoposttool_ix"

export const nestingOurApi ="/PAG/nestingTime"


export const ttCountVendor ="/PAG/ttCountVendor"


export const dashboardVendor ="/PAG/vendorDashboard"


export const ttAgingVendor ="/PAG/ttAgingCount"


export const sitesCountCustomer ="/PAG/ttCountCustomer"


export const dashboardCustomer ="/PAG/customerDashboard"


export const sitesAgingCustomer ="/PAG/sitesAgingCount"


export const warRoomActivity ="/PAG/warRoomActivity"

export const customerDashboard ="/PAG/customerDashboard"


export const geoFencing ="/PAG/geoFencing"

export const allMapSites ="/PAG/allMapSites"

export const crewHandoverApi ="/PAG/ranCrewHandover"

export const ChatfileUpload="/chat_file_upload"

export const ixModule="/PAG/ixDashboard"

export const rttDashboard="/PAG/rttDashboard"

export const ixDasboard ="/PAG/ix/module"

export const awsDashboard = '/PAG/aws_callLogs'


export const aws_callLogsdb = '/PAG/aws_callLogsdb'


export const aws_callLogschartdb = '/PAG/aws_callLogschartdb'




// export const rttDashboard ="/PAG/rtt/dashboard"
// ============================EXCEL EXPORTER===========================================

export const ixValidation= "/updateValidation/"

export const export_mop = '/PAG/export/mop'


export const templatelssvurl = '/PAG/template/lssv'

export const templatessvurl = '/PAG/template/ssv'

export const export_ran_dashboard = '/PAG/export/ran/dashboard'

export const export_inflight_dashboard = '/PAG/export/inflight/dashboard'

export const export_magenta_dashboard = '/PAG/export/magenta/dashboard'

export const export_networkMDB_dashboard = '/PAG/export/networkMDB/dashboard'

export const export_ran_tracker = '/PAG/export/ran/tracker'

export const export_ran_daily = '/PAG/export/ran/daily'

export const export_sla_ran_report = '/PAG/export/ran/sla'

export const export_Spillover_ran_report = '/PAG/export/ran/spilover'

export const export_nesting_ran_report = '/PAG/export/ran/nestingreport'

export const export_nesting_ran_tracker = '/PAG/export/ran/nestingtracker'

export const export_tt_tracker = '/PAG/export/tt/tracker'

export const export_tt_dashboard = '/PAG/export/tt/dashboard'

export const export_enoo_dashboard = '/PAG/export/enoo/dashboard'

export const export_enoo_tracker = '/PAG/export/enoo/tracker'

export const timeZoneApi ="/PAG/timeZone"

export const manageutilizationApi ="/PAG/manage/utilization"

export const pierTTGroupapi ="/PAG/pierTTGroup"


export const zipUpploader="/zip_uploader"

export const SevenTwoAlarm ="/PAG/72alarm/"

export const updateSite ="/updateIxSiteStatus"

export const sevenTwoActivePending="/upadatesevenTwoAlarm"

export const mobileDatacoptl="/PAG/mobileDatacoptl/"
export const ssvApi ="/PAG/rfPerformance/ssv"

export const wildCardSevenTwo="/PAG/sevenTwo/add"

export const SSVDashboardAPI ="/PAG/ssvDashboard"

export const LSSVDashboardAPI ="/PAG/lSSVDashboard"



export const SSVApproverDashboardAPI ="/PAG/ssvApproverDashboard"

export const LSSVApproverDashboardAPI ="/PAG/lSSVApproverDashboard"



export const SevenTwoAlarmFetchSingle ="/PAG/72alarm"
// export const addRTTForms ="/PAG/sevenTwo/add"
export const addRTTForms ="/PAG/rttDashboard/add"

export const mopFilters="/mopfilters"

export const ranfilters="/ranfilters"

export const ttfilters="/ttfilters"

export const enoofilters="/enoofilters"

export const userTableData="/PAG/userTableData"

export const changePasswordApi="/PAG/changePassword"



export const nwfilters = "/nwfilters"

export const commonfilters="/commonfilters"

export const ssvfilters="/ssvfilters"

export const lsvfilters="/lssvfilters"




export const VendorScoreCardApi="/VendorScoreCard/"

export const VendorScoreCardDashboard="/VendorScoreCardDashboard"

export const scorecardpiefilters="/scorecardpiefilters"

export const scoreCard="/vendorScoreCard/pie"

export const firstNest="/scoreCardFilter/firstNest/"

export const secondNest= "/scoreCardFilter/secondNest/"

export const thirdNest="/scoreCardFilter/third/"

export const managevendorscorecardApi="/PAG/manage/vendorscorecard"

export const secondPie ="/scoreCardFilter/secondPie/"

export const secondStage="/scoreCardFiltersecondStage/"


export const PreSwapChecklistApi="/PAG/PreSwapChecklist"



