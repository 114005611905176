import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Table2 = ({id}) => {
     




  const dispatch =useDispatch()
  // let siteDetail={siteInfo:{}}
   let siteInfo={}
// const[data,setData]=useState([]);
  // let  siteDetail={siteInfo:{}}
  let siteDetail =useSelector((state)=>{
    console.log("the url =",state)
  let site =state.OneCommonResponse.singleSite
  
  if(typeof(site)== typeof({}) ){
    siteInfo=site
    console.log("th e sit e is sit=",siteInfo)
    return {siteInfo}
  }
  
  })
  return (
    <div>  <table className=" table-bordered" style={{ width: "100%"}}>
       
    <tr>
      <th>Account </th>
      <td >{siteDetail? siteDetail.siteInfo.account:""}</td>
    </tr>
    <tr>
      <th >MARKET</th>
      <td >{siteDetail? siteDetail.siteInfo.market:""}</td>
    </tr>

      
    <tr>
      <th >Cabinet ID	</th>
      <td ></td>
    </tr> 
    <tr>
      <th>CX Crew Vendor	</th>
      {/* <td >{siteDetail? siteDetail.siteInfo.cxCrewId.name:""}</td> */}
    </tr>
      
         
    <tr>
      <th >CX Crew Lead Contact Number</th>
      <td >{siteDetail?siteDetail.siteInfo.cxCrewId? siteDetail.siteInfo.cxCrewId.mobilenumber:"":''}</td>
    </tr>
        
 
    <tr>
      <th>CX Crew Name	</th>
      <td >{siteDetail?siteDetail.siteInfo.cxCrewId? siteDetail.siteInfo.cxCrewId.name:"":''}</td>
    </tr>
      
        
    <tr>
      <th>CX Crew Lead Company</th>
      <td >Nokia</td>
    </tr>

    <tr>
      <th >CX Crew Lead E-mail ID	:</th>
      <td >{siteDetail?siteDetail.siteInfo.cxCrewId? siteDetail.siteInfo.cxCrewId.email:"":''}</td>
    </tr>
 
    <tr>
      <th>IX Crew Vendor	</th>
      {/* <td >{siteDetail? siteDetail.ixCrewId.name:''}</td> */}
    </tr>

    <tr>
      <th >IX Crew Lead Contact Number</th>
      <td >{siteDetail?siteDetail.siteInfo.ixCrewId? siteDetail.siteInfo.ixCrewId.mobilenumber:"":''}</td>
    </tr>

    <tr>
      <th>IX Crew Name	</th>
      <td >{siteDetail?siteDetail.siteInfo.ixCrewId? siteDetail.siteInfo.ixCrewId.name:"":''}</td>
    </tr>

        
    <tr>
      <th>IX Crew Lead Company</th>
      <td >Nokia</td>
    </tr>

    <tr>
      <th >IX Crew Lead E-mail ID	:</th>
      <td >{siteDetail?siteDetail.siteInfo.ixCrewId? siteDetail.siteInfo.ixCrewId.email:"":''}</td>
    </tr>

    <tr>
      <th >Service Affecting:</th>
      <td >{siteDetail?siteDetail.siteInfo.ixCrewId? siteDetail.siteInfo.serviceAffected:"":''}</td>
    </tr>

    <tr>
      <th >Day MOP or Night MOP:</th>
      <td >{siteDetail? siteDetail.siteInfo.mop:""}</td>
    </tr>
    <tr>
      <th>Reason for Day MOP	
  :</th>
      <td >{siteDetail? siteDetail.siteInfo.dayMopReason:""}</td>
    </tr>

    <tr>
      <th >Outage Approval:</th>
      <td >pending</td>
    </tr>
    <tr>
      <th >Deployment Manager		:</th>
      <td >pending</td>
    </tr>
    <tr>
      <th>Planned SoW	</th>
        <td>{
              siteDetail? siteDetail.siteInfo.sowDetails?siteDetail.siteInfo.sowDetails.values.map((item)=>{
                return item+","
              })
              :'':''
            }
            </td>
    </tr>

    <tr>
      <th >Outage Approved MW Time</th>
      <td >pending</td>
    </tr>
    <tr>
      <th >Cancellation Reason(if any)</th>
      <td >pending</td>
    </tr>
  </table></div>
  )
}

export default Table2;