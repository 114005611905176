import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { common } from '../../services/AdminService';
import swal from "sweetalert";
import { FirstPageGone } from '../../common/commondata';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function customLogin(reqUrl,token,history){
    return (dispatch)=>{
        common(reqUrl+token).then((response)=>{
            if(response.data){
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                // console.log(response.data.rolename=="GC Login",FirstPageGone[response.data.rolename]==undefined?'/dashboard':FirstPageGone[response.data.rolename],"response")
                dispatch(loginConfirmedAction(response.data));
                console.log(response.data.rolename, response.data.mopAccess, "checker")
                if (response.data.rolename == "GC Login") {
                    if (response.data.mopAccess) {
                        console.log(response.data.rolename, response.data.mopAccess,FirstPageGone["GC Login True"], "checker")
                        history.push(FirstPageGone["GC Login True"])
                    } else {
                        console.log(response.data.rolename, response.data.mopAccess,FirstPageGone["GC Login False"], "checker")
                        history.push(FirstPageGone["GC Login False"])
                    }
                }else{
                    FirstPageGone[response.data.rolename] == undefined ? history.push('/dashboard') : history.push(FirstPageGone[response.data.rolename])
                }
            }else if (response.status == 401) {
                console.log(response)
                swal(response.data.msg)
            }
        })
        .catch((error)=>{
            
            console.log(error.response.data.msg,"errorerrorerror")
            swal(error.response.data.msg)
            const errorMessage = formatError(error);
            dispatch(loginFailedAction(errorMessage));
            window.location.href="https://dev.mpulsenet.com/signin"

        })
    }
}

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                console.log(response, "Auth Action :50")
                if (response.status == 200) {
                    saveTokenInLocalStorage(response.data);
                    runLogoutTimer(
                        dispatch,
                        response.data.expiresIn * 1000,
                        history,
                    );


                    // console.log(response.data.rolename=="GC Login",FirstPageGone[response.data.rolename]==undefined?'/dashboard':FirstPageGone[response.data.rolename],"response")

                    dispatch(loginConfirmedAction(response.data));
                    console.log(response.data.rolename, response.data.mopAccess, "checker")
                    if (response.data.rolename == "GC Login") {
                        if (response.data.mopAccess) {

                            console.log(response.data.rolename, response.data.mopAccess,FirstPageGone["GC Login True"], "checker")
                            history.push(FirstPageGone["GC Login True"])
                        } else {

                            console.log(response.data.rolename, response.data.mopAccess,FirstPageGone["GC Login False"], "checker")
                            history.push(FirstPageGone["GC Login False"])
                        }
                    }else{
                        FirstPageGone[response.data.rolename] == undefined ? history.push('/dashboard') : history.push(FirstPageGone[response.data.rolename])
                    }

                    // history.push('/dashboard');

                } else if (response.status == 401) {
                    console.log(response)
                    swal(response.data.msg)
                }


                //window.location.reload();

                //history.pushState('/index');

            })
            .catch((error) => {
                console.log(error.response.data.msg,"errorerrorerror")
                swal(error.response.data.msg)

                const errorMessage = formatError(error);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
