import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import { AccountReducerAction, FetchSingleRecordAction, PreSwapChecklistAction, PreSwapChecklistActionSubmit, UserListReducerAction } from '../../../store/actions/createTicketManageFields';
import { OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, PreSwapChecklistApi, allTechnologyApi, baseURL, crewCompanyApi, listUserApi_two, siteDblistSiteId } from '../../../common/config';
import { useForm } from 'react-hook-form';
import "../../../css/style.css"
import "../../../css/custom.css"

import DatePicker from "react-datepicker";
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { connectStorageEmulator } from 'firebase/storage';
import TableComponent from '../../../common/TableComponent';
import DownloadNew from '../../../icons/Svg/DownloadNew';
import CommonBulkUploaderButton from '../Custom/CommonBulkUploaderButton';
import { Upload } from '@mui/icons-material';
import { MultiSelect } from 'react-multi-select-component';
import { mopcommonsowDetail } from '../../../common/variables';


const PreSwapChecklist = () => {


    let dispatch = useDispatch()

    const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm({});

    const [siteFile, uploadedFile] = useState();
    const [TotalSubmitter, setTotalSubmitter] = useState([]);

    // let  = []÷
    const [OpenShow, setOpenShow] = useState(false);
    let [sow, setSow] = useState([])
    let [seltechOutage, setselTechOutage] = useState([])
    let [selTech, setselTech] = useState([])

    let [finalData, setfinalData] = useState([])
    let allSiteList = useSelector((state) => {
        let dataall = state?.CommonResponse?.startAwaitedSite.map((itew) => {
            return <option value={itew.uniqueSiteId}>{itew.siteId}</option>
        })
        return dataall
    })

    let allProductList = useSelector((state) => {
        let dataall = state?.OneCommonResponse?.productCode.map((itew) => {
            return <option value={itew.uniqueid}>{itew.projectCode}</option>
        })
        return dataall
    })


    let allProjectList = useSelector((state) => {
        let dataall = state?.OneCommonResponse?.productId.map((itew) => {
            return itew?.data.map((itew) => {
                return <option value={itew.uniqueid}>{itew.projectId}</option>
            })
            // return <option value={itew.uniqueid}>{itew.projectCode}</option>
        })
        return dataall
    })

    // OneCommonResponse.productCode
    let allpreswapchecklistoption = useSelector((state) => {
        let dataall = state?.CommonResponse?.preswapchecklist.map((itew) => {
            return <option value={itew.parentId}>{itew.parentName}</option>
        })
        return dataall
    })

    let allpreswapchecklist = useSelector((state) => {
        let dataall = state?.CommonResponse?.preswapchecklist
        return dataall
    })


    let allpreswapcheckOldList = useSelector((state) => {
        let dataall = state?.CommonResponse?.preswapchecklistOld
        return dataall
    })


    let alloflistuser = useSelector((state) => {
        let dataall = state?.CommonResponse?.listuser.map((itew) => {
            return <option value={itew.uniqueid}>{itew.name}</option>
        })
        return dataall
    })

    let alloftechnology = useSelector((state) => {
        let dataall = state?.CommonResponse?.technology.map((itew) => {
            return {
                label: itew.technologyName,
                value: itew.uniqueid
            }
        })
        return dataall
    })






    // listuser




    console.log(allSiteList, finalData, "allSiteListallSiteList")

    const getSiteData = (sitename) => {
        setOpenShow(false)

        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, sitename))
        setValue("sitename", sitename)
        console.log(sitename, "allSiteListallSiteList")
    }

    const getProductData = (sitename) => {
        setOpenShow(false)

        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, sitename))
        setValue("sitename", sitename)
        console.log(sitename, "allSiteListallSiteList")
    }

    const getProjectData = (sitename) => {
        setOpenShow(true)
        // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, sitename))
        dispatch(PreSwapChecklistAction(PreSwapChecklistApi + "/" + sitename))
        setValue("sitename", sitename)
        console.log(sitename, "allSiteListallSiteList")
    }





    const getparentpreswapchecklist = (uid) => {
        setValue("parentpreswapcheck", uid)
        console.log(allpreswapchecklist.findIndex(itm => (itm.parentId == uid)), "allSiteListallSiteList")
        console.log(uid, "allSiteListallSiteList")
        let ind = allpreswapchecklist.findIndex(itm => (itm.parentId == uid))

        let dataAll = allpreswapchecklist[ind]
        console.log(dataAll, "dataAlldataAlldataAll")
        let optionsAll = dataAll.childs.map((itw) => {
            return <option value={itw.uniqueId}>{itw.name}</option>
        })
        setfinalData(optionsAll)
    }

    const getchildpreswapchecklist = (uid) => {
        setValue("childpreswapcheck", uid)
        console.log(uid, "getchildpreswapchecklist")

    }


    const setUpchoice = (uid) => {
        setValue("choice", uid)

    }


    // const uploadCsvFile = (e) => {
    //     console.log(e.target.files[0]);
    //     uploadedFile(e.target.files[0]);
    // };


    const uploadCsvFile = (uniqueId, e) => {

        console.log("loggerdataamitloggerdataamit")
        let oneInd = TotalSubmitter.findIndex(itm => itm.uniqueId == uniqueId)

        // let onew = TotalSubmitter[oneInd]

        // // onew["file"] = e.target.files[0]

        // // console.log(e.target.files[0], oneInd, uniqueId, "loggerdataamit");
        // // uploadedFile(e.target.files[0]);


        if (oneInd == -1) {
            let onew = {
                "textarea":"",
                "checked":e,
                "uniqueId":uniqueId,
                "file":e.target.files[0]
            }
            TotalSubmitter.push(onew)
        }
        else {
            let onew = TotalSubmitter[oneInd]
            onew["file"] = e.target.files[0]
        }
    };


    const checkedValue = (uniqueId, e) => {

        console.log("loggerdataamitloggerdataamit")
        let oneInd = TotalSubmitter.findIndex(itm => itm.uniqueId == uniqueId)
        if (oneInd == -1) {
            let onew = {
                "textarea":"",
                "checked":e,
                "uniqueId":uniqueId,
                "file":null
            }
            TotalSubmitter.push(onew)
        }
        else {
            let onew = TotalSubmitter[oneInd]
            onew["checked"] = e
        }
    };


    console.log(TotalSubmitter,"TotalSubmitter")
    const textareacheckedValue = (uniqueId, e) => {
        let oneInd = TotalSubmitter.findIndex(itm => itm.uniqueId == uniqueId)
        if (oneInd == -1) {
            let onew = {
                "textarea":e,
                "checked":false,
                "uniqueId":uniqueId,
                "file":null
            }
            TotalSubmitter.push(onew)
        }
        else {
            let onew = TotalSubmitter[oneInd]
            onew["textarea"] = e
        }
    };



    const onSubmit = (data) => {

        console.log(TotalSubmitter,data,"TotalSubmitterTotalSubmitter")
        const form = new FormData();
        form.append("uploadFile", siteFile);
        Object.keys(data).map((item) => {
            form.append(item, data[item])
        })

        TotalSubmitter.map((item) => {

            console.log(item,"itemitem")
            // form.append(item, data[item])
        })

        console.log(form,"TotalSubmitterTotalSubmitter")

        // dispatch(PreSwapChecklistActionSubmit(PreSwapChecklistApi, form, data.sitename))
    }


    const handleSowChanges = (e) => {
        console.log(e, "e")
        setSow(e)
        console.log("the e=2", e)
        // setSowError(false)
    }


    const techOutagehandleChange = (e) => {
        // console.log("multiselect=", tech)
        // setTechnologyError('')
        setselTechOutage(e)
    }

    const techhandleChange = (e) => {
        // console.log("multiselect=", tech)
        // setTechnologyError('')
        setselTech(e)
    }




    useEffect(() => {
        dispatch(AccountReducerAction(siteDblistSiteId))
        dispatch(PreSwapChecklistAction(PreSwapChecklistApi))

        dispatch(UserListReducerAction(listUserApi_two))
        dispatch(AccountReducerAction(allTechnologyApi));

    }, [])
    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
                className="mx-2"
            >
                <div className='row'>


                    <div className='col-md-3'>
                        <label>{"Site Id"}</label>
                        <select
                            {...register("siteId", {
                                required: "Please Select Site Id",
                            })}
                            onChange={(e) => { getSiteData(e.target.value) }}>
                            <option value="">Select</option>
                            {allSiteList}
                        </select>
                    </div>

                    <div className='col-md-3'>
                        <label>{"Project Code"}</label>
                        <select
                            {...register("projectCode", {
                                required: "Please Select Project Code",
                            })}
                            onChange={(e) => { getProductData(e.target.value) }}>
                            <option value="">Select</option>
                            {allProductList}
                        </select>
                    </div>

                    <div className='col-md-3'>
                        <label>{"Project Id"}</label>
                        <select
                            {...register("projectId", {
                                required: "Please Select Project Id",
                            })}
                            onChange={(e) => { getProjectData(e.target.value) }}>
                            <option value="">Select</option>
                            {allProjectList}
                        </select>
                    </div>


                    <div className='col-md-3'>
                        <label>{"GC"}</label>
                        <select
                            {...register("gc", {
                                required: "Please Select GC"
                            })}
                            onChange={(e) => { console.log(e.target.value) }}>
                            <option value="">Select</option>
                            {alloflistuser}
                        </select>
                    </div>


                    <div className='col-md-3'>
                        <label>{"Technology"}</label>
                        <MultiSelect
                            className="text-dark"
                            hasSelectAll={false}
                            options={alloftechnology}
                            onChange={techhandleChange}
                            value={selTech}
                        />
                    </div>




                    <div className='col-md-3'>
                        <label>{"Technology By Technology Outage"}</label>
                        <MultiSelect
                            className="text-dark"
                            hasSelectAll={false}
                            options={alloftechnology}
                            onChange={techOutagehandleChange}
                            value={seltechOutage}
                        />
                    </div>




                    <div className='col-md-3'>
                        <label>{"SOW"}</label>
                        {/* <select onChange={(e) => { getSiteData(e.target.value) }}>
                            <option value="">Select</option>
                            {allSiteList}
                        </select> */}
                        <MultiSelect
                            className="text-dark"
                            options={mopcommonsowDetail}
                            onChange={handleSowChanges}
                            value={sow}
                        />
                    </div>


                    <div className='col-md-3'>
                        <label>{"CR No."}</label>

                        <input style={{ height: "32px" }}
                            {...register("crNumber", {
                                required: "Please Enter CR Number",
                                pattern: {
                                    value: /CR[0-9]{9}$/,
                                    message: "Please Enter A Valid CR Number ( CR+9 digits )"
                                }
                            })}


                            type='text' className='form-control' />
                    </div>




                    <div className='col-md-3'>
                        <label>{"Crane Needed"}</label>
                        <select
                            {...register("craneNeeded", {
                                required: "Please Select GC"
                            })}>
                            <option selected disabled value={""}>Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>








                    {/* 
                        Type Of Work(SI/NSI) SI
                        Sectors Impacted ALL SECTOR
                        Sector by Sector Outage NO 
                    */}
                    <div className='col-md-3'>
                        <label>{"Tentative Swap Date"}</label>

                        <DatePicker
                            placeholderText="MM/DD/YYYY"
                            id="color-of-datepicker"
                            className="date-picker-changes "
                            popperPlacement='bottom'
                            // selected={fstartdate}
                            autoComplete={"off"}
                            // onChange={(e) => {
                            //     setfstartdate(e);
                            //     // setfenddate(e);
                            // }}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>

                    {/* <div className='col-md-4'>
                        <label>Parent</label>
                        <select onChange={(e) => { getparentpreswapchecklist(e.target.value) }}>
                            <option value="">Select</option>
                            {allpreswapchecklistoption}
                        </select>
                    </div>


                    <div className='col-md-4'>
                        <label>Child</label>

                        <select onChange={(e) => { getchildpreswapchecklist(e.target.value) }}>
                            <option value="">Select</option>
                            {finalData}
                        </select>
                    </div> */}

                </div>


                {/* <div className='row'>

                    <div class='col-md-4'>

                        <label>Choice</label>

                        <select onChange={(e) => { setUpchoice(e.target.value) }}>
                            <option name="">Select</option>
                            <option name="Yes">Yes</option>
                            <option name="No">No</option>
                        </select>


                    </div>






                    <div class="col-md-4">
                        <label>Select File</label>

                        <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input
                                type="file"
                                className="form-control btn btn-primary "
                                id="inputGroupFile04"
                                aria-describedby="inputGroupFileAddon04"
                                aria-label="Upload"
                                name="file"
                                onChange={uploadCsvFile}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose File
                        </label>
                    </div>


                    <div className='col-md-4'>
                        <button

                            className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "40px!important;", position: "absolute", bottom: "0px" }}
                            // onClick={() => setDatePicker(!showDatePicker)}
                            type='submit'
                        >
                            <i class="fa fa-filter mx-2"></i>Submit
                        </button>

                    </div>

                </div> */}




                {/* allpreswapcheckOldList */}


                {/* <div
                className="table-responsive"
                style={{ color: "black", border: "0px solid black", marginTop: "10px" }}
            >
                <table className="table "
                    style={{ height: "auto" }}

                >
                    <thead>
                        <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Site ID</td>
                        <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Parent</td>
                        <td style={{ width: "40%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Child</td>
                        <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Choice</td>
                        <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>File</td>
                    </thead>
                    <tbody>
                        {
                            allpreswapcheckOldList.map((its) => {
                                return <tr>
                                    <td style={{ border: "2px solid black" }}>{its.sitename}</td>
                                    <td style={{ border: "2px solid black" }}>{its.parentpreswapcheck}</td>
                                    <td style={{ border: "2px solid black" }}>{its.childpreswapcheck}</td>
                                    <td style={{ border: "2px solid black" }}>{its.choice}</td>
                                    <td style={{ border: "2px solid black" }}><a href={baseURL + its.uploadFile_path} className="" download><DownloadNew /></a></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div> */}

                {
                    OpenShow ? <> <div
                        className="table-responsive"
                        style={{ color: "black", border: "0px solid black", marginTop: "10px" }}
                    >
                        <table className="table "
                            style={{ height: "auto" }}

                        >
                            <thead>
                                <th style={{ width: "50%", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Pre Swap Check List</th>
                                <th style={{ width: "14px", maxWidth: "14px", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Status</th>
                                <th style={{ width: "10%", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Upload File</th>
                                <th style={{ width: "10%", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Download File</th>
                                <th style={{ width: "15%", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Remarks</th>
                            </thead>
                            <tbody>
                                {
                                    allpreswapchecklist.map((its) => {
                                        return <><tr>
                                            <td colSpan={5} style={{ border: "2px solid black", textAlignLast: "left", color: "black", backgroundColor: "#00c7ff" }}>{its.parentName}</td>

                                        </tr>
                                            {
                                                its.childs.map((itcs) => {
                                                    return <tr>
                                                        {console.log(itcs, "itcsitcsitcs")}
                                                        <td style={{ border: "2px solid black", textAlignLast: "left" }}>{itcs.name}</td>
                                                        <td style={{ border: "2px solid black", maxWidth: "20px" }}>

                                                            <label class="switch">
                                                                <input type="checkbox" onChange={(e) => { checkedValue(itcs.uniqueId, e.target.checked) }} />
                                                                <span class="slider"></span>
                                                            </label>

                                                        </td>
                                                        {/* <td style={{ border: "2px solid black" }}>
                                                    <CommonBulkUploaderButton typeForm={"SSV File"} shortCode={`LSSVFileUpload__CP__`+item.ssvUniqueId} btnname={<Upload />} classes={"btn btn-primary btn-xs  mx-1"} afterLoad={LSSVDashboardAPI}/>
                                                    <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                                                        <input
                                                            type="file"
                                                            className="form-control btn btn-primary "
                                                            id="inputGroupFile04"
                                                            aria-describedby="inputGroupFileAddon04"
                                                            aria-label="Upload"
                                                            name="file"
                                                            onChange={uploadCsvFile}
                                                        />
                                                        <i class="fa fa-cloud-upload"></i> Choose File
                                                    </label>
                                                </td> */}
                                                        <td style={{ border: "2px solid black" }}><p href={baseURL + its.uploadFile_path} className="" onChange={(e) => { console.log(itcs.uniqueId, e.target.checked, "checkboxcheckboxcheckbox") }} >
                                                        </p>
                                                            <label class="custom-file-upload btn btn-xs col-md-12">
                                                                <input
                                                                    type="file"
                                                                    className="form-control btn btn-primary "
                                                                    id="inputGroupFile04"
                                                                    aria-describedby="inputGroupFileAddon04"
                                                                    aria-label="Upload"
                                                                    name="file"
                                                                    onChange={(e) => { uploadCsvFile(itcs.uniqueId, e) }}
                                                                />
                                                                {/* <i class="fa fa-cloud-upload"></i> Choose File */}
                                                                <Upload />
                                                            </label>
                                                        </td>
                                                        <td style={{ border: "2px solid black" }}><a href={baseURL + its.uploadFile_path} className="" download><DownloadNew /></a></td>
                                                        <td style={{ border: "2px solid black", textAlignLast: "left" }}>
                                                            <textarea onChange={(e) => { textareacheckedValue(itcs.uniqueId, e.target.value) }}>

                                                            </textarea>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </>
                                        //     <td style={{ border: "2px solid black" }}>{its.parentpreswapcheck}</td>
                                        //     <td style={{ border: "2px solid black" }}>{its.childpreswapcheck}</td>
                                        //     <td style={{ border: "2px solid black" }}>{its.choice}</td>
                                        //     <td style={{ border: "2px solid black" }}><a href={baseURL + its.uploadFile_path} className="" download><DownloadNew /></a></td>
                                        // </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                        <br />
                        <br />
                        <div className='row'>
                            <div className='col-md-4'>
                                <button

                                    className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                                    style={{ marginTop: "40px!important;", position: "absolute", bottom: "0px" }}
                                    // onClick={() => setDatePicker(!showDatePicker)}
                                    type='submit'
                                >
                                    <i class="fa fa-filter mx-2"></i>Submit
                                </button>

                            </div>
                        </div></> : <></>
                }

            </form>

            <br />
            <br />
            {/* <div classname="container">
                <div classname="form-row">
                    <div classname="form-group col-md-3">

                    </div>
                    <div classname="form-group col-md-3">
                    </div>
                    <div classname="form-group col-md-3">
                    </div>


                    <div classname="form-group col-md-3">


                    </div>

                    <div classname="form-group col-md-3">

                        <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                            <input
                                type="submit"
                                className="form-control btn btn-primary "
                                id="inputGroupFile04"
                                aria-describedby="inputGroupFileAddon04"
                                aria-label="Upload"
                                name="file"
                                {...register("fileUploaded")}
                            />
                            <i class="fa fa-cloud-upload"></i> Choose File
                        </label>
                    </div>

                </div>
            </div> */}
        </>
    )
}

export default PreSwapChecklist
