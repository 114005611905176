/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    document.getElementsByClassName("content-body")[0].style.marginLeft = "0rem"
    function toggleFunc() {
      console.log("menu-toggle" in aaa.classList, aaa.classList, "classList")
      if (aaa.classList.length == 2) {
        document.getElementsByClassName("content-body")[0].style.marginLeft = "2rem"
      } else {
        document.getElementsByClassName("content-body")[0].style.marginLeft = "14rem"
      }

      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    toggleFunc()
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    let userDetails = JSON.parse(window.localStorage.getItem("userDetails"))
    console.log("userDetails", userDetails)
    if (userDetails?.manageadmin === "Granted") {
      userDetails.rolename = "Super Admin";
    }
    /// Active menu
    let dashBoard = [
      "",
      "event-list",
      "event",
      "customers",
      "analytics",
      "reviews",
      "task",
    ],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],


      gcLogin = [

      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        //"post",

      ],
      redux = [
        "todo",
        "form-redux",
        "form-redux-wizard",
      ],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = [
        "table-bootstrap-basic",
        "table-datatable-basic",
        "table-sorting",
        "table-filtering",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];


    let rolesArray = {
      "MPAG": ["Administrator", "Super Admin", "Manage Engineer"],
      "MOP": ["Administrator", "Super Admin", "RF Engineer", "Development", "FOPS", "PAG Admin", "PAG Engineer", "Manage Engineer"],
      "MOPCREATE": ["GC Login"],
      "RAN": ["Administrator", "Super Admin", "GC Login", "RF Engineer", "Development", "FOPS", "PAG Admin", "PAG Engineer", "MobileTeam Manager", "TruNorthe", "Manage Engineer"],
      "RANFULL": ["Administrator", "Super Admin", "Manage Engineer"],
      "IXSupport": ["Super Admin", "Manage Engineer"],
      "TTFULL": ["Administrator", "Super Admin", "Manage Engineer"],
      "TT": ["Administrator", "Super Admin", "RF Engineer", "Development", "FOPS", "PAG Admin", "PAG Engineer", "TruNorthe", "MobileTeam Manager", "Manage Engineer"],
      "E911": ["Administrator", "Super Admin", "Manage Engineer"],
      "RTT": ["Super Admin", "Manage Engineer"],
      "SeventyTwoHours": ["Super Admin", "Manage Engineer"],
      "RFPerformance": ["Super Admin", "Manage Engineer", "RF Engineer"],
      "RFPerformanceOther": ["Super Admin", "Manage Engineer"],
      "RFPerformanceApproval": ["Super Admin", "RF Engineer"],
      
      "ProjectDatabase": ["Super Admin", "Manage Engineer"],
      "SuperAdmin": ["Super Admin"],
      "PostIXRFSupport": [],
      "IXSupportErixcion": [""],
      "Reports": [""],
      "ChangePassword": [],
      // "ChangePassword":["Administrator","Super Admin","GC Login","RF Engineer","Development","FOPS","PAG Admin","PAG Engineer","MobileTeam Manager","TruNorthe","Manage Engineer"],
      "NetworkDatabase": [""]
    }


    // let rolesArray={
    //   "MPAG":["Administrator","Super Admin"],
    //   "MOP":["Administrator","Super Admin","RF Engineer","Development","FOPS","PAG Admin","PAG Engineer"],
    //   "MOPCREATE":["GC Login"],
    //   "RAN":["Administrator","Super Admin","GC Login","RF Engineer","Development","FOPS","PAG Admin","PAG Engineer","MobileTeam Manager","TruNorthe"],
    //   "RANFULL":["Administrator","Super Admin"],
    //   "IXSupport":["Super Admin"],
    //   "TTFULL":["Administrator","Super Admin"],
    //   "TT":["Administrator","Super Admin","RF Engineer","Development","FOPS","PAG Admin","PAG Engineer","TruNorthe","MobileTeam Manager"],
    //   "E911":["Administrator","Super Admin"],
    //   "RTT":["Super Admin"],
    //   "SeventyTwoHours":["Super Admin"],
    //   "RFPerformance":["Super Admin"],
    //   "ProjectDatabase":["Super Admin"],
    //   "SuperAdmin":["Super Admin"],
    //   "PostIXRFSupport":[],
    //   "IXSupportErixcion":[""],
    //   "Reports":[""],
    //   "ChangePassword":[],
    //   // "ChangePassword":["Administrator","Super Admin","GC Login","RF Engineer","Development","FOPS","PAG Admin","PAG Engineer","MobileTeam Manager","TruNorthe","Manage Engineer"],
    //   "NetworkDatabase":[""]
    // }

    console.log(rolesArray["MPAG"], userDetails?.rolename, rolesArray["MPAG"], "testing role module")

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">


            {/* ============================================NEW SIDEBAR===================================================== */}


            {/* ================================================MPAG START================================================= */}
            {userDetails?.rolename == "Super Admin" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("MPAG")) ?
              <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#" >
                  <i className="flaticon-381-networking"></i>

                  <span style={{ fontSize: "15px" }} className="nav-text">MPAG</span>
                </Link>
                <ul >
                  <li>
                    {/* <Link style={{ fontSize: "13px" }} className={`${path === "dashboard" ? "mm-active" : ""}`} to="/PAG/customer/dashboard" onClick={() => this.props.onClick3()}>Customer Dashboard</Link>
                          <Link style={{ fontSize: "13px" }} className={`${path === "vendordashboard" ? "mm-active" : ""}`} to="/PAG/vendor/dashboard" onClick={() => this.props.onClick3()}>Vendor Dashboard</Link> */}

                    <Link style={{ fontSize: "13px" }} className={`${path === "customerdashboard" ? "mm-active" : ""}`} to="/PAG/customer/dashboard" onClick={() => this.props.onClick3()}>Customer Dashboard</Link>
                    <Link style={{ fontSize: "13px" }} className={`${path === "customerdashboard" ? "mm-active" : ""}`} to="/PAG/Pre-Swap-Check-list" onClick={() => this.props.onClick3()}>Pre Swap Check list</Link>
                    <Link style={{ fontSize: "13px" }} className={`${path === "vendordashboard" ? "mm-active" : ""}`} to="/PAG/vendor/dashboard" onClick={() => this.props.onClick3()}>Vendor Dashboard</Link>
                    <Link style={{ fontSize: "13px" }} className={`${path === "awsdashboard" ? "mm-active" : ""}`} to="/PAG/aws/dashboard" onClick={() => this.props.onClick3()}>AWS Dashboard</Link>
                    {/* <Link style={{ fontSize: "13px" }} className={`${path === "awsdashboard" ? "mm-active" : ""}`} to="/PAG/vendorScore/dashboard" onClick={() => this.props.onClick3()}>Vendor Score Dashboard</Link> */}
                    <Link style={{ fontSize: "13px" }} className={`${path === "awsdashboard" ? "mm-active" : ""}`} to="/PAG/vendorScoreCard/dashboard" onClick={() => this.props.onClick3()}>Vendor Score Dashboard</Link>
                  </li>
                </ul>
              </li> : ""
            }

            {/* ================================================MPAG END================================================= */}
            {/* ================================================MOP START================================================= */}


            <>
              {userDetails ?

                <>
                  <>

                    {rolesArray["MOPCREATE"].indexOf(userDetails?.rolename) != -1 && userDetails?.mopAccess ?
                      <li className={`${gcLogin.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="/PAG/Network" >
                          <i className="flaticon-381-television"></i>
                          <span style={{ fontSize: "15px" }} className="nav-text">MOP</span>
                        </Link>
                        <ul >
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/Mop/database" ? "mm-active" : ""}`} to="/PAG/Mop/database" >MOP Dashboard</Link></li>
                          {/* <li><Link className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/Mop/database">Request MOP</Link></li> */}

                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/Mop/database" ? "mm-active" : ""}`} to="/PAG/Mop/database/open" >Create Request</Link></li>
                          {/* <li><Link className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/Mop/database">Request MOP</Link></li> */}
                        </ul>
                      </li> : ""}


                    { userDetails?.rolename == "Super Admin" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("MOP")) ?
                      <li className={`${gcLogin.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="/PAG/Network" >
                          <i className="flaticon-381-television"></i>
                          <span style={{ fontSize: "15px" }} className="nav-text">MOP</span>
                        </Link>
                        <ul >
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/Mop/database" ? "mm-active" : ""}`} to="/PAG/Mop/database" >MOP Dashboard</Link></li>
                        </ul>
                      </li> : ""}
                  </>

                  {/* ================================================MOP END================================================= */}

                  {/* ================================================RAN START================================================= */}



                  <>
                    {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RAN SUPPORT")) || userDetails?.rolename == "Super Admin"  ?
                      <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="#" >
                          <i className="flaticon-381-television"></i>
                          <span style={{ fontSize: "15px" }} className="nav-text">RAN Support</span>
                        </Link>
                        <ul >
                          <li>
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-profile" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/ran/support/dashboard">
                              RAN Dashboard
                            </Link>
                          </li>

                          {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RAN SUPPORT")) || rolesArray["RANFULL"].indexOf(userDetails?.rolename) != -1 ?
                            <>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/support/tracker"
                                >
                                  RAN Tracker
                                </Link>
                              </li>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/support/dailyReport"
                                >
                                  Daily Activity Report
                                </Link>
                              </li>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/sla/report"
                                >
                                  SLA Report
                                </Link>
                              </li>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/executivespillover/report"
                                >
                                  Executive Spillover Report
                                </Link>
                              </li>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/nestingTime/report"
                                >
                                  Nesting Report
                                </Link>
                              </li>
                              <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/ran/nestingTime/tracker"
                                >
                                  Nesting Tracker
                                </Link>
                              </li>

                            </> : ""}
                        </ul>
                      </li> : ""}
                  </>


                  {/* ================================================RAN END================================================= */}



                  {/* ================================================IX Support Start================================================== */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("IX SUPPORT")) || userDetails?.rolename == "Super Admin"  ? <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="#" >


                      <i className="flaticon-381-television"></i>
                      <span style={{ fontSize: "15px" }} className="nav-text">IX Support</span>
                    </Link>
                    <ul >

                      {/* <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}> */}
                      {/* <Link className="has-arrow ai-icon" to="/PAG/ix/module" > */}
                      {/* <i className="flaticon-381-networking"></i> */}

                      {/* <span style={{ fontSize: "15px" }} className="nav-text"> */}
                      {/* IX Scheduler */}
                      {/* </span> */}
                      {/* </Link> */}
                      {/* </li> */}


                      <li>
                        <Link style={{ fontSize: "13px" }}
                          className={`${path === "app-calender" ? "mm-active" : ""}`}
                          onClick={() => this.props.onClick()}
                          to="/PAG/ix/module"
                        >
                          IX Scheduler
                        </Link>
                      </li>


                      {/* <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="/PAG/ix/calender" > */}
                      {/* <i className="flaticon-381-networking"></i> */}

                      {/* <span style={{ fontSize: "15px" }} className="nav-text"> */}
                      {/* IX Calendar */}
                      {/* </span> */}
                      {/* </Link>
                      </li> */}

                      <li>
                        <Link style={{ fontSize: "13px" }}
                          className={`${path === "app-calender" ? "mm-active" : ""}`}
                          onClick={() => this.props.onClick()}
                          to="/PAG/ix/calender"
                        >
                          IX Calendar
                        </Link>
                      </li>
                      {/* <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="/PAG/ix/dashboard" > */}
                      {/* <i className="flaticon-381-networking"></i> */}

                      {/* <span style={{ fontSize: "15px" }} className="nav-text"> */}
                      {/* IX Dashboard */}
                      {/* </span> */}
                      {/* </Link>
                      </li> */}
                      <li>
                        <Link style={{ fontSize: "13px" }}
                          className={`${path === "app-calender" ? "mm-active" : ""}`}
                          onClick={() => this.props.onClick()}
                          to="/PAG/ix/dashboard"
                        >
                          IX Dashboard
                        </Link>
                      </li>
                    </ul>

                  </li> : ""}


                  {/* ================================================IX Support End================================================== */}

                  {/* ================================================TT START================================================= */}

                  {userDetails?.rolename == "Super Admin" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("TROUBLE TICKET")) ?
                    <>
                      <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="#" >
                          <i className="flaticon-381-television"></i>
                          <span style={{ fontSize: "15px" }} className="nav-text">Trouble Tickets</span>
                        </Link>
                        <ul >
                          <li>
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-profile" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/troubleTickets/dashboard">
                              TT Dashboard
                            </Link>
                          </li>
                          {rolesArray["TTFULL"].indexOf(userDetails?.rolename) != -1 || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("TROUBLE TICKET")) ?
                            <li>
                              <Link style={{ fontSize: "13px" }}
                                className={`${path === "app-calender" ? "mm-active" : ""}`}
                                onClick={() => this.props.onClick()}
                                to="/PAG/troubleTickets/tracker"
                              >
                                TT Tracker
                              </Link>
                            </li> : ""}
                        </ul>
                      </li>
                    </>
                    : ""
                  }

                  {/* ================================================TT END================================================= */}


                  {/* ================================================E911 START================================================= */}

                  {userDetails?.rolename == "Super Admin" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("E911 CALL TEST")) ?
                    <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-television"></i>
                        <span style={{ fontSize: "13px" }} className="nav-text">E911 Call Test</span>
                      </Link>
                      <ul >
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-profile" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/E911/dashboard">
                            E911 DashBoard
                          </Link>
                        </li>
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-calender" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/E911/Tracker"
                          >
                            E911 Tracker
                          </Link>
                        </li>
                      </ul>
                    </li>

                    : ""
                  }

                  {/* ================================================E911 END================================================= */}

                  {/* ================================================RTT START================================================= */}


                  {userDetails?.rolename == "Super Admin" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RTT")) ?

                    <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-television"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">RTT</span>
                      </Link>
                      <ul >
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-profile" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/rtt/dashboard">
                            DashBoard
                          </Link>
                        </li>
                      </ul>
                    </li>

                    : ""}




                  {/* ================================================RTT END================================================= */}

                  {/* =============ss===================================72 Hours Alarm  START================================================= */}


                  {/* {userDetails?.rolename == "Super Admin" || userDetails?.rolename == "Administrator" ?  */}
                  {/* {1==1/  */}

                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("SEVENTY TWO")) || userDetails?.rolename == "Super Admin"  ?
                    <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-television"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">72 Hours Alarm</span>
                      </Link>
                      <ul >
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-profile" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/72alarm/dashboard">
                            DashBoard
                          </Link>
                        </li>
                        {/* <li>
                        <Link style={{ fontSize: "13px" }}
                          className={`${path === "app-calender" ? "mm-active" : ""}`}
                          onClick={() => this.props.onClick()}
                          to="/PAG/72alarm/tracker"
                        >
                          Tracker
                        </Link>
                      </li> */}
                      </ul>
                    </li> : ""}
                  {/* ================================================72 Hours END================================================= */}





                  {/* ================================================RF Performance Start================================================== */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RF PERFORMANCE")) || userDetails?.rolename == "Super Admin"  ?

                    <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-television"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">RF Performance </span>
                      </Link>
                      <ul >

                        {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RF PERFORMANCE_OTHER")) || rolesArray["RFPerformanceOther"].indexOf(userDetails?.rolename) != -1 ?
                          <li>
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-profile" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/ssv/dashboard">
                              SSV Dashboard
                            </Link>
                          </li> : ""
                        }

                        {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RF PERFORMANCE_APPROVAL")) || rolesArray["RFPerformanceApproval"].indexOf(userDetails?.rolename) != -1 ?

                          <li>
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-profile" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/ssv/Approval/dashboard">
                              SSV Approval
                            </Link>
                          </li> : ""}


                        {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RF PERFORMANCE_OTHER")) || rolesArray["RFPerformanceOther"].indexOf(userDetails?.rolename) != -1 ?

                          < li >
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-calender" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/launchSSSNNP/dashboard"
                            >
                              Launch, SSS & NNP
                            </Link>
                          </li> : ""}

                        {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("RF PERFORMANCE_APPROVAL")) || rolesArray["RFPerformanceApproval"].indexOf(userDetails?.rolename) != -1 ?

                          < li >
                            <Link style={{ fontSize: "13px" }}
                              className={`${path === "app-calender" ? "mm-active" : ""}`}
                              onClick={() => this.props.onClick()}
                              to="/PAG/launchSSSNNP/Approval/dashboard"
                            >
                              SSS Approval
                            </Link>
                          </li> : ""}
                        {/* <li>
                                <Link style={{ fontSize: "13px" }}
                                  className={`${path === "app-calender" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()}
                                  to="/PAG/rfperformance/sheet"
                                >
                                  Sheet
                                </Link>
                              </li> */}

                      </ul>
                    </li>
                    : ""}


                  {/* ================================================RF Performance End================================================== */}



                  {/* ================================================Project Database START================================================= */}



                  {/* {1==2 ?  */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("NETWORK DATABASE")) || userDetails?.rolename == "Super Admin"  ?
                    <>

                      <li className={`${redux.includes(path) ? "mm-active" : ""}`}>

                        <Link className="has-arrow ai-icon" to="#" >
                          <i className="flaticon-381-book"></i><span style={{ fontSize: "15px" }} className="nav-text">Network Database</span>
                        </Link>
                        <ul>
                          <li><Link className={`${path === "networkDatabase" ? "mm-active" : ""}`} to="/networkDatabase">Network Database</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "inflight" ? "mm-active" : ""}`} to="/inflight">Inflight Database</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "magenta" ? "mm-active" : ""}`} to="/magenta">Magenta Database</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/network/database" ? "mm-active" : ""}`} to="/PAG/network/database">Project Database</Link></li>



                        </ul>
                      </li>
                    </>
                    : ""}

                  {/* { rolesArray["NetworkDatabase"].indexOf(userDetails?.rolename)!=-1 || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("Network")) ? */}
                  {/* <>

                      <li className={`${redux.includes(path) ? "mm-active" : ""}`}>

                        <Link className="has-arrow ai-icon" to="#" >
                          <i className="flaticon-381-book"></i><span style={{ fontSize: "15px" }} className="nav-text">Network Database</span>
                        </Link>
                        <ul>
                          <li><Link className={`${path === "networkDatabase" ? "mm-active" : ""}`} to="/networkDatabase">Network Database</Link></li>
                        </ul>
                      </li>
                    </>
                    : ""} */}


                  {/* ================================================Project Database END================================================= */}


                  {/* ================================================Super Admin START================================================= */}

                  {/* {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("SUPER ADMIN")) || rolesArray["SuperAdmin"].indexOf(userDetails?.rolename) != -1 ? */}
                  {((userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values.includes("SUPER ADMIN")) || rolesArray["SuperAdmin"].indexOf(userDetails?.rolename) != -1) ?
                    <>
                      <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="#" >
                          <i className="flaticon-381-book"></i><span style={{ fontSize: "15px" }} className="nav-text">Admin Dashboard</span>
                        </Link>
                        <ul>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "todo" ? "mm-active" : ""}`} to="#">App SignUp Request</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/account" ? "mm-active" : ""}`} to="/PAG/manage/account">Manage Account</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/market" ? "mm-active" : ""}`} to="/PAG/manage/market">Manage Markets </Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/userAccount" ? "mm-active" : ""}`} to="/PAG/userAccount">Manage User</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/vendor" ? "mm-active" : ""}`} to="/PAG/manage/vendor">Manage Vendor</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Manage Vendor score card</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/crewcompany" ? "mm-active" : ""}`} to="/PAG/manage/crewcompany">Manage Crews Company</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/userCrew" ? "mm-active" : ""}`} to="/PAG/userCrew">Manage Crew</Link></li>
                          {/* <li><Link className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/rfds">Manage RFDS</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/technology">Manage Technology</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/TeamMobile" ? "mm-active" : ""}`} to="/PAG/TeamMobile">Deployment Managers</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/Tier" ? "mm-active" : ""}`} to="/PAG/Tier">Manager Tier</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manageEngineer" ? "mm-active" : ""}`} to="/PAG/manageEngineer">Manage Engineers </Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/ManageSite" ? "mm-active" : ""}`} to="/PAG/ManageSite">Manage Site</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/ttPIERGroup" ? "mm-active" : ""}`} to="/PAG/manage/ttPIERGroup">Manage Pier TT Group</Link></li>
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/scoreCard">Manage Score Card</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/oss">Manage OSS</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/utilization">Manage Utilization</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Upload Master</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/cr">Manage Cr Number</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Manage Mail Center</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/dropDown">Manage Dropdown List</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/MOPInstruction" ? "mm-active" : ""}`} to="/PAG/manage/MOPInstruction">Manage MOP Instruction</Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "PAG/manage/MOPInstruction" ? "mm-active" : ""}`} to="/PAG/manage/MOPInstruction">Manage Site Access</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Manage KPI Graph</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Manage Automate Report</Link></li> */}
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Master Password </Link></li> */}
                          <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/PAG/manage/geoFencing">Manage Mobile Geofencing</Link></li>
                          {/* <li><Link style={{ fontSize: "13px" }} className={`${path === "form-redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Manage Old Records</Link></li> */}



                        </ul>
                      </li></> : ""}


                  {/* ================================================Super Admin END================================================= */}

                  {/* ================================================Post IX RF Support START================================================= */}



                  {/* {userDetails?.rolename == "Administrator" || (userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("POSTIX")) ? */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("POST IX RF SUPPORT")) || userDetails?.rolename == "SUPER ADMIN" ?
                    <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-television"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">Post IX RF Support</span>
                      </Link>
                      <ul >
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-profile" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/post-ix-rf/support/dashboard">
                            POST IX RF SUPPORT Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-calender" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/post-ix-rf/support/tracker"
                          >
                            Post IX RF Support Tracker
                          </Link>
                        </li>
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-calender" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/kpi/degradedSite/dashboard"
                          >
                            KPI Degraded Sites Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-calender" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/post-ix-rf/onAir"
                          >
                            On Air
                          </Link>
                        </li>
                        <li>
                          <Link style={{ fontSize: "13px" }}
                            className={`${path === "app-calender" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()}
                            to="/PAG/post-ix-rf/closeoutReport"
                          >
                            Closeout Report
                          </Link>
                        </li>
                      </ul>
                    </li> : ""}


                  {/* ================================================Post IX RF Support END================================================= */}


                  {/* ================================================Reports START================================================= */}


                  {/* {userDetails?.rolename == "Administrator" ? */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("REPORTS")) || rolesArray["Reports"].indexOf(userDetails?.rolename) != -1 ?
                    <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
                      <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-381-controls-3"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">Reports</span>
                      </Link>
                    </li> : ""}


                  {/* ================================================Reports END================================================= */}

                  {/* ================================================Change Password START================================================= */}


                  {/* {userDetails?.rolename == "Super Admin" ? */}
                  {(userDetails?.rolename == "Manage Engineer" && userDetails?.department?.values?.includes("CHANGE PASSWORD")) || rolesArray["ChangePassword"].indexOf(userDetails?.rolename) != -1 ?
                    <li className={`${path === "change-password" ? "mm-active" : ""}`}>
                      <Link to="/PAG/ChangePassword" className="ai-icon" >
                        <i className="flaticon-381-settings-2"></i>
                        <span style={{ fontSize: "15px" }} className="nav-text">Change Password</span>
                      </Link>
                    </li> : ""}


                  {/* ================================================Change Password END================================================= */}
                </> : ""}
            </>


          </MM >
        </PerfectScrollbar >
      </div >
    );
  }
}

export default SideBar;
