export const CREATE_POST_ACTION = '[Post Action] Create Post';
export const CONFIRMED_CREATE_POST_ACTION =
    '[Post Action] Confirmed Create Post';
export const GET_POSTS = '[Post Action] Get Posts';
export const CONFIRMED_GET_POSTS = '[Post Action] Confirmed Get Posts';

export const EDIT_POST_ACTION = '[Post Action] Edit Post';
export const CONFIRMED_EDIT_POST_ACTION =
    '[Post Action] Confirmed Edit Post';
export const CONFIRMED_DELETE_POST_ACTION =
    '[Post Action] Confirmed Delete Post';

export const FETECH_SITES ='[Post Action] Fetech Sites'


export const FETECH_MOP_SITES ='[Post Action] Clear Fetech MOP Sites'


export const FETECH_COUNT_DATA ='[Post Action] Fetech List Of Count Sites'



export const UPLOAD_FILE  ='[Post Action] Upload File' 

export const FETECH_BUCKET  ='[Post Action] Fetch Bucket'

export const FETECH_PROJECT_STATUS ='[Post Action] Fetch ProjectStatus'

export const FETECH_ACCOUNT ='[Post Action] Fetch Account' 

export const FETECH_MOP_Instruction ='[Post Action] MOP Instaruction Fetcher' 

export const ONEFETECH_ACCOUNT ='[Post Action] One Fetch Account' 

export const FETECH_USER_ACCOUNT ='[Post Action] One Fetch User Account' 

export const FETECH_USER_LIST ='[Post Action] One Fetch User List'

export const ONE_USER_ACCOUNT ='[Post Action] One USER Account' 


export const FETECH_USER_ROLE ='[Post Action] List Fetch User Role' 

export const FETECH_TECHNOLOGY='[Post Action] Fetech Technology'

export const FETECH_MARKETS ='[Post Action] Fetech Markets'


export const FETECH_FILTER_USER ='[Post Action] Fetech Filter User'


export const FETECH_CSV ='[Post Action] Fetech Filter User'

export const FETECH_PDF ='[Post Action] Fetech PDF TO OCR '

export const FETECH_GSM_UTMS ='[Post Action] Fetech GSM UTMS '

export const FETECH_RFDS_LIST ='[Post Action] List of Fetech RFDS '




export const FETECH_EQUIPMENT ='[Post Action] Fetech Equipment'


export const FETECH_VENDOR ='[Post Action] Fetech Vendor'


export const FETECH_CREW_COMPANY ='[Post Action] Fetech Crew Company'

export const FETECH_OSS  ='[Post Action] Fetech Oss'

export const FETECH_SINGLE_PROJECT ='[Project Action] Fetech SingleProject'

export const FETECH_RAN_DASHBOARD  ='[Post Action] Fetech Ran Dashboard'

export const FETECH_CLEAR_RAN_DASHBOARD  ='[Post Action] Fetech Clear Ran Dashboard'

export const ADVANCE_FETECH_RAN_DASHBOARD  ='[Post Action] Advance Fetech Ran Dashboard'

export const ADVANCE_CLEAR_FETECH_RAN_DASHBOARD  ='[Post Action] Advance Fetech Clear Ran Dashboard'

export const NOTIFICATION_LIST  ='[Post Action] NOTIFICATION_LIST'

export const FETECH_IX_DASHBOARD  ='[Post Action] Fetech IX Dashboard'

export const FETECH_RTT_DASHBOARD  ='[Post Action] Fetech RTT Dashboard'

export const FETECH_TIMELINE ='[Post Action] Fetech Timeline'



export const FETECH_TT_STATUS ='[Post Action] Fetech TT Site Status'

export const FETECH_MOP_STATUS ='[Post Action] Fetech MOP Site Status'

export const FETECH_RAN_STATUS ='[Post Action] Fetech Ran Site Status'

export const FETECH_PIER_TT_GROUP ='[Post Action] Fetech Pier TT Group'

export const FETECH_NETWORK_SITE_STATUS ='[Post Action] Fetech Network Site Status'

export const FETECH_SITEID ='[Post Action] Fetech SiteId '

export const FETECH_CR_NUMBER ='[Post Action] Fetech Cr Number'

export const FETECH_ADD_CR_NUMBER='[Post Action] Fetech Add Cr Number'

export const FETECH_RAN_TRACKER ='[Post Action] Fetech Ran Tracker'


export const FETECH_SLA_RAN_TRACKER ='[Post Action] Fetech Ran SLA Tracker'



export const FETECH_CLEAR_RAN_TRACKER ='[Post Action]Clear Fetech Ran Tracker'


export const FETECH_CLEAR_SLA_RAN_TRACKER ='[Post Action]Clear SLA Fetech Ran Tracker'



export const FETECH_SEND_MAIL ='[Post Action] Fetech Send Mail'

export const FETECH_ALL_DROP_DOWN='[Post Action] Fetech All Drop Down'

export const FETECH_SINGLE_DROPDOWN ='[Post Action] Fetech Single DropDown'

export const FETECH_SINGLE_A_DROPDOWN ='[Post Action] FETECH_SINGLE_A_DROPDOWN'

export const FETECH_PRODUCT_CODE ='[Post Action] Fetech Product Code'

export const GET_ALL_MAP_SITES ='[Post Action] List all Sites for map'
export const INFLIGHT_FETECH_CSV_DATA ='[Post Action] Infight Fetech CSV Data'
export const MAGENTA_FETECH_CSV_DATA ='[Post Action] Magenta Fetech CSV Data'
export const NETWORK_DATABASE_FETECH_CSV_DATA ='[Post Action] Network Database Fetech CSV Data'
export const NETWORK_DATABASE_FETECH_LOGGER_DATA ='[Post Action] Network Database Fetech LOGGER Data'


export const CLEAR_NETWORK_DATABASE_FETECH_CSV_DATA ='[Post Action] Clear Network Database Fetech CSV Data'



export const FETECH_SINGLE_MOPS ='[Post Action] Fetech One Mops'


export const CLEAR_FETECH_SINGLE_RAN_PROJECT ='[Post Action]Clear Fetech Ran Data'

export const CLEAR_FETECH_SINGLE_MOPS ='[Post Action]Clear Fetech One Mops'


export const FETECH_PRODUCT_ID ='[Post Action] Fetech Product Id'

export const FETECH_TIER_LIST ='[Post Action] Fetech Tier List'

export const LCL_MOP_DASHBOARD_FILTER ='[Post Action] data of LCL_MOP_DASHBOARD_FILTER'

export const RESET_LCL_MOP_DASHBOARD_FILTER ='[Post Action] RESET data of LCL_MOP_DASHBOARD_FILTER'

export const LCL_SLA_DASHBOARD_FILTER ='[Post Action] data of LCL_SLA_DASHBOARD_FILTER'

export const RESET_LCL_SLA_DASHBOARD_FILTER ='[Post Action] RESET data of RESET_LCL_SLA_DASHBOARD_FILTER'

export const LCL_RAN_DASHBOARD_FILTER ='[Post Action] data of LCL_RAN_DASHBOARD_FILTER'

export const RESET_LCL_RAN_DASHBOARD_FILTER ='[Post Action] RESET data of RESET_LCL_RAN_DASHBOARD_FILTER'

export const LCL_TT_FILTER ='[Post Action] data of LCL_TT_FILTER'

export const RESET_LCL_TT_FILTER ='[Post Action] RESET data of RESET_LCL_TT_FILTER'

export const LCL_LEXPORT_TT_FILTER ='[Post Action] EXPORT data of LCL_LEXPORT_TT_FILTER'

export const LCL_ENOO_FILTER ='[Post Action] data of LCL_ENOO_FILTER'

export const RESET_LCL_ENOO_FILTER ='[Post Action] RESET data of RESET_LCL_ENOO_FILTER'

export const LCL_NESTING_FILTER ='[Post Action] data of LCL_ENOO_FILTER'

export const RESET_LCL_NESTING_FILTER ='[Post Action] RESET data of RESET_LCL_ENOO_FILTER'

export const LCL_LSSV_FILTER ='[Post Action] data of LCL_LSSV_FILTER'

export const RESET_LCL_LSSV_FILTER ='[Post Action] RESET data of RESET_LCL_LSSV_FILTER'

export const LCL_SSV_FILTER ='[Post Action] data of LCL_SSV_FILTER'

export const RESET_LCL_SSV_FILTER ='[Post Action] RESET data of RESET_LCL_SSV_FILTER'

export const LCL_IX_DASHBOARD_FILTER ='[Post Action] data of LCL_IX_DASHBOARD_FILTER'

export const RESET_LCL_IX_DASHBOARD_FILTER ='[Post Action] RESET data of RESET_LCL_IX_DASHBOARD_FILTER'



export const UPDATE_POP_UP ='[Post Action] Update The Pop Up'

export const FETECH_SINGLE_PROJECTDATABASE ='[Post Action] Single Project Database'


export const FETECH_SINGLE_RAN_PROJECT ='[Post Action] Single Ran Project Database'

export const FETECH_SINGLE_IX_PROJECT ='[Post Action] Single IX Project Database'

export const FETECH_SINGLE_TECH_RAN_PROJECT ='[Post Action] Single Tech Ran Project Database'




export const Get_Last_Saved_Data ='[Post Action] GetLastSavedData'
export const Save_Last_Saved_Data ='[Post Action] SaveLastSavedData'



export const FETECH_TT_PROJECT ='[Post Action] Trouble Ticket Database'
export const FETECH_CLEAR_TT_PROJECT ='[Post Action] Clear Trouble Ticket Database'


export const FETECH_E911_PROJECT ='[Post Action] E911 Database'

export const FETECH_NEW_E911_PROJECT ='[Post Action] New E911 Database'




export const FETECH_RAN_SLA='[Pot Action] Ran Sla'
export const FETECH_RAN_SPILOVER='[Pot Action] Ran SPILOVER'

export const VENDOR_CHART_TIMELINE ='[Post Action] Vendor Chart Timeline'
export const TT_VENDOR_CHART_TIMELINE ='[Post Action] TT Vendor Chart Timeline'
export const DASHBOARD_VENDOR_CHART_TIMELINE ='[Post Action] TT Vendor Chart Timeline'
export const TT_AGING_CHART_TIMELINE ='[Post Action] TT Aging Chart Timeline'

export const WAR_ROOM_CHART_TIMELINE ='[Post Action] WAR Room Chart Timeline'


export const FETECH_NESTING_TIMELINE ='[Post Action] Nesting Timeline'


export const FETECH_MOBILE_DATA_COP_TIMELINE ='[Post Action] MOBILE DATA COP TIMELINE'




export const FETECH_RAN_NESTING_TRACKER ='[Post Action] Ran Nesting Tracker Timeline'

export const FETECH_RAN_NESTING_REPORT ='[Post Action] Ran Nesting Report Timeline'


export const FETECH_CLEAR_RAN_NESTING_TRACKER ='[Post Action] Ran Nesting Clear Tracker Timeline'

export const FETECH_CLEAR_RAN_NESTING_REPORT ='[Post Action] Ran Nesting  Clear Report Timeline'


// FETECH_CLEAR_RAN_DASHBOARD


export const CHAT_MESSAGE_PENDING ='[Post Action] CHAT_MESSAGE_PENDING'    

export const FETECH_GEO_FENCING ='[Post Action] Geo Fencing'

export const FETECH_MANAGE_UTILIZATION ='[Post Action] Manage Utilization'



export const SET_LOADER ='[Post Action] Set Loader'


export const SET_ASSINGNED_ENGINEER="[Post Action] Set Assigneed Engineer"

export const AWS_CHART_TIMELINE = '[POST Action] AWS_CHART_TIMELINE'

export const AWS_CHART_CALL_TIMELINE = '[POST Action] AWS_CHART_CALL_TIMELINE'

export const TIME_ZONE ="[POST Action] Time Zone"


export const CLEAR_IX_SINGLE_API="[Post Action]Clear Ix Single Api"

export const FETECH_72_Alarm="[Post Action] Fetech 72 Alarm"

export const SSV_DASHBOARD ="[Post Action] Fetech SSV Dashboard"

export const LAUNCH_SSVDASHBOARD ="[Post Action] Fetech Launch SSV Dashboard"

export const SSV_APPROVER_DASHBOARD ="[Post Action] Fetech APPROVER SSV Dashboard"

export const LAUNCH_APPROVER_SSVDASHBOARD ="[Post Action] Fetech Launch APPROVER SSV Dashboard"



export const  FILTERDROPDOWN ='[Post Action] Fetech Filter Data'
export const  FUNVENDOR_SCORE_CARD ='[Post Action] Fetech FUNVENDOR_SCORE_CARD'
export const  FUNVENDOR_SCORE_CARD_DASHBOARD ='[Post Action] Fetech FUNVENDOR_SCORE_CARD_DASHBOARD'





export const LCL_RAN_SLA_FILTER ='[Post Action] data of LCL_RAN_SLA_FILTER'
export const RESET_LCL_SLA_FILTER ='[Post Action] RESET data of RESET_LCL_SLA_FILTER'



export const FETECH_NEW_RTT_PROJECT ='[Post Action] New RTT Database'




export const FETECH_RTT_PROJECT ='[Post Action] RTT Database'




export const FETECH_NEW_ST_PROJECT ='[Post Action] New ST Database'
export const FETECH_ST_PROJECT ='[Post Action] ST Database'
export const FETECH_NEW_IX_PROJECT ='[Post Action] New IX Database'
export const FETECH_IX_PROJECT ='[Post Action] IX Database'



export const GETBARCHARTDATA="[Post Action] GETBARCHARTDATA"
export const GETBARCHARTDATATWO="[Post Action] GETBARCHARTDATATWO"


export const PRESWAPCHECKLIST="[Post Action] PRESWAPCHECKLIST"
export const PRESWAPCHECKLISTOLD="[Post Action] PRESWAPCHECKLISTOLD"
