import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer,{siteReducer,DashPostsReducer, commonReducer, OnecommonReducer, csvReducer, OnePopUpReducer, ChartReducer, loaderReducer, countAllReducer, lcl_dashboard_filter_Reducer, oldDataReducer, vendorScoreCardReducer} from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
//import rootReducers from './reducers/Index';
import todoReducers from './reducers/Reducers';
import { reducer as reduxFormReducer } from 'redux-form';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
	form: reduxFormReducer,	
    sites:siteReducer,
    dashposts:DashPostsReducer,
    CommonResponse:commonReducer,
    OneCommonResponse:OnecommonReducer,
    vendorScoreCard:vendorScoreCardReducer,
    csvResponse:csvReducer,
    popUpState:OnePopUpReducer,
    lclFilterDashboard:lcl_dashboard_filter_Reducer,
    chartState:ChartReducer,
    spinnerState:loaderReducer,
    countState:countAllReducer,
    oldDataState:oldDataReducer
	
}); 

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
