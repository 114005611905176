import swal from 'sweetalert';
import { listUserApi_two, mopDashSiteStatus } from '../../common/config';
import { common } from '../../services/AdminService';
import {
    PAG_maindashboard,
    PAG_dashboard,
    formatPosts,
    NetworkDatabase,
    UploadExcelFile,
    uploadTicketData,
    UploadPDFFile
    
} from '../../services/Pag';
import { AccountReducerAction, list_rfds_all, popUpUpdate, setLoaderAction, UserListReducerAction } from './createTicketManageFields';
import {
    CONFIRMED_CREATE_POST_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_POSTS,
    FETECH_SITES,
    UPLOAD_FILE,
    FETECH_PDF,
    FETECH_COUNT_DATA,
    FETECH_MOP_SITES
} from './PostTypes';

// export function deletePostAction(postId, history) {
//     return (dispatch, getState) => {
//         deletePost(postId).then((response) => {
//             dispatch(confirmedDeletePostAction(postId));
//             history.push('/postpage');
//         });
//     };
// }

// export function confirmedDeletePostAction(postId) {
//     return {
//         type: CONFIRMED_DELETE_POST_ACTION,
//         payload: postId,
//     };
// }

// export function createPostAction(postData, history) {
   
// 	return (dispatch, getState) => {
// 		console.log(postData);
// 		console.log('create');
//         createPost(postData).then((response) => {
// 			//console.log(response);
//             const singlePost = {
//                 ...postData,
//                 id: response.data.name,
//             };
//             dispatch(confirmedCreatePostAction(singlePost));
//             history.push('/postpage');
//         });
//     };
// }
export function CSVUploadAction(fileData){
    console.log("fileData",fileData)
    return (dispatch,getState)=>{
        console.log("fileData",fileData)
        UploadExcelFile(fileData).then((response)=>{
            console.log("response=",response)
            swal({
                title: "Good job!",
                text: response.data.msg,
                icon: "success",
                button: "Ok",
              })
        })

    }
}

export function PDFUploadAction(url,fileData,showSwal=true){
    return (dispatch,getState)=>{
        console.log("fileData",fileData)
        UploadPDFFile(url,fileData).then((response)=>{
            console.log("response=hjkhbahjduksyfgesrakujy",response)
            dispatch(popUpUpdate(response))
            let res= response.data.data;
            dispatch(PDFDatabaseAction(res))
            if(showSwal){
                swal({
                    title: "File Uploaded Succesfully",
                    // text: response.data.msg,
                    icon: "success",
                    button: "Ok",
                })
            }
            if(url=="multi_pdf_upload"){
                dispatch(list_rfds_all(url))
            }
        })

    }
}



export function PDFUploadActionreturn(url,fileData,retur=undefined,afterLoad=""){
    return (dispatch,getState)=>{
        console.log("fileData",fileData)
        
        dispatch(setLoaderAction(true))
        UploadPDFFile(url,fileData).then((response)=>{
            
            dispatch(setLoaderAction(false))
            console.log("response=hjkhbahjduksyfgesrakujy",url,response)
            
            dispatch(popUpUpdate(response))
            let res= response.data.data;
            
            dispatch(PDFDatabaseAction(res))
            if(retur!="mopbulkfile" && retur!="noShow")
            swal({
                title: "File Uploaded Succesfully",
                // text: response.data.msg,
                icon: "success",
                button: "Ok",
              })
              
            // if(retur=="usercrew"){
            //     dispatch(UserListReducerAction(afterLoad))
            // }else{
            //     dispatch(AccountReducerAction(afterLoad))
            // }
        }).catch((err)=>{
            
            dispatch(popUpUpdate(err.response))
            
            dispatch(setLoaderAction(false))
            console.log("site upload error=",err.response)
            if(err.response.data.data){
                let missed=err.response.data.data.map((item)=>{
                    return item
                })
                swal({
                    title: "Oops",
                    text: err.response.data.msg+" "+missed,
                    icon: "error",
                    button: "Ok",
                  })
            }
            if(err.response.data.message){
                swal({
                    title: "Oops",
                    text: err.response.data.message,
                    icon: "error",
                    button: "Ok",
                  })
            }

            
            // dispatch(PDFDatabaseAction(res))
            // let missed=err.response.data.data.map((item)=>{
            //     return item
            // })
            // swal({
            //     title: "Oops",
            //     text: err.response.data.msg+" "+missed,
            //     icon: "error",
            //     button: "Ok",
            //   })
        })

    }
}



export function  PDFDatabaseAction(res){
    return {
        type: FETECH_PDF,
        payload: res,
    };
}

export function uploadSiteTicketAction(siteData){
    return (dispatch,getState)=>{
        console.log("upload site data in action =",siteData)
        uploadTicketData(siteData).then((response)=>{
            console.log("response=",response)
            swal({
                title: "Good job!",
                text: response.data.msg,
                icon: "success",
                button: "Ok",
              })
        })
        .catch((err)=>{
            console.log("site upload error=",err)
            swal({
                title: "Oops",
                text: err.response.data.msg,
                icon: "error",
                button: "Ok",
              })
        })
    }
}

export function PAG_maindashboardAction() {
    return (dispatch, getState) => {
        
        PAG_maindashboard().then((response) => {
			console.log(response);
            let posts = response.data.data;
            dispatch(confirmedPAG_maindashboard(posts));
        });
    };
}

export  function NetworkDatabaseAction(){
    return (dispatch,getState)=>{
        NetworkDatabase().then((resp)=>{
            console.log("response =",resp)
            let res= resp.data.data;
            dispatch(ReleaseNetworkDatabaseAction(res))
        })
    }
}

export function MopDatabaseAction(reqUrl,type=true,callr=true){
    return (dispatch,getState)=>{
        if(type){
            dispatch(setLoaderAction(true))
        }
        common(reqUrl).then((response)=>{
            if(type){
                dispatch(setLoaderAction(false))
            }
            if(type){
                dispatch(ClearReleaseNetworkDatabaseAction(response.data.data))
            }else{
                dispatch(ReleaseNetworkDatabaseAction(response.data.data))
            }
            if(callr){
                dispatch(AccountReducerAction(mopDashSiteStatus))
            }
            dispatch(ReleaseCountDatabaseAction([response.data.count,"MOP Dashboard"]))
        })
        .catch((err)=>{
            dispatch(ClearReleaseNetworkDatabaseAction([]))
            console.log("the error in Mop")
        })
    }
}

export function ReleaseNetworkDatabaseAction(res,type){
    return {
        type: FETECH_SITES,
        payload: res,
    };
}


export function ClearReleaseNetworkDatabaseAction(res){
    return {
        type: FETECH_MOP_SITES,
        payload: res,
    };
}


export function  ReleaseCountDatabaseAction(res){
    return {
        type: FETECH_COUNT_DATA,
        payload: res,
    };
}



// export function ClearReleaseNetworkDatabaseAction(){
//     return {
//         type: FETECH_SITES,
//         payload: [],
//     };
// }

export function PAG_dashboardAction() {
    return (dispatch, getState) => {
        
        PAG_dashboard().then((response) => {
			console.log(response);
            let posts = response.data.data;
            dispatch(confirmedPAG_dashboard(posts));
        });
    };
}

// export function confirmedCreatePostAction(singlePost) {
	
//     return {
//         type: CONFIRMED_CREATE_POST_ACTION,
//         payload: singlePost,
//     };
// }

export function confirmedPAG_maindashboard(data) {
    return {
        type: CONFIRMED_GET_POSTS,
        payload: data,
    };
}


export function confirmedPAG_dashboard(data) {
    return {
        type: CONFIRMED_GET_POSTS,
        payload: data,
    };
}


// export function confirmedUpdatePostAction(post) {

//     return {
//         type: CONFIRMED_EDIT_POST_ACTION,
//         payload: post,
//     };
// }

// export function updatePostAction(post, history) {
//     return (dispatch, getState) => {
//         updatePost(post, post.id).then((reponse) => {
// 			//console.log(reponse);
//             dispatch(confirmedUpdatePostAction(post));
//             history.push('/postpage');
//         });
			
//     };
// }
