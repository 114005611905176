import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import MapView from "./Modal Body/MapView";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";
import { PDFUploadActionreturn } from "../../../store/actions/dashboardActions";
import { useDispatch, useSelector } from 'react-redux';
import { CsvUpload, dynamic_dashboard, listTmobileUserApi, listUserApi_two, managEngineer } from "../../../common/config";
import { AccountReducerAction, TierListReducerAction, UserListReducerAction, inflightGetCSVReducerAction, magentaGetCSVReducerAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
const CommonBulkUploader = ({ typeForm,shortCode,reutun,btnname="Upload File",classes="btn-xs mt-3 mr-2 mb-2 float-right",afterLoad=""}) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [fileshow, setfileShow] = useState(false);
  const [closer, setCloser] = useState(false);
  const [siteFile, uploadedFile] = useState([]);
  
  

  const filehandleClose = () => {
    setfileShow(false);
    uploadedFile([])
  }
  const filehandleShow = () => {
    setfileShow(true)
    dispatch(popUpUpdate({}))
    setCloser(true)
  }

  let data = useSelector((state) => {
    
    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        filehandleClose()
        if(afterLoad!=""){
          dispatch(popUpUpdate({}))
        
          if(afterLoad==listUserApi_two){
            dispatch(UserListReducerAction(listUserApi_two))
          }else if(afterLoad==listTmobileUserApi){
            dispatch(UserListReducerAction(listTmobileUserApi))
          }else if(afterLoad==managEngineer){
            dispatch(TierListReducerAction(managEngineer))
          }else if(afterLoad=="/magenta" + dynamic_dashboard){
            dispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard))
          }else if(afterLoad=="/inflight" + dynamic_dashboard){
            dispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard)) 
          }
          else{
            dispatch(AccountReducerAction(afterLoad))
          }
        }
        
        
      }
    }
  })

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form")
    let newform = new FormData();
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", shortCode)
    console.log("form==", newform)
    dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew",afterLoad))
    // setfileShow(false);
  };

  
  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    uploadedFile(e.target.files[0])

  };

  return (
    <>
      <Modal show={fileshow} onHide={filehandleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title >Upload {typeForm}</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
          <div className="input-group mb-3 fileSection">
          <div class="form-group col-md-12">
            <div>
              <label class="custom-file-upload btn btn-outline-primary col-md-8">
                <input
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  multiple
                  onChange={uploadCsvFile}
                />
                <i class="fa fa-cloud-upload"></i> Choose File
              </label>
              
              <label>{siteFile.length!=0?"1 ":"No"} File Selected</label>


            </div>
            </div>

            
            <button type="submit" className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
          </div>

        </form>

      </Modal>
      <Button className={classes} onClick={() => { filehandleShow();}}>{btnname}</Button>
    </>
  );
};

export default CommonBulkUploader;
