import MUIDataTable from 'mui-datatables'
import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { customDataTableOptionObject, getMuiThemeCommon, getdatedatalistnext, wrapText, } from '../../../common/variables'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, clearIxSingleSite, deleteAcccountReducerAction, popUpUpdate } from '../../../store/actions/createTicketManageFields'
import { addRTTForms, baseURL, ixModule, rttDashboard } from '../../../common/config'
import AdvCommonBulkUploader from '../Custom/AdvCommonBulkUploader'
import CommonModal from '../Custom/CommonModal'
import SingleDateModal from '../Custom/Modal Body/SingleDateModal'
import Download from '../../../icons/Svg/Download'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert';
import DownloadNew from '../../../icons/Svg/DownloadNew';

const RTTDashboard = () => {
  let [commonModalState, setcommonModalState] = useState()
  let [modalBdy, setModal] = useState(false)
  const [closer, setCloser] = useState(false);

  const [bulkApproveModalState, setBulkApproveModal] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const dispatch = useDispatch()
  let history = useHistory()
  function openModal(id, tag) {
    setcommonModalState(tag)
    setModal(true)

  }
  const deleteHandler = (e, url) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setCloser(true)
          dispatch(deleteAcccountReducerAction(url, e))
          dispatch(AccountReducerAction(rttDashboard));
        }
      });
  }
  let currentUrl = window.location.href

  let options = useSelector((state) => {


    // if (state.popUpState) {
    //   console.log(state.popUpState.popstatus.status, closer, state.popUpState.popstatus.status == 201 && closer, "popUpStatestatus")
    //   console.log(reqPreModal, "openModal");
    //   if (state.popUpState.popstatus.status == 201 && closer) {
    //     console.log(reqPreModal, "openModal");
    //     setCloser(false);
    //     setReqPreModal(false);
    //     setcommonModalState(false)
    //     setNestingTime(false)
    //     dispatch(popUpUpdate ({}));
    //     dispatch(AccountReducerAction(rttDashboard));
    //   }
    // }
    let sitesdata = state.CommonResponse.listrttData;
    let rows = []

    const CommonModalFun = (body, title, header, type = "md") => {
      setcommonModalState(true);
      setcommonModalSize(type);
      setcommonModalTitle(title);
      setcommonModalBody(body);
      setcommonModalHeader(header);
    };


    if (sitesdata && sitesdata.length > 0) {
      console.log("the mjfownedefcjk=", sitesdata)

      rows = sitesdata.map((item) => {

        let proId = ""

        let proCode = ""

        let proname = ""
        console.log("njhwbfewcfsjkefw=", item)

        let dsiteType = item["dtResult"]?.siteType
        proname = item["dtResult"]?.ProgramName
        const siteInfo = item["ndResult"]

        if (siteInfo) {
          // item["edit"]=item.wildCard=="true"?<p   onClick={()=>{dispatch(clearIxSingleSite());history.push("/PAG/rtt/form/WildCard/"+item["ranSupportId"])}}>Edit</p>:<Link  to ={"/PAG/rtt/form/edit/"+item["ranSupportId"]} onClick={()=>dispatch(clearIxSingleSite())}>Edit</Link>
          item["edit"] = item.wildCard == "true" ?
            <div className="d-flex">
              <Link to={"/PAG/rtt/form/WildCard/" + item["ranSupportId"]} className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center" onClick={() => { dispatch(clearIxSingleSite()) }}><i className="fa fa-pencil"></i></Link>
              <button onClick={(e) => deleteHandler(item["ranSupportId"], addRTTForms)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button>
            </div>
            :
            <div className="d-flex">
              <Link to={"/PAG/rtt/form/edit/" + item["ranSupportId"]} className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center" onClick={() => dispatch(clearIxSingleSite())}><i className="fa fa-pencil"></i></Link>
              <button onClick={(e) => deleteHandler(item["ranSupportId"], ixModule)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button>
            </div>
          item["market"] = siteInfo.market
          // dsiteType = dsiteType+siteInfo.siteType
          item["siteId"] = siteInfo.siteId

          console.log(siteInfo, "siteInfositeInfo")
          proCode = proCode + siteInfo.projectCode
          proId = proId + siteInfo.projectId

          item["account"] = siteInfo.account
          // item["pUniqueIdDetails"]=''
        }


        item["projectcode"] = proCode
        item["proname"] = (
          <p
            className={"text-black my-0 modalHoverClass"}
            data-toggle="tooltip"
            data-placement="top"
            title="Click Here For More Details"
            onClick={() =>
              CommonModalFun(
                <p
                  style={{
                    border: "2px solid black",
                    color: "black",
                    padding: 2,
                  }}
                  className="CommonModal"
                >
                  {proname}
                </p>,
                "Program Name",
                "Program Name"
              )
            }
          >
            {wrapText(proname)}
          </p>
        );
        item["projectId"] = proId


        item["siteType"] = dsiteType

        let ttidsData = ''
        let pierttidsData = ''
        if (item.troubleTickets && item.troubleTickets.length > 0) {
          ttidsData = item.troubleTickets.map((itemInner) => {
            return itemInner.ttid;
          });

          item["ttIds"] = ttidsData.join(", ");


          pierttidsData = item.troubleTickets.map((itemInner) => {
            return itemInner.tmoPierTtid;
          });
          item["pierttIds"] = pierttidsData.join(", ");

        }

        if (item['ILogout_timeStamp'] || item["wildCard"] == "true") {
          item["LTPOR"] = (
            <p>
              {item.postCheckRequest && item["wildCard"] == "true" ? getdatedatalistnext(item.postCheckRequest) : getdatedatalistnext(item.postCheckRequest)}
            </p>
          );


          if (item["mopStartTime"]) {
            item["ixStartDate"] = getdatedatalistnext(item["mopStartTime"])

          }
          if (item["scheduleDate"]) {
            item["ixStartDate"] = getdatedatalistnext(item["scheduleDate"])
          } // if(item["IXDasboard"]==1){
          // item["ixStartDate"]=item["mopStartTime"]?item["mopStartTime"]: item["scheduleDate"]
          // }
          // item["market"]=siteInfo?siteInfo.market:""
        }

        // item["market"]=
        item["rttFileModal"] =
          <div className=' d-flex justify-content-evenly'>
            <AdvCommonBulkUploader typeForm={"RTT Submission File"} shortCode={"rttFileUpload"} uId={item.ranSupportId} wildCard={item.wildCard == "true" ? true : false} />
            {item.rttFile ?
              <a href={baseURL + "/uploads/" + item.rttFile} className="" download><DownloadNew /></a>
              // <a href={baseURL + "/uploads/" + item.rttFile} className="" download><Download /></a>
              : ""
            }
          </div>
        item["rttDateModal"] = <p
          className='badge-primary badge'
        // onClick={
        //   ()=>{

        //     openModal(item.ranSupportId,<SingleDateModal  label="Please Select Date" dataKey="rttCompleteDate" sendUrl={rttDashboard} uid={item.ranSupportId} />)

        //   }
        // }
        >
          {item.rttCompleteDate ? item.rttCompleteDate : ""}
        </p>
        item["sow"] = item["sowList"] ? item["sowList"].values ? item["sowList"].values.join(",") : "" : ""
        return item
      })
      // rows.push(sitesdata)
    }
    return ({ rows })
  })
  const addRtt = () => (
    // <Link to="/PAG/rtt/form" className="btn btn-xs btn-primary mx-2 py-2" link="" onClick={() => dispatch(clearIxSingleSite())}>
    //   Add RTT
    // </Link>
    <></>
  )
  let columns = [
    // {
    //   "name": "account",
    //   "label": "Account",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {

    //         whiteSpace: "wrap",
    //         outline: "1.5px solid black",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 101
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "160px",
    //         padding: "1px 1px",
    //         outline: "1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "market",
    //   "label": "Market",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {

    //         whiteSpace: "wrap",
    //         outline: "1.5px solid black",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 101
    //       },
    //     }), setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "160px",
    //         padding: "1px 1px",
    //         outline: "1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    {
      "name": "account",
      "label": "Account",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0px",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0px",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            position: "sticky",
            zIndex: 100
          },
        }),
      }
    },
    {
      "name": "market",
      "label": "Market",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "120px",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "120px",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            position: "sticky",
            zIndex: 100
          },
        }),
      }
    },
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "240px",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "240px",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            position: "sticky",
            zIndex: 100
          },
        }),
      }
    },
    // {
    //   "name": "",
    //   "label": "Site ID",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {

    //         whiteSpace: "wrap",
    //         outline: "1.5px solid black",
    //         left: "70px",
    //         alignItem: "center",
    //         padding: "1px 5px",
    //         background: "#143b64",
    //         minWidth: "160px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 101
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "70px",
    //         overflowY: "hidden",
    //         minWidth: "160px",
    //         padding: "1px 5px",
    //         outline: "1.5px solid black",
    //         background: "White",
    //         position: "sticky",
    //         zIndex: 100
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "siteId",
    //   "label": "Site ID",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {

    //         whiteSpace: "wrap",
    //         outline: "1.5px solid black",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 101
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",

    //         overflowY: "hidden",
    //         minWidth: "160px",
    //         padding: "1px 1px",
    //         outline: "1.5px solid black",
    //         background: "White",
    //       },
    //     }),
    //   }
    // },

    {
      "name": "projectcode",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "165px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },

    {
      "name": "proname",
      "label": "Program Name",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "165px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },


    {
      "name": "projectId",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ixStartDate",
      "label": "IX START DATE",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "sow",
      "label": "SOW",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },


    {
      "name": "siteType",
      "label": "Project Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "LTPOR",
      "label": "IX Logout Date & Time",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "160px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ILogout_timeStamp",
      "label": "IX Complete Date & Time",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "160px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ttIds",
      "label": "mPulse TT",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "250px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "pierttIds",
      "label": "CLIENT TT",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "250px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "",
      "label": "TT Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "rttFileModal",
      "label": "RTT Submission Files",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "rttDateModal",
      "label": "RTT Closure Date & Time",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "160px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "edit",
      "label": "Edit",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "160px",
            color: "white",
            position: "sticky",
            zIndex: 100
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },

  ]
  // console.log("thr response array=",rows)
  useEffect(() => {
    dispatch(AccountReducerAction(rttDashboard))
  }, [])
  return (
    <div >


      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
      <CommonModal
        modalSize={"xm"}
        setXlShow={setModal}
        xlShow={modalBdy}
        Body={commonModalState}
        Header={"RTT Closer Date Time"}
        Title={""}
      ></CommonModal>
      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={options.rows}
          columns={columns}
          options={customDataTableOptionObject(addRtt, false, "rttDashbord", '', '')}
        />
      </ThemeProvider>
    </div>
  )
}

export default RTTDashboard