// import React, { useEffect, useState } from 'react'
// import { Dropdown } from 'react-bootstrap'
// import "../../../css/customStyle.css"
// import DataTable, { createTheme } from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
// import axios from "axios";
// import { useSelector, useDispatch } from 'react-redux'
// import { NetworkDatabaseAction, CSVUploadAction, PAG_maindashboardAction } from "../../../store/actions/dashboardActions"
// import TrendingDownIcon from '@mui/icons-material/TrendingDown';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
// import { FormControl } from 'react-bootstrap'
// import moment from "moment"
// import "react-datepicker/dist/react-datepicker.css";

// import "../../../css/custom.css"



// // import { Chart } from "react-google-charts";
// import Chart from 'react-apexcharts';
// import { GetChartAction } from '../../../store/actions/createTicketManageFields';
// import { dashboardVendor, ttAgingVendor, ttCountVendor } from '../../../common/config';
// import DatePicker from "react-datepicker";
// import { CustomInput } from 'reactstrap';
// const VendorDashboard = () => {
//   let now = new Date();
//   let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
//   let endd = moment(startt).add(1, "days").subtract(1, "seconds");
//   const [accordianState, setAccordianState] = useState(false)
//   const [oneTime, setoneTime] = useState(true)
//   const [oneTime1, setoneTime1] = useState(true)
//   const [siteFile, uploadedFile] = useState();
//   const [remarks, setRemarks] = useState();
//   const [changeWeek, setChangeWeek] = useState();
//   const [notchangeWeek, setnotchangeWeek] = useState();
//   const [Week, setWeekName] = useState("Week");
//   const [adingDate, setadingDate] = useState();
//   const [start, setstart] = useState(startt);
//   const [end, setend] = useState(endd);
//   const [startDate, setStartDate] = useState();

//   const [dateRange, setDateRange] = useState([null, null]);
//   const [rangestartDate, rangeendDate] = dateRange;



//   let columns = []

//   let morethanenew = []
//   let lessthannew = []
//   let assignvendornew = []
//   let rowData = []
//   var posts = []
//   var new_posts = []
//   var tableData_new = {};

//   var TotalTicket = "--"
//   var piechartdata = [
//     ["Task", "Hours per Day"]
//   ];

//   var piechartoptions = {
//     is3D: true,
//     backgroundColor: 'transparent',
//     responsive: true,

//     legend: {
//       position: 'right',
//       textStyle: { fontSize: 10, color: 'black' },

//     },
//   };

//   var downtable_columns = []
//   var downtable_data = []

//   var unique_weeks = []
//   var vendor = []
//   var open = []
//   var close = []

//   const count = useSelector((state) => {
//     let vendornew = []
//     let opennew = []
//     let closenew = []

//     let weekks = []
//     let ticketsData;
//     console.log(state, "state")
//     // if (state.chartState.ttVendorChartData && state.chartState.ttVendorChartData.length > 0) {
//     let rowData = state.chartState.ttVendorChartData ? state.chartState.ttVendorChartData.length > 0 ? state.chartState.ttVendorChartData : [] : []
//     let agingrowData = state.chartState.ttVendorAgingChartData ? state.chartState.ttVendorAgingChartData.length > 0 ? state.chartState.ttVendorAgingChartData : [] : []

//     ticketsData = state.chartState.vendorDashboard ? state.chartState.vendorDashboard : {}


//     if (oneTime1 == oneTime1) {
//       if (agingrowData && agingrowData.length > 0) {
//         // setoneTime1(false)
//         console.log("agingrowData", agingrowData)
//         agingrowData.map((item) => {

//           let inner = item.add
//           console.log("agingrowDatainner", inner)

//           if (inner.length == 2) {
//             if (inner[0].status == "greater") {
//               morethanenew.push(inner[0].count)
//             }
//             if (inner[0].status == "less") {
//               lessthannew.push(inner[0].count)
//             }
//             if (inner[1].status == "greater") {
//               morethanenew.push(inner[1].count)
//             }
//             if (inner[1].status == "less") {
//               lessthannew.push(inner[1].count)
//             }
//           }
//           if (inner.length == 1) {
//             if (inner[0].status == "less") {
//               lessthannew.push(inner[0].count)
//               morethanenew.push(0)
//             }
//             if (inner[0].status == "greater") {
//               morethanenew.push(inner[0].count)
//               lessthannew.push(0)
//             }
//           }
//           if (inner.length == 0) {
//             morethanenew.push(0)
//             lessthannew.push(0)
//           }
//           assignvendornew.push(item._id.vendor)
//         })

//       }
//     }

//     if (rowData) {
//       rowData.map((item) => {
//         console.log(item.vendor, item.weekNumber == "08", "item.weekNumber==08", item.status)
//         if (unique_weeks.indexOf(item.weekNumber) > -1) {
//           unique_weeks.push(item.weekNumber)
//         }

//         if (1 == 1) {
//           console.log(item.data, "item.data")
//           let inner = item.data

//           console.log(inner[0].status == "Close", "inner")
//           if (inner.length == 2) {
//             console.log(inner[0].status, "innerstatus")
//             if (inner[0].status == "Open") {
//               console.log(inner[0].count, "innerstatus")
//               opennew.push(inner[0].count)
//             }
//             if (inner[0].status == "Close") {
//               console.log(inner[0].count, "innerstatus")
//               closenew.push(inner[0].count)
//             }

//             if (inner[1].status == "Open") {
//               console.log(inner[1].count, "innerstatus")
//               opennew.push(inner[1].count)
//             }
//             if (inner[1].status == "Close") {
//               console.log(inner[1].count, "innerstatus")
//               closenew.push(inner[1].count)
//             }

//           }


//           if (inner.length == 1) {
//             console.log(inner[0].status, "innerstatus")
//             if (inner[0].status == "Open") {
//               console.log(inner[0].count, "innerstatus")
//               opennew.push(inner[0].count)
//               closenew.push(0)
//             }
//             if (inner[0].status == "Close") {
//               console.log(inner[0].count, "innerstatus")
//               closenew.push(inner[0].count)
//               opennew.push(0)
//             }

//           }

//           if (inner.length == 0) {

//             closenew.push(0)
//             opennew.push(0)

//           }



//           vendornew.push(item._id.vendor)
//           console.log(opennew, closenew, vendornew, "innerdsadaas")



//         }
//       })
//     }



//     for (let i = 1; i <= 52; i++) {
//       let weekday = "0" + i.toString()
//       if (changeWeek == weekday) {
//         weekks.push(<option selected value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
//       } else {
//         weekks.push(<option value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
//       }
//     }


//     return { opennew, closenew, vendornew, weekks, ticketsData, lessthannew, morethanenew, assignvendornew }

//   })

//   console.log(count, "countcountcount")
//   const dispatch = useDispatch()
//   function accordianHandler() {
//     setAccordianState(!accordianState)
//   }


//   let ttvardata = {
//     series: [
//       {
//         name: "Open",
//         data: count.opennew,
//       },
//       {
//         name: "Close",
//         data: count.closenew,
//       },
//     ],
//     options: {
//       title: {
//         text: "Vendor TT Open Vs Closed "+ Week +" "+ changeWeek,
//         align: 'left',
//         margin: 10,
//         offsetX: 0,
//         offsetY: 0,
//         floating: false,
//         style: {
//           fontSize: '14px',
//           fontWeight: 'bold',
//           fontFamily: undefined,
//           color: '#263238'
//         },
//       },
//       chart: {
//         // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
//         background: '',
//         dropShadow: {
//           enabled: false,
//           enabledOnSeries: undefined,
//           top: 0,
//           left: 0,
//           blur: 3,
//           color: 'black',
//           opacity: 1
//         },
//         type: "bar",
//         height: 250,
//         toolbar: {
//           show: true,
//         },
//       },

//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: "55%",
//           // endingShape: "rounded",
//         },
//       },
//       dataLabels: {
//         enabled: true,
//       },

//       legend: {
//         show: true,
//         fontSize: "12px",
//         fontWeight: 300,

//         labels: {
//           colors: "#000000",
//         },
//         position: "bottom",
//         horizontalAlign: "center",
//         markers: {
//           width: 19,
//           height: 19,
//           strokeWidth: 0,
//           radius: 19,
//           margin: 1,
//           strokeColor: "#fff",
//           fillColors: ["#f16722", "#143b64"],
//           offsetX: 0,
//           offsetY: 0,
//         },
//       },
//       yaxis: {
//         labels: {
//           formatter: function (val) {
//             return val?val.toFixed(0):""
//           },
//           style: {
//             colors: "#000000",
//           },
//         },

//       },
//       // stroke: {
//       //   show: true,
//       //   width: 2,
//       //   colors: ["black"],

//       // },
//       xaxis: {
//         categories: count.vendornew,
//         labels: {
//           style: {
//             colors: "#000000",
//           },
//         },
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: true,
//         },

//       },
//       // grid: {
//       //   yaxis: {
//       //     lines: {
//       //       show: false
//       //     },
//       //   },
//       // },
//       fill: {
//         colors: ["#f16722", "#143b64"],
//         // backgroundColor:"#ff00ff",
//         opacity: 1,
//       },
//       // tooltip: {
//       //   y: {
//       //     formatter: function (val) {
//       //       return val + " Ticket";
//       //     },
//       //   },
//       // },
//     }
//   }


//   let agingvardata = {
//     series: [
//       {
//         name: "Less Than 48 Hrs",
//         data: count.lessthannew,
//       },
//       {
//         name: "More Than 48 Hrs",
//         data: count.morethanenew,
//       },
//     ],
//     options: {
//       title: {
//         text: "Current TT Ageing As of " + adingDate,
//         align: 'left',
//         margin: 10,
//         offsetX: 0,
//         offsetY: 0,
//         floating: false,
//         style: {
//           fontSize: '14px',
//           fontWeight: 'bold',
//           fontFamily: undefined,
//           color: '#263238'
//         },
//       },
//       chart: {
//         // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
//         background: '',
//         dropShadow: {
//           enabled: false,
//           enabledOnSeries: undefined,
//           top: 0,
//           left: 0,
//           blur: 3,
//           color: 'black',
//           opacity: 1
//         },
//         type: "bar",
//         height: 250,
//         toolbar: {
//           show: true,
//         },
//       },

//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: "55%",
//           // endingShape: "rounded",
//         },
//       },
//       dataLabels: {
//         enabled: true,
//       },

//       legend: {
//         show: true,
//         fontSize: "12px",
//         fontWeight: 300,

//         labels: {
//           colors: "#000000",
//         },
//         position: "bottom",
//         horizontalAlign: "center",
//         markers: {
//           width: 19,
//           height: 19,
//           strokeWidth: 0,
//           radius: 19,
//           margin: 1,
//           strokeColor: "#fff",
//           fillColors: ["#f16722", "#143b64"],
//           offsetX: 0,
//           offsetY: 0,
//         },
//       },
//       yaxis: {
//         labels: {
//           formatter: function (val) {
//             return val?val.toFixed(0):""
//           },
//           style: {
//             colors: "#000000",
//           },
//         },

//       },
//       // stroke: {
//       //   show: true,
//       //   width: 2,
//       //   colors: ["black"],

//       // },
//       xaxis: {
//         categories: count.assignvendornew,
//         labels: {
//           style: {
//             colors: "#000000",
//           },
//         },
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: true,
//         },

//       },
//       // grid: {
//       //   yaxis: {
//       //     lines: {
//       //       show: false
//       //     },
//       //   },
//       // },
//       fill: {
//         colors: ["#f16722", "#143b64"],
//         // backgroundColor:"#ff00ff",
//         opacity: 1,
//       },
//       // tooltip: {
//       //   y: {
//       //     formatter: function (val) {
//       //       return val + " Ticket";
//       //     },
//       //   },
//       // },
//     }
//   }


//   function changeWeekF(e, typ = undefined) {
//     if (typ == "no") {
//       console.log(e, "changeWeek")
//       setChangeWeek(e)
//     } else {
//       console.log(e.target.value, "changeWeek")
//       setChangeWeek(e.target.value)
//     }
//   }

//   let percent = "--";
//   let type = 0;
//   console.log(count.ticketsData, "count.ticketsData")
//   if (count.ticketsData) {
//     if (oneTime && count.ticketsData.currentWeek) {
//       setoneTime(false)
//       setChangeWeek(count.ticketsData.currentWeek)
//       // changeWeekF(count.ticketsData.currentWeek, "no")
//       // console.log(count.ticketsData.currentWeek, "count.ticketsData")
//       setadingDate(count.ticketsData.adingDate)
//     }


//     if (count.ticketsData.yesterdayOpen == 0) {
//       percent = count.ticketsData.todayOpen * 100
//       type = 1
//     }
//     else if (count.ticketsData.todayOpen == 0) {
//       percent = count.ticketsData.yesterdayOpen * 100
//       type = 2
//     }
//     else if (count.ticketsData.todayOpen > count.ticketsData.yesterdayOpen) {
//       percent = count.ticketsData.todayOpen * 100 / count.ticketsData.yesterdayOpen
//       type = 1
//     }
//     else if (count.ticketsData.todayOpen < count.ticketsData.yesterdayOpen) {
//       percent = count.ticketsData.yesterdayOpen * 100 / count.ticketsData.todayOpen
//       type = 2
//     }

//     else if (count.ticketsData.todayOpen == count.ticketsData.yesterdayOpen) {
//       percent = "100"
//     }

//     percent = percent + "%"
//   }

//   // let now = new Date();
//   // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
//   // let end = moment(start).add(1, "days").subtract(1, "seconds");
//   let ranges = {
//     "Today Only": [moment(start), moment(end)],
//     "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
//     "3 Days": [moment(start).subtract(3, "days"), moment(end)]
//   }
//   let local = {
//     "format": "DD-MM-YYYY HH:mm",
//     "sundayFirst": false,
//     days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'So'],
//     months: [
//       'January',
//       'February',
//       'March',
//       'April',
//       'May',
//       'June',
//       'July',
//       'August',
//       'September',
//       'October',
//       'November',
//       'December',],
//     fromDate: 'From Date',
//     toDate: 'To Date',
//     selectingFrom: 'Selecting From',
//     selectingTo: 'Selecting To',
//     maxDate: 'Max Date',
//     close: 'Close',
//     apply: 'Apply',
//     cancel: 'Cancel'
//   }



//   function applyCallback(startDate, endDate) {
//     this.setState({
//       start: startDate,
//       end: endDate
//     }
//     )
//   }

//   function yearWiseCaller(stdate, from) {
//     console.log(stdate, "yearWiseCaller", "logger")

//     let minDate = moment(stdate).startOf("year")
//     let maxDate = moment(stdate).endOf("year")
//     setChangeWeek(minDate.format("yyyy"))
//     setWeekName("Year ")
//     commonapicaller(minDate, maxDate, from)
//   }
//   function rangeWiseCaller(updateDate, from) {

//     console.log("setDateRange", updateDate)
//     let minDate = moment(updateDate[0])
//     if (updateDate[1] != null) {
//       let maxDate = moment(updateDate[1])
//       commonapicaller(minDate, maxDate, from)
//       setWeekName("From ")
//       setChangeWeek(minDate.format("MM/DD/yyyy")+" To "+maxDate.format("MM/DD/yyyy"))
//     }


//     console.log(dateRange, "yearWiseCaller", "logger")
//     console.log(dateRange, "")
//   }
//   function quaterWiseCaller(date, from) {
//     let minDate = moment(date).startOf("quarter")
//     let maxDate = moment(date).endOf("quarter")
//     commonapicaller(minDate, maxDate, from)
//     setChangeWeek(minDate.format("Q") + ", Year "+minDate.format("yyyy"))
//     setWeekName("Quater")
//     console.log(date, "quaterWiseCaller", "logger")
//   }
//   function monthWiseCaller(date, from) {
//     let minDate = moment(date).startOf("month")
//     let maxDate = moment(date).endOf("month")
//     setChangeWeek(minDate.format("MMMM")+ ", Year "+minDate.format("yyyy"))
//     setWeekName("Month ")
//     commonapicaller(minDate, maxDate, from)
//   }
//   function weekWiseCaller(nowe, from) {
//     console.log(nowe.target.value, startDate, "nowe", "")
//     let minDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W","")).startOf("week").isoWeekday(1)
//     let maxDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W","")).endOf("week").isoWeekday(1)
//     console.log(moment().week, maxDate, "maxDatemaxDate")
//     setChangeWeek(nowe.target.value.split("-")[1].replace("W","")+", Year "+minDate.format("yyyy"))
//     setWeekName("Week ")
//     commonapicaller(minDate, maxDate, from)
//   }

//   function commonapicaller(apiStartDate, apiEndDate, from) {

//     console.log("logger",apiStartDate.week(),apiStartDate.format("MM/DD/YYYY HH:mm:SS"), apiEndDate.format("MM/DD/YYYY"))
//     dispatch(GetChartAction(`${from}`, `?start=${apiStartDate.format("MM/DD/YYYY_hh:mm:ss")}&end=${apiEndDate.format("MM/DD/YYYY_hh:mm:ss")}`))
//   }





//   // let maxDate = moment(start).add(24, "hour")

//   useEffect(() => {

//     console.log("ttCountVendor", ttCountVendor)
//     var currentDateR = moment();
//     var weekStart = currentDateR.clone().startOf('isoWeek');
//     var weekEnd = currentDateR.clone().endOf('isoWeek');

//     dispatch(GetChartAction(`${ttCountVendor}`, `?start=${weekStart.format("MM/DD/YYYY_hh:mm:ss")}&end=${weekEnd.format("MM/DD/YYYY_hh:mm:ss")}`))
//     // dispatch(GetChartAction(ttCountVendor, ""))
//     dispatch(GetChartAction(dashboardVendor, ""))
//     dispatch(GetChartAction(ttAgingVendor, ""))
//   }, [notchangeWeek])
//   return (
//     <>
//       <div className="row">
//         <div className="col-xl-6 col-md-6 col-lg-6 boxcol">
//           <div className="card ticket-bx">
//             <div className="card-body" style={{ padding: "0.875rem" }}>
//               <div className="d-sm-flex d-block pb-sm-3 align-items-end">

//                 <div className="mr-auto pr-3 mb-2 mb-sm-0">
//                   <span className="text-white  font-w200 d-block mb-sm-3 mb-2"><b>Today TT Opened</b></span>
//                   <h2 className="fs-40 text-white mb-0">{count.ticketsData ? count.ticketsData.todayOpen : "--"}<span className=" ml-2">Tickets</span></h2>
//                 </div>
//                 <div className="d-flex flex-wrap ftsize">
//                   {type == 1 ? <TrendingUpIcon fontSize='inherit' /> : ""}
//                   {type == 2 ? <TrendingDownIcon fontSize='inherit' /> : ""}


//                   <div className="ml-3">
//                     <p className="text-warning  mb-0">{percent}</p>
//                     <span className="fs-12">than last day</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="progress mt-3 mb-4" style={{ height: "15px" }}>
//                 <div className="progress-bar-striped progress-bar-animated" style={{ width: "86%", height: "15px" }} role="progressbar">
//                   <span className="sr-only">86% Complete</span>
//                 </div>
//               </div>
//               {/* <p className="fs-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</p> */}
//               {/* <Link to={"#"} className="text-white">View detail<i className="las la-long-arrow-alt-right scale5 ml-3"></i></Link> */}
//             </div>
//             <div className="card-body" style={{ padding: "0.875rem" }}>
//               <div className="d-sm-flex d-block pb-sm-3 align-items-end">
//                 <div className="mr-auto pr-3 mb-2 mb-sm-0">
//                   <span className="text-white  font-w200 d-block mb-sm-3 mb-2 "><b>Total  TT's Open</b></span>
//                   <h2 className="fs-40 text-white mb-0">{count.ticketsData ? count.ticketsData.totalOpen : "--"}<span className=" ml-2">Tickets</span></h2>
//                 </div>

//               </div>
//             </div>
//           </div>

//         </div>

//         <div className="col-xl-6 col-md-6 col-lg-6 boxcol">

//           <div className="card border card-border-blue">
//             <div className="card-body card-body-custom">
//               <div className='form-group d-flex justify-content-start'>
//                 {/* <label className='mx-2'>Week</label>
//                 <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
//                   {count.weekks}
//                 </select>
//                  */}
//                 {/* <div>
//                   <DateTimeRangeContainer
//                     smartMode={true}
//                     noMobileMode={true}
//                     ranges={ranges}
//                     start={start}
//                     end={end}
//                     local={local}
//                     maxDate={maxDate}
//                     style={{ display: 'flex' }}
//                     applyCallback={applyCallback}
//                   >
//                     <FormControl
//                       id="formControlsTextB"
//                       type="text"
//                       label="Text"
//                       placeholder="Enter text"
//                     />
//                   </DateTimeRangeContainer>
//                 </div> */}
//                 <div className='row row-custom'>
//                   <div className='col-4 one-line-col1 pd-0'>
//                     <label>Select Date</label>
//                     <DatePicker
//                       selectsRange={true}
//                       placeholderText={"Select Date"}
//                       startDate={rangestartDate}
//                       endDate={rangeendDate}
//                       onChange={(update) => {
//                         setDateRange(update);
//                         rangeWiseCaller(update, ttCountVendor)
//                       }}
//                     />

//                   </div>
//                   <div className='col-2 one-line-col2 pd-0'>
//                     <label>Year</label>
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         yearWiseCaller(date, ttCountVendor)
//                       }}
//                       showYearPicker
//                       placeholderText='Select'
//                       dateFormat="yyyy"

//                     />
//                   </div>
//                   <div className='col-3 one-line-col3 pd-0'>
//                     <label>Quarter</label>
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         quaterWiseCaller(date, ttCountVendor)
//                       }}
//                       dateFormat="yyyy, QQQ"
//                       placeholderText='Select'
//                       showQuarterYearPicker
//                     />

//                   </div>

//                   <div className='col-3 one-line-col4 pd-0'>
//                     <label>Month</label>
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         monthWiseCaller(date, ttCountVendor)
//                       }}
//                       dateFormat="MMM/yyyy"
//                       placeholderText='Select'
//                       showMonthYearPicker
//                     />


//                   </div>


//                   <div className='col-2 one-line-col5 pd-0'>
//                     <label>Week</label>

//                   <div class="react-datepicker-wrapper">
//                     <div class="react-datepicker__input-container">

//                       <input type='week'
//                         placeholder='Select Week'
//                         style={{height: "32.5px"}}
//                         onChange={(date) => {
//                           weekWiseCaller(date, ttCountVendor)
//                         }}
//                       />
//                     </div>
//                   </div>

//                   </div>

//                 </div>
//               </div>
//               <div className="mixed-chart ">
//                 <Chart
//                   options={ttvardata.options}
//                   series={ttvardata.series}
//                   type="bar"
//                   height={300}
//                 />


//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-xl-6 col-md-6 col-lg-6 boxcol">
//           <div className="card border card-border-blue">
//             <div className="card-body card-body-custom">
//               <div className='form-group d-flex justify-content-start'>
//                 {/* <label className='mx-2'>Week</label>
//             <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
//               {count.weekks}
//             </select> */}

//                 {/* <div className='row row-custom'>
//                   <div className='col-4 one-line-col1 pd-0'>
//                     <DatePicker
//                       selectsRange={true}
//                       startDate={rangestartDate}
//                       endDate={rangeendDate}
//                       onChange={(update) => {
//                         setDateRange(update);
//                         rangeWiseCaller(update, ttAgingVendor)
//                       }}
//                     />

//                   </div>
//                   <div className='col-2 one-line-col2 pd-0'>
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         yearWiseCaller(date, ttAgingVendor)
//                       }}
//                       showYearPicker
//                       dateFormat="yyyy"

//                     />
//                   </div>
//                   <div className='col-3 one-line-col3 pd-0'>
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         quaterWiseCaller(ttAgingVendor)
//                       }}
//                       dateFormat="yyyy, QQQ"
//                       showQuarterYearPicker
//                     />

//                   </div>

//                   <div className='col-3 one-line-col4 pd-0'>

//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => {
//                         setStartDate(date)
//                         monthWiseCaller(ttAgingVendor)
//                       }}
//                       dateFormat="MM/yyyy"
//                       showMonthYearPicker
//                     />
//                   </div>


//                   <div className='col-2 one-line-col5 pd-0'>
//                     <select onChange={(e) => {
//                       changeWeekF(e)
//                       weekWiseCaller(ttAgingVendor)
//                     }}>
//                       {count.weekks}
//                     </select>

//                   </div>

//                 </div> */}
//               </div>
//               <div className="mixed-chart ">

//                 <Chart
//                   options={agingvardata.options}
//                   series={agingvardata.series}
//                   type="bar"
//                   height={350}
//                 />


//               </div>
//             </div>
//           </div>  
//         </div>
//       </div>
//     </>
//   )
// }

// export default VendorDashboard;




import React, { useEffect, useState } from 'react'
// import { Dropdown } from 'react-bootstrap'
// import "../../../css/customStyle.css"
// // import DataTable, { createTheme } from 'react-data-table-component';
// // import DataTableExtensions from 'react-data-table-component-extensions';
// import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
// // import { NetworkDatabaseAction, CSVUploadAction, PAG_maindashboardAction } from "../../../store/actions/dashboardActions"
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// // import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
// // import { FormControl } from 'react-bootstrap'
import moment from "moment"
// import "react-datepicker/dist/react-datepicker.css";

// import "../../../css/custom.css"

// // fusion starts
// // import FusionCharts from "fusioncharts/core";
// // import candyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
// // import FusionCharts from "fusioncharts";
// // import charts from "fusioncharts/fusioncharts.charts";
// // import ReactFusioncharts from "react-fusioncharts";
import candyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import FusionCharts from "fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
// // fusion ends

// // import { Chart } from "react-google-charts";
// // import Chart from 'react-apexcharts';
import { GetChartAction } from '../../../store/actions/createTicketManageFields';
import { dashboardVendor, ttAgingVendor, ttCountVendor } from '../../../common/config';
import DatePicker from "react-datepicker";
// // import { CustomInput } from 'reactstrap';


// // Resolves charts dependancy
ReactFusioncharts.fcRoot(FusionCharts, charts, candyTheme);
// // charts(FusionCharts);
// // Register the candy theme
// // FusionCharts.addDep(candyTheme);
const VendorDashboard = () => {

  // chart starts


  // useEffect(() => {
  //  fetch('http://127.0.0.1:8095/PAG/ran/siteStatus' , {

  //  }).then(res => {
  //   console.warn('site status =>>' , res?.data)
  //  }).catch(err => console.log(err))
  // } , [])

  //  charts ends

  let now = new Date();
  let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endd = moment(startt).add(1, "days").subtract(1, "seconds");
  const [accordianState, setAccordianState] = useState(false)
  const [oneTime, setoneTime] = useState(true)
  const [oneTime1, setoneTime1] = useState(true)
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [changeWeek, setChangeWeek] = useState();
  const [notchangeWeek, setnotchangeWeek] = useState();
  const [Week, setWeekName] = useState("Year");
  const [adingDate, setadingDate] = useState();
  const [start, setstart] = useState(startt);
  const [end, setend] = useState(endd);
  const [startDate, setStartDate] = useState();

  const [dateRange, setDateRange] = useState([null, null]);
  const [rangestartDate, rangeendDate] = dateRange;



  let columns = []

  let morethanenew = []
  let lessthannew = []
  let assignvendornew = []
  let rowData = []
  var posts = []
  var new_posts = []
  var tableData_new = {};

  var TotalTicket = "--"
  var piechartdata = [
    ["Task", "Hours per Day"]
  ];

  var piechartoptions = {
    is3D: true,
    backgroundColor: 'transparent',
    responsive: true,

    legend: {
      position: 'right',
      textStyle: { fontSize: 10, color: 'black' },

    },
  };

  var downtable_columns = []
  var downtable_data = []

  var unique_weeks = []
  var vendor = []
  var open = []
  var close = []

  const count = useSelector((state) => {
    let vendornew = []
    let opennew = []
    let closenew = []

    let weekks = []
    let ticketsData;
    console.log(state, "state")
    // if (state.chartState.ttVendorChartData && state.chartState.ttVendorChartData.length > 0) {
    let rowData = state.chartState.ttVendorChartData ? state.chartState.ttVendorChartData.length > 0 ? state.chartState.ttVendorChartData : [] : []
    let agingrowData = state.chartState.ttVendorAgingChartData ? state.chartState.ttVendorAgingChartData.length > 0 ? state.chartState.ttVendorAgingChartData : [] : []

    ticketsData = state.chartState.vendorDashboard ? state.chartState.vendorDashboard : {}

    console.log(ticketsData,"ticketsDataticketsDataticketsData")

    if (oneTime1 == oneTime1) {
      if (agingrowData && agingrowData.length > 0) {
        // setoneTime1(false)
        console.log("agingrowData", agingrowData)
        agingrowData.map((item) => {

          let inner = item.add
          console.log("agingrowDatainner", inner)

          if (inner.length == 2) {
            if (inner[0].status == "greater") {
              morethanenew.push(inner[0].count)
            }
            if (inner[0].status == "less") {
              lessthannew.push(inner[0].count)
            }
            if (inner[1].status == "greater") {
              morethanenew.push(inner[1].count)
            }
            if (inner[1].status == "less") {
              lessthannew.push(inner[1].count)
            }
          }
          if (inner.length == 1) {
            if (inner[0].status == "less") {
              lessthannew.push(inner[0].count)
              morethanenew.push(0)
            }
            if (inner[0].status == "greater") {
              morethanenew.push(inner[0].count)
              lessthannew.push(0)
            }
          }
          if (inner.length == 0) {
            morethanenew.push(0)
            lessthannew.push(0)
          }
          assignvendornew.push(item._id.vendor)
        })

      }
    }

    if (rowData) {
      rowData.map((item) => {
        console.log(item.vendor, item.weekNumber == "08", "item.weekNumber==08", item.status)
        if (unique_weeks.indexOf(item.weekNumber) > -1) {
          unique_weeks.push(item.weekNumber)
        }

        if (1 == 1) {
          console.log(item.data, "item.data")
          let inner = item.data

          console.log(inner[0].status == "Close", "inner")
          if (inner.length == 2) {
            console.log(inner[0].status, "innerstatus")
            if (inner[0].status == "Open") {
              console.log(inner[0].count, "innerstatus")
              opennew.push(inner[0].count)
            }
            if (inner[0].status == "Close") {
              console.log(inner[0].count, "innerstatus")
              closenew.push(inner[0].count)
            }

            if (inner[1].status == "Open") {
              console.log(inner[1].count, "innerstatus")
              opennew.push(inner[1].count)
            }
            if (inner[1].status == "Close") {
              console.log(inner[1].count, "innerstatus")
              closenew.push(inner[1].count)
            }

          }


          if (inner.length == 1) {
            console.log(inner[0].status, "innerstatus")
            if (inner[0].status == "Open") {
              console.log(inner[0].count, "innerstatus")
              opennew.push(inner[0].count)
              closenew.push(0)
            }
            if (inner[0].status == "Close") {
              console.log(inner[0].count, "innerstatus")
              closenew.push(inner[0].count)
              opennew.push(0)
            }

          }

          if (inner.length == 0) {

            closenew.push(0)
            opennew.push(0)

          }



          vendornew.push(item._id.vendor)
          console.log(opennew, closenew, vendornew, "innerdsadaas")



        }
      })
    }



    for (let i = 1; i <= 52; i++) {
      let weekday = "0" + i.toString()
      if (changeWeek == weekday) {
        weekks.push(<option selected value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
      } else {
        weekks.push(<option value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
      }
    }


    return { opennew, closenew, vendornew, weekks, ticketsData, lessthannew, morethanenew, assignvendornew }

  })


  // const [dataSource, setDataSource] = useState({
  //   chart: {
  //     caption: "Total Opened Trouble Tickets",
  //     // subcaption: " Top 5 Developed Countries",
  //     // numbersuffix: "%",
  //     // showsum: "1",
  //     plottooltext:
  //       "$label produces <b>$dataValue</b>",
  //     theme: "candy",
  //     drawcrossline: "1"
  //   },
  //   categories: [
  //     {
  //       category: [
  //         {
  //           label: "Today TT Opened"
  //         },
  //         {
  //           label: "Total  TT's Open"
  //         },
  //         {
  //           label: "TT ageing less than 48hrs"
  //         },
  //         {
  //           label: "TT ageing more than 48hrs"
  //         },
  //       ]
  //     }
  //   ],
  //   dataset: [
  //     {
  //       seriesname: "Today TT Opened",
  //       data: [
  //         {
  //           value: "400"
  //         },
  //         {
  //           value: "830"
  //         },
  //         {
  //           value: "500"
  //         },
  //         {
  //           value: "420"
  //         },
  //         {
  //           value: "790"
  //         },
  //         {
  //           value: "380"
  //         }
  //       ]
  //     },
  //     {
  //       seriesname: "Total  TT's Open",
  //       data: [
  //         {
  //           value: "350"
  //         },
  //         {
  //           value: "620"
  //         },
  //         {
  //           value: "410"
  //         },
  //         {
  //           value: "370"
  //         },
  //         {
  //           value: "720"
  //         },
  //         {
  //           value: "310"
  //         }
  //       ]
  //     },
  //     {
  //       seriesname: 'TT ageing less than 48hrs',
  //       data: count?.lessthannew || []
  //     },
  //     {
  //       seriesname: 'TT ageing more than 48hrs',
  //       data: count?.morethanenew || []
  //     }
  //   ]
  // })

  const dispatch = useDispatch()
  function accordianHandler() {
    setAccordianState(!accordianState)
  }


  let ttvardata = {
    series: [
      {
        name: "Open",
        data: count.opennew,
      },
      {
        name: "Close",
        data: count.closenew,
      },
    ],
    options: {
      title: {
        text: "Vendor TT Open Vs Closed " + Week + " " + changeWeek,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        },
      },
      chart: {
        // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
        background: '',
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: 'black',
          opacity: 1
        },
        type: "bar",
        height: 250,
        toolbar: {
          show: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },

      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 300,

        labels: {
          colors: "#000000",
        },
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          margin: 1,
          strokeColor: "#fff",
          fillColors: ["#f16722", "#143b64"],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0) : ""
          },
          style: {
            colors: "#000000",
          },
        },

      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["black"],

      // },
      xaxis: {
        categories: count.vendornew,
        labels: {
          style: {
            colors: "#000000",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

      },
      // grid: {
      //   yaxis: {
      //     lines: {
      //       show: false
      //     },
      //   },
      // },
      fill: {
        colors: ["#f16722", "#143b64"],
        // backgroundColor:"#ff00ff",
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val + " Ticket";
      //     },
      //   },
      // },
    }
  }


  let agingvardata = {
    series: [
      {
        name: "Less Than 48 Hrs",
        data: count?.lessthannew || [],
      },
      {
        name: "More Than 48 Hrs",
        data: count?.morethanenew || [],
      },
    ],
    options: {
      title: {
        text: "Current TT Ageing As of " + adingDate,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        },
      },
      chart: {
        // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
        background: '',
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: 'black',
          opacity: 1
        },
        type: "bar",
        height: 250,
        toolbar: {
          show: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },

      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 300,

        labels: {
          colors: "#000000",
        },
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          margin: 1,
          strokeColor: "#fff",
          fillColors: ["#f16722", "#143b64"],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0) : ""
          },
          style: {
            colors: "#000000",
          },
        },

      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["black"],

      // },
      xaxis: {
        categories: count.assignvendornew,
        labels: {
          style: {
            colors: "#000000",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

      },
      // grid: {
      //   yaxis: {
      //     lines: {
      //       show: false
      //     },
      //   },
      // },
      fill: {
        colors: ["#f16722", "#143b64"],
        // backgroundColor:"#ff00ff",
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val + " Ticket";
      //     },
      //   },
      // },
    }
  }


  function changeWeekF(e, typ = undefined) {
    if (typ == "no") {
      console.log(e, "changeWeek")
      setChangeWeek(e)
    } else {
      console.log(e.target.value, "changeWeek")
      setChangeWeek(e.target.value)
    }
  }

  let percent = "--";
  let type = 0;
  console.log(count.ticketsData, "count.ticketsData")
  if (count.ticketsData) {
    if (oneTime && count.ticketsData.currentWeek) {
      setoneTime(false)
      // setChangeWeek(count.ticketsData.currentWeek)
      // changeWeekF(count.ticketsData.currentWeek, "no")
      // console.log(count.ticketsData.currentWeek, "count.ticketsData")
      setadingDate(count.ticketsData.adingDate)
    }


    if (count.ticketsData.yesterdayOpen == 0) {
      percent = count.ticketsData.todayOpen * 100
      type = 1
    }
    else if (count.ticketsData.todayOpen == 0) {
      percent = count.ticketsData.yesterdayOpen * 100
      type = 2
    }
    else if (count.ticketsData.todayOpen > count.ticketsData.yesterdayOpen) {
      percent = ((count.ticketsData.todayOpen-count.ticketsData.yesterdayOpen) / count.ticketsData.yesterdayOpen) * 100
      // percent = count.ticketsData.todayOpen * 100 / count.ticketsData.yesterdayOpen
      type = 1
    }
    else if (count.ticketsData.todayOpen < count.ticketsData.yesterdayOpen) {
      // percent = count.ticketsData.yesterdayOpen * 100 / count.ticketsData.todayOpen
      percent = ((count.ticketsData.todayOpen-count.ticketsData.yesterdayOpen) / count.ticketsData.yesterdayOpen) * 100
      type = 2
    }

    else if (count.ticketsData.todayOpen == count.ticketsData.yesterdayOpen) {
      percent = "100"
      percent = 0
    }

    // percent = percent + "%"
    percent = percent.toFixed(2)+"%"
  }

  // let now = new Date();
  // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  // let end = moment(start).add(1, "days").subtract(1, "seconds");
  let ranges = {
    "Today Only": [moment(start), moment(end)],
    "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
    "3 Days": [moment(start).subtract(3, "days"), moment(end)]
  }
  let local = {
    "format": "DD-MM-YYYY HH:mm",
    "sundayFirst": false,
    days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'So'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',],
    fromDate: 'From Date',
    toDate: 'To Date',
    selectingFrom: 'Selecting From',
    selectingTo: 'Selecting To',
    maxDate: 'Max Date',
    close: 'Close',
    apply: 'Apply',
    cancel: 'Cancel'
  }



  function applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate
    }
    )
  }

  function yearWiseCaller(stdate, from) {
    console.log(stdate, "yearWiseCaller", "logger")

    let minDate = moment(stdate).startOf("year")
    let maxDate = moment(stdate).endOf("year")
    setChangeWeek(minDate.format("yyyy"))
    setWeekName("Year ")
    commonapicaller(minDate, maxDate, from)
  }
  function rangeWiseCaller(updateDate, from) {

    console.log("setDateRange", updateDate)
    let minDate = moment(updateDate[0])
    if (updateDate[1] != null) {
      let maxDate = moment(updateDate[1])
      commonapicaller(minDate, maxDate, from)
      setWeekName("From ")
      setChangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"))
    }

    console.log(dateRange, "yearWiseCaller", "logger")
    console.log(dateRange, "")
  }
  function quaterWiseCaller(date, from) {
    let minDate = moment(date).startOf("quarter")
    let maxDate = moment(date).endOf("quarter")
    commonapicaller(minDate, maxDate, from)
    setChangeWeek(minDate.format("Q") + ", Year " + minDate.format("yyyy"))
    setWeekName("Quater")
    console.log(date, "quaterWiseCaller", "logger")
  }
  function monthWiseCaller(date, from) {
    let minDate = moment(date).startOf("month")
    let maxDate = moment(date).endOf("month")
    setChangeWeek(minDate.format("MMMM") + ", Year " + minDate.format("yyyy"))
    setWeekName("Month ")
    commonapicaller(minDate, maxDate, from)
  }
  function weekWiseCaller(nowe, from) {
    console.log(nowe.target.value, startDate, "nowe", "")
    let minDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).startOf("week").isoWeekday(1)
    let maxDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).endOf("week").isoWeekday(1)
    console.log(moment().week, maxDate, "maxDatemaxDate")
    setChangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
    setWeekName("Week ")
    commonapicaller(minDate, maxDate, from)
  }

  function commonapicaller(apiStartDate, apiEndDate, from) {

    console.log("logger", apiStartDate.week(), apiStartDate.format("MM/DD/YYYY HH:mm:SS"), apiEndDate.format("MM/DD/YYYY"))
    dispatch(GetChartAction(`${from}`, `?start=${apiStartDate.format("MM/DD/YYYY_hh:mm:ss")}&end=${apiEndDate.format("MM/DD/YYYY_hh:mm:ss")}`))
  }

  // let maxDate = moment(start).add(24, "hour")

  useEffect(() => {

    console.log("ttCountVendor", ttCountVendor)
    var currentDateR = moment();
    var weekStart = currentDateR.clone().startOf('isoWeek');
    var weekEnd = currentDateR.clone().endOf('isoWeek');

    
    setChangeWeek(moment().format("YYYY"))

    dispatch(GetChartAction(ttCountVendor, `?start=${moment().startOf("year").format("MM/DD/YYYY_hh:mm:ss")}&end=${moment().endOf("year").format("MM/DD/YYYY_hh:mm:ss")}`))
    // dispatch(GetChartAction(`${ttCountVendor}`, `?start=${weekStart.format("MM/DD/YYYY_hh:mm:ss")}&end=${weekEnd.format("MM/DD/YYYY_hh:mm:ss")}`))
    // dispatch(GetChartAction(ttCountVendor, ""))
    dispatch(GetChartAction(dashboardVendor, ""))
    dispatch(GetChartAction(ttAgingVendor, ""))
  }, [notchangeWeek])

  let vendorOpenClose = {
    chart: {
      caption: "Vendor TT Open VS Closed - " + Week + " " + changeWeek,
      dataLabel: '1',
      captionFontSize: "14",
      dataLabelFontSize: '14',
      valueFontSize:10,
      valuePosition:'outside',
      dataLabelFontBold: '1',
      dataLabelFontColor: '#ffffff',
      dataLabelPadding: '5',
      dataLabelPlacement: 'middle',
      plottooltext: "<b>$label</b><br>Value: $value",
      theme: "candy",
      drawcrossline: "1",
      "seriesNameFontSize": "12",
      exportEnabled: true,
      exportAtClient: true,
      exportfilename: "Vendor TT Open VS Closed - " + Week + " " + changeWeek,


    },

    categories: [
      {
        category: Array.isArray(count?.vendornew) ? count?.vendornew.map(data => { return { label: data?.replace('Tower Team', 'TT'), "labelFontSize": "10" } }) : [],
      }
    ],
    dataset: [
      {
        seriesname: "Open",
        color: "#4ae036",
        data: Array.isArray(count?.opennew) ? count?.opennew.map(data => { return { value: data, ...(+data !== 0 && { showValue: "1" }), color: "#4ae036" } }) : [],
      },
      {
        seriesname: "Close",
        color: "#FF00FF",
        data: Array.isArray(count?.closenew) ? count?.closenew.map(data => { return { value: data, ...(+data !== 0 && { showValue: "1" }), color: "#FF00FF" } }) : [],

      },

    ]
  }

  let ageing = {
    chart: {
      caption: "Current TT Ageing of " + count?.ticketsData?.adingDate || "",
      dataLabel: '1',
      baseFontSize:"10",
      valueFontSize:10,
      valuePosition:'outside',
      captionFontSize: "14",
      dataLabelFontSize: '14',
      dataLabelFontBold: '1',
      dataLabelFontColor: '#000000',
      dataLabelPadding: '5',
      dataLabelPlacement: 'middle',
      plottooltext: "<b>$label</b><br>Value: $value",
      theme: "candy",
      drawcrossline: "1",
      "seriesNameFontSize": "12",
      exportEnabled: true,
      exportAtClient: true,
      exportfilename: "Current TT Ageing of " + count?.ticketsData?.adingDate || ""

    },

    categories: [
      {
        category: Array.isArray(count?.assignvendornew) ? count?.assignvendornew?.map(data => { return { label: data?.replace('Tower Team', 'TT'), "labelFontSize": "10" } }) : []
      }
    ],
    dataset: [
      {
        seriesname: "Less than 48 HRS",
        color: "#4ae036",
        data: Array.isArray(count?.lessthannew) ? count?.lessthannew.map(data => { return { value: data, ...(+data !== 0 && { showValue: "1" }), color: "#4ae036" } }) : [],
      },

      {
        seriesname: "More than 48 HRS",
        color: "#FF00FF",
        data: Array.isArray(count?.morethanenew) ? count?.morethanenew.map(data => { return { value: data, ...(+data !== 0 && { showValue: "1" }), color: "#FF00FF" } }) : [],
      },
    ]
  }


  console.warn("count => ", count)

  return (
    <>
      <div style={{ marginBottom: "2rem", display: "grid", gridTemplateColumns: "repeat(2 , 50%)", gridTemplateRows: "repeat(8 , 105px)", gridRowGap: "0.3rem" }}>

        <div className="" style={{ gridColumn: "span 1", gridRow: "span 4", padding: "0.4rem" }}>
          <div className="ticket-bx" style={{ paddingLeft: "1rem", height: "98%", background: 'rgb(38, 42, 51)' }}>
            <div className="card-body" style={{ padding: "0.875rem", background: 'rgb(38, 42, 51)' }}>
              <div className="d-sm-flex d-block pb-sm-3 align-items-end">

                <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white  d-block" style={{ fontSize: "17px" }}>Today TT Opened</span>
                  <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count?.ticketsData ? count?.ticketsData?.todayOpen : "--"}<span className="fs-16 ml-2">Tickets</span></h2>
                </div>
                <div className="d-flex flex-wrap ftsize">
                  {type == 1 ? <TrendingUpIcon fontSize='inherit' /> : ""}
                  {type == 2 ? <TrendingDownIcon fontSize='inherit' /> : ""}


                  <div className="ml-3">
                    <p className="text-warning mb-0" style={{ fontSize: "20px" }}>{percent}</p>
                    <span className="fs-13">than last day</span>
                  </div>
                </div>
              </div>
              
              <div className="mr-auto pr-3 mb-2 mb-sm-0">
                <span className="text-white  d-block" style={{ fontSize: "17px" }}>Yesterday TT Opened</span>
                <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count?.ticketsData ? count?.ticketsData?.yesterdayOpen : "--"}<span className="fs-16 ml-2">Tickets</span></h2>
              </div>
              <div className="progress mt-1" style={{ height: "20px" }}>
                <div className="progress-bar-striped progress-bar-animated" style={{ width: "86%", height: "100%" }} role="progressbar">
                  <span className="sr-only">86% Complete</span>
                </div>
              </div>

              {/* <p className="fs-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</p> */}
              {/* <Link to={"#"} className="text-white">View detail<i className="las la-long-arrow-alt-right scale5 ml-3"></i></Link> */}
            </div>
            
            <div className="card-body" style={{ padding: "0.875rem" }}>
              <div className="d-sm-flex d-block pb-1 align-items-end">
                <div className="mr-auto pr-3">
                  <span className="text-white  d-block mb-1" style={{ fontSize: "17px" }}>Total  TT's Open</span>
                  <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count.ticketsData ? count.ticketsData.totalOpen : "--"}<span className="fs-16 ml-2">Tickets</span></h2>
                </div>
              </div>

            </div>

            <div className="d-sm-flex d-block pb-sm-3 align-items-end" style={{ padding: "0.875rem" }}>

                <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white  d-block mb-2" style={{ fontSize: "17px" }}>TT Ageing More Than 48 Hrs</span>
                  {/* <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count?.morethanenew?.length || 0}</h2> */}
                  <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count.ticketsData ? count.ticketsData.gttAgingT : "--"}</h2>
                </div>
                
                <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white  d-block mb-2" style={{ fontSize: "17px" }}>TT Ageing Less Than 48 Hrs</span>
                  {/* <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count?.lessthannew?.length || 0}</h2> */}
                  <h2 className=" text-white mb-0" style={{ fontSize: "16px" }}>{count.ticketsData ? count.ticketsData.lttAgingT : "--"}</h2>
              
                </div>



                
            </div>
          </div>

        </div>

        <div className="" style={{ gridColumn: "span 1", gridRow: "span 4", padding: "0.4rem" }}>
          {/* <div className='d-flex justify-content-end' style={{ background: 'rgb(38, 42, 51)', color: "white", padding: "10px", paddingBottom: "2px" }}>
            <div style={{ cursor: "pointer", background: "black", width: "38px", height: "38px", borderRadius: "50%", padding: "8px" }}>
              <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
            </div>
          </div> */}
          <div className='form-group row' style={{ background: 'rgb(38, 42, 51)', color: "white" }}>
            {/* <label className='mx-2'>Week</label>
                <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
                  {count.weekks}
                </select>
                 */}
            {/* <div>
                  <DateTimeRangeContainer
                    smartMode={true}
                    noMobileMode={true}
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    maxDate={maxDate}
                    style={{ display: 'flex' }}
                    applyCallback={applyCallback}
                  >
                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Text"
                      placeholder="Enter text"
                    />
                  </DateTimeRangeContainer>
                </div> */}

            <div style={{"flex-basis":'10%'}}>
              <label style={{ color: "white" }}>Year</label>
              <DatePicker
                selected={startDate}
                className='newFormatting'
                onChange={(date) => {
                  setStartDate(date)
                  yearWiseCaller(date, ttCountVendor)
                }}
                showYearPicker
                placeholderText='Select'
                dateFormat="yyyy"

              />
            </div>
            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Quarter</label>
              <DatePicker
                selected={startDate}
                className='newFormatting'
                onChange={(date) => {
                  setStartDate(date)
                  quaterWiseCaller(date, ttCountVendor)
                }}
                dateFormat="yyyy, QQQ"
                placeholderText='Select'
                showQuarterYearPicker
              />

            </div>

            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Month</label>
              <DatePicker
                selected={startDate}
                className='newFormatting'
                onChange={(date) => {
                  setStartDate(date)
                  monthWiseCaller(date, ttCountVendor)
                }}
                dateFormat="MMM/yyyy"
                placeholderText='Select'
                showMonthYearPicker
              />


            </div>

            <div style={{"flex-basis":'25%'}}>
              <label style={{ color: "white" }}>Week</label>

              <div class="react-datepicker-wrapper">
                <div class="react-datepicker__input-container">

                  <input type='week'
                    placeholder='Select Week'
                    className='newFormatting'
                    style={{ height: "30px" }}
                    onChange={(date) => {
                      weekWiseCaller(date, ttCountVendor)
                    }}
                  />
                </div>
              </div>

            </div>

            <div style={{"width":'35%'}}>
              <label style={{ color: "white" }}>Select Date</label>
              <DatePicker
                selectsRange={true}
                className='newFormatting'
                placeholderText={"Select Date"}
                startDate={rangestartDate}
                endDate={rangeendDate}
                onChange={(update) => {
                  setDateRange(update);
                  rangeWiseCaller(update, ttCountVendor)
                }}
              />

            </div>

          </div>
          <div className="mixed-chart ">
            {/* <Chart
                  options={ttvardata.options}
                  series={ttvardata.series}
                  type="bar"
                  height={300}
                /> */}
            <ReactFusioncharts
              type="mscolumn2d"
              width="100%"
              height="260"
              dataFormat="JSON"
              dataSource={vendorOpenClose}
            />
            <div>
              <table style={{ width: "100%", marginTop: "-2px" }} className="chartTable">
                <thead>
                  <tr>
                    {/* <td style={{ borderWidth: "1px",  borderTopWidth : "3px" , borderStyle: "solid", borderColor: "black", background: "rgb(38, 42, 51)", fontSize: "11px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                    {count?.vendornew?.length ?  count?.vendornew?.map(data => <td style={{  borderWidth: "1px",  borderTopWidth : "3px" , borderStyle: "solid", borderColor: "black", background: "rgb(38, 42, 51)", fontSize: "11px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || ""}</td>) : ["DIY","DIY - TT","Neticom All Projects","Nokia","Nokia - TT"].map(data => <td style={{ border: "1px", borderTop: "2px", fontSize: "11px", background: "rgb(38, 42, 51)", fontSize: "11px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)} */}
                    <td style={{ border: "1px", borderTopWidth : "3px", borderStyle: "solid", borderColor: "black", background: "rgb(38, 42, 51)", fontSize: "11px", color: "white", display: "table-cell" }}>{"Activity"}</td>
                    {count?.vendornew?.length ? count?.vendornew?.map(data => <td style={{ border: "1px", borderTopWidth : "3px",borderStyle: "solid", borderColor: "black", background: "rgb(38, 42, 51)", fontSize: "11px", color: "white", display: "table-cell" }}>{data?data.replace("Tower Team", "TT") : " "}</td>) : ["DIY", "DIY - TT", "Neticom All Projects", "Nokia", "Nokia - TT"].map(data => <td style={{ border: "1px", borderTopWidth : "3px", borderStyle: "solid", borderColor: "black", background: "rgb(38, 42, 51)", fontSize: "11px", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Open</td>
                    {count?.opennew?.length ? count?.opennew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(count?.vendornew?.length || 5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Close</td>
                    {count?.closenew?.length ? count?.closenew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(count?.vendornew?.length || 5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="" style={{ gridColumn: "span 1", gridRow: "span 4", marginTop: "16px" }}>
          <div className="mixed-chart" style={{ background: 'rgb(38, 42, 51)', marginTop: "-1.1rem" }}>
            {/* <div className='d-flex justify-content-end' style={{ background: 'rgb(38, 42, 51)', color: "white", padding: "10px" , paddingBottom : "0px" }}>
              <div style={{ cursor: "pointer", background: "black", width: "38px", height: "38px", borderRadius: "50%", padding: "8px" }}>
                <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
              </div>
            </div> */}
            <div className='form-group row' >
              {/* <label className='mx-2'>Week</label>
            <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
              {count.weekks}
            </select> */}

              {/* <div className='row row-custom'>
                  <div className='col-4 one-line-col1 pd-0'>
                    <DatePicker
                      selectsRange={true}
                      startDate={rangestartDate}
                      endDate={rangeendDate}
                      onChange={(update) => {
                        setDateRange(update);
                        rangeWiseCaller(update, ttAgingVendor)
                      }}
                    />

                  </div>
                  <div className='col-2 one-line-col2 pd-0'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        yearWiseCaller(date, ttAgingVendor)
                      }}
                      showYearPicker
                      dateFormat="yyyy"

                    />
                  </div>
                  <div className='col-3 one-line-col3 pd-0'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        quaterWiseCaller(ttAgingVendor)
                      }}
                      dateFormat="yyyy, QQQ"
                      showQuarterYearPicker
                    />

                  </div>

                  <div className='col-3 one-line-col4 pd-0'>

                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        monthWiseCaller(ttAgingVendor)
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                  <div className='col-2 one-line-col5 pd-0'>
                    <select onChange={(e) => {
                      changeWeekF(e)
                      weekWiseCaller(ttAgingVendor)
                    }}>
                      {count.weekks}
                    </select>

                  </div>

                </div> */}
            </div>
            <ReactFusioncharts
              type="mscolumn2d"
              width="100%"
              height="290"
              dataFormat="JSON"
              dataSource={ageing}
            />
            <div>
              <table style={{ width: "100%" }} className="chartTable">
                <thead>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                    {count?.assignvendornew?.length ? count?.assignvendornew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data?.replace('Tower Team', 'TT')}</td>) : ['MobileComm FOPS', 'Neticom', 'DIY', 'Neticom - TT', 'Nokia', 'MobileComm', 'Nokia TT', 'DIY - TT'].map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}> {" < 48 HRS"}</td>
                    {count?.lessthannew?.length ? count?.lessthannew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(count?.assignvendornew?.length || 8).fill(" ").map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{" > 48 HRS"}</td>
                    {count?.morethanenew?.length ? count?.morethanenew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(count?.assignvendornew?.length || 8).fill(" ").map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        {/* <div className="col-xl-6 col-md-6 col-lg-6 boxcol">
          <ReactFusioncharts
            type="stackedcolumn2d"
            width="100%"
            height="100%"
            dataFormat="JSON"
            dataSource={dataSource}
          />
        </div> */}
      </div>

      {/* fusion chart */}
      
      <div style={{visibility:"hidden"}}>
        {setTimeout(()=>{document.querySelectorAll("[pointer-events='bounding-box']").forEach(data=>data.style.display="none")},2000)}
        </div>
      {/* {setTimeout(()=>{document.querySelectorAll("[pointer-events='bounding-box']").forEach(data=>data.style.display="none")},2000)} */}

    </>
  )
}

export default VendorDashboard