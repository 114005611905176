import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";

import { Row, Col, Card, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import swal from "sweetalert";
import moment from "moment";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  google_map_api,
  center,
  dbdatetodate,
  customDataTableOptionObject,
  getMuiThemeCommon,
  dbdatetomoment,
  getdatedatalistnext,
  more_info,
  rancustomDataTableOptionObject,
  smart_more_info,
} from "../../../common/variables";
import {
  AccountReducerAction,
  addUserAction,
  ExportXLSXDownloadReducerAction,
  FetchSingleRecordAction,
  getFilterDropDown,
  GetTimeLineAction,
  LCLranDashboardReducerAction,
  popUpUpdate,
  ResetLCLranDashboardReducerAction,
} from "../../../store/actions/createTicketManageFields";
import {
  approveOrDisapprove,
  getAllDropDown,
  listSiteId,
  ranDashboard,
  ranSiteStatus,
  singleProjectApi,
  timelineApi,
  reqPost,
  reqPreCheckCancel,
  ranDashboardTimeLine,
  reqPostCheckCancel,
  ranTracker,
  export_ran,
  export_ran_tracker,
  ranfilters,
} from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import TableComponent from "../../../common/TableComponent";
import CommonModal from "../Custom/CommonModal";
import DatePicker from "react-datepicker";
import RanFilterList from "../Custom/Modal Body/RanFilterList";
import FilterData from "../../../icons/Svg/FilterData";

const RanSupportTracker = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [OneTimere, setOneTimere] = useState(false);
  
  const [bulkApproveModalState, setBulkApproveModal] = useState(false);
  const [exportApiData, setexportApiData] = useState("");
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");

  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false);
  const [reqPreModal, setReqPreModal] = useState(false);
  const [ResetFilter, setResetFilter] = useState(false);
  const [siteDetail, setSiteDetail] = useState({});
  const [siteUId, setSiteId] = useState();
  const history = useHistory();
  
  const [fullUrL, setfullUrL] = useState("");
  const [filterBadges,setFilterBadge] =useState()
  const [showDatePicker, setDatePicker] = useState(false);
  const [closer, setCloser] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  let excelData = [];
  console.log(history, "history");

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const [uniqueId, setUniqueId] = useState();

  const dispatch = useDispatch();
  const reqPostCheck = (e) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("the will dele=", willDelete);
        dispatch(AccountReducerAction(reqPost + e));
        dispatch(AccountReducerAction(ranDashboard));
        swal("Post Check Requsted", {
          icon: "success",
        });

        setCloser(true);
      }
    });
  };
  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };


  const ClearFilter = () => {
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();

    setOneTimere(true)

    setexportApiData(ranTracker)
    setResetFilter(true)
    let Filleddata=dispatch(ResetLCLranDashboardReducerAction())
    dispatch(AccountReducerAction(ranTracker+Filleddata,true,true));
  };

  function filterhandler(){
    
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(ranfilters))
    setcommonModalState(true)
    setcommonModalBody(<RanFilterList from={ranTracker} setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData}/>)
    setcommonModalTitle("RAN Filters")
    setcommonModalHeader("RAN Filter")

  }
  const setDateFilter = (e) => {
    e.preventDefault();
    dateRange[0].startDate = formatDate(dateRange[0].startDate);
    dateRange[0].endDate = formatDate(dateRange[0].endDate);
    console.log("the filtered data=", dateRange);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");

    // if (currentUrl.includes("?")) {
    //   dispatch(
    //     AccountReducerAction(
    //       ranDashboard +
    //         "?" +
    //         urlArray.pop() +
    //         "&start=" +
    //         dateRange[0].startDate +
    //         "&end=" +
    //         dateRange[0].endDate
    //     )
    //   );
    // } else {

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }

    if (fenddate < fstartdate) {
      
      swal("Oops", "End Date Should be Greater than Start Date", "info");
      return;


    } else {
      setResetFilter(true)

      setexportApiData(ranDashboard + "?start=" + moment(fstartdate).format("yyyy-MM-DD") + "&end=" + moment(fenddate).format("yyyy-MM-DD"))
    
      let Filleddata=dispatch(LCLranDashboardReducerAction({dateUrl:`start=${moment(fstartdate).format("yyyy-MM-DD")}&end=${moment(fenddate).format("yyyy-MM-DD")}`}))
      console.log(Filleddata,"FilleddataFilleddata")
      dispatch(AccountReducerAction(ranTracker + Filleddata,true));
      // dispatch(
      //   AccountReducerAction(
      //     ranTracker +
      //     "?start=" +
      //     moment(fstartdate).format("yyyy-MM-DD") +
      //     "&end=" +
      //     moment(fenddate).format("yyyy-MM-DD")
      //   )
      // );
    }
    // }
  };
  const ApprovalHandler = (item) => {
    console.log("the item ApprovalHandler is =.", item);
    dispatch(
      addUserAction(approveOrDisapprove + item.ranSupportId, {
        status: "Approve",
      })
    );
  };

  
  const exportAllData = () => {

    
    let Filleddata=dispatch(LCLranDashboardReducerAction())
    dispatch(ExportXLSXDownloadReducerAction(export_ran_tracker,"RANTRACKER_" + moment(new Date()).format("MM-DD-YYYY"),Filleddata))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }
  const cancelPreCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPreCheckCancel + id));
  };

  const cancelPostCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPostCheckCancel + id));
  };

  const startcalltest = (dtSiteId, dtprojectUniqueId, id) => {
    // dispatch(AccountReducerAction(reqPostCheckCancel + id))
    let data = {
      siteUniqueId: dtSiteId,
      ranId: id,
      createFrom: "Ran Support",
      creatorId: id,
      projectUniqueId: dtprojectUniqueId,
      index: 0,
    };
    history.push({
      pathname: "/PAG/E911CallTest/form",
      creator: data,
    });
  };
  const rejectHandler = (item) => {
    dispatch(
      addUserAction(approveOrDisapprove + item.ranSupportId, {
        status: "Not Approve",
      })
    );
  };

  const AddCustomButton = () => (
    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >
      <p onClick={filterhandler} className=" mx-2 " style={{"align-self": "center"}}><FilterData/></p>

      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>


      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",

          margin: "18px 10px auto 10px"
        }}
      >
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          selected={fenddate}
          autoComplete={"off"}
          onChange={(e) => {
            setfenddate(e);
          }}
          minDate={fstartdate}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>

      {userDetails.rolename !== "GC Login"
        // && (
        // <button
        //   className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
        //   data-toggle="modal"
        //   data-target="#exampleModall"
        //   onClick={() => setMapModal(true)}
        // >
        //   Map View
        // </button>
        // )
      }
      
      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>

      {/* <ExportToExcel
        apiData={excelData}
        fileName={"RanDashboard_Tracker" + moment(new Date()).format("MMDDYYYY")}
      /> */}
    </fregment>
  );

  let getMuiTheme = () =>
    createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "line-height": "1 !important",
              padding: "6px 0px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              maxHeight: 45,
              fontSize: 13,
              overflowY: "auto",
              padding: "0px 2px !important",

              margin: "auto",

              // maxHeight: 45,
              //   fontSize: 10,
              //   overflowY: "hidden",
              //   overflowX: "auto",
              //   padding: "3px",
              //   margin: "auto",
              //   textAlign: "center",
              //   width: "max-content",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: '#f00',

        //     },
        //   },
        // },
        MuiTableCell: {
          styleOverrides: {
            head: {
              maxHeight: 20,
              fontSize: 15,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              "& .MuiToolbar-root": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    });

  let columns = [
    
    
    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            // outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            // outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // minWidth:"150px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },

    
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            // outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            // outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    // {
    //   label: "Schedule Date",
    //   name: "scheduleDate",
    //   options: {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    // {
    //   label: "Week #",
    //   name: "scheduleWeek",
    //   options: {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nonowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         textAlign: "center",
    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    // {
    //   label: "Site Id",
    //   name: "siteId",
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nonowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         "min-width": "90px",
    //         "max-width": "120px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    {
      name: "projectCode",
      label: "Project Code",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nonowrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "200px",
            "min-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            textAlign: "center",
            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "projectId",
      label: "Project Id",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nonowrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "200px",
            "min-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "tempi",
      label: "Technology",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            overflow: "hidden",
            background: "#143b64",
            margin: "auto",
            "min-width": "350px",
            "max-width": "400px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "auto",
            minWidth: "200px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "account",
      label: "Account",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nonowrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "100px",
            "max-width": "140px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "40px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",
            textAlign: "center",
            background: "White",
          },
        }),
      },
    },
    {
      name: "requestForm",
      label: "Schedule Source",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            outline:"1.5px solid black",
            padding: "1px 1px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            textAlign: "center",
            overflow: "hidden",
            minWidth: "2px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "ranBucket",
      label: "RAN Bucket",

      // customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "80px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nonowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "crNo",
      label: "Cr Number",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nonowrap",
            alignItem: "center",
            "min-width": "90px",
            "max-width": "120px",


            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "gcType",
      label: "Gc Type",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth: "35px",
          },
        }),
        setCellProps: () => ({
          style: {
            textAlign: "center",
            minWidth: "35px",
          },
        }),
      },
    },
    {
      name: "gcCompany",
      label: "GC Company",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "GCVendor",
      label: "GC Vendor",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "GCName",
      label: "GC Name",
      sortable: true,
      options: {

        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",

            "min-width": "100px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",



            background: "White",
          },
        }),
      },
    },
    {
      name: "GCEmail",
      label: "GC Email",

      // customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            padding: "1px 1px",
            color: "white",
            "min-width": "160px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",



            background: "White",
          },
        }),
      },
    },
    {
      name: "GCContact",
      label: "GC Contact",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    // {
    //   name: "CXVendor",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         minWidth: "100px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "CX Crew Vendor",
    // },
    // {
    //   name: "CXContactNumber",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         overflowX: "hidden",
    //         background: "rgb(20, 59, 100)",
    //         "max-width": "150px",
    //         "min-width": "130px",
    //         color: "white",
    //         padding: "1px 1px",
    //       },
    //     }),
    //   },
    //   label: "CX Crew Lead Contact Number",
    // },
    // {
    //   name: "CXName",
    //   label: "CX Crew Lead Name",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         "max-width": "110px",
    //         "min-width": "100px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    // },
    // {
    //   name: "CXCompany",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         "max-width": "150px",
    //         "min-width": "130px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "CX Crew Lead Company",
    // },
    // {
    //   name: "CXEmail",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nonowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         "min-width": "150px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "CX Crew Lead E-mail ID",
    // },
    // {
    //   name: "IXVendor",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         minWidth: "100px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "IX Crew Vendor",
    // },
    // {
    //   name: "IXContactNumber",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         overflowX: "hidden",
    //         background: "rgb(20, 59, 100)",
    //         "max-width": "150px",
    //         "min-width": "130px",
    //         color: "white",
    //       },
    //     }),
    //   },
    //   label: "IX Crew Lead Contact Number",
    // },
    // {
    //   name: "IXName",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         "max-width": "110px",
    //         "min-width": "100px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "IX Crew Lead Name",
    // },
    // {
    //   name: "IXCompany",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         minWidth: "100px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",
    //         minWidth: "5px",
    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "IX Crew Lead Company",
    // },
    // {
    //   name: "IXEmail",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         minWidth: "130px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",


    //         background: "White",
    //       },
    //     }),
    //   },
    //   label: "IX Crew Lead E-mail ID",
    // },
    {
      name: "serviceAffected",
      label: "Service Affecting",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "90px",
            "min-width": "90px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "dayNightMop",
      label: "Day/Night MOP",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "90px",
            "min-width": "90px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "dayMopReason",
      label: "Day MOP Reason",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nonowrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "130px",
            "min-width": "160px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "crStatus",
      label: "Cr Status",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "TMOOutageApprovedMWTime",
      label: "Outage Approved MW Time",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "150px",
            "min-width": "143px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "tmoDeploymentManager",
      label: "Deployment Manager",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "150px",
            "min-width": "143px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "pSow",
      label: "Planned SOW",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },

    {
      name: "ssowDetailsModal",
      label: "SOW Details",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "250px",
            "min-width": "250px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "sowCommentsModal",
      label: "SOW Comments",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            "min-width": "260px",
            "max-width": "300px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",


            background: "White",
          },
        }),
      },
    },
    {
      name: "nestingTime",
      label: "Nesting Time",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "60px",
            "min-width": "50px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            textAlign: "center",
            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "",
      label: "Pre-Check Nest Info",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "150px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "",
      label: "INTERNAL PAG WO",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "LTPCR",
      label: "Login Time PreChecks Requested(mobile-app)",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "180px",
            "min-width": "180px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    // {
    //   name: "preCheck",
    //   label: "Pre-Checks",
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         background: "rgb(20, 59, 100)",
    // color:"white"

    //       }
    //     })
    //   }
    // },
    {
      name: "preCheckEngineer",
      label: "Precheck Engineer",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "100px",
            "min-width": "90px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "BSCIpContactPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "BSC IP Contact",
    },
    {
      name: "UMTSIpContactPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "UMTS IP Contact",
    },
    {
      name: "LTEIpContactPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "LTE IP Contact",
    },
    {
      name: "fiveGIpContactPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "5G IP Contact",
    },
    {
      name: "GSMAlarmPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "58px",
            "min-width": "40px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "GSM Alarms",
    },
    {
      name: "UMTSAlarmPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "58px",
            "min-width": "40px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "UMTS Alarms",
    },
    {
      name: "LTEAlarmPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "58px",
            "min-width": "40px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "LTE Alarms",
    },
    {
      name: "FiveGAlarmPre",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "58px",
            "min-width": "40px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "5G Alarms",
    },
    {
      name: "precheckAlarmIssueModal",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "200px",
            "max-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Pre check alarms/Issues",
    },
    {
      name: "HO CX Crew Vendor",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO CX Crew Vendor",
    },
    {
      name: "HO CX Crew Lead Contact Number",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO CX Crew Lead Contact Number",
    },
    {
      name: "HO CX Crew Lead Name",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO CX Crew Lead Name",
    },
    {
      name: "HO CX Crew Lead Company",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO CX Crew Lead Company",
    },
    {
      name: "HO CX Crew Lead E-mail ID",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO CX Crew Lead E-mail ID",
    },
    {
      name: "HO IX Crew Vendor",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO IX Crew Vendor",
    },
    {
      name: "HO IX Crew Lead Contact Number",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO IX Crew Lead Contact Number",
    },
    {
      name: "HO IX Crew Lead Name",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO IX Crew Lead Name",
    },
    {
      name: "HO IX Crew Lead Company",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "100px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO IX Crew Lead Company",
    },
    {
      name: "HO IX Crew Lead E-mail ID",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "130px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "HO IX Crew Lead E-mail ID",
    },
    {
      name: "LTPCD",
      label: "Login Time Precheck Delivered",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "183px",
            "min-width": "150px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "postCheckEngineer",
      label: "Postcheck Engineer",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "100px",
            "min-width": "90px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "LTPOR",
      label: "Logout Time Postcheck Request",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "170px",
            "min-width": "160px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "postGsmDownTime",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "80px",
            "min-width": "76px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "GSM Downtime",
    },
    {
      name: "postBSCIpContact",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "60px",
            "max-width": "70px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "BSC IP Contact",
    },
    {
      name: "postUmtsDownTime",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "80px",
            "min-width": "76px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "UMTS Downtime",
    },
    {
      name: "postUmtsIpContact",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nonowrap",

            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "UMTS IP Contact",
    },
    {
      name: "postLteDownTime",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "80px",
            "min-width": "76px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "LTE Downtime",
    },
    {
      name: "postLTEIPContact",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "120px",
            "min-width": "110px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "LTE IP Contact",
    },
    {
      name: "post5GDownTime",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "80px",
            "min-width": "76px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "5G Downtime",
    },
    {
      name: "post5GIPContact",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "120px",
            "min-width": "110px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "5G IP Contact",
    },
    {
      name: "postGSMAlarm",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "60px",
            "min-width": "50px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "GSM Alarms",
    },
    {
      name: "postUMTSlAlarm",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "60px",
            "min-width": "50px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "UMTS Alarms",
    },
    {
      name: "postLteAlarm",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "60px",
            "min-width": "50px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "LTE Alarms",
    },
    {
      name: "post5GAlarm",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "60px",
            "min-width": "50px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "5G Alarms",
    },
    {
      name: "postCheckAlarmIssueModal",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "200px",
            "min-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Post check alarms /Issues",
    },
    {
      name: "postActivityStatusTechnologyLevel",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Activity Status(POR Level)",
    },
    {
      name: "postActivityStatusSiteLevel",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Activity Status (Site Level)",
    },
    {
      name: "Not Completed/Cancelled/Rollback Reason",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Not Completed/Cancelled/Rollback Reason",
    },
    {
      name: "wasTSOrScriptingWasRequired",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Was Scripting or TS required?",
    },
    {
      name: "tsscripting",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Engineer responsible for Scripting or TS",
    },
    {
      name: "ttOpen",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "TT (s) Opened?",
    },
    {
      name: "ttIds",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            padding: "1px 1px",
            textAlign: "center",
            background: "#143b64",
            minWidth: "250px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nonowrap",

            overflowY: "hidden",
            minWidth: "250px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "TT ID (s)",
    },
    {
      name: "postSiteUpTime",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Site-Up Time",
    },
    {
      name: "activityWentOutOfApprovedWindow",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "170px",
            "min-width": "165px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Activity went out of approved window?",
    },
    {
      name: "timeAfterApprovedWindow",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Time after approved window",
    },
    {
      name: "postActivityNotescstm",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            padding: "1px 1px",

            "max-width": "200px",
            "min-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",
            minWidth: "5px",
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
      label: "Activity Notes",
    },
    {
      name: "LTPOD",
      label: "Logout Time Postcheck Delivered",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "max-width": "190px",
            "min-width": "180px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
  ];

  <th scope="col">CX Crew Vendor</th>;
  let cxTableHeader = [
    "CX Crew Vendor",
    "CX Crew Lead Contact Number",
    "CX Crew Lead Name",
    "CX Crew Lead Company",
    "CX Crew Lead E-mail ID",
  ];
  let ixTableHeader = [
    "IX Crew Vendor",
    "IX Crew Lead Contact Number",
    "IX Crew Lead Name",
    "IX Crew Lead Company",
    "IX Crew Lead E-mail ID",
  ];
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {
    if (data == "reqPreCheck") {
      setReqPreModal(true);
      console.log("the req premodal =", data);
      setUniqueId(id);
      setCloser(true);
    } else if (id == null) {
      setCrewModal(true);
      setSiteDetail(data);
      console.log("th dta is  =", reqPreModal);
      console.log("the main data is =", siteDetail);
    }
  }
  function showDetails(id) {
    setXlShow(true);
    setSiteId(id.ranSupportId);
    console.log("dfghedrhetsetghrrtd", id);
    dispatch(GetTimeLineAction(ranDashboardTimeLine, id.ranSupportId));
    dispatch(FetchSingleRecordAction(ranDashboard, id.ranSupportId));
  }
  const count = useSelector((state) => {
    console.log("the state is=", state);
    let sitesdata = state.CommonResponse.ranDashboard;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }

    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if (state.popUpState.popstatus.status == 201 && closer) {
        setCloser(false);
        setReqPreModal(false);
        dispatch(AccountReducerAction(ranDashboard));
        popUpUpdate({});
      }
    }
    let ranSiteStatus = state.CommonResponse.ranSiteStatus;
    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus);
      siteStatus = ranSiteStatus;
    }
    console.log(mark, "mark");

    
    let totalCount=state.countState.ranTrackerCount

    

    if (Object.keys(state.CommonResponse.ranTracker).length > 0) {
      if (Object.keys(state.CommonResponse.ranTracker).length > 0) {
        if (state.CommonResponse.ranTracker.length > 0) {
          rowData = state.CommonResponse.ranTracker.map(
            (item, index) => {
              console.log("i am caling", state);
              item["action"] = (
                <>
                  <button className="btn btn-primary shadow btn-xs sharp mr-1">
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button className="btn btn-danger shadow btn-xs sharp">
                    <i className="fa fa-trash"></i>
                  </button>
                </>
              );

              // item["gcType"] = item["cxixuserIdresult"]["crewType"];
              // item["GCContact"] = item["cxixuserIdresult"]["mobilenumber"];
              // item["GCName"] = item["cxixuserIdresult"]["name"];
              // item["GCVendor"] = item["cxixuserIdresult"]["vendor"];
              // item["gcCompany"] = item["cxixuserIdresult"]["crewLeadCompanyId"];
              // item["GCEmail"] = item["cxixuserIdresult"]["email"];

              if (item.cxCrewId.name) {
                item["gcType"] = item["cxCrewId"]["crewType"];
                item["GCContact"] = item["cxCrewId"]["mobilenumber"];
                item["GCName"] = item["cxCrewId"]["name"];
                item["GCVendor"] = item["cxCrewId"]["vendor"];
                item["gcCompany"] = item["cxCrewId"]["crewLeadCompanyId"];
                item["GCEmail"] = item["cxCrewId"]["email"];
              } else if (item.ixCrewId.name) {
                item["gcType"] = item["ixCrewId"]["crewType"];
                item["GCContact"] = item["ixCrewId"]["mobilenumber"];
                item["GCName"] = item["ixCrewId"]["name"];
                item["GCVendor"] = item["ixCrewId"]["vendor"];
                item["gcCompany"] = item["ixCrewId"]["crewLeadCompanyId"];
                item["GCEmail"] = item["ixCrewId"]["email"];
              }

              item["5GId"] = "";
              item["LTEId"] = "";
              item["UMTSId"] = "";
              item["mixedModeId"] = "";
              item["TMOOutageApprovedMWTime"] = item["RF_Approved_MW_Time"]&&item["RF_Approved_EndTime_Time"]?getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1]:""
          
              // item["TMOOutageApprovedMWTime"] = getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1]
              item["siteId"] = item.ndResult.siteId;
              let pc=[]
              item.projectaUniqueId.map((item) => {
                if(pc.indexOf(item.projectCode)==-1){
                  pc.push(item.projectCode)
                }
              });
              item["projectCode"] = pc.join(", ")
              // item["projectCode"] = item.projectaUniqueId ? item.projectaUniqueId.map(function (el) { return el.projectCode; }).join(", ") : "";
              let prjID = item.projectbUniqueId ? item.projectbUniqueId.map(function (el) { return el.projectId; }).join(", ") : "";
              
              item["projectId"] = smart_more_info(prjID,"","Project Id",CommonModalFun)
              item["crNo"] = item.crNumber;
              item["siteType"] = item.ndResult.siteType;
              if (item["mopDownTime"]) {
                let cTime = new Date();
                let sdate = moment(new Date(item["mop9StartTime"])).format(
                  "MM/DD/YYYY"
                );
                console.log("the date is =", sdate);
                console.log(
                  "the date is =",
                  moment(item["mopStartTime"]).format("MM/DD/YYYY")
                );
                // console.log("the date is =",item["mopDownTime"])
                // console.log("the real data=",cTime.getHours())
                // console.log("the real datasdasa=",cTime.getDate())
                // console.log("the real datdadasdasdasdaada=", sdate.getDate())

                // console.log("the date is =",item["mopDownTime"].getDate())
                // if(sdate.getMonth()==cTime.getMonth() && sdate.getDate()==cTime.getDate() && cTime.getHours()>=sdate.getHours() ){
                //   let remainingTime =sdate.getHours()>=cTime.getHours()
                //   console.log("the difference is =",remainingTime)
                //   item['progress']=<div style={{ width: 40, height: 40 }}><CircularProgressbar value={remainingTime/100} maxValue={1} text={`${remainingTime}%`} /></div>
                // }
                console.log(
                  "the real time=",
                  new Date(item["mopDownTime"]).getTime()
                );
                // item["progress"] = (
                //   <div style={{ width: 40, height: 40 }}>
                //     <CircularProgressbar
                //       value={66 / 100}
                //       maxValue={1}
                //       text={`${66}%`}
                //     />
                //   </div>
                // );
              }

              // item['siteId'] = <a href={'/PAG/ran/support/dashboard/'+item.newuniqueId} target="_blank">{item.siteId}</a>
              // console.log("item is =", item["technology"]);
              // item['technology']=''
              item["account"] = item.ndResult.account;
              // item["crew"] = (
              //   <button
              //     className="btn btn-sm btn-primary"
              //     onClick={() => openModal(item)}
              //   >
              //     Crew
              //   </button>
              // );

              if (item["mopStartTime"] && item["mopEndTime"]) {
                let mopSTime = item["mopStartTime"].split(" ").pop();
                let mopETime = item["mopEndTime"].split(" ").pop();
                item["rfMWTime"] = mopSTime + " to " + mopETime;
              }

              // if (item["ranBucket"] == "In Progress") {
              //   item["status"] = (
              //     <p
              //       className=" text-white py-2 rounded"
              //       style={{
              //         fontSize: "10px",
              //         lineHeight: "13px",
              //         backgroundColor: "#935ab7",
              //       }}
              //     >
              //       <Link
              //         className="text-white"
              //         to={"/PAG/ran/OntheFlyForm/" + item.ranSupportId}
              //       >
              //         {item.ranStatus}
              //       </Link>
              //     </p>
              //   );
              // } else if (item["ranBucket"] == "Scheduled") {
              //   item["status"] = (
              //     <p
              //       className=" text-white py-2 rounded"
              //       style={{
              //         fontSize: "10px",
              //         lineHeight: "13px",
              //         backgroundColor: "orange",
              //         marginBottom: "0px",
              //       }}
              //     >
              //       <Link
              //         className="text-white"
              //         to={"/PAG/ran/OntheFlyForm/" + item.ranSupportId}
              //       >
              //         {item.ranStatus}
              //       </Link>
              //     </p>
              //   );
              // } else if (item["ranBucket"] == "In Progress - Hold") {
              //   item["status"] = (
              //     <p
              //       className="bg-primary text-white py-2 rounded"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       <Link
              //         className="text-white"
              //         to={"/PAG/ran/OntheFlyForm/" + item.ranSupportId}
              //       >
              //         {item.ranStatus}
              //       </Link>
              //     </p>
              //   );
              // } else {
              //   item["status"] = (
              //     <p
              //       className="bg-success text-white py-2 rounded"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       <Link
              //         className="text-white"
              //         to={"/PAG/ran/OntheFlyForm/" + item.ranSupportId}
              //       >
              //         {item.ranStatus}
              //       </Link>
              //     </p>
              //   );
              // }

              //Planned sow
              // if (item.ranStatus == "Login Request - CR Approval Requested") {
              //   item["crStatus"] = (
              //     <>
              //       <button
              //         className="btn btn-success shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center"
              //         onClick={() => ApprovalHandler(item)}
              //       >
              //         <i className="fa fa-check"></i>
              //       </button>
              //       <button
              //         className="btn btn-danger shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center "
              //         onClick={() => rejectHandler(item)}
              //       >
              //         <i className="fa fa-times"></i>
              //       </button>
              //     </>
              //   );
              // } else if (item.ranStatus == "Login Awaited - CR Approved") {
              //   item["mPrecheckReq"] = (
              //     <button
              //       className="badge badge-warning"
              //       onClick={() => openModal("reqPreCheck", item.ranSupportId)}
              //     >
              //       Req Precheck
              //     </button>
              //   );
              // } else if (
              //   item.ranStatus == "Login Awaited - CR Approved" ||
              //   item.ranStatus == "Login Request - CR Approval Requested"
              // ) {
              //   item["crStatus"] = (
              //     <p className="badge badge-success">Approved</p>
              //   );
              // } else if (item.ranStatus == "Pre-check Ongoing") {
              //   item["preCheck"] = (
              //     <button className="badge badge-warning">
              //       <Link
              //         className="text-black"
              //         to={"/PAG/ran/OntheFlyForm/preCheck/" + item.ranSupportId}
              //       >
              //         Run-PreCheck
              //       </Link>
              //     </button>
              //   );
              //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
              //   item["mPrecheckReq"] = (
              //     <button
              //       className="badge badge-danger"
              //       onClick={() => cancelPreCheckReq(item.ranSupportId)}
              //     >
              //       Cancel
              //     </button>
              //   );
              // } else if (item.ranStatus == "Nest Active") {
              //   item["preCheck"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   item["mPrecheckReq"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   item["postCh"] = (
              //     <p
              //       className="badge badge-secondary"
              //       onClick={() => reqPostCheck(item.ranSupportId)}
              //     >
              //       Req Postcheck
              //     </p>
              //   );
              // } else if (item.ranStatus == "Post-check Ongoing") {
              //   item["preCheck"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
              //   item["mPrecheckReq"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   item["postCh"] = (
              //     <p
              //       className="badge badge-danger"
              //       onClick={() => cancelPostCheckReq(item.ranSupportId)}
              //     >
              //       Cancel
              //     </p>
              //   );
              //   item["postChecks"] = (
              //     <p className="badge badge-success">
              //       <Link
              //         className="text-white"
              //         to={
              //           "/PAG/ran/OntheFlyForm/postCheck/" + item.ranSupportId
              //         }
              //       >
              //         Run PostCheck
              //       </Link>
              //     </p>
              //   );
              // } else if (
              //   item.ranStatus == "Post-Check Completed - Validation ongoing"
              // ) {
              //   item["preCheck"] = (
              //     <p
              //       className="badge badge-success "
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   // item['preCheck'] =<button className="btn btn-sm  btn-success" >Run-PreCheck</button>
              //   item["mPrecheckReq"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Pre Check Completed
              //     </p>
              //   );
              //   item["postCh"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Post-Check Completed - Validation ongoing
              //     </p>
              //   );
              //   item["postChecks"] = (
              //     <p
              //       className="badge badge-success"
              //       style={{ fontSize: "10px", lineHeight: "13px" }}
              //     >
              //       Post-Check Completed - Validation ongoing
              //     </p>
              //   );
              // }

              // if (item.ctaenable == 1) {
              //   if (item.ennoSubmit) {
              //     item["CTA"] = (
              //       <button className="badge badge-success">Completed</button>
              //     );
              //   } else {
              //     item["CTA"] = (
              //       <button
              //         className="badge badge-warning"
              //         onClick={() =>
              //           startcalltest(
              //             item.dtSiteId,
              //             item.dtprojectUniqueId,
              //             item.ranSupportId
              //           )
              //         }
              //       >
              //         Start Call Test
              //       </button>
              //     );
              //   }
              // } else {
              //   item["CTA"] = "NA";
              // }
              // // dasdas

              item["LTPCR"] = item.preCheckRequest ? getdatedatalistnext(item.preCheckRequest) : ""
              item["LTPCD"] = item.preCheckDelivered ? getdatedatalistnext(item.preCheckDelivered) : ""
              item["LTPOR"] = item.postCheckRequest ? getdatedatalistnext(item.postCheckRequest) : ""
              item["LTPOD"] = item.postCheckDelivered ? getdatedatalistnext(item.postCheckDelivered) : ""


              item["account"] = item.ndResult ? item.ndResult.account : ""

              item["market"] = item.ndResult ? item.ndResult.market : ""

              item["ttOpen"] = item.ttCount > 0 ? "Yes" : "No";
              if (item.cxCrewId.email != undefined) {
                item["CXContactNumber"] = <p>{item.cxCrewId.mobilenumber}</p>;
                item["CXVendor"] = <p>{item.cxCrewId.vendor}</p>;
                item["CXName"] = <p>{item.cxCrewId.name}</p>;
                item["CXCompany"] = <p>{item.cxCrewId.crewLeadCompanyId}</p>;
                item["CXEmail"] = <p>{item.cxCrewId.email}</p>;
              }

              if (item.ixCrewId.email != undefined) {
                item["IXContactNumber"] = <p>{item.ixCrewId.mobilenumber}</p>;
                item["IXVendor"] = <p>{item.ixCrewId.vendor}</p>;
                item["IXName"] = <p>{item.ixCrewId.name}</p>;
                item["IXCompany"] = <p>{item.ixCrewId.crewLeadCompanyId}</p>;
                item["IXEmail"] = <p>{item.ixCrewId.email}</p>;
              }

              if (item.postSiteUpTimeNA) {
                item["postSiteUpTime"] = "NA";
              }



              // if(item.ranStatus !="Login Awaited - CR Approved" && item.ranStatus !="Login Awaited - CR Approved")
              // else if(item.)
              let pSow = "";
              let pSowArray = item["sowList"]?.values || [];
              if (pSowArray && pSowArray.length > 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })
                console.log("sgrthdhr", pSowArray.length);
                for (let i = 0; i < pSowArray.length; i++) {
                  console.log("sgrthdhr", pSowArray[i]);
                  pSow += pSowArray[i] + ", ";
                }
                //  item['technology']=tec
              }
              // console.log("item is tec =", tec);

              //  console.log("item is =",item['technology'])
              // item['technology']=tec
              console.log("the psow is =", pSow);
              item["pSow"] = pSow;

              let ssowDetails = "";
              let sowDetailsArr = item["sowDetails"]?.values || []
              if (sowDetailsArr && sowDetailsArr.length > 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })
                for (let i = 0; i < sowDetailsArr.length; i++) {
                  console.log("sgrthdhr", sowDetailsArr[i]);
                  ssowDetails += sowDetailsArr[i] + ", ";
                }
                //  item['technology']=tec
              }
              // console.log("item is tec =", tec);

              //  console.log("item is =",item['technology'])
              // item['technology']=tec
              console.log("the psow is =", pSow);
              item["ssowDetailsModal"] = (
                <p
                  className={"text-black my-0 modalHoverClass"}
                  onClick={() =>
                    CommonModalFun(
                      <p
                        style={{
                          border: "2px solid black",
                          color: "black",
                          padding: 2,
                        }}
                        className="CommonModal"
                      >
                        {ssowDetails}
                      </p>,
                      "Comment",
                      "SOW Details"
                    )
                  }
                >
                  {ssowDetails}
                </p>
              );
              //Technology
              let tec = "";
              // let techArray = item["technology"]?.values || []
              // if (techArray && techArray.length > 0) {
              //   //  item['technology'].forEach((item)=>{
              //   //   tec +=item.label +','
              //   //  })
              //   console.log("sgrthdhr", techArray.length);
              //   for (let i = 0; i < techArray.length; i++) {
              //     console.log("sgrthdhr", techArray[i]);
              //     tec += techArray[i] + ", ";
              //   }
              //   //  item['technology']=tec
              // }
              console.log("item is tec =", tec);

              //  console.log("item is =",item['technology'])
              // item['technology']=tec
              // item["temp"] = tec;
              let techArray = item["techtake"] ? item["techtake"] : [];
              item["tempi"] = smart_more_info(techArray.join(", "),"","Technology",CommonModalFun)
              item["postActivityNotescstm"] = smart_more_info(item["postActivityNotes"],"","Activity Notr",CommonModalFun)
              
              
              console.log("item is tec =", techArray);
              // let excelObject={}
              // for(let i=0;i<columns.length;i++){

              //   excelObject[columns[i].label]='item['+columns[i].name + ']'

              // }
              // excelData.push(excelObject)
              // console.log("the excel data is =",excelData)
              return item;
            }
          );
          excelData = state.CommonResponse.ranTracker.map((item) => {
            let tec = "";

            //  console.log("item is =",item['technology'])
            // item['technology']=tec
            item["temp"] = item["techtake"]?item["techtake"].join(", "):"";
            let pSow = "";
            let pSowArray = item["sowList"]?.values || []
            if (pSowArray && pSowArray.length > 0) {
              //  item['technology'].forEach((item)=>{
              //   tec +=item.label +','
              //  })
              console.log("sgrthdhr", pSowArray.length);
              for (let i = 0; i < pSowArray.length; i++) {
                console.log("sgrthdhr", pSowArray[i]);
                pSow += pSowArray[i] + ", ";
              }
              //  item['technology']=tec
            }
            // item["sowCommentsModal"] = (
            //   <p
            //     className={"text-black my-0 modalHoverClass"}
            //     onClick={() =>
            //       CommonModalFun(
            //         <p
            //           style={{
            //             border: "2px solid black",
            //             color: "black",
            //             padding: 2,
            //           }}
            //           className="CommonModal"
            //         >
            //           {item["sowComments"]}
            //         </p>,
            //         "Comment",
            //         "SOW Comment"
            //       )
            //     }
            //   >
            //     {item["sowComments"]}
            //   </p>
            // );


            item["sowCommentsModal"] = smart_more_info(item["sowComments"],"","SOW Comment",CommonModalFun)

            let sowDetailsArr = item["sowDetails"]?.values || []
              
            item["ssowDetailsModal"] = smart_more_info(sowDetailsArr.join(","),"","SOW Details",CommonModalFun)

            {}

            // console.log("item is tec =", tec);

            //  console.log("item is =",item['technology'])
            // item['technology']=tec
            console.log("the psow is =", pSow);
            item["pSow"] = pSow;
            if (item["mopStartTime"] && item["mopEndTime"]) {
              let mopSTime = item["mopStartTime"].split(" ").pop();
              let mopETime = item["mopEndTime"].split(" ").pop();
              item["rfMWTime"] = mopSTime + " to " + mopETime;
            }

            const myMomentObject = dbdatetomoment(item["scheduleDate"])

            item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()

            let ttidsData = [];
            
            ttidsData = item.troubleTickets.map((itemInner) => {
              return itemInner.ttid;
            });

            
            item["ttIdsNew"] = ttidsData.join(", ");
            item["ttOpen"] = ttidsData.length>0?"Yes":"No";

            

            item["ttIds"] = (
              <p
                className={"text-black my-0 modalHoverClass"}
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {ttidsData.join(", ")}
                    </p>,
                    "Comment",
                    "TT Id's"
                  )
                }
              >
                {ttidsData.join(", ")}
              </p>
            );
            item["precheckAlarmIssueModal"] = (
              <p
                data-toggle="tooltip" 
                data-placement="top"
                title="Click Here For More Details"
                className={"text-black my-0 modalHoverClass"}
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                    {item["precheckAlarmIssue"]}
                    </p>,
                    "Comment",
                    "Precheck alarm issue"
                  )
                }
              >
                {more_info(item["precheckAlarmIssue"])}
              </p>
            );
            item["postCheckAlarmIssueModal"] = (
              <p
                className={"text-black my-0 modalHoverClass"}
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["postCheckAlarmIssue"]}
                    </p>,
                    "Comment",
                    "Postcheck alarm issue"
                  )
                }
              >
                {more_info(item["postCheckAlarmIssue"])}
                {/* {item["postCheckAlarmIssue"]} */}
              </p>
            );
            let excelObject = {
              "Schedule Date": item["scheduleDate"],
              "Week #": item["scheduleWeek"],
              "Site Id": item.ndResult.siteId,
              "Project Code": item["projectCode"],
              "Project Id": item["projectId"],
              Technology: item["temp"],
              Account: item.ndResult ? item.ndResult.account : "",
              Market: item.ndResult ? item.ndResult.market : "",
              "RAN Bucket": item["ranBucket"],
              "Cr Number": item["crNo"],
              "GC Type":item["gcType"],
              "GC Company" : item["gcCompany"],
              "GC Vendor" : item["GCVendor"],
              "GC Name" : item["GCName"],
              "GC Email" : item["GCEmail"],
              "GC Contact" : item["GCContact"],
              // "CX Crew Vendor": item["CX Crew Vendor"],
              // "CX Crew Lead Contact Number":
              //   item["CX Crew Lead Contact Number"],
              // "CX Crew Lead Name": item["CX Crew Lead Name"],
              // "CX Crew Lead Company": item["CX Crew Lead Company"],
              // "CX Crew Lead E-mail ID": item["CX Crew Lead E-mail ID"],
              // "IX Crew Vendor": item["IX Crew Vendor"],
              // "IX Crew Lead Contact Number":
              //   item["IX Crew Lead Contact Number"],
              // "IX Crew Lead Name": item["IX Crew Lead Name"],
              // "IX Crew Lead Company": item["IX Crew Lead Company"],
              // "IX Crew Lead E-mail ID": item["IX Crew Lead E-mail ID"],
              "Service Affecting": item["serviceAffected"],
              "Day or Night MOP": item["dayNightMop"],
              "Day MOP Reason": item["dayMopReason"],
              "Cr Status": item["crStatus"],
              "Outage Approved MW Time": item["TMOOutageApprovedMWTime"],
              "Deployment Manager": item["tmoDeploymentManager"],
              "Planned SOW": item["pSow"],
              "SOW Details": item["ssowDetails"],
              "SOW Comments": item["sowComments"],
              "Nesting Time": item["mopDownTime"],
              "Pre-Check Nest Info": item[""],
              "INTERNAL PAG WO": item[""],
              "Login Time PreChecks Requested(mobile-app)": item.LTPCR,
              "Precheck Engineer": item["preCheckEngineer"],
              "BSC IP Contact": item["postBSCIpContact"],
              "UMTS IP Contact": item["postUmtsIpContact"],
              "LTE IP Contact": item["postLTEIPContact"],
              "5G IP Contact": item["post5GIPContact"],
              "GSM Alarms": item["postGSMAlarm"],
              "UMTS Alarms": item["postUMTSlAlarm"],
              "LTE Alarms": item["postLteAlarm"],
              "5G Alarms": item["post5GAlarm"],
              "Pre check alarms/Issues": item["precheckAlarmIssue"],
              "HO CX Crew Vendor": item["HO CX Crew Vendor"],
              "HO CX Crew Lead Contact Number":
                item["HO CX Crew Lead Contact Number"],
              "HO CX Crew Lead Name": item["HO CX Crew Lead Name"],
              "HO CX Crew Lead Company": item["HO CX Crew Lead Company"],
              "HO CX Crew Lead E-mail ID": item["HO CX Crew Lead E-mail ID"],
              "HO IX Crew Vendor": item["HO IX Crew Vendor"],
              "HO IX Crew Lead Contact Number":
                item["HO IX Crew Lead Contact Number"],
              "HO IX Crew Lead Name": item["HO IX Crew Lead Name"],
              "HO IX Crew Lead Company": item["HO IX Crew Lead Company"],
              "HO IX Crew Lead E-mail ID": item["HO IX Crew Lead E-mail ID"],
              "Login Time Precheck Delivered": item.LTPCD,
              "Postcheck Engineer": item["postCheckEngineer"],
              "Logout Time Postcheck Request": item.LTPOR,
              "GSM Downtime": item["postGsmDownTime"],
              "UMTS Downtime": item["postUmtsDownTime"],
              "LTE Downtime": item["postLteDownTime"],
              "5G Downtime": item["post5GDownTime"],
              "Post check alarms /Issues": item["postCheckAlarmIssue"],
              "Activity Status(POR Level)": item[""],
              "Activity Status(Site Level)":
                item["postActivityStatusSiteLevel"],
              "Not Completed/Cancelled/Rollback Reason":
                item["Not Completed/Cancelled/Rollback Reason"],
              "Was Scripting or TS required?": item["wasTSOrScriptingWasRequired"],
              "Engineer responsible for Scripting or TS": item["tsscripting"],
              "TT (s) Opened?": item.ttCount > 0 ? "Yes" : "No",
              "TT ID (s)": item["ttIdsNew"],
              "Site-Up Time": item["postSiteUpTime"],
              "Activity went out of approved window?":item["activityWentOutOfApprovedWindow"],
              "Time after approved window": item["timeAfterApprovedWindow"],
              "Activity Notes": item["postActivityNotes"],
              "Logout Time Postcheck Delivered": item.LTPOD
            }
            //   "Schedule Date": item["scheduleDate"],
            //   "Week No": item["scheduleWeek"],
            //   "Main Id": item.ndResult.siteId,
            //   "Project Code": item["projectCode"],
            //   "Project Id": item["projectId"],
            //   Technology: item["temp"],
            //   "Cr Number": item["crNo"],
            //   Account: item.ndResult ? item.ndResult.account : "",
            //   Market: item.ndResult ? item.ndResult.market : "",
            //   "RAN Bucket": item["ranBucket"],
            //   "CX Crew Vendor": item["CX Crew Vendor"],
            //   "CX Crew Lead Contact Number":
            //     item["CX Crew Lead Contact Number"],
            //   "CX Crew Lead Name": item["CX Crew Lead Name"],
            //   "CX Crew Lead Company": item["CX Crew Lead Company"],
            //   "CX Crew Lead E-mail ID": item["CX Crew Lead E-mail ID"],
            //   "IX Crew Vendor": item["IX Crew Vendor"],
            //   "IX Crew Lead Contact Number":
            //     item["IX Crew Lead Contact Number"],
            //   "IX Crew Lead Name": item["IX Crew Lead Name"],
            //   "IX Crew Lead Company": item["IX Crew Lead Company"],
            //   "IX Crew Lead E-mail ID": item["IX Crew Lead E-mail ID"],
            //   "Service Affecting": item["serviceAffected"],
            //   "Day or Night MOP": item["dayNightMop"],
            //   "Day MOP Reason": item["dayMopReason"],
            //   "Cr Status": item["crStatus"],
            //   "Outage Approved MW Time": item["rfMWTime"],
            //   "Deployment Manager": item["rfMWTime"],
            //   "Planned SOW": item["pSow"],
            //   "SOW Details": item["ssowDetails"],
            //   "SOW Comments": item["sowComments"],
            //   "Nesting Time": item["nestingTime"],
            //   "Pre-Check Nest Info": item[""],
            //   "INTERNAL PAG WO": item[""],
            //   "Login Time PreChecks Requested(mobile-app)": item.preCheckRequest
            //     ? dbdatetodate(item.preCheckRequest)
            //     : "",
            //   "Precheck Engineer": item["preCheckEngineer"],
            //   "BSC IP Contact": item["postBSCIpContact"],
            //   "UMTS IP Contact": item["postUmtsIpContact"],
            //   "LTE IP Contact": item["postLTEIPContact"],
            //   "5G IP Contact": item["post5GIPContact"],
            //   "GSM Alarms": item["postGSMAlarm"],
            //   "UMTS Alarms": item["postUMTSlAlarm"],
            //   "LTE Alarms": item["postLteAlarm"],
            //   "5G Alarms": item["post5GAlarm"],
            //   "Pre check alarms/Issues": item["precheckAlarmIssue"],
            //   "HO CX Crew Vendor": item["HO CX Crew Vendor"],
            //   "HO CX Crew Lead Contact Number":
            //     item["HO CX Crew Lead Contact Number"],
            //   "HO CX Crew Lead Name": item["HO CX Crew Lead Name"],
            //   "HO CX Crew Lead Company": item["HO CX Crew Lead Company"],
            //   "HO CX Crew Lead E-mail ID": item["HO CX Crew Lead E-mail ID"],
            //   "HO IX Crew Vendor": item["HO IX Crew Vendor"],
            //   "HO IX Crew Lead Contact Number":
            //     item["HO IX Crew Lead Contact Number"],
            //   "HO IX Crew Lead Name": item["HO IX Crew Lead Name"],
            //   "HO IX Crew Lead Company": item["HO IX Crew Lead Company"],
            //   "HO IX Crew Lead E-mail ID": item["HO IX Crew Lead E-mail ID"],
            //   "Login Time Precheck Delivered": item.preCheckDelivered
            //     ? dbdatetodate(item.preCheckDelivered)
            //     : "",
            //   "Postcheck Engineer": item["postCheckEngineer"],
            //   "Logout Time Postcheck Request": item.postCheckRequest
            //     ? dbdatetodate(item.postCheckRequest)
            //     : "",
            //   "GSM Downtime": item["postGsmDownTime"],
            //   "UMTS Downtime": item["postUmtsDownTime"],
            //   "LTE Downtime": item["postLteDownTime"],
            //   "5G Downtime": item["post5GDownTime"],
            //   "Post check alarms /Issues": item["postCheckAlarmIssue"],
            //   "Activity Status(POR Level)": item[""],
            //   "Activity Status(Site Level)":
            //     item["postActivityStatusSiteLevel"],
            //   "Not Completed/Cancelled/Rollback Reason":
            //     item["Not Completed/Cancelled/Rollback Reason"],
            //   "Was Scripting or TS required?":
            //     item["Was Scripting or TS required?"],
            //   "Engineer responsible for Scripting or TS":
            //     item["Engineer responsible for Scripting or TS"],
            //   "TT (s) Opened?": item.ttCount > 0 ? "Yes" : "No",
            //   "TT ID (s)": item["ttIdsNew"],
            //   "Site-Up Time": item["postSiteUpTime"],
            //   "Activity went out of approved window?":
            //     item["activityWentOutOfApprovedWindow"],
            //   "Time after approved window": item["Time after approved window"],
            //   "Activity Notes": item["Activity Notes"],
            //   "Logout Time Postcheck Delivered": item.postCheckDelivered
            //     ? dbdatetodate(item.postCheckDelivered)
            //     : "",
            // };
            return excelObject;
          });
          console.log("jdbcjhbsdaikc", state);
          const rowDataOne = state.CommonResponse.ranDashboard;
          if (rowDataOne > 0) {
            Object.keys(rowDataOne).map((key, index) => {
              console.log(key, "key");
              columns.push({
                name: key,
                selector: key,
                sortable: true,
              });
            });
          }
        }
      }
    }

    // console.log("columns", state.sites.sites.TotalTicket);

    return { rowData, columns, mark, siteStatus,totalCount };
  });
  console.log("the tracker rows =", excelData);
  const addCustomToolbar = () => (
    <ExportToExcel
      apiData={excelData}
      fileName={"RanTracker_" + moment(new Date()).format("MM/DD/YYYY")}
    />
  );
  const options = {
    download: true,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,

    rowsPerPage: 25,
    count: 100,
    rowsPerPageOptions: [25, 50, 75],
    fixedHeader: true,
    fixedFooter: true,
    tableBodyHeight: "100%",
    responsive: "scroll",
    overflowY: "scroll",
  };

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  console.log("yhe row data isn=", rowData);
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  const sortedOrder={
    // name: 'scheduleDate',
    // direction: 'desc'
  }
  useEffect(() => {
    dispatch(AccountReducerAction(ranTracker,true,true));
    dispatch(AccountReducerAction(ranSiteStatus));
    dispatch(AccountReducerAction(getAllDropDown));
    dispatch(AccountReducerAction(listSiteId));

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);


  console.log(count,"customDataTableOptionObject")
  return (
    <>
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
      <div className="mui-t-h"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        {filterBadges?.length>0?<p style={{color: "black",border:"0px solid black"}}className={"badge"}>Selected Filter : &nbsp;&nbsp;</p>:""}{filterBadges}
            
        <ThemeProvider theme={getMuiThemeCommon()}>
          <MUIDataTable 
            data={rowData} 
            columns={columns} 
            // options={customDataTableOptionObject(AddCustomButton, false, "RanTracker", '', '',sortedOrder)} 
            // options={customDataTableOptionObject(AddCustomButton,false,"RanTracker","","",false,"ranTracker","",rowData.length,count.totalCount,false)}
            options={rancustomDataTableOptionObject(AddCustomButton,false,"RanTracker", "","",sortedOrder,AccountReducerAction,ranTracker,history.location.search,rowData.length,count.totalCount,[fstartdate,fenddate],OneTimere,setOneTimere,setResetFilter,ResetFilter)}
            
          />

          {}
        </ThemeProvider></div>
    </>
  );
};

export default RanSupportTracker;